import React, { Component, useState } from "react";
import axios from "axios";

import { Card } from "react-bootstrap";
import GetHowToBuy from "./GetHowToBuy";
import "./howToBuy.css";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
const Blog = () => {
  const [changeState, setChangeState] = useState(false);
  const [buyHome, setBuyHome] = useState({
    title: "",
    description: "",
    FormData: {},
  });
  // const [image, setImage] = useState();
  const [file, setFile] = useState();
  const onChange = (e) => {
    setBuyHome({ ...buyHome, [e.target.name]: e.target.value });
  };
  // const handleImage = (e) => {
  //   const file = e.target.files[0];
  //   setFileToBase(file);
  //   console.log(file);
  // };
  // const setFileToBase = (file) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onloadend = () => {
  //     setImage(reader.result);
  //   };
  // };

  const dataFile = new FormData();
  dataFile.append("title", buyHome.title);
  dataFile.append("description", buyHome.description);
  dataFile.append("howToImage", file);

  const [editor, setEditor] = useState({
    editorState: EditorState.createEmpty(),
  });
  const onEditorStateChange = (editorState) => {
    setEditor({
      editorState,
    });
    setBuyHome({
      ...buyHome,
      description: editorState.getCurrentContent().getPlainText(),
    });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/how-to-buy/how-to-buy-house`;
    const data = dataFile;
    axios
      .post(url, data, {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf",
          "admin-token": localStorage.getItem("admin-token"),
        },
      })
      .then((data) => {})
      .catch((error) => {});
    setBuyHome({
      title: "",
      description: "",
      FormData: {},
    });

    setChangeState(!changeState);
    // console.log(dataFile);
  };
  return (
    <div>
      <Card>
        <h1>Add Blog :-</h1>
        <form onSubmit={onSubmit}>
          <div className="form-group green-border-focus">
            <label htmlFor="exampleFormControlTextarea5">Title</label>
            <input
              className="form-control"
              name="title"
              onChange={onChange}
              value={buyHome.title}
              required
            ></input>
          </div>

          <div>
            <label htmlFor="exampleFormControlTextarea5">Description</label>
            <Editor
              editorState={editor.editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={onEditorStateChange}
            />
          </div>

          <div>
            <input
              className="form-control my-2"
              type="file"
              placeholder="Attach Image"
              required
              onChange={(e) => setFile(e.target.files[0])}
              name="cancelAttachedFile"
            />
          </div>
          <button
            type="submit"
            className="btn btn-success btn-icon text-white px-5"
          >
            Submit
          </button>
        </form>
      </Card>
      <GetHowToBuy setChangeState={setChangeState} changeState={changeState} />
    </div>
  );
};

export default Blog;
