import React, { useContext, useState, useEffect } from "react";
import { Button, Modal, ModalBody, Table } from "react-bootstrap";
import AdminContext from "../../../adminContext/AdminContext";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import axios from "axios";
import FileBase64 from "react-file-base64";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import { toast } from "react-toastify";
// import newImage from "../../../assets/images/ZZ5H.gif";
import newImage from "../../../../assets/images/ZZ5H.gif";

const EditBlog = (props) => {
  // console.log(title);

  console.log(props.description);
  const adminContext = useContext(AdminContext);
  const { handleModal, setHandleModal } = adminContext;
  let initialValue = {
    title: props.title,

    _id: props._id,
    blogImage: props.blogImage,
  };

  const [blogEditData, setBlogEditData] = useState(initialValue);

  const onChange = (e) => {
    console.log(e.target.value);
    setBlogEditData((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });
  };
  // to pass the value inside the edito
  let editorState = EditorState.createWithContent(
    ContentState.createFromBlockArray(convertFromHTML(props.description))
  );
  // end
  const [file2, setFile2] = useState();
  const [description, setDescription] = useState(editorState);
  const [description1, setDescription1] = useState('');
  const onEditorStateChange = (editorState) => {
    setDescription(editorState);
  };
  const dataFile = {};
  if (blogEditData.title) {
    dataFile.title = blogEditData.title;
  }
  if (blogEditData.description != undefined) {
    dataFile.description = description1;
  }
  if (blogEditData._id) {
    dataFile._id = blogEditData._id;
  }
  if (blogEditData.blogImage) {
    dataFile.blogImage = blogEditData.blogImage;
  }

  const formData = new FormData();
  if (blogEditData.title) {
    dataFile.title = blogEditData.title;
  formData.append("title", blogEditData.title);
  formData.append("url", blogEditData.title.toLocaleLowerCase().split(' ').join('-'));

}
  if (blogEditData.description != undefined) {
   
  formData.append("description", description1);

  }
  formData.append("blogImage",file2);
  formData.append("_id",blogEditData._id);
 
  

 
  // useEffect(() => {
  //   setBlogEditData(initialValue);
  // }, []);
  const handleFileChange = (e) => {
    setFile2(e.target.files[0]);
    console.log(e.target.files[0]);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    // const url = `${process.env.REACT_APP_BACKEND_DATA}/api/blog/update-blog`;
    // const data = dataFile;
    // console.log(">>>>",data);
    // axios
    //   .put(url, data ,{
    //     responseType: "arraybuffer",
    //     headers: {
    //       Accept: "application/pdf",
    //       "admin-token": localStorage.getItem("admin-token"),
    //     },
    //   })
    //   .then((data) => {
    //     props.setChangeState(!props.changeState);
    //   })
    //   .catch((error) => {});

    axios.put(
      `${process.env.REACT_APP_BACKEND_DATA}/update-blog-new`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
      )
      .then((data) => {
      props.setChangeState(!props.changeState);
      // console.log(data);
    
      toast.success("Blog Updated successfully", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // setEditor("");

      // setChangeState(!changeState);
      // window.location.reload();
    })
    .catch((error) => {
      //(error);
    });
    setHandleModal(false);
  };

  


  // console.log(blogEditData);

      //  let dis = props.description
      //  console.log(dis);
  const contents = props.description
  // const contents=  blogEditData.description.value
  const [img, setjValue] = useState("");
  const [value, setValue] = useState("");
  function uploadImageCallBack(file) {
    return new Promise((resolve, reject) => {
      const url2 = `${process.env.REACT_APP_BACKEND_DATA}/blg-upload`;
      const dataFile = new FormData();

      dataFile.append("img", file);
      // console.log(dataFile);
      axios
        .post(url2, dataFile, {
          // responseType: "arraybuffer",
          headers: {
            Accept: "application/pdf",
          },
        })
        .then((response) => {
          setjValue(response);
          response.json();
          setValue(response);

          //  resolve(response)
        })
        .catch((error) => {});
    });
  }

  // function uploadImageCallBack(file) {
  //   return new Promise((resolve, reject) => {
  //     const xhr = new XMLHttpRequest();
  //     xhr.open("POST", `${process.env.REACT_APP_BACKEND_DATA}/blg-upload`);
  //     const data = new FormData();
  //     data.append("img", file);
  //     xhr.send(data);
  //     xhr.addEventListener("load", () => {
  //       const response = JSON.parse(xhr.responseText);
  //       console.log(response);
  //       resolve(response);
  //     });
  //     xhr.addEventListener("error", () => {
  //       const error = JSON.parse(xhr.responseText);
  //       console.log(error);
  //       reject(error);
  //     });
  //   });
  // }
  function handleChance(e) {
    console.log(e);
    setDescription1(e)
    //   return { ...perState, [e.target.name]: e.target.value };
    // });
    
  }

  console.log(description1);
  return (
    <>
      {(props.title && props.title.length === 0) ||
      (props.description && props.description.length === 0) ? (
        <div
          style={{
            // height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <img style={{ maxWidth: "7%" }} src={newImage} />
        </div>
      ) : (
        <div>
          <form>
            <Modal
              size="xl"
              show={handleModal}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header>
                <Modal.Title>Edit Blog Page</Modal.Title>
                <Button
                  onClick={() => setHandleModal(false)}
                  className="btn-close"
                  variant=""
                >
                  x
                </Button>
              </Modal.Header>
              <Modal.Body>
                <div className="card-blog-modal-body">
                  <form onSubmit={onSubmit}>
                    <div className="form-group green-border-focus">
                      <label htmlFor="exampleFormControlTextarea5">Title</label>
                      <input
                        className="form-control"
                        name="title"
                        value={blogEditData.title}
                        onChange={onChange}
                      ></input>
                    </div>
                    <div className="form-group green-border-focus">
                      <label htmlFor="exampleFormControlTextarea5">
                        Description
                      </label>

                      <img src={img.data ? img.data : ""} />
                      {/* <textarea
                        className="form-control"
                        name="description"
                        value={blogEditData.description}
                        onChange={onChange}
                        id="exampleFormControlTextarea5"
                        rows="3"
                      ></textarea> */}
                      <SunEditor
                        autoFocus={true}
                        placeholders="enter text"
                        // width="560"
                        height="200"  
                        onChange={handleChance}
                        setContents={contents}
                        // getContents
                        toggleDisplayBlocks
                        // convertHTMLForCodeView={blogEditData.description}
                        // setContents={initialContent}
                        // value={draftToHtml(
                        // convertToRaw(description.getCurrentContent())
                        // )}
                        //  ref={(val) => (blogEditData.description = val)}
                        // onChange={handleChance}
                        // onEditorStateChange={onEditorStateChange}
                        // editorState={description}
                        setOptions={{
                          buttonList: [
                            [
                              "bold",
                              "fontSize",
                              "underline",
                              "italic",
                              "strike",
                              "formatBlock",
                              "subscript",
                              "superscript",
                              "fontColor",
                              "outdent",
		               	"indent",
                              "hiliteColor",
                              "paragraphStyle",
                              "textStyle",
                              "align",
                              "horizontalRule",
                              "list",
                              "lineHeight",
                              "table",
                              "image",
                              "video",
                              "audio",
                              "link",
                            ],
                          ],
                        }}
                      />
                      {/* 
                      <Editor
                        editorState={description}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        // toolbarClassName="toolbar-class"
                        value="sdfasdfas"
                        onEditorStateChange={onEditorStateChange}
                        toolbar={{
                          inline: { inDropdown: true },
                          list: { inDropdown: true },
                          textAlign: { inDropdown: true },
                          link: { inDropdown: true },
                          history: { inDropdown: true },
                          image: {
                            uploadCallback: uploadImageCallBack,
                            alt: { present: true, mandatory: true },
                          },
                        }}
                      /> */}
                      <textarea
                        style={{ display: "none" }}
                        disabled
                        ref={(val) => (blogEditData.description = val)}
                        value={draftToHtml(
                          convertToRaw(description.getCurrentContent())
                        )}
                      />
                    </div>
                    <div>
                    <input type="file" 
            onChange={handleFileChange}
            
            className="form-control"/>
            {/* <FileBase64
              className=""
              type="file"
              multiple={false}
              value="dfgh"
              onDone={({ base64 }) =>
                setBlogData({ ...blogData, blogImage: base64 })
              }
            /> */}
          {/* </div> */}
                      {/* <FileBase64
                        type="file"
                        multiple={false}
                        onDone={({ base64 }) =>
                          setBlogEditData({
                            ...blogEditData,
                            blogImage: base64,
                          })
                        }
                      /> */}
                    </div>
                    <button
                      type="submit"
                      className="btn btn-success btn-icon text-white px-5"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </Modal.Body>
            </Modal>
          </form>
        </div>
      )}
    </>
  );
};

export default EditBlog;
