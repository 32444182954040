import React from "react";
import "./Services.css";
import MortgageBroker from "../../../assets/images/homePage/mortgageBroker.jpg";
import PropertyManager from "../../../assets/images/homePage/propertyManager.jpg";
import BuildingInspection from "../../../assets/images/homePage/buildingInspection.jpg";
import Solicitor from "../../../assets/images/homePage/solicitor.jpg";
import Accountant from "../../../assets/images/homePage/accountant.jpg";
import ByersImg from'./byres.jpg'
function Services() {

  
  return (
    <div className="services">
      <h2 className="home-mid-heading">Our Service</h2>
      <span className="service-span"></span>
      <p className="heading-description">
        You'll get one of the top rated experts covering everything you need to
        go through your home journey under one roof
      
      </p>
      <div className="services-container">  
        <div className="services-list">
          <div className="services-list-img">
            <img src={MortgageBroker} alt="About Image" />
          </div>
          <i className="fe fe-map-pin service-list-icon" />
          <h3>Mortgage Broker</h3>
          <p style={{ fontWeight: "900" }}>
            Lending expert will take care of your funding needs
          </p>
          <ul>
            <li>
              <i
                className="fa fa-angle-double-right mx-1"
                style={{ color: "#008eb5" }}
              />
              Have access to more than 40 lenders 
            </li>
            <li>
              <i
                className="fa fa-angle-double-right mx-1"
                style={{ color: "#008eb5" }}
              />
              Assist you to get one of the best rates and best products 
            </li>
            <li>
              <i
                className="fa fa-angle-double-right mx-1"
                style={{ color: "#008eb5" }}
              />
              Does all the groundwork for you, saving your time
            </li>
          </ul>
        </div>
        <div className="services-list">
          <div className="services-list-img">
            <img src={ByersImg} alt="About Image" />
          </div>
          <i className="fe fe-map-pin service-list-icon" />
          <h3>Buyers Advocate</h3>
          <p style={{ fontWeight: "900" }}>
           A licensed and experienced buyers advocate will
          </p>
          <ul>
            <li>
              <i
              
                className="fa fa-angle-double-right mx-1"
                style={{ color: "#008eb5" }}
              />
              Search the right property for you
            </li>
            <li>
              <i
                className="fa fa-angle-double-right mx-1"
                style={{ color: "#008eb5" }}
              />
              Negotiate/Bid on your behalf 
            </li>
            <li>
              <i
                className="fa fa-angle-double-right mx-1"
                style={{ color: "#008eb5" }}
              />
              Hand hold you throughout the buying process
            </li>
          </ul>
        </div>
        <div className="services-list">
          <div className="services-list-img">
            <img alt="About Image" src={Solicitor} />
          </div>{" "}
          <i className="fe fe-map-pin service-list-icon" />
          <h3>Solicitor/Conveyancer</h3>
          <p style={{ fontWeight: "900" }}>
            Legal expert will ensure all the legal requirements are met
          </p>
          <ul>
            <li>
              <i
                className="fa fa-angle-double-right mx-1"
                style={{ color: "#008eb5" }}
              />
              Cunduct the relavant searches
            </li>
            <li>
              <i
                className="fa fa-angle-double-right mx-1"
                style={{ color: "#008eb5" }}
              />
              Exchange contracts
            </li>
            <li>
              <i
                className="fa fa-angle-double-right mx-1"
                style={{ color: "#008eb5" }}
              />
              Transfer ownership
            </li>
          </ul>
        </div>
        <div className="services-list">
          <div className="services-list-img">
            <img alt="About Image" src={BuildingInspection} />
          </div>{" "}
          <i className="fe fe-map-pin service-list-icon" />
          <h3>Building and Pest Inspection</h3>
          <p style={{ fontWeight: "900" }}>
            Expert inspector will help you by identifying if the house has any 
          </p>
          <ul>
            <li>
              <i
                className="fa fa-angle-double-right mx-1"
                style={{ color: "#008eb5" }}
              />
              Structural issues
            </li>
            <li>
              <i
                className="fa fa-angle-double-right mx-1"
                style={{ color: "#008eb5" }}
              />
               Unsafe areas and repair recommendations            </li>
            <li>
              <i
                className="fa fa-angle-double-right mx-1"
                style={{ color: "#008eb5" }}
              />
               Current or potential Pest damages
            </li>
          </ul>
        </div>

        <div className="services-list">
          <div className="services-list-img">
            <img
              src="https://themes.hibootstrap.com/levix/wp-content/uploads/2021/09/advisor2.jpg"
              alt="About Image"
            />
          </div>{" "}
          <i className="fe fe-map-pin service-list-icon" />
          <h3>Depreciation Schedule </h3>
          <p style={{ fontWeight: "900" }}>
            Expert quantity surveyer will assess wear and tear of your property
            and produces .
          </p>
          <ul>
            <li>
              <i
                className="fa fa-angle-double-right mx-1"
                style={{ color: "#008eb5" }}
              />
              One of the highest tax savings schedule.
            </li>
            <li>
              <i
                className="fa fa-angle-double-right mx-1"
                style={{ color: "#008eb5" }}
              />
              ATO compliant.
            </li>
            <li>
              <i
                className="fa fa-angle-double-right mx-1"
                style={{ color: "#008eb5" }}
              />
              Fast delivery of simple and easy to read report .
            </li>
          </ul>
        </div>
        <div className="services-list">
          <div className="services-list-img">
            <img alt="About Image" src={PropertyManager} />
          </div>{" "}
          <i className="fe fe-map-pin service-list-icon" />
          <h3>Property Manager </h3>
          <p style={{ fontWeight: "900" }}>
            Local, highly rated property manager will take care of your property
            like their own by .
          </p>
          <ul>
            <li>
              <i
                className="fa fa-angle-double-right mx-1"
                style={{ color: "#008eb5" }}
              />
              Helping you chose the right tenant.
            </li>
            <li>
              <i
                className="fa fa-angle-double-right mx-1"
                style={{ color: "#008eb5" }}
              />
              Periodic inspections and compliance to legislatations.
            </li>
            <li>
              <i
                className="fa fa-angle-double-right mx-1"
                style={{ color: "#008eb5" }}
              />
              On time rent transfers and assessments to increase rent .
            </li>
          </ul>
        </div>
        <div className="services-list">
          <div className="services-list-img">
            <img alt="About Image" src={Accountant} />
          </div>
          <i className="fe fe-map-pin service-list-icon" />
          <h3>Accountant</h3>
          <p style={{ fontWeight: "900" }}>
            Specialist in both individual and property tax returns who will .
          </p>
          <ul>
            <li>
              <i
                className="fa fa-angle-double-right mx-1"
                style={{ color: "#008eb5" }}
              />
              Help you save immediate tax get.
            </li>
            <li>
              <i
                className="fa fa-angle-double-right mx-1"
                style={{ color: "#008eb5" }}
              />
              Maximum tax deductions provide .
            </li>
            <li>
              <i
                className="fa fa-angle-double-right mx-1"
                style={{ color: "#008eb5" }}
              />
              Strategies to reduce future taxes .
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Services);
