import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

function BuildingInsurance() {
    const navigate = useNavigate();

    useEffect(() => {
      if (!localStorage.getItem("token")) {
        navigate("/login");
      }
    },[]);
 return (
  <div>
   <Card className="mt-9 pt-7 pb-0 col-md-7">
    <Card.Header className="mx-auto" style={{ textAlign: "center" }}>
     <h2>Building Insurance </h2>
    </Card.Header>
    <Card.Body>
     <textarea
      className="border block col-md-9 mt-6 mx-auto"
      style={{ height: "100px", boxShadow: "#aeacac 4px 4px 7px" }}
      name="text"
      id="mortgageMessage"
      cols="30"
      rows="10"
     ></textarea>
     <div className="mt-8" style={{ textAlign: "center" }}>
      <Link to="/application-dashboard">
       <button className="btn btn-primary btn-icon text-white mx-3 mb-3 col-md-5">Go Back To The Application Dashboard</button>
      </Link>
     </div>
    </Card.Body>
   </Card>
  </div>
 );
}

export default BuildingInsurance;
