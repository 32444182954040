import React, { useState, useContext, useEffect } from "react";
import { Card, Form } from "react-bootstrap";
import AdminContext from "../../adminContext/AdminContext";
import FeeHistory from "./feeHistoryAndEdit/FeeHistory";
import newImage from "../../../assets/images/ZZ5H.gif";

function FeeDetails() {
  const initialValue = {
    type: "",
    fee: "",
  };
  const [formInput, setFormInput] = useState(initialValue);
  const [incomingData, setIncomingData] = useState([]);
  const dashboardContext = useContext(AdminContext);
  const { handleFeeDetailsApi, dummystate } = dashboardContext;

  // setUsersData(table);
  const callingApiForContext = async () => {
    setIncomingData(await handleFeeDetailsApi());
  };
  useEffect(() => {
    callingApiForContext();
  }, [dummystate]);

  const handleChange = (e) => {
    setFormInput((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });
  };

  // console.log(incomingData);
  return (
    <>
      {incomingData.length === 0 ? (
        <div
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img style={{ maxWidth: "7%" }} src={newImage} />
        </div>
      ) : (
        <div className="mt-6">
          <div>
            <Card className="mb-5">
              <Card.Header>
                <h3 className="card-title">View / Update Fee</h3>
              </Card.Header>
              <div className="card-body pt-2">
                <Form.Group>
                  <div className=" row mb-4">
                    <label htmlFor="inputName" className="col-md-3 form-label">
                      Category
                    </label>
                    <div className="col-md-9">
                      <select
                       style={{background: "#cef0ef"}}
                        name="type"
                        value={formInput.type}
                        className="form-select"
                        onChange={handleChange}
                      >
                        <option value="">Select Category</option>
                        <option value="Solicitor">Solicitor</option>
                        <option value="Conveyancer">Conveyancer</option>
                        <option value="Building Inspection">
                          Building and Pest Inspection
                        </option>
                        <option value="Depreciation Schedule">
                          Depreciation schedule
                        </option>
                      </select>
                    </div>
                  </div>
                </Form.Group>
              </div>
            </Card>
            <FeeHistory formInput={formInput} incomingData={incomingData} />
          </div>
        </div>
      )}
    </>
  );
}

export default FeeDetails;
