import React from "react";
import HomeImage from "../../assets/images/homePage/pic1.jpg";
import HomeImage2 from "../../assets/images/homePage/pic2.jpg";
import HomeImage3 from "../../assets/images/homePage/pic3.jpg";
import HomeImage4 from "../../assets/images/homePage/pic4.jpg";
import { Link } from "react-router-dom";

import Slider from "react-slick";

import "./Home.css";

const HomeTop = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,

    arrows: false,
  };
  return (
    <div>
      <div className="home-top">
        <div className="home-top-aside1">
          <span>
            <h1 className="home-top-title">
              Just<span style={{ color: "var(--blue)" }}>nests</span> Being with
              you in your dream home journey!
            </h1>
            {/* <p>
      Fugiat laborum incididunt do ea nisi qui aliqua sint adipisicing
      cillum incididunt sint est.Voluptate adipisicing excepteur laborum
      id nisi minim irure mollit enim.
    </p> */}
            <Link to="/register" className="i-want-to-buy-button">
              <button> Sign up free</button>
            </Link>
          </span>
        </div>


        <Slider {...settings} className="home-top-aside2  d-none-tap" >
          <img src={HomeImage} alt={HomeImage}  class="img2"/>
          <img src={HomeImage2} alt={HomeImage}  class="img2"/>
          <img src={HomeImage3} alt={HomeImage}  class="img2"/>
          <img src={HomeImage4} alt={HomeImage}  class="img2"/>
        </Slider>

      </div>
    </div>
  );
};

export default React.memo(HomeTop);
