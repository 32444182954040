import "./App.css";
import axios from 'axios';


import React  from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../src/sitePages/home/Home";
import ContactUs from "./sitePages/contactUs/ContactUs";
import AdminMain from "./Admin/AdminMain";
import AdminLogin from "./Admin/AdminLogin";
import Header from "./component/Header/Header";
import UserLogin from "./User/login/Login/UserLogin";
import UserRegister from "./User/Register/UserRegister";
import UserDashboard from "./User/UserDasboard/UserDashboard";
import ApplicationDashboard from "./User/ApplicationDashboard/ApplicationDashboard";
import ApplicationData from "./User/ApplicationData/ApplicationData";
import UserState from "./User/UserContext/UserState";
import GetLoan from "./User/GetLoan/GetLoan";
import UserApiState from "./User/UserApi/UserApiState";
import EngageSolicitorMain from "./User/EngageSolicitor/EngageSolicitorMain";
import LegalPayment from "./User/LegalPayment/LegalPayment";
import BuildingInspection from "./User/ApplicationDashboard/optionPages/BuildingInsurance";
import LandlordInspection from "./User/ApplicationDashboard/optionPages/LandlordInsurance";
import Profile from "./User/Profile/Profile";
import BuildingPest from "./User/BuildingPest/BuildingPest";
import ScrollToTop from "./scrollTop/ScrollTop";
import PropertyManager from "./User/PropertyManager/PropertyManager";
import DepreciationSchedule from "./User/DepreciationSchedule/DepreciationSchedule";
import EngageAccountant from "./User/EngageAccountant/EngageAccountant";
import AdminState from "./Admin/adminContext/AdminState";

import ForgotPassword from "./User/login/forgotPassword/ForgotPassword";
import AdminForgotPassword from "./Admin/pages/forgotPassword/AdminForgotPassword";
import ResetPassword from "./User/login/forgotPassword/ResetPassword";
import AdminResetPassword from "./Admin/pages/forgotPassword/AdminResetPassword";
import AboutUs from "./sitePages/aboutUs/AboutUs";
import BlogPage from "./sitePages/blogsPage/BlogPage";
import PrivacyAndPolicy from "./User/PrivacyAndPolicy/PrivacyAndPolicy";
import TermAndCondition from "./User/TermAndCondition/TermAndCondition";
import AutoComplete from "./AutoComplete";
import BlogAfter from "./sitePages/blogsPage/BlogAfter";
import HowToBuyBlogPage from "./sitePages/blogsPage/HowToBuyBlogPage";
import FAQ from "./User/FAQ/FAQ";
import HowItWork from "./User/HowItWork/HowItWork";
import NeedHelp from "./sitePages/needHelp/NeedHelp"
import BlogSingle from "./sitePages/blogsPage/BlogSingle"
import Promocode from "./Admin/pages/promocode/Promocode";
import Accounting from "./sitePages/Accounting/Accounting";
import Buyers from "./User/BuyersAdvocate/Buyers";

function App() {
  const [incomingData, setIncomingData] = React.useState();

  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_DATA}/get-all-blog-index`)
      .then(response => {
        setIncomingData(response.data);
        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);
  console.log(incomingData);
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <UserApiState>
          <AdminState>
            <UserState>
              <Header />
              <div className="app-wrapper">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/about-us" element={<AboutUs />} />
                  <Route path="/contact-us" element={<ContactUs />} />
                  <Route path="/accounting" element={<Accounting/>} />
                  <Route path="/need-help" element={<NeedHelp />} />
                  <Route path="/blog-page" element={<BlogPage incomingData={incomingData} />} />
                  <Route path="/blog-after" element={<BlogAfter />} />
                  <Route path="/login" element={<UserLogin />} />
                  <Route path="/admin" element={<AdminLogin />} />
                  <Route path="/admin/*" element={<AdminMain />} />
                  <Route path="/register" element={<UserRegister />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route
                    path="/admin-forgot-password"
                    element={<AdminForgotPassword />}
                  />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route
                    path="/admin-reset-password"
                    element={<AdminResetPassword />}
                  />
                  <Route path="/dashboard" element={<UserDashboard />} />
                  {/* <Route path="//admin/promocode/promocode" element={<Promocode/>} /> */}
                  <Route
                    path="/application-dashboard"
                    element={<ApplicationDashboard />}
                  />
                  <Route
                    path="/application-data"
                    element={<ApplicationData />}
                  />
                  <Route
                    path="/solicitor-conveyancer"
                    element={<EngageSolicitorMain />}
                  />
                  <Route path="/get-loan" element={<GetLoan />} />
                  <Route path="/buyers-advocate" element={<Buyers/>} />

                  <Route
                    path="/building-insurance"
                    element={<BuildingInspection />}
                  />
                  <Route
                    path="/landlord-insurance"
                    element={<LandlordInspection />}
                  />

                  <Route path="/profile" element={<Profile />} />
                  <Route
                    path="/building-pest-inspection"
                    element={<BuildingPest />}
                  />
                  <Route
                    path="/property-manager"
                    element={<PropertyManager />}
                  />
                  <Route
                    path="/depreciation-schedule"
                    element={<DepreciationSchedule />}
                  />
                  <Route
                    path="/privacy-policy"
                    element={<PrivacyAndPolicy />}
                  />
                  <Route
                    path="/terms-and-conditions"
                    element={<TermAndCondition />}
                  />
                  <Route
                    path="/engage-accountant"
                    element={<EngageAccountant />}
                  />
                  <Route path="/F-A-Q" element={<FAQ />} />
                  <Route path="/how-it-works" element={<HowItWork />} />

                  <Route path="/legal-payment" element={<LegalPayment />} />
                  <Route path="/how-to-buy" element={<HowToBuyBlogPage />} />
                  <Route path="/testing" element={<AutoComplete />} />
                  <Route path="*" element={<Home />} />
             
                  <Route path="/just-blogs/:url" element={<BlogSingle />} />
              
         
                </Routes>
              </div>
            </UserState>
          </AdminState>
        </UserApiState>
      </BrowserRouter>
    </>
  );
}

export default App;
