import React, { useEffect, useState } from "react";
import { Link, useNavigate ,useParams} from "react-router-dom";
import axios from "axios";
import ReactPaginate from "react-paginate";

import "./BlogPage.css";
import Footer from "../../component/footer/Footer";
import HowToBuyHome from "../../assets/images/homePage/howToBuyHome.jpg";
import newImage from "../../assets/images/ZZ5H.gif";
import BlogMore from "./BlogMore";
const BlogPage = () => {
  const [incomingData, setIncomingData] = useState();
  console.log(incomingData);
  
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;
  useEffect(() => {
    async function fetchData() {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_DATA}/get-all-blog-index`);
      const jsonData = await response.json();
      setIncomingData(jsonData.reverse());
    }
    
    fetchData();
    const items = incomingData; 
    
    
      
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(incomingData && incomingData.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(incomingData && incomingData.length / itemsPerPage));
  }, [itemOffset, itemsPerPage,currentItems]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % incomingData.length;
    setItemOffset(newOffset);
  };
  console.log(currentItems);
  return (
    <>
      
         {incomingData ?  <BlogMore incomingData={incomingData}/>
         :
         <div
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img style={{ maxWidth: "7%" }} src={newImage} />
        </div>}
          <Footer />
    </>
  );
};

export default BlogPage;
