import React, { useState } from "react";
import { toast } from "react-toastify";
import Footer from "../../component/footer/Footer";
import "./NeedHelp.css";

function NeedHelp() {
 const initialInput = {
  name: "",
  email: "",
  number: "",
  subject: "",
  message: "",
 };
 const [formInput, setFormInput] = useState(initialInput);
 const [error2, setError2] = useState(false);
 const [error4, setError4] = useState(false);
 const handleChange = (e) => {
  setFormInput((perState) => {
   return { ...perState, [e.target.name]: e.target.value };
  });
 };
 const handleSubmit = (e) => {
  e.preventDefault();
  if (formInput.email === initialInput.email) {
   setError2(true);
  }
  if (formInput.subject === initialInput.subject) {
   setError4(true);
  } else {
   //  const url = `${process.env.REACT_APP_BACKEND_DATA}/api/admin/admin-update`;
   //   const data = {};
   //   axios
   //     .post(url, data, {
   //       headers: {
   //         Accept: "application/json",
   //         "Content-Type": "application/json",
   //         "admin-token": localStorage.getItem("admin-token"),
   //       },
   //     })
   //     .then((response) => {
   //   setFormInput(initialInput);
   //   toast.success("Thanks for Contacting Us", {
   //       position: "top-center",
   //       autoClose: 2000,
   //       hideProgressBar: false,
   //       closeOnClick: true,
   //       pauseOnHover: true,
   //       draggable: true,
   //       progress: undefined,
   //     });
   //    setTimeout(() => {
   //     setError2(false);
   //     setError4(false);
   //    }, 4000);
   //   })
   //     .catch((error) => {});
   //   setFormInput(initialValue);
   //   setError(false);
  }
 };
 return (
  <div className="need-help">
   <div className="need-help-container">
    <div>
     <div className="need-help-heading">
      <h6>Need Help ?</h6>
      <h3>Support Request</h3>
      <span className="service-span" style={{ margin: "0" }}></span>
     </div>
     <form onSubmit={handleSubmit}>
      <div className="need-help-form-group">
       <div className="need-help-form-list">
        <div className="form-label">
         Email :{" "}
         <span
          style={{
           color: "red",
           fontSize: "13px",
           display: error2 ? "" : "none",
          }}
         >
          (Input Required)
         </span>
        </div>
        <input
         type="email"
         className="form-control"
         onChange={handleChange}
         placeholder="Email*"
         name="email"
         value={formInput.email}
        />
       </div>
      </div>
      <div className="need-help-form-group">
       <div className="need-help-form-list">
        <div className="form-label">
         Subject :{" "}
         <span
          style={{
           color: "red",
           fontSize: "13px",
           display: error4 ? "" : "none",
          }}
         >
          (Input Required)
         </span>
        </div>
        <input
         className="form-control"
         onChange={handleChange}
         placeholder="Subject*"
         name="subject"
         value={formInput.subject}
        />
       </div>
      </div>
      <div className="need-help-form-group">
       <div className="need-help-form-list">
        <div className="form-label" name="message">
         Your Request Detail :{" "}
        </div>
        <textarea
         className="form-control"
         onChange={handleChange}
         placeholder="Your request"
         name="message"
         rows="4"
         cols="150"
         value={formInput.message}
        />
       </div>
      </div>
      <button className="btn btn-primary need-help-btn" type="submit">
       Send Request
      </button>
     </form>
    </div>
    <div></div>
   </div>
   <Footer />
  </div>
 );
}

export default NeedHelp;
