import React, { useState, useEffect, useRef, useContext } from "react";
import UserApiContext from "../UserApi/UserApiContext";
import { Card, Button, Row, Modal } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
// import short_id from 'shortid';
import { toast } from "react-toastify";

import axios from "axios";
import "./BuildingPest.css";
import "react-toastify/dist/ReactToastify.css";
import Updateaddress from "../ApplicationDashboard/Updateaddress";
const BuildingPest = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, []);

  //............promo data..................//
  const [promodata, setPromodata] = useState(0);
  const [allpromodata, setAllpromodata] = useState();
  const [promoApplied, setpromoApplied] = useState("false");
  const [userPromoCode, setUserPromo] = useState();

  const sendusedpromodata = () => {
    let promoName = allpromodata.promoName;
    let promoCode = userPromoCode;
    let service = "Receive Building / Pest";
    let custumerName = tokendata.name;
    let custumerId = tokendata.user_id;
    let Ammount = allpromodata.discountAmount;
    // let custumerId = userInfo.email;
    let companyName = tokendata.agencyName;
    axios
      .post(`${process.env.REACT_APP_BACKEND_DATA}/used-promo`, {
        promoCode,
        promoName,
        service,
        custumerId,
        custumerName,
        companyName,
        Ammount,
        id,
      })
      .then((response) => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const validateuserPromo = () => {
    let userPromo = userPromoCode;
    let userState = tokendata.lookingToBuy;
    let userServise = "Receive Building / Pest";

    axios
      .post(`${process.env.REACT_APP_BACKEND_DATA}/verify-promo`, {
        userPromo,
        userState,
        userServise,
      })
      .then((response) => {
        console.log(response);
        if (response.data.promodata) {
          setPromodata(response.data.promodata.discountAmount);
          setpromoApplied("true");
          setAllpromodata(response.data.promodata);
          // sendusedpromodata()
        }
        if (response.data.msg == "Successfully Applied Promocode") {
          toast.success(`${response.data.msg}`, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(response.data.msg, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const location = useLocation();
  const [paymentAmount, setpaymentAmount] = useState();
  const refClose = useRef(null);
  const [tokendata, settokendata] = useState([]);
  const UserApi = useContext(UserApiContext);
  const { feeDetails, sendDashboardData, callingTaskMailer, loggedInUserData } =
    UserApi;

  let initialValue = {
    propertyAddress: tokendata.address && tokendata.address,
    propertyState: tokendata.propertyState && tokendata.propertyState,
    sellerAgent: tokendata.agentName && tokendata.agentName,
    sellerAgentPhone: tokendata.agentPhone && tokendata.agentPhone,
    sellerAgentEmail: tokendata.agentEmail && tokendata.agentEmail,
  };
  // console.log(tokendata);

  const [editable, setEditable] = useState({
    propertyAddress: "",
    propertyState: "",
    sellerAgent: "",
    sellerAgentPhone: "",
    sellerAgentEmail: "",
    paymentAmount: "",
  });
  const id = location.state.id;
  const [show3, setShow3] = useState(false);
  // to get the logged in user application data
  const [first, setFirst] = useState();
  const updateData = async (data) => {
    setFirst(editable);

    const objid = location.state.id;

    console.log(editable);
    const AgentName =
      editable.sellerAgent == ""
        ? initialValue.sellerAgent
        : editable.sellerAgent;
    const AgentEmail =
      editable.sellerAgentEmail == ""
        ? initialValue.sellerAgentEmail
        : editable.sellerAgentEmail;
    const AgentNumber =
      editable.sellerAgentPhone == ""
        ? initialValue.sellerAgentPhone
        : editable.sellerAgentPhone;

    if (AgentNumber.charAt(0) == "0" && AgentNumber.length == 10) {
      let result = await fetch(
        `${process.env.REACT_APP_BACKEND_DATA}/agent-details`,
        {
          method: "post",

          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ objid, AgentName, AgentEmail, AgentNumber }),
        }
      );
      toast.success("Updated successfully", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      window.location.reload();
    } else {
      toast.error("Enter 10 digits number starting with 0", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    refClose.current.click();
  };

  //============================================================================
  const [AgentName, setAgentName] = useState("");
  const [AgentEmail, setAgentEmail] = useState("");
  const [AgencyName, setAgencyName] = useState("");
  const [AgentNumber, setAgentNumber] = useState("");

  console.log(AgentName, AgentEmail, AgentNumber, tokendata);
  const AgentDetails = async () => {
    let objid = id;
    if (
      (AgentNumber && AgentNumber.length >= 1 && AgentNumber.length < 10) ||
      AgentNumber.length > 10
    ) {
      toast.error("Enter 10 digits number", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      if (AgentNumber.charAt(0) == "0") {
        let result = await fetch(
          `${process.env.REACT_APP_BACKEND_DATA}/agent-details`,
          {
            method: "post",

            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              objid,
              AgencyName,
              AgentName,
              AgentEmail,
              AgentNumber,
            }),
          }
        );
        result = await result.json();
        toast.success("Updated successfully", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        handleClose();
        window.location.reload();
      } else {
        toast.error("Enter 10 digits number starting with 0", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  //======================================================================================
  const findAddress = async () => {
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/application/view-single-application/${id}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      });

      settokendata(response.data);
    } catch (error) {}
  };

  console.log(tokendata);
  //Finding state so that we can set the price according to the state
  let findState = "";
  if (tokendata.propertyState == "N/A") {
    findState = tokendata.lookingToBuy;
  }
  if (tokendata.lookingToBuy == "N/A") {
    findState = tokendata.propertyState;
  }
  const [allfeeDetails, setAllFeeDetails] = useState([]);
  const findFeeDetails = async () => {
    setAllFeeDetails(await feeDetails());
    // bedroomPrice = feeDetails && allfeeDetails[2].feeDetails;
    // console.log(allfeeDetails);
  };

  let newData = [];
  const newArrayObj = () => {
    {
      allfeeDetails &&
        allfeeDetails.map((data) => {
          data.feeDetails.map((item) => {
            if (item.partnerState == findState) {
              return newData.push(item);
            }
          });
        });
    }
  };
  newArrayObj();
  useEffect(() => {
    findFeeDetails();
    findAddress();
  }, [first]);
  let bedroomPrice = newData[2];
  const [show, setShow] = useState(false);

  const editDiv = () => {
    setShow(true);
  };

  const handleChange = (e) => {
    setEditable({ ...editable, [e.target.name]: e.target.value });
  };

  const reportData = {
    price1: "",

    price2: "",

    price3: "",
  };

  const findPrice = (data) => {
    setpaymentAmount(data);
    console.log(data);
    console.log(paymentAmount);
    // paymentAmount:paymentAmount,
  };

  let initialmessage = { message: "" };
  const [message, setMessage] = useState(initialmessage);

  const onChange = (e) => {
    setMessage({ ...message, [e.target.name]: e.target.value });
  };
  let todayDate = Date.parse(new Date());
  const [findType, setFindType] = useState("");

  const [findBedRoomSize, setFindBedRoomSize] = useState("");

  if (findBedRoomSize == "One") {
    reportData.price1 = bedroomPrice.oneB;
    reportData.price2 = bedroomPrice.oneP;
    reportData.price3 = bedroomPrice.oneC;
  }
  if (findBedRoomSize == "Two") {
    reportData.price1 = bedroomPrice.twoB;
    reportData.price2 = bedroomPrice.twoP;
    reportData.price3 = bedroomPrice.twoC;
  }
  if (findBedRoomSize == "Three") {
    reportData.price1 = bedroomPrice.threeB;
    reportData.price2 = bedroomPrice.threeP;
    reportData.price3 = bedroomPrice.threeC;
  }
  if (findBedRoomSize == "Four") {
    reportData.price1 = bedroomPrice.fourB;
    reportData.price2 = bedroomPrice.fourP;
    reportData.price3 = bedroomPrice.fourC;
  }
  if (findBedRoomSize == "PlusFour") {
    reportData.price1 = bedroomPrice.plusFourB;
    reportData.price2 = bedroomPrice.plusFourP;
    reportData.price3 = bedroomPrice.plusFourC;
  }

  const [userInfo, setUserInfo] = useState([]);

  const Authtoken = localStorage.getItem("token");
  const token = async () => {
    const newData = await loggedInUserData(Authtoken);

    setUserInfo(newData);
  };
  useEffect((e) => {
    token();
  }, []);
  const [color, setcolor] = useState();
  // console.log(color);
  const [active, setActive] = useState(true);
  //Sending data to the server
  const buildingAndPestSubmit = (item) => {
    const newData = {
      message: item.applicationType.message,
      name: userInfo.name,
      email: userInfo.email,
      phone: userInfo.phone,
      progress: "In Progress",
      paymentAmount: paymentAmount,
      type: item.applicationType.type,
      state: item.applicationType.selectState,
      createdDate: item.applicationType.createdDate,
      bedRoomSize: item.applicationType.bedRoomSize,
      taskId: item.applicationType.task_id,
    };

    sendDashboardData(newData, id);
    setActive(false);

    // callin mail api
    const mailData = {};
    mailData.customerName = tokendata.name;
    mailData.requestType = newData.type;
    mailData.taskid = newData.taskId;
    mailData.custId = id;
    mailData.Ammount = allpromodata != undefined ?allpromodata.discountAmount :"N/A";

    mailData.customerId = tokendata.user_id;
    mailData.customerEmailId = tokendata.email;
    mailData.customerPhoneNumber = tokendata.phone;
    mailData.purchaseType = tokendata.propertyPlan;
    mailData.propertyType = tokendata.propertType;
    mailData.propertyAddress = tokendata.address;
    if (tokendata.lookingToBuy != "N/A") {
      mailData.propertyState = tokendata.lookingToBuy;
    } else {
      mailData.propertyState = tokendata.propertyState;
    }
    mailData.instructionFromCustomer = newData.message;
    if (promoApplied == "true") {
      sendusedpromodata();
    }
    callingTaskMailer(mailData);
  };
  console.log(initialValue);
  return (
    <>
      {active ? (
        <div>
          <Card className="d-flex">
            <Row className="row row-sm">
              <div className="col-md-5">
                <Card.Header className="building-pest-bedroom-heading ">
                  <h3>How many bedrooms are there in the property ?</h3>
                </Card.Header>
                <Card.Body>
                  <button
                    className=" btn block building-pest-room-button col-md-6"
                    style={{
                      margin: "10px auto",
                      backgroundColor:
                        findBedRoomSize == "One" ? "#52ab6b" : "#7896cc",
                    }}
                    onClick={() => setFindBedRoomSize("One")}
                  >
                    One
                  </button>
                  <button
                    className="btn   block building-pest-room-button col-md-6"
                    style={{
                      margin: "10px auto",
                      backgroundColor:
                        findBedRoomSize == "Two" ? "#52ab6b" : "#7896cc",
                    }}
                    onClick={() => setFindBedRoomSize("Two")}
                  >
                    Two
                  </button>
                  <button
                    className=" btn block building-pest-room-button col-md-6"
                    style={{
                      margin: "10px auto",
                      backgroundColor:
                        findBedRoomSize == "Three" ? "#52ab6b" : "#7896cc",
                    }}
                    onClick={() => setFindBedRoomSize("Three")}
                  >
                    Three
                  </button>
                  <button
                    className="btn block building-pest-room-button col-md-6"
                    style={{
                      margin: "10px auto",
                      backgroundColor:
                        findBedRoomSize == "Four" ? "#52ab6b" : "#7896cc",
                    }}
                    onClick={() => setFindBedRoomSize("Four")}
                  >
                    Four
                  </button>
                  <button
                    className=" btn block building-pest-room-button col-md-6"
                    style={{
                      margin: "10px auto",
                      backgroundColor:
                        findBedRoomSize == "PlusFour" ? "#52ab6b" : "#7896cc",
                    }}
                    onClick={() => setFindBedRoomSize("PlusFour")}
                  >
                    More Then Four
                  </button>
                </Card.Body>
              </div>
              <div className="col-md-7">
                <div className="row">
                  <div className="col-6">
                    <Card.Header className="d-flex  align-items-center">
                      <h3 style={{ marginRight: "1rem" }}>Property details</h3>
                      <i
                        className="fe fe-edit-3"
                        style={{
                          fontSize: "1.6rem",
                          marginTop: "-1.2rem",
                          cursor: "pointer",
                        }}
                        onClick={editDiv}
                      />

                      <Modal show={show}>
                        <Modal.Body>
                          {/* <div className="d-flex mb-3 ">
                        <label className="m-2 col-md-3">
                          Property address:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="propertyAddress"
                          onChange={handleChange}
                          defaultValue={initialValue.propertyAddress}
                        />
                      </div>
                      <div className="d-flex mb-3 ">
                      <label className="m-2 col-md-3">
                          State
                        </label>
                        <input
                          className="form-control"
                          name='propertyState'
                          defaultValue={
                            tokendata.lookingToBuy !== 'N/A'
                              ? tokendata.lookingToBuy
                              : tokendata.propertyState
                          }
                        />
                      </div> */}
                          <div className="d-flex mb-3 ">
                            <label className="m-2 col-md-3">
                              Seller Agent:
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="sellerAgent"
                              onChange={handleChange}
                              defaultValue={initialValue.sellerAgent}
                            />
                          </div>
                          <div className="d-flex mb-3 ">
                            <label className="m-2 col-md-3">
                              Seller Agent Phone:
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              name="sellerAgentPhone"
                              onChange={handleChange}
                              defaultValue={initialValue.sellerAgentPhone}
                            />
                          </div>
                          <div className="d-flex mb-3 ">
                            <label className="m-2 col-md-3">
                              Seller Agent Email:
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="sellerAgentEmail"
                              onChange={handleChange}
                              defaultValue={initialValue.sellerAgentEmail}
                            />
                          </div>
                        </Modal.Body>
                        <div className="d-inline-block text-center">
                          <Button
                            className="m-3 col-md-4"
                            onClick={() => {
                              updateData();
                            }}
                          >
                            Update{" "}
                          </Button>
                          <Button
                            ref={refClose}
                            className="m-3 col-md-4"
                            onClick={() => setShow(false)}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Modal>
                    </Card.Header>
                    <Card.Body>
                      {/* <div className="building-inspection-form-input mb-4">
                    <div
                      className="building-inspection-form-inner"
                      style={{ width: '30%' }}
                    >
                      State:
                    </div>
                    <div className="p-1 border building-inspection-form-input">
                      <input
                        type="text"
                        name="propertyState"
                        readOnly
                           
                        value={ tokendata.lookingToBuy !== 'N/A'
                        ? tokendata.lookingToBuy
                        : tokendata.propertyState}
                        style={{ width: '100%' }}
                      />
                    </div> */}
                      {/* </div> */}
                      <div className="building-inspection-form-input mb-4">
                        <div
                          className="building-inspection-form-inner"
                          style={{ width: "30%" }}
                        >
                          Seller Agent:
                        </div>
                        <div className="p-1 border building-inspection-form-input">
                          <input
                            type="text"
                            name="sellerAgent"
                            readOnly
                            value={initialValue.sellerAgent}
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                      <div className="building-inspection-form-input mb-4">
                        <div
                          className="building-inspection-form-inner"
                          style={{ width: "30%" }}
                        >
                          Seller Agent Phone:
                        </div>
                        <div className="p-1 border building-inspection-form-input">
                          <input
                            type="text"
                            name="sellerAgentPhone"
                            readOnly
                            value={initialValue.sellerAgentPhone}
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                      <div className="building-inspection-form-input mb-4">
                        <div
                          className="building-inspection-form-inner"
                          style={{ width: "30%" }}
                        >
                          Seller Agent Email:
                        </div>
                        <div className="p-1 border building-inspection-form-input">
                          <input
                            type="text"
                            name="sellerAgentEmail"
                            readOnly
                            value={initialValue.sellerAgentEmail}
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                    </Card.Body>
                  </div>
                  <div className="col-6">
                    <Card.Header>
                      <h3>Address details </h3>
                      <i
                        className="fe fe-edit-3"
                        style={{
                          fontSize: "1.6rem",
                          marginTop: "-1.2rem",
                          cursor: "pointer",
                          marginLeft: "15px",
                        }}
                        onClick={() => setShow3(true)}
                      />
                    </Card.Header>
                    <Card.Body>
                      <div className="building-inspection-form-input mb-4">
                        <div
                          className="building-inspection-form-inner"
                          style={{ width: "30%" }}
                        >
                          State:
                        </div>
                        <div className="p-1 border building-inspection-form-input">
                          <input
                            type="text"
                            name="propertyState"
                            readOnly
                            value={
                              tokendata.lookingToBuy !== "N/A"
                                ? tokendata.lookingToBuy
                                : tokendata.propertyState
                            }
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>

                      <div
                        className="building-inspection-form-inner "
                        style={{ width: "100%" }}
                      >
                        Property address:
                      </div>

                      {show3 ? (
                        <Updateaddress />
                      ) : (
                        <div className="p-1 border building-inspection-form-input">
                          <input
                            type="text"
                            name="propertyState"
                            readOnly
                            value={tokendata.address}
                            style={{ width: "100%" }}
                          />
                        </div>
                      )}
                    </Card.Body>
                  </div>
                </div>
              </div>
            </Row>
          </Card>
          <div>
            <Card className="col-md-12">
              <Card.Header style={{ textAlign: "center", display: "block" }}>
                <h2>
                  What report do you need ?{" "}
                  <span
                    class="mdi mdi-information-variant bg-info rounded-circle px-1 text-white"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="We recommend combined building and pest inspection if you are unsure"
                  ></span>
                </h2>
              </Card.Header>
              <div className="building-inspection-card">
                {" "}
                <Card
                  className="building-inspection-inner-card pt-6 pb-7 selectable-button m-3 act"
                  style={{
                    background: "hwb(231deg 95% 2%)",
                    color: "#2e2e2ec4",
                    cursor: "pointer",
                    backgroundColor:
                      findType == "Building Inspection"
                        ? "#52ab6b"
                        : "hwb(231deg 95% 2%)",
                  }}
                  onClick={() => {
                    setcolor({
                      background: "hwb(231deg 95% 2%)",
                      color: "#2e2e2ec4",
                      cursor: "pointer",
                    });
                    findPrice(reportData.price1);
                    setpaymentAmount(reportData.price1);
                    setFindType("Building Inspection");
                  }}
                >
                  {" "}
                  <Card.Header className="mb-5" style={{ textAlign: "center" }}>
                    <h2>Building Inspection</h2>
                  </Card.Header>
                  <Card.Body className="mt-6">
                    <span>
                      {" "}
                      <span>
                        ${" "}
                        <span className="building-pest-room-price">
                          {reportData.price1}{" "}
                        </span>
                      </span>
                      INC GST
                    </span>
                    <ul>
                      <li>Inc</li>
                      <li>
                        <i className="fe fe-check-circle mx-1" />
                        Construction method
                      </li>
                      <li>
                        <i className="fe fe-check-circle mx-1" />
                        Safety Hazards
                      </li>
                      <li>
                        <i className="fe fe-check-circle mx-1" />
                        Defects
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
                <Card
                  className="building-inspection-inner-card pt-6 pb-7 selectable-button m-3"
                  style={{
                    background: "hwb(231deg 95% 2%)",
                    color: "#2e2e2ec4",
                    cursor: "pointer",
                    backgroundColor:
                      findType == "Pest Inspection"
                        ? "#52ab6b"
                        : "hwb(231deg 95% 2%)",
                  }}
                  onClick={() => {
                    findPrice(reportData.price2);
                    setFindType("Pest Inspection");
                  }}
                >
                  <Card.Header className="mb-5" style={{ textAlign: "center" }}>
                    <h2>Pest Inspection</h2>
                  </Card.Header>
                  <Card.Body className="mt-6">
                    <span>
                      <span>
                        ${" "}
                        <span className="building-pest-room-price">
                          {reportData.price2}{" "}
                        </span>
                      </span>
                      INC GST
                    </span>
                    <ul>
                      <li>Inc</li>
                      <li>
                        <i className="fe fe-check-circle mx-1" />
                        Timber & other pest attack
                      </li>
                      <li>
                        <i className="fe fe-check-circle mx-1" />
                        Termite, Fungal evidence
                      </li>
                      <li>
                        <i className="fe fe-check-circle mx-1" />
                        Conditions to attack
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
                <Card
                  className="building-inspection-inner-card pt-6 pb-7 selectable-button m-3"
                  style={{
                    background: "hwb(231deg 95% 2%)",
                    color: "#2e2e2ec4",
                    cursor: "pointer",
                    backgroundColor:
                      findType == "Building and Pest Inspection"
                        ? "#52ab6b"
                        : "hwb(231deg 95% 2%)",
                  }}
                  onClick={() => {
                    findPrice(reportData.price3);
                    setFindType("Building and Pest Inspection");
                  }}
                >
                  <Card.Header className="mb-5" style={{ textAlign: "center" }}>
                    <h2>Combined Building & Pest Inspection</h2>
                  </Card.Header>
                  <Card.Body>
                    <span>
                      <span>
                        ${" "}
                        <span className="building-pest-room-price">
                          {reportData.price3}{" "}
                        </span>
                      </span>
                      INC GST
                    </span>
                    <ul>
                      <li>Inc</li>
                      <li>
                        <i className="fe fe-check-circle mx-1" />
                        Building Inspection
                      </li>
                      <li>
                        <i className="fe fe-check-circle mx-1" />
                        Pest Inspection
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
              </div>
            </Card>
            <Card>
              <div className="d-flex">
                {/* <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center"> */}
                <input
                  type="text"
                  className=" m-2 form-control"
                  onChange={(e) => setUserPromo(e.target.value)}
                  placeholder="Apply your promocode if you have any?"
                  style={{ width: "17rem" }}
                />
                {/* </div> */}
                {/* <div className="col-lg-6 col-md-6 col-sm-12 text-center "> */}
                <Button
                  style={{ width: "fit-content" }}
                  onClick={validateuserPromo}
                  className="m-2 building-inspection-button "
                >
                  Apply Promo Code
                </Button>
                {/* </div> */}
              </div>
              <div>
                {promodata ? (
                  <>
                    <div className="mt-4 text-center">
                      <h3>
                        Congratulations! you have received ${promodata} discount{" "}
                      </h3>
                      <h4>
                        New Depreciation Schedule fees is{" "}
                        {+paymentAmount - +promodata}
                      </h4>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div style={{ textAlign: "center" }}>
                <textarea
                  placeholder="Any Special Instruction to inspector ?"
                  className=" col-md-10 mt-5 mb-5"
                  style={{ border: "1px solid black" }}
                  name="message"
                  id="solicitorConveyancer"
                  onChange={onChange}
                ></textarea>{" "}
              </div>
              <div style={{ textAlign: "center" ,fontSize: "smaller"}}>
<b>Disclamer:</b> The cost is indicative only, the expert will provide an accurate quote upon contact which might be slightly lower or higher than what is displayed here.
            </div>
              <div className="text-center">
                <button
                  className="m-4 border  building-inspection-button"
                  style={{ borderRadius: "5px", padding: "9px" }}
                  onClick={() => {
                    navigate("/application-dashboard ", {
                      state: {
                        id: id,
                      },
                    });
                  }}
                >
                  Go Back
                </button>
                <Button
                  className="m-4 building-inspection-button"
                  onClick={() => {
                    // handleSubmit();
                    findType && findBedRoomSize
                      ? buildingAndPestSubmit({
                          id: id,

                          type: "paymentPropertyAddress",
                          applicationType: {
                            applicationType: "buildingAndPest",
                            price:
                              findType == "Building Inspection"
                                ? reportData.price1
                                : findType == "Pest Inspection"
                                ? reportData.price2
                                : findType == "Building and Pest Inspection"
                                ? reportData.price3
                                : "",
                            type: findType,
                            bedRoomSize: findBedRoomSize,
                            selectState: location.state.state,
                            submitType: "Building and Pest Inspection",
                            message: message.message,
                            createdDate: todayDate,
                            // taskId: short_id.generate(),
                          },
                        })
                      : toast.warning("please select 1 option", {
                          position: "top-center",
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                  }}
                >
                  Confirm
                </Button>
              </div>
            </Card>
          </div>
        </div>
      ) : (
        <Card className="mt-9 pt-7 pb-0 col-md-7">
          <Card.Header className="mx-auto" style={{ textAlign: "center" }}>
            <h2>
              All done! Sit back and relax. Our Building/Pest Inspection expert
              will get in touch with you
            </h2>
          </Card.Header>
          <Link
            className="mt-4"
            style={{ textAlign: "center" }}
            target="_blank"
            to={"/blog-page"}
          >
            <span className="btn btn-success btn-icon text-white mb-3">
              Go to blog page
            </span>
          </Link>
          <div style={{ textAlign: "center" }} className="my-6">
            <button
              className="btn btn-primary btn-icon text-white mb-4"
              style={{ fontSize: "1rem" }}
              onClick={() => {
                navigate("/application-dashboard", {
                  state: {
                    id: id,
                  },
                });
              }}
            >
              Go back to Application Dashboard
            </button>
          </div>
        </Card>
      )}
    </>
  );
};

export default BuildingPest;
