import React, { useState, useRef, useContext, useEffect } from 'react';
import userContext from '../UserContext/UserContext';
import UserApiContext from '../UserApi/UserApiContext';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';


import { Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PlacesAutocomplete, { geocodeByAddress, getLatLng,} from 'react-places-autocomplete';

// function for google place api

const apiKey = 'AIzaSyA4SevNv3t7rPZiN2JJ5z4SMlcNpjewx3M';
const mapApiJs = 'https://maps.googleapis.com/maps/api/js';
const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';

// load google map api js

function loadAsyncScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    Object.assign(script, {
      type: 'text/javascript',
      async: true,
      src,
    });
    script.addEventListener('load', () => resolve(script));
    document.head.appendChild(script);
  });
}

const extractAddress = (place) => {
  const address = {
    city: '',
    state: '',
    zip: '',
    country: '',
    plain() {
      const city = this.city ? this.city + ', ' : '';
      const zip = this.zip ? this.zip + ', ' : '';
      const state = this.state ? this.state + ', ' : '';
      return city + zip + state + this.country;
    },
  };

  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach((component) => {
    const types = component.types;
    const value = component.long_name;

    if (types.includes('locality')) {
      address.city = value;
    }

    if (types.includes('administrative_area_level_2')) {
      address.state = value;
    }

    if (types.includes('postal_code')) {
      address.zip = value;
    }

    if (types.includes('country')) {
      address.country = value;
    }
  });
  return address;
};

const PropertyDetails = (props) => {
  const context = useContext(userContext);
  const UserApi = useContext(UserApiContext);
  const [tokendata, settokendata] = useState([]); // to get the user data
  const navigate = useNavigate();
  const { applicationData } = context;
  const {
    creatingApplication,
    userApplicationData,
    loggedInUserData,
    newApplication,
  } = UserApi;
  //setting  the user prperty details
  const initialValue = {
    address: '',
    propertyState: '',
    agentName: '',
    agencyName: '',
    agentEmail: '',
    agentPhone: '',
    name: '',
  };
  const [details, setDetails] = useState(initialValue);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (  details.agentPhone.length >=1 && details.agentPhone.length <= (9)|| details.agentPhone.length > (10) 
    ) {
      toast.error('Enter 10 digits number', {
        position: 'top-center',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
     if( details.address == '' || details.propertyState =='' ){
        toast.error('Please fill address and state', {
          position: 'top-center',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    }else  if (details.agentPhone == '' || details.agentPhone.charAt(0) == "0") {
      const newApplicationData = { ...details, ...applicationData };
      //  console.log(newApplicationData);
      //  console.log(props.id);
      await userApplicationData(newApplicationData, props.id);
      setTimeout(() => {
        navigate('/application-dashboard', {
          state: { id: props.id },
        });
      }, 1000);
    }else {
        console.log(details);
        console.log(applicationData);

        toast.error('Enter 10 digits number starting with 0', {
          position: 'top-center',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    
    }
  };
  //  mearging the name with details
  console.log(details);

  const Authtoken = localStorage.getItem('token');
  const token = async () => {
    //Getting the logged in user data
    const newData = await loggedInUserData(Authtoken);
    setDetails({
      ...details,
      name: newData.name,
      phone: newData.phone,
      email: newData.email,
    });
    settokendata({ ...newData });
  };
  useEffect(() => {
    token();
  }, []);
  const onChange = (e) => {
    setDetails((preState) => {
      return { ...preState, [e.target.name]: e.target.value };
    });
  };

  // adding google place api---------------------------------------------
  const searchInput = useRef(null);
  const [address, setAddress] = useState({});

  // init gmap script
  const initMapScript = () => {
    // if script already loaded
    if (window.google) {
      return Promise.resolve();
    }

    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  };

  // do something on address change
  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    setAddress(extractAddress(place));
    setDetails((preState) => {
      return { ...preState, address: searchInput.current.value };
    });
    let completeAddress = searchInput.current.value;
    let nsw = /NSW/;
    let vic = /VIC/;
    let qld = /QLD/;
    let sa = /SA/;
    let wa = /WA/;
    let tas = /TAS/;
    let nt = /NT/;
    let act = /ACT/;
    if (nsw.test(completeAddress)) {
      setDetails((preState) => {
        return { ...preState, propertyState: 'NSW' };
      });
    }
    if (vic.test(completeAddress)) {
      setDetails((preState) => {
        return { ...preState, propertyState: 'VIC' };
      });
    }
    if (qld.test(completeAddress)) {
      setDetails((preState) => {
        return { ...preState, propertyState: 'QLD' };
      });
    }
    if (sa.test(completeAddress)) {
      setDetails((preState) => {
        return { ...preState, propertyState: 'SA' };
      });
    }
    if (wa.test(completeAddress)) {
      setDetails((preState) => {
        return { ...preState, propertyState: 'WA' };
      });
    }
    if (tas.test(completeAddress)) {
      setDetails((preState) => {
        return { ...preState, propertyState: 'TAS' };
      });
    }
    if (nt.test(completeAddress)) {
      setDetails((preState) => {
        return { ...preState, propertyState: 'NT' };
      });
    }
    if (act.test(completeAddress)) {
      setDetails((preState) => {
        return { ...preState, propertyState: 'ACT' };
      });
    }
  };

  // init autocomplete
  const initAutocomplete = () => {
    if (!searchInput.current) return;

    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current
    );
    // if (searchInput.current.value != null) {
    //   setDetails({ ...details, address: searchInput.current.value });
    // }

    autocomplete.setFields(['address_component', 'geometry']);
    autocomplete.addListener('place_changed', () =>
      onChangeAddress(autocomplete)
    );
  };

  const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
    const url = `${geocodeJson}?key=${apiKey}&latlng=${lat},${lng}`;

    searchInput.current.value = 'Getting your location...';
    fetch(url)
      .then((response) => response.json())
      .then((location) => {
        const place = location.results[0];
        const _address = extractAddress(place);

        setAddress(_address);
        searchInput.current.value = _address.plain();
      });
  };

  const findMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        reverseGeocode(position.coords);
      });
    }
  };

  // creating blank appliction
  const createapp = () => {
    creatingApplication();
  };

  // load map script after mounted
  useEffect(() => {
    initMapScript().then(() => initAutocomplete());
    createapp();
  }, []);
  return (
    <>
      <div className="d-flex flex-column  pt-4 pb-2 ">
        <Card.Header style={{ margin: 'auto' }}>
       

          <h3>
            Great! What are the property details? <Tooltip title="Property details are required so that you don't have to provide them seperately to service providers">
        <button class="mdi mdi-information-variant bg-info rounded-circle px-1 text-white"  ></button> 
</Tooltip>
            {/* <span
       className="border m-2"
       data-toggle="tooltip"
       data-placement="top"
       title="Property details are required so that you don't have to provide them seperately to service providers"
       style={{ borderRadius: "50%", padding: "1px 10px 1px 10px", background: "aliceblue", color: "#378fb5" }}
      >
            </span> */}
          </h3>
        </Card.Header>
        <Card.Body>
          <form >
          {/* <ReactTooltip place="bottom" type="dark" effect="solid" /> */}

            <div>
              <div className="search">
                <input
                  className="form-control col-md-14 mx-auto mb-4"
                  name="address"
                  ref={searchInput}
                  type="text"
                  placeholder="Property Address"
                />
              </div>
            </div>
            <div>
              <select
                className="form-control col-md-14 mx-auto mb-4"
                name="propertyState"
                onChange={onChange}
                value={details.propertyState}
              >
                <option value="">Select State</option>
                <option value="NSW">NSW</option>
                <option value="VIC">VIC</option>
                <option value="QLD">QLD</option>
                <option value="SA">SA</option>
                <option value="WA">WA</option>
                <option value="TAS">TAS</option>
                <option value="NT">NT</option>
                <option value="ACT">ACT</option>
              </select>
            </div>
            <div>
              <input
                className="form-control col-md-14 mx-auto mb-4"
                onChange={onChange}
                name="agentName"
                placeholder="Selling Agent Name"
                type="text"
              />
            </div>
            <div>
              <input
                className="form-control col-md-14 mx-auto mb-4"
                onChange={onChange}
                name="agencyName"
                placeholder="Selling Agency Name"
                type="text"
              />
            </div>
            <div>
              <input
                className="form-control col-md-14 mx-auto mb-4"
                onChange={onChange}
                name="agentEmail"
                placeholder="Selling Agent Email ID"
                type="email"
              />
            </div>
            <div>
              <input
                className="form-control col-md-14 mx-auto mb-4"
                onChange={onChange}
                name="agentPhone"
                placeholder="Selling Agent Phone Number"
                type="number"
              />
            </div>
            <Button className=" mt-6 col-md-7 block mx-auto "onClick={handleSubmit}>
              Done
            </Button>
          </form>
        </Card.Body>
      </div>
    </>
  );
};

export default PropertyDetails;
