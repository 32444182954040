import React, { Component, useState } from "react";
import axios from "axios";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { Card } from "react-bootstrap";
import GetBlog from "./GetBlog";
import "./blog.css";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import FileBase64 from "react-file-base64";
const Blog = () => {
  const [description1, setDescription1] = useState('');
  const [changeState, setChangeState] = useState(false);
  const [blogData, setBlogData] = useState({
    title: "",
    blogImage: "",
  });
  // const [image, setImage] = useState();
  const [file, setFile] = useState();
  const onChange = (e) => {
    setBlogData({ ...blogData, [e.target.name]: e.target.value });
  };
  // const handleImage = (e) => {
  //   const file = e.target.files[0];
  //   setFileToBase(file);
  //   console.log(file);
  // };
  // const setFileToBase = (file) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onloadend = () => {
  //     setImage(reader.result);
  //   };
  // };

  // const dataFile = new FormData();
  // dataFile.append("title", blogData.title);
  // dataFile.append("description", blogData.description);
  // dataFile.append("blogImage", file);

  // const [editor, setEditor] = useState({
  //   editorState: EditorState.createEmpty(),
  // });
  // const onEditorStateChange = (editorState) => {
  //   setEditor({
  //     editorState,
  //   });
  //   setBlogData({
  //     ...blogData,
  //     description: editorState.getCurrentContent().getPlainText(),
  //   });
  // };
  let editorState = EditorState.createEmpty();
  const [description, setDescription] = useState(editorState);
  const [file1 ,setFile1] =useState()
  const onEditorStateChange = (editorState) => {
    setDescription(editorState);
  };
  console.log(description1);
  const onSubmit = async (e)  => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_BACKEND_DATA}/upload-blog-new'`;

    const formData = new FormData();
    formData.append("blogImage", file1);
    formData.append("title", blogData.title);
    formData.append("url", blogData.title.toLocaleLowerCase().split(' ').join('-'));
    formData.append("description", description1);
    const data = {
      title: blogData.title,
      description: description1,
      blogImage: blogData.blogImage,
    };
    
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DATA}/upload-blog-new`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((data) => {
        // console.log(data);
        setBlogData({
          title: "",
          description: "",
          blogImage: "",
        });
        toast.success("Blog added successfully", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setEditor("");
        setChangeState(!changeState);
        window.location.reload();
      })
      .catch((error) => {
        //(error);
      });

    // console.log(dataFile);
  };
  console.log(blogData.description);
  const uploadCallback = (file, callback) => {
    console.log(file);
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader();
      console.log(reader);
      reader.onloadend = async () => {
        const form_data = new FormData();
        form_data.append("file", file);
        const res = await uploadFile(form_data);
        setValue("thumbnail", res.data);
        resolve({ data: { link: process.env.REACT_APP_API + res.data } });
      };
      reader.readAsDataURL(file);
    });
  };
  function handleChance(e) {
    console.log(e);
    setDescription1(e)
    
    
  }
  const handleFileChange = (e) => {
    setFile1(e.target.files[0]);
  };
  const config = {
    image: { uploadCallback: uploadCallback },
  };
  return (
    <div>
      <Card>
        <h1>Add Blog :-</h1>
        <form onSubmit={onSubmit}>
          <div className="form-group green-border-focus">
            <label htmlFor="exampleFormControlTextarea5">Title</label>
            <input
              className="form-control"
              name="title"
              onChange={onChange}
              value={blogData.title}
              required
            ></input>
          </div>

          <div>
            <label htmlFor="exampleFormControlTextarea5">Description</label>
            <SunEditor
              height="300"
              h
          
                  z-index="99" 
                  position="fixed"
                  max-width=" 81vw"
              onChange={handleChance}
              setOptions={{
                buttonList: [
                  [
                    "bold",
                    "fontSize",
                    "underline",
                    "italic",
                    "strike",
                    "formatBlock",
                    "subscript",
                    "superscript",
                    "fontColor",
                    "outdent",
		               	"indent",
                    "hiliteColor",
                    "paragraphStyle",
                    "textStyle",
                    "align",
                    "horizontalRule",
                    "list",
                    "lineHeight",
                    "table",
                    "image",
                    "video",
                    "audio",
                    "link",
                  ],
                ],
              }}
            />
            {/* <Editor
              editorState={description}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              value={blogData.description}
              onEditorStateChange={onEditorStateChange}
              toolbar={config}
            /> */}
            <textarea
              style={{ display: "none" }}
              disabled
              ref={(val) => (blogData.description = val)}
              value={draftToHtml(convertToRaw(description.getCurrentContent()))}
            />
          </div>

          <div>
            <input type="file" 
            onChange={handleFileChange}
            
            className="form-control"/>
            {/* <FileBase64
              className=""
              type="file"
              multiple={false}
              value="dfgh"
              onDone={({ base64 }) =>
                setBlogData({ ...blogData, blogImage: base64 })
              }
            /> */}
          </div>
          <button
            type="submit"
            className="btn btn-success btn-icon text-white px-5"
          >
            Submit
          </button>
        </form>
      </Card>
      <GetBlog setChangeState={setChangeState} changeState={changeState} />
    </div>
  );
};

export default Blog;
