import React, { useContext, useState, useEffect } from 'react';
import userContext from '../UserContext/UserContext';
import UserApiContext from '../UserApi/UserApiContext';
import { Button, Card } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
const LookingToBuy = (props) => {
  const navigate = useNavigate();
  const [name, setName] = useState({
    name: '',
    email: '',
    phone: '',
  });
  const UserApi = useContext(UserApiContext);
  const {
    creatingApplication,
    newApplication,
    userApplicationData,
    loggedInUserData,
  } = UserApi;
  const context = useContext(userContext);
  const { lookingToBuy, applicationData } = context;
  
  const handleSubmit = async (data) => {
    
    // await createapp();
    await userApplicationData(
      {
        ...applicationData,
        lookingToBuy: data,
        name: name.name,
        email: name.email,
        phone: name.phone,
      },
      props.id
      );
      // navigate('/application-dashboard');



   setTimeout(() => {
     navigate('/application-dashboard', {
      state: { id: props.id },
    });
   }, 500);  
  };
  let id = props.id;
  const Authtoken = localStorage.getItem('token');
  const token = async () => {
    const newData = await loggedInUserData(Authtoken);

    setName({ name: newData.name, email: newData.email, phone: newData.phone });
  };

  // creating blank appliction
  const createapp = () => {
    creatingApplication();

  };

  useEffect(() => {
    token();
    createapp();
  }, []);
  useEffect(() => {
    console.log('newApplication', newApplication._id,newApplication);
  }, [newApplication]);
  const states = [
    { state: 'NSW' },
    { state: 'VIC' },
    { state: 'QLD' },
    { state: 'SA' },
    { state: 'TAS' },
    { state: 'WA' },
    { state: 'NT' },
    { state: 'ACT' },
  ];
  return (
    <>
      <div className="d-flex flex-column pt-4 pb-2">
        <Card.Header style={{ margin: 'auto' }}>
          <h3>Where are you looking to buy</h3>
        </Card.Header>
        <Card.Body>
          {states.map((item, ind) => (
            // <Link
            //   to="/application-dashboard"
            //   state={{ id: id }}
            //   key={ind + 1}
            //   style={{ color: 'white', textDecoration: 'none' }}
            //   onClick={() => {
            //     handleSubmit(item.state);
            //   }}
            // >
            <Button
              className="mb-3 mt-2 col-md-9 block "
              style={{ margin: 'auto' }}
              key={ind}
              onClick={() => handleSubmit(item.state)}
            >
              {item.state}
            </Button>
            //</Link>
          ))}
        </Card.Body>
      </div>
    </>
  );
};

export default LookingToBuy;
