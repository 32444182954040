import React from "react";
import * as datatable from "./TableFormat";
import { Row, Card, Col } from "react-bootstrap";
import { tableDataItems } from "../revenueTables/summary/TableData";

function Table(props) {
    // console.log(props.filter);
//     const [formInput, setFormInput] = React.useState(props.filter)
const [incomingData, setincomingData] = React.useState(props.incomingData)
    
 return (
  <div>
   <Row className=" row-sm">
    <Col lg={12}>
     <Card className="mt-4">
      <Card.Body>
       <div className="table-responsive">
        <datatable.DataTables input= {props.formInput} filter= {props.filter}incomingData= {incomingData}/>
       </div>
      </Card.Body>
     </Card>
    </Col>
   </Row>
  </div>
 );
}

export default Table;
