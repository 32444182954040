import React, { useContext, useState, useEffect } from "react";
import { Button, Modal, ModalBody, Table } from "react-bootstrap";
import AdminContext from "../.././adminContext/AdminContext"
import axios from "axios";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML, 
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import { toast } from "react-toastify";

import newImage from "../../.././assets/images/ZZ5H.gif";
const EditBlog = (props) => {
  console.log(props.category)
  const adminContext = useContext(AdminContext);
  const { handleModal, setHandleModal } = adminContext;
  let initialValue = {
    title: props.title,

    _id: props._id,
    category: props.category,
  };
  const [description1, setDescription1] = useState('');
  const [blogEditData, setBlogEditData] = useState(initialValue);

  const onChange = (e) => {
    setBlogEditData((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });
  };
  // to pass the value inside the edito
  let editorState = EditorState.createWithContent(
    ContentState.createFromBlockArray(convertFromHTML(props.description))
  );
  // end
  const [description, setDescription] = useState(editorState);
  const onEditorStateChange = (editorState) => {
    setDescription(editorState);
  };
  const dataFile = {};
  if (blogEditData.title) {
    dataFile.title = blogEditData.title;
  }
  if (blogEditData.description != undefined) {
    dataFile.description = description1;
  }
  if (blogEditData._id) {
    dataFile._id = blogEditData._id;
  }
  
dataFile.category = props.category;


  // useEffect(() => {
  //   setBlogEditData(initialValue);
  // }, []);
  const onSubmit = (e) => {
    e.preventDefault();
    
    console.log(dataFile);

    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/email/email-template`;
   
    axios
      .post(url, dataFile, {
        headers: {
          "admin-token": localStorage.getItem("admin-token"),
        },
      })
      .then(({ data }) => {
       console.log(data)
      })
      .catch((error) => {
        console.log(error);
      });
      
      window.location.reload()  
      toast.success('Updated successfully', {
        position: 'top-center',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    setHandleModal(false);
  };

  const contents = props.description
  function handleChance(e) {
    console.log(e);
    setDescription1(e)
    //   return { ...perState, [e.target.name]: e.target.value };
    // });
    
  }
  return (
    <>
      {(props.title && props.title.length === 0) ||
      (props.description && props.description.length === 0) ? (
        <div
          style={{
            // height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <img style={{ maxWidth: "7%" }} src={newImage} />
        </div>
      ) : (
        <div>
          <form>
            <Modal
              size="xl"
              show={handleModal}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header>
                <Modal.Title>Edit Email Template</Modal.Title>
                <Button
                  onClick={() => setHandleModal(false)}
                  className="btn-close"
                  variant=""
                >
                  x
                </Button>
              </Modal.Header>
              <Modal.Body>
                <div className="card-blog-modal-body">
                  <form onSubmit={onSubmit}>
                    <div className="form-group green-border-focus">
                      <label htmlFor="exampleFormControlTextarea5">Title</label>
                      <input
                 
                        className="form-control"
                        name="title"
                        value={blogEditData.title}
                        onChange={onChange}
                      ></input>
                    </div>
                    <div className="form-group green-border-focus">
                      <label htmlFor="exampleFormControlTextarea5">
                        Description
                      </label>
                      {/* <textarea
                        className="form-control"
                        name="description"
                        value={blogEditData.description}
                        onChange={onChange}
                        id="exampleFormControlTextarea5"
                        rows="3"
                      ></textarea> */}

<SunEditor
                        autoFocus={true}
                        placeholders="enter text"
                        // width="560"
                        height="350"  
                        onChange={handleChance}
                        setContents={contents}
                        // getContents
                        toggleDisplayBlocks
                        // convertHTMLForCodeView={blogEditData.description}
                        // setContents={initialContent}
                        // value={draftToHtml(
                        // convertToRaw(description.getCurrentContent())
                        // )}
                        //  ref={(val) => (blogEditData.description = val)}
                        // onChange={handleChance}
                        // onEditorStateChange={onEditorStateChange}
                        // editorState={description}
                        setOptions={{
                          buttonList: [
                            [
                              "bold",
                              "fontSize",
                              "underline",
                              "italic",
                              "strike",
                              "formatBlock",
                              "subscript",
                              "superscript",
                              "fontColor",
                              "hiliteColor",
                              "paragraphStyle",
                              "textStyle",
                              "align",
                              "horizontalRule",
                              "list",
                              "lineHeight",
                              "table",
                              "image",
                              "video",
                              "audio",
                              "link",
                            ],
                          ],
                        }}
                      />
                      {/* <Editor
                        editorState={description}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        value="sdfasdfas"
                        name="dis"

                        onEditorStateChange={onEditorStateChange}
                      /> */}
                      <textarea
                        style={{ display: "none" }}
                        disabled
                        ref={(val) => (blogEditData.description = val)}
                        value={draftToHtml(
                          convertToRaw(description.getCurrentContent())
                        )}
                      />
                    </div>
                    <div>
           <div style={{ fontSize: "1.3rem", marginBottom: "1rem" }}>
           Select Dynamic Fields{" "}
           </div>
           <div style={{ fontSize: "1rem", marginBottom: "1rem" }}>
             <div>{"{customerName}"}</div>
             <div>{"{requestType}"}</div>
             <div>{"{taskid}"}</div>
             <div>{"{customerId}"}</div>
             <div>{"{customerEmailId}"}</div>
             <div>{"{customerPhoneNumber}"}</div>
             <div>{"{purchaseType}"}</div>
             <div>{"{propertyType}"}</div>
             <div>{"{propertyAddress}"}</div>
             <div>{"{propertyState}"}</div>
             <div>{"{Signature}"}</div>
             <div>{"{instructionFromCustomer}"}</div>
           </div>
        </div>
                    <button
                      type="submit"
                      className="btn btn-success btn-icon text-white px-5"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </Modal.Body>
            </Modal>
          </form>
        </div>
      )}
    </>
  );
};

export default EditBlog;
