import React, { useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Button } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";
import AdminContext from "../../../../../adminContext/AdminContext";
import {CSVLink, CSVDownload} from 'react-csv';


export const DataTables = ({
  incomingData,
  incomingPaymentData,
  incomingPartnerData,
}) => {
  const [data, setData] = React.useState();
  useEffect(() => {
    setData(incomingData);
  });
  const adminContext = useContext(AdminContext);
  let {
    stateDepricationRevenueDetails,
    setStateDepricationRevenueDetails,
    stateDepricationPayableDetails,
    setStateDepricationPayableDetails,
    stateDepricationNetIncomeDetails,
    setStateDepricationNetIncomeDetails,
  } = adminContext;

  // console.log(incomingPartnerData);
  let NetIncomeincomingPaymentData = 0;
  let NetnewPartnerDetails = 0;
  let newpartnerDetails = 0;
  let newpaymentDetail = 0;
  let newNetIncome = 0;
  let settingData = (data1, data2) => {
    newpaymentDetail = newpaymentDetail + data1;
    newpartnerDetails = newpartnerDetails + data2;
    newNetIncome = newNetIncome + data1 - data2;
    setStateDepricationRevenueDetails(newpaymentDetail);
    setStateDepricationPayableDetails(newpartnerDetails);
    setStateDepricationNetIncomeDetails(newNetIncome);
  };

  function convertArrayOfObjectsToCSV(array) {
    let result;
  
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    console.log(array);
    const keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {

    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    // <Button onClick={(e) => onExport(e.target.value)}>Export</Button>
    <Button type="button" className="ml-5" onClick={()=>tableToCSV()}>  Export</Button>
    
  );

  // console.log(data);
  const columns = [
    {
      name: "Task ID",
      selector: (row) => [row.task_id],
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (row) => [row.name],
      sortable: true,
    },
    {
      name: "Customer ID",
      selector: (row) => [row.user],
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => [row.progress],
      sortable: true,
    },
    {
      name: "Assigned Date",
      selector: (row) => (
        <div>
          {row.assignDate === undefined
            ? "N/A"
            : row.assignDate.split("-").reverse().join("/")}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Completion Date",
      selector: (row) =>row.completeDate,
      sortable: true,
    },
    {
      name: "Customer Payment",
      selector: (row) => row.paymentAmount,
        // incomingPaymentData
        //   .filter((data) => {
        //     return row.taskId === data.taskId;
        //   })
        //   .map((filteredData) => {
        //     NetIncomeincomingPaymentData = filteredData.paymentAmount;
        //     return filteredData.paymentAmount;
        //   }),
      sortable: true,
    },
    {
      name: "Payable to Partner",
      selector: (row) => incomingPartnerData.map((data)=>{
        if(data.fee){
          return data.fee
        }
      }),
          
      sortable: true,
    },
    {
      name: "Net Income",
      selector: (row) =>  row.paymentAmount  - incomingPartnerData.map((data)=>{
        if(data.fee){
          return data.fee
        }
      }),
          
      sortable: true,
      // {
      //   settingData(
      //     parseInt(NetIncomeincomingPaymentData),
      //     parseInt(NetnewPartnerDetails)
      //   );
      //   return (
      //     parseInt(NetIncomeincomingPaymentData) -
      //     parseInt(NetnewPartnerDetails)
      //   );
      // },
      // sortable: true,
    },
  ];
   let x = 0
 let  xyz = incomingData.map((data)=>{
    if(data.paymentAmount){
       x += data.paymentAmount
    }
  })  

  let y = 0
  let yxz =incomingPartnerData.map((data)=>{
if(data.fee){
  y += data.fee
}
})

// console.log(x );
var sum = x - y
  const tableDatas = {
    columns,
    data,
  };
  const actionsMemo = React.useMemo(
    () => 
    // <CSVLink data={incomingData} ><span className="btn btn-primary">Export</span></CSVLink>,
    <Button type="button" className="ml-5" onClick={()=>tableToCSV()}>  Export</Button>,
    []
  );





  function tableToCSV() {
 
    // Variable to store the final csv data
    var csv_data = [];
  
    // Get each row data
    var rows = document.getElementsByClassName('trr');
    console.log(rows);
    for (var i = 0; i < rows.length; i++) {
  
        // Get each column data
        var cols = rows[i].querySelectorAll('td,th');
  
        // Stores each csv row data
        var csvrow = [];
     
        for (var j = 0; j < cols.length; j++) {
  
            // Get the text data of each cell
            // of a row and push it to csvrow
            csvrow.push(cols[j].innerHTML);
        }
  
        // Combine each column value with comma
        csv_data.push(csvrow.join(","));
    }
  
    // Combine each row data with new line character
    csv_data = csv_data.join('\n');
  
    // Call this function to download csv file 
    downloadCSVFile(csv_data);
  
  }
  
  function downloadCSVFile(csv_data) {
  
    // Create CSV file object and feed
    // our csv_data into it
    var CSVFile = new Blob([csv_data], {
        type: "text/csv"
    });
  
    // Create to temporary link to initiate
    // download process
    var temp_link = document.createElement('a');
  
    // Download csv file
    temp_link.download = "Depreciation Schedule.csv";
    var url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;
  
    // This link should not be displayed
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);
  
    // Automatically click the link to
    // trigger download
    temp_link.click();
    document.body.removeChild(temp_link);
  }

  return (
    <>

<table className="d-none" border="1" cellSpacing="0" cellPadding="10">
			<tr className="trr">
				<th>Task ID</th>
				<th>Customer Name</th>
				<th>Customer ID</th>
				<th>Status </th>
				<th>Assigned Date</th>
				<th>Completion Date</th>
				<th>Customer Payment</th>
				<th>Payable to Partner</th>
				<th>Net Income</th>
			</tr>
      {
      data?.map((row)=>{

       return(
        <>
        <tr className="trr">
				<td>{row.task_id}</td>
		
				<td>{row.name}</td>
				<td>{row.user}</td>
				<td>{row.progress}</td>
				<td>{row.assignDate === undefined
            ? "N/A"
            : row.assignDate.split("-").reverse().join("/")}</td>
				<td>{row.completeDate}</td>
				<td>{ row.paymentAmount}</td>
			
        <td>{incomingPartnerData.map((data)=>{
        if(data.fee){
          return data.fee
        }
      })}</td>
				<td>{ row.paymentAmount  - incomingPartnerData.map((data)=>{
        if(data.fee){
          return data.fee
        }
      })}</td>
				
			
			</tr>
      <tr className="trr d-none">
      {/* <td>Total</td>
      <td> Revenue: ${" "}{x}
          </td>
      <td> Payable: ${" "}{y}
         </td>
      <td>Net Income: ${" "}{sum}
        
          </td> */}
    </tr>
   </>
   ) })}
		</table>
      <DataTableExtensions {...tableDatas}>
        <DataTable
          actions={actionsMemo}
          columns={columns}
          data={data}
          pagination
          highlightOnHover
          responsive
          dense
        />
      </DataTableExtensions>
      <div className="form-control d-flex " style={{ justifyContent: "end" }}>
        <div className="mx-5" style={{ fontWeight: "bold" }}>
          Revenue: $ {x}
        </div>
        <div className="mx-5" style={{ fontWeight: "bold" }}>
          Payable: $ {y}
        </div>
        <div className="mx-5" style={{ fontWeight: "bold" }}>
          Net Income: $ { sum
          
      }
     {localStorage.setItem("DepRev", x)}
     {localStorage.setItem("DepPayable", y)}
     {localStorage.setItem("DeppNet", sum)}
   
        </div>
      </div>
    </>
  );
};
