import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import axios from 'axios';

import './Setting.css';

function RemoveAccount() {
  const [allAdmin, setAllAdmin] = useState([]);

  // getAllAdmin
  const getAllAdmin = () => {
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/admin/all-admin`;

    axios
      .get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'admin-token': localStorage.getItem('admin-token'),
        },
      })
      .then((response) => {
        setAllAdmin(response.data.admin);
        console.log('get add admin', response);
        toast.success('Successfully Added', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {});
  };

  //delete admin
  const deleteAdmin = (id) => {
    // console.log('id', id);
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/admin/delete-admin`;

    axios({
      url: url,
      method: 'delete',
      data: { _id: id },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'admin-token': localStorage.getItem('admin-token'),
      },
    }).then(function (response) {
      console.log('delete admin', response);
      getAllAdmin();
      // toast.success('delete admin', {
      //   position: 'top-center',
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
    });
  };

  useEffect(() => {
    getAllAdmin();
  }, []);
  return (
    <div className="remove-account-container">
      <h2 className="mx-4 my-5">Remove Account</h2>

      {allAdmin.length > 0 &&
        allAdmin.map((data) => (
          <Card className="pt-6" key={data._id}>
            <Card.Body
              className="d-flex py-0"
              style={{ justifyContent: 'space-between' }}
            >
              <div>
                <h4>{data.email}</h4>
              </div>
              <div>
                <i
                  className="fe fe-trash-2 p-2"
                  style={{ fontSize: '18px', borderRadius: '5px' }}
                  onClick={() => deleteAdmin(data._id)}
                />
              </div>
            </Card.Body>
          </Card>
        ))}
    </div>
  );
}

export default RemoveAccount;
