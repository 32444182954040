import React from "react";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

function SettingAccount() {
  return (
    <Card className="col-md-4 mt-9" style={{ alignItems: "center"}}>
      <hr/>
      <Link to='/admin/setting-add-account'>
      <Button className="d-block m-2">
        <i className="fe fe-settings mx-2" />
        Add Account
      </Button>
      </Link>
      <hr/>
      <Link to='/admin/setting-edit-account'>
      <Button className="d-block m-2">
        <i className="fe fe-edit mx-2" />
        Edit Account{" "}
      </Button>
      </Link>
      <hr/>
      <Link to='/admin/setting-remove-account'>
      <Button className="d-block m-2">
        <i className="fe fe-trash-2 mx-2" />
        Remove Account
      </Button>
      </Link>
      <hr/>
    </Card>
  );
}

export default SettingAccount;
