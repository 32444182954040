import React, { useState } from "react";
import axios from "axios";
import { Form, Col } from "react-bootstrap";
import { Card, Button, Image } from "react-bootstrap";
import { toast } from "react-toastify";

function Addpromo() {
  const [promoName, setPromoName] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [service, setService] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [voucherNumber, setVoucherNumber] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [notes, setNotes] =useState('')
//for state

  const [applicablestate, setapplicablestate] = useState([]);

  const handleChange = (event) => {
    const { value } = event.target;
    if (applicablestate.includes(value)) {
      setapplicablestate(applicablestate.filter((v) => v !== value));
    } else {
      setapplicablestate([...applicablestate, value]);
    }
  };
  console.log('promoName',promoName =='')
///..........................
// console.log(applicablestate);


const [t,setf]=useState(false)
const allstate =()=>{
  setf(!t)
  if(t){

    const alldata= ["ACT","NT","NSW","VIC","QLD","SA","WA","TAS","All"]
    console.log(alldata);
    setapplicablestate([...alldata]);
  }else{
    applicablestate.splice(0, applicablestate.length);
  }

}
console.log(applicablestate);
const handleSubmit = (e) => {
  e.preventDefault();
  
  if(promoName !='' && promoCode != '' && applicablestate != '' && service != '' && endDate!='' && startDate !='' && discountAmount !=""  && voucherNumber!=""){
    axios
      .post(`${process.env.REACT_APP_BACKEND_DATA}/create-promo`, {
        promoName,
        promoCode,
        service,
        discountAmount,
        voucherNumber,
        startDate,
        applicablestate,
        endDate,
        notes,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
      toast.success("Successfully Added", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    window.location.reload();
  }else{
    toast.error("Please fill all feilds", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  };
  return (
    <div className="mt-6">
      <Card>
        <Card.Body>
          <Card.Title>
            <b>Add Promo Code</b>
          </Card.Title>
          <hr />

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="promoName">Promo Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="promoName"
                    value={promoName}
                    onChange={(e) => setPromoName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="promoCode">Promo Code:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="promoCode"
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value.toUpperCase() )}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect2">
                    Applicable services
                  </label>
                  <select
                  onChange={ (e)=>setService(e.target.value)}
                    className="form-control"
                    id="exampleFormControlSelect2"
                  >
                    <option value={""}>Select services</option>
                    <option value={"Mortgage Broker"}>Mortgage Broker </option>
                    <option value={"Engage Solicitor / Conveyancer"}>Engage Solicitor / Conveyancer</option>
                    <option value={"Receive Building / Pest"}>Receive Building / Pest</option>
                    <option value={"Receive Depreciation Schedule"}>Receive Depreciation Schedule</option>
                    <option value={"Engage Property Manager"}>Engage Property Manager</option>
                    <option value={"Engage Accountant"}>Engage Accountant</option>
                  </select>
                </div>
                
                <div className="form-group">
                  <label htmlFor="discountAmount">Discount Amount:</label>
                  <input
                    type="number"
                    className="form-control"
                    id="discountAmount"
                    value={discountAmount}
                    onChange={(e) => setDiscountAmount(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="voucherNumber">Number of Vouchers:</label>
                  <input
                    type="number"
                    className="form-control"
                    id="voucherNumber"
                    value={voucherNumber}
                    onChange={(e) => setVoucherNumber(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="startDate">Start Date:</label>

                  <input
                    type="date"
                    className="m-1 form-control mr-2"
                    id="startDate"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                  <label htmlFor="endDate">End Date:</label>

                  <input
                    type="date"
                    className=" m-1 form-control"
                    id="endDate"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
                <div className="form-label">Service State</div>
                <div className="custom-controls-stacked mt-3 mb-6 text-center">
                  <div className="d-block">
                    <label className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        name="checkbox1"
                        value={"NSW"}
                        checked={applicablestate.includes("NSW")}
                        onChange={handleChange}
                        className="custom-control-input"
                      />
                      <span className="custom-control-label">NSW</span>
                    </label>
                    <label className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        name="checkbox2"
                        value={"VIC"}
                        checked={applicablestate.includes("VIC")}
                        onChange={handleChange}
                        className="custom-control-input"
                      />
                      <span className="custom-control-label">VIC</span>
                    </label>
                    
                  </div>
                  <div className="d-block">
                    <label className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                
                        name="checkbox3"
                        value={"QLD"}
                        checked={applicablestate.includes("QLD")}
                        onChange={handleChange}
                        className="custom-control-input"
                      />
                      <span className="custom-control-label">QLD</span>
                    </label>

                    <label className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        name="checkbox4"
                        value={"SA"}
                        checked={applicablestate.includes("SA")}
                        onChange={handleChange}
                        className="custom-control-input"
                      />
                      <span className="custom-control-label">SA</span>
                    </label>
                  </div>
                  <div className="d-block">
                    <label className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        name="checkbox5"
                        value={"WA"}
                        checked={applicablestate.includes("WA")}
                        onChange={handleChange}
                        className="custom-control-input"
                      />
                      <span className="custom-control-label">WA</span>
                    </label>

                    <label className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        name="checkbox6"
                        value={"TAS"}
                        checked={applicablestate.includes("TAS")}
                        onChange={
                          handleChange
                      }
                        className="custom-control-input"
                        />
                      <span className="custom-control-label">TAS</span>
                    </label>
                  </div>
                  <div className="d-block">
                    <label className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        name="checkbox9"
                        value={"ACT"}
                        checked={applicablestate.includes("ACT")}
                        onChange={handleChange}
                        className="custom-control-input"
                      />
                      <span className="custom-control-label">ACT</span>
                    </label>
                    <label className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        name="checkbox7"
                        value={"NT"}
                        checked={applicablestate.All?true: applicablestate.includes("NT")}
                        onChange={handleChange}
                        className="custom-control-input"
                    // onClick={allstate}
                      />
                      <span className="custom-control-label">NT</span>
                    </label>
                    <label className="custom-control custom-checkbox"
                    >
                      <input
                        type="checkbox"
                        name="checkbox7"
                        value={"All"}
                        checked={applicablestate.includes("All")}
                        // onChange={handleChange}
                        onClick={()=>allstate()}
                        className="custom-control-input"
                      />
                      <span className="custom-control-label">ALL</span>
                    </label>
                
                    
                  </div>
                </div>
              </div>
              <div className="container">
             <label htmlFor="">Notes</label>
              <textarea className="form-control " onChange={(e)=>setNotes(e.target.value)} value={notes}></textarea>
             </div>
            </div>

            <div className="d-flex justify-content-center">
              <button type="submit" className="btn btn-primary m-2">
                Submit
              </button>
            </div>
          </form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Addpromo;
