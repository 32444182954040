import React from "react";

const PaymentPropertyAddress = (props) => {
  return (
    <div
      className="property-address mt-7 "
      //   style={{ border: "1px solid black" }}
    >
      <div className="text-center my-4">
        <h4> Property Details</h4>
      </div>
      <div className="m-3">
        <div className="pt-2 pb-2 d-flex justify-content-between">
          <span style={{ fontSize: "1.3rem" }}>Property address :</span>
          <span>{props.propertyAddress}</span>
        </div>
        <div className="pt-2 pb-2 d-flex justify-content-between">
          <span style={{ fontSize: "1.3rem" }}>Seller Agent :</span>
          <span>{props.sellerAgent}</span>
        </div>
        <div className="pt-2 pb-2 d-flex justify-content-between">
          <span style={{ fontSize: "1.3rem" }}>Seller Agent Phone :</span>
          <span>{props.SellerAgentPhone}</span>
        </div>
        <div className="pt-2 pb-2 d-flex justify-content-between">
          <span style={{ fontSize: "1.3rem" }}>Seller Agent Email</span>
          <span>{props.SellerAgentEmail}</span>
        </div>
      </div>
    </div>
  );
};

export default PaymentPropertyAddress;
