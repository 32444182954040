import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { useNavigate } from "react-router-dom";
import newImage from ".././../../assets/images/ZZ5H.gif"
import axios from "axios";

export const DataTables = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  // const [paymentData, setPaymentData] = React.useState([]);
  const handleFeeDetailsApi = () => {
    return axios
      .get(
        `${process.env.REACT_APP_BACKEND_DATA}/api/payment-history/single-payment-history`,

        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
        }
      )

      .then((response) => {
        setData(response.data);
        return response.data;
      })
      .catch((error) => {});
  };

  useEffect(() => {
    handleFeeDetailsApi();
  }, []);
  function ExportFunction(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = ExportFunction(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <div
      className="exportFunction"
      onClick={(e) => onExport(e.target.value)}
      style={{ color: "blue", cursor: "pointer" }}
    >
      Download
    </div>
  );
  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(data)} />,
    []
  );

  const columns = [
    {
      name: "Task ID",
      selector: (row) => [row.taskId],
      sortable: true,
    },
    {
      name: " Task Name",
      selector: (row) => [row.taskName],
      sortable: true,
    },
    {
      name: "Payment Amount",
      selector: (row) => [row.paymentAmount],
      sortable: true,
    },
    {
      name: "Payment Date",
      selector: (row) => [row.paymentDate],
      sortable: true,
    },
  ];
  const tableDatas = {
    columns,
    data,
  };
  return (
//     <>{data.length === 0 ?
//  <div
//           style={{
//             height: "80vh",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <img style={{ maxWidth: "7%" }} src={newImage} />
//         </div>: 
//     <DataTableExtensions {...tableDatas}>
//       <DataTable
//         columns={columns}
//         data={data}
//         pagination
//         highlightOnHover
//         responsive
//         dense
//       />
//     </DataTableExtensions>
//     }
    // </>
    <>
    <h1>No
records found</h1>
    </>
  );
};
