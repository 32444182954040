import React, { useContext, useEffect, useState, Buffer } from "react";
import { Card } from "react-bootstrap";
import AdminContext from "../../adminContext/AdminContext";
import EditBlog from "./blogAndEdit/EditBlog";
import "./blog.css";
import axios from "axios";

// const fileToDataUri = (file) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onload = (event) => {
//       resolve(event.target.result);
//     };
//     reader.readAsDataURL(file);
//   });
const GetBlog = (props) => {
  const [allBlogData, setAllBlogData] = useState([]);
  const admincontext = useContext(AdminContext);
  const { getAllBlog, setHandleModal } = admincontext;
  const [dummy, setDummy] = useState();
  const [initialValue, setInitialValue] = useState([
    {
      title: "",
      description: "",
      _id: "",
      blogImage: "",
    },
  ]);

  const [dataUri, setDataUri] = useState("");

  //   const newData = (file) => {
  //     if (!file) {
  //       setDataUri("");
  //     }

  //     fileToDataUri(file).then((dataUri) => {
  //       setDataUri(dataUri);
  //     });
  //   };

  //   const downloadImage = (type) => {
  //     const url = `${process.env.REACT_APP_BACKEND_DATA}/download-image?fileName=${type}`;
  //     axios
  //       .get(url, { responseType: "blob" })
  //       .then((response) => {
  //         newData(response.data);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   };

  const getAllBlogData = async () => {
    setAllBlogData(await getAllBlog());
  };

  useEffect(() => {
    getAllBlogData();
  }, [props.changeState]);
  const handleEdit = (title, description, _id, blogImage) => {
    setHandleModal(true);
    setInitialValue({
      title,
      description,
      _id,
      blogImage,
    });
    setShowModal(!showModal);
  };
  const deleteBlog = (_id) => {
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/blog/delete-blog`;
    const data = { _id: _id };

    axios({
      url: url,
      method: "delete",
      data: { _id: _id },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "admin-token": localStorage.getItem("admin-token"),
      },
    })
      .then(function (response) {
        props.setChangeState(!props.changeState);
      })
      .catch((error) => {});
  };
  const [showModal, setShowModal] = useState(false);

  const uploadCallback = (file, callback) => {
    console.log(file);
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader();
      console.log(reader);
      reader.onloadend = async () => {
        const form_data = new FormData();
        form_data.append("file", file);
        const res = await uploadFile(form_data);
        setValue("thumbnail", res.data);
        resolve({ data: { link: process.env.REACT_APP_API + res.data } });
      };
      reader.readAsDataURL(file);
    });
  };

  const config = {
    image: { uploadCallback: uploadCallback },
  };
  return (
    <div>
      {allBlogData &&
        allBlogData.map((item, ind) => (
          <Card key={ind} className="card-blog-card">
            <div className="card-blog-body">
              <div className="card-blog-image">
                {<img src={`https://just.justnests.com.au/${item.blogImage}`} alt="" />}
              </div>
              <div className="card-blog-details">
                <div className="admin-card-blog-title">{item.title}</div>
                <div
                  className="admin-card-blog-description"
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              </div>
              <div className="blog-delete-and-edit">
                <span>
                  {" "}
                  <i
                    className="zmdi zmdi-edit"
                    style={{
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleEdit(
                        item.title,
                        item.description,
                        item._id,
                        item.blogImage
                      );
                    }}
                  />
                </span>
                <span onClick={() => deleteBlog(item._id)}>
                  <i
                    className="zmdi zmdi-delete"
                    style={{
                      fontSize: "20px",
                      cursor: "pointer",
                      color: "#b73232",
                    }}
                  />
                </span>
              </div>
            </div>
          </Card>
        ))}
      {showModal ? (
        <EditBlog
          title={initialValue.title}
          description={initialValue.description}
          _id={initialValue._id}
          setChangeState={props.setChangeState}
          blogImage={initialValue.blogImage}
          changeState={props.changeState}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default GetBlog;
