import React from "react";
import DataTables, * as datatable from "./TableFormat";
import { Row,Card, Col} from "react-bootstrap";

function Table({userData ,fetchData}) {
 return (
  <div>
   <Row className=" row-sm">
    <Col lg={12}>
     <Card>
      {/* <Card.Body> */}
       <div className="table-responsive">
        <DataTables  data={userData} fetchData={fetchData}/ >
       </div>
      {/* </Card.Body> */}
     </Card>
    </Col>
   </Row>
  </div>
 );
}

export default Table;
