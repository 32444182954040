import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import { Tooltip } from 'react-tippy';

import UserApiContext from "../UserApi/UserApiContext";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";

import "./EngageSolicitorMain.css";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
const EngageSolicitorMain = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, []);
//............promo data..................//
const [promodata, setPromodata] = useState(0);
const [allpromodata, setAllpromodata] = useState();
const [promoApplied, setpromoApplied] = useState("false");
const [userPromoCode, setUserPromo] = useState();


const sendusedpromodata = () => {
  let promoName = allpromodata.promoName;
  let promoCode = userPromoCode;
  let service = "Engage Solicitor / Conveyancer";
  let custumerName = userInfo.name;
  let custumerId = allData.user_id;
  let Ammount = allpromodata.discountAmount;
  // let custumerId = userInfo.email;
  let companyName = allData.agencyName;
  axios
    .post(`${process.env.REACT_APP_BACKEND_DATA}/used-promo`, {
      promoCode,
      promoName,
      service,
      custumerId,
      custumerName,
      companyName,
      Ammount,
      id,
    })
    .then((response) => {})
    .catch((error) => {
      console.error(error);
    });
};


const validateuserPromo = () => {
  if(findType == "Conveyancer" || findType == "Solicitor"){
    console.log(1234);

  
  let userPromo = userPromoCode;
  let userState = allData.lookingToBuy;
  let userServise = "Engage Solicitor / Conveyancer";


  axios
    .post(`${process.env.REACT_APP_BACKEND_DATA}/verify-promo`, {
      userPromo,
      userState,
      userServise,
    })
    .then((response) => {

      console.log(response);
      if (response.data.promodata) {
        setPromodata(response.data.promodata.discountAmount);
        setpromoApplied("true");
        setAllpromodata(response.data.promodata);
        // sendusedpromodata()
      }
      if (response.data.msg == "Successfully Applied Promocode") {
        toast.success(`${response.data.msg}`, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(response.data.msg, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      
    })
    .catch((error) => {
      console.error(error);
    });
  }else{
    toast.error("Please select service", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  }
};

//....................................................//

  const [allData, setAllData] = useState([]); // getting single data

  const [allFeeDetails, setAllFeeDetails] = useState([]);
  let initialValue = { message: "" };
  const [message, setMessage] = useState(initialValue);

  const location = useLocation();
  const UserApi = useContext(UserApiContext);
  const { sendDashboardData, callingTaskMailer, feeDetails, loggedInUserData } =
    UserApi;
  let id = location.state.id;
  console.log('allData',allData)
  const fees = {
    solicitor: {
      "full fees": location.state.solicitor.fullFee ?  location.state.solicitor.fullFee : '',
      "Upfront fees": location.state.solicitor.upfrontFee,
    },
    conveyancer: {
      "full fees": location.state.conveyancer.fullFee,
      "Upfront fees": location.state.conveyancer.upfrontFee,
    },
  };
  
  const [findType, setFindType] = useState("");
  var feees = findType == "Solicitor"
  ? fees.solicitor["Upfront fees"]
  : findType == "Conveyancer"
  ? fees.conveyancer["Upfront fees"]
  : "0"

  feees = +feees - +promodata
  let todayDate = Date.parse(new Date());

  const onChange = (e) => {
    setMessage({ ...message, [e.target.name]: e.target.value });
  };
  //Caling the get function to get all the fee Details
  const callingFeedetails = async () => {
    setAllFeeDetails(await feeDetails());
  };
  useEffect(() => {
    callingFeedetails();
  }, []);

  const [userInfo, setUserInfo] = useState([]);

  const Authtoken = localStorage.getItem("token");
  const token = async () => {
    const newData = await loggedInUserData(Authtoken);

    setUserInfo(newData);
  };
  useEffect((e) => {
    token();
  }, []);

  const [selectfee,setselectfee]=useState()
  const [active, setActive] = useState(true);

  const solicitorAndConveyancerSubmit = (item) => {
    const newData = {
      message: item.applicationType.message,
      name: userInfo.name,
      email: userInfo.email,
      phone: userInfo.phone,
      progress: "In Progress",
      state: item.applicationType.selectState,
      upfrontFee:findType == "Solicitor"
      ? fees.solicitor["Upfront fees"]
      : findType == "Conveyancer"
      ? fees.conveyancer["Upfront fees"]
      : "",
      type: item.applicationType.type,
      createdDate: item.applicationType.createdDate,
      taskId: item.applicationType.task_id,
    };
    if(promoApplied == "true"){
      sendusedpromodata()
    }

    sendDashboardData(newData, id);
    setActive(false);

    //calling mail api
    const mailData = {};
    mailData.customerName = allData.name;
    mailData.requestType = newData.type;
    mailData.taskid = newData.taskId;
    mailData.custId = id;
    mailData.Ammount = allpromodata != undefined ?allpromodata.discountAmount :"N/A";


    mailData.customerId = allData.user_id;
    mailData.customerEmailId = allData.email;
    mailData.customerPhoneNumber = allData.phone;
    mailData.purchaseType = allData.propertyPlan;
    mailData.propertyType = allData.propertType;
    mailData.propertyAddress = allData.address;
    if (allData.lookingToBuy != "N/A") {
      mailData.propertyState = allData.lookingToBuy;
    } else {
      mailData.propertyState = allData.propertyState;
    }
    mailData.instructionFromCustomer = newData.message;

    callingTaskMailer(mailData);
  };

  const dummy = async () => {

    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/application/view-single-application/${id}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      });
      const initialValue = [];
      response.data.TASKNAME.map((newData) => {
        initialValue.push(newData);
      });
      // setGettingData(initialValue);
      setAllData(response.data);
      // console.log
    } catch (error) {}

    
  };
  useEffect(() => {
    dummy();
  }, []);
  return (
    <>
      {active ? (
        <Card className="col-md-10">
          <div>
            <div>
              <Card.Header style={{ textAlign: "center", display: "block" }}>
                <h1>Who do you want to engage <Tooltip title="We recommend combined building and pest inspection if you are unsure"> <span class="mdi mdi-information-variant bg-info rounded-circle px-1 text-white"  data-toggle="tooltip" data-placement="right" ></span></Tooltip></h1>
              </Card.Header>
              <div
                className="solicitor-card-outer  "
                style={{ margin: "40px 111px" }}
              >
                {" "}
                <Card
                  className="solicitor-card-inner  pt-5 pb-5 selectable-button border"
                  style={{
                    background:
                      findType == "Solicitor"
                        ?  "#69C2A2"
                        : "#c8dfe8",
                    color: "#2e2e2ec4",
                    cursor: "pointer",
                    // backgroundColor: "#69C2A2"
                  }}
                  onClick={() => setFindType("Solicitor")}
                >
                  <Card.Body>
                    {/* <input
                      type="radio"
                      checked={findType == "Solicitor" ? true : false}
                      readOnly
                      style={{ height: "20px", width: "100%" }}
                    /> */}
                    <Card.Header
                      className="mb-5"
                      style={{ textAlign: "center" }}
                    >
                      <h1>Solicitor</h1>
                    </Card.Header>
                    <div className="text-center">
                      <span
                        style={{
                          fontSize: "30px",
                          fontWeight: "bold",
                          color: "rgb(98 89 202)",
                          
                        }}
                      >
                        {fees.solicitor["full fees"]}
                      </span>{" "}
                      INC GST
                    </div>
                    <ul>
                      <li>
                        <i className="fe fe-check-circle mx-1" />
                        Legals
                      </li>
                      <li>
                        <i className="fe fe-check-circle mx-1" />
                        Disbursements
                      </li>
                      <li>
                        <i className="fe fe-check-circle mx-1" />
                        Goverment Searches
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
                <Card
                  className="solicitor-card-inner pt-5 pb-5 selectable-button border"
                  style={{
                    background:
                      findType == "Conveyancer"
                        ? "#69C2A2"
                        : "#c8dfe8",
                    color: "#2e2e2ec4",
                    cursor: "pointer",
                    // backgroundColor:"#e6b5df"
                  }}
                  onClick={() => setFindType("Conveyancer")}
                >
                  <Card.Body>
                    {/* <input
                      type="radio"
                      checked={findType == "Conveyancer" ? true : false}
                      readOnly
                      style={{ height: "20px", width: "100%" }}
                    /> */}
                    <Card.Header
                      className="mb-5"
                      style={{ textAlign: "center" }}
                    >
                      <h1>Conveyancer</h1>
                    </Card.Header>
                    <div className="text-center">
                      <span
                        style={{
                          fontSize: "30px",
                          fontWeight: "bold",
                          color: "rgb(98 89 202)",
                     
                        }}
                      >
                        {fees.conveyancer["full fees"]}
                      </span>{" "}
                      INC GST
                    </div>
                    <ul>
                      <li>
                        <i className="fe fe-check-circle mx-1" />
                        Legals
                      </li>
                      <li>
                        <i className="fe fe-check-circle mx-1" />
                        Disbursements
                      </li>
                      <li>
                        <i className="fe fe-check-circle mx-1" />
                        Goverment Searches
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
              </div>

              <Card
                className="col-md-10 pt-2 pb-2"
                style={{ background: "hwb(231deg 95% 2%)", color: "#2e2e2ec4" }}
              >
                <Card.Body>
                  <div>
                    Upfront fees payable{" "}
                    <span
                      style={{
                        color: "rgb(98 89 202)",
                        fontWeight: "600",
                        fontSize: "25px",
                        
                      }}
                    >
                      {findType == "Solicitor"
                        ? fees.solicitor["Upfront fees"]
                        : findType == "Conveyancer"
                        ? fees.conveyancer["Upfront fees"]
                        : ""}
                      
                    </span>
                  </div>
                  <div>Remaining will be adjusted during settlement</div>
                </Card.Body>

                </Card>


              <Card
                className="col-md-10 pt-2 pb-2"
               
              >
                <Card.Body >
                  <div className="d-flex">
                  <input
                  type="text"
                  className=" m-2 form-control"
                  onChange={(e) => setUserPromo(e.target.value)}
                  placeholder="Apply your promocode if you have any?"
                  style={{ width: "17rem" }}
                />
                <Button
                  onClick={validateuserPromo}
                style={{width:"fit-content"}}

                  className="m-2 building-inspection-button "
                >
                  Apply Promo Code
                </Button>
                  </div>

                </Card.Body>

                </Card>
                <div className="">
             
              {/* <div className="col-lg-6 col-md-6 col-sm-12 text-center "> */}
                
              {/* </div> */}

              {promodata ? (
                <>
                  <div className="mt-4 text-center">
                    <h3>
                      Congratulations! you have received ${promodata} discount{" "}
                    </h3>
                    <h4>New Legal  Fee is {findType == "Solicitor" ? +fees.solicitor["full fees"] - +promodata: +fees.conveyancer["full fees"] - +promodata}</h4>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
              <div style={{ textAlign: "center" }}>
                <textarea
                type="textarea"
                   style={ {border: "1px solid black",borderRadius: "5px"}}
                  placeholder="Any Special Instruction to Legal ?"
                  className=" col-md-10 mt-5 mb-5"
                  name="message"
                  onChange={onChange}
                  id="solicitorConveyancer"
                ></textarea>{" "}
              </div>
              <div style={{ textAlign: "center" ,fontSize: "smaller"}}>
<b>Disclamer:</b> The cost is indicative only, the expert will provide an accurate quote upon contact which might be slightly lower or higher than what is displayed here.
            </div>
              <div className="text-center">
                <button
                  className="m-4 w-20 border"
                  style={{ borderRadius: "5px", padding: "9px" }}
                  onClick={() =>
                    navigate("/application-dashboard ", {
                      state: {
                        id: id,
                      },
                    })
                  }
                >
                  Go Back
                </button>
                <Button
                  className="m-4 w-25 "
                  onClick={() => {
                    // handleSubmit();
                    findType
                      ? solicitorAndConveyancerSubmit({
                          applicationType: {
                            applicationType: "solicitorAndConveyancer",
                            type:
                              findType == "Solicitor"
                                ? "Solicitor"
                                : "Conveyancer",
                            fullFee:
                              findType == "Solicitor"
                                ? fees.solicitor["full fees"]
                                : fees.conveyancer["full fees"],
                            upfrontFee:
                              findType == "Solicitor"
                                ? fees.solicitor["Upfront fees"]
                                : fees.conveyancer["Upfront fees"],
                            selectState:
                              findType == "Solicitor"
                                ? location.state.solicitor.partnerState
                                : location.state.conveyancer.partnerState,
                            message: message.message,
                            createdDate: todayDate,
                            taskId: uuidv4(),
                          },
                        })
                      : toast.warning("please select 1 option", {
                          position: "top-center",
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                  }}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </div>
        </Card>
      ) : (
        <Card className="mt-9 pt-7 pb-0 col-md-7">
          <Card.Header className="mx-auto" style={{ textAlign: "center" }}>
            <h2>
              All done! Sit back and relax. Our Legal expert will get in touch
              with you
            </h2>
          </Card.Header>
          <Link className="mt-4"  style={{ textAlign: 'center' }} target="_blank" to={'/blog-page'}><span  className="btn btn-success btn-icon text-white mb-3">Go to blog page</span></Link>
          <div style={{ textAlign: "center" }} className="my-6">
            <button
              className="btn btn-primary btn-icon text-white mb-4 p-3"
              onClick={() => {
                navigate("/application-dashboard", {
                  state: {
                    id: id,
                  },
                });
              }}
            >
              Go back to Application Dashboard
            </button>
          </div>
        </Card>
      )}
    </>
  );
};

export default EngageSolicitorMain;
