import React, { useState, useContext, useEffect } from 'react';
import { Button, Card, ProgressBar } from 'react-bootstrap';
import PropertyPlan from './PropertyPlan';
import TypeOfProperty from './TypeOfProperty';
import ShortListedProperty from './ShortListedProperty';
import PropertyDetails from './PropertyDetails';
import LookingToBuy from '../ApplicationData/LookingToBuy';
import UserApiContext from '../UserApi/UserApiContext';
import { useLocation, useNavigate } from 'react-router-dom';

const ApplicationData = (props) => {

const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/login');
    }
  }, []);

  const apiContext = useContext(UserApiContext);
  const { newApplication } = apiContext;

  const location = useLocation();

  let id = '';
  if (location.state.type == 'application-data') {
    id = newApplication._id;
  } else {
    id = location.state.id;
  }

  const [active, setActive] = useState(1);
  const changeState = (data) => {
    setActive(active + data);
  };
  let now = 0;
  if (active == 1) {
    now = 20;
  } else if (active == 2) {
    now = 40;
  } else if (active == 3) {
    now = 80;
  } else if (active == 4 || active == 5) {
    now = 99;
  }

  return (
    <div>
      <ProgressBar now={now} />
      <Card className="mt-7 d-flex justify-content-center align-items-center col-md-5">
        <div>
          {active === 1 && <PropertyPlan changeState={changeState} />}
          {active === 2 && <TypeOfProperty changeState={changeState} />}
          {active === 3 && <ShortListedProperty changeState={changeState} />}
          {active === 4 && (
            <PropertyDetails changeState={changeState} id={id} />
          )}
          {active === 5 && <LookingToBuy changeState={changeState} id={id} />}
        </div>

        <div className="col-md-9">
          <Button
            className="text-white col-md-3 block mb-4"
            disabled={active === 1 ? true : ''}
            style={{ margin: 'auto' }}
            onClick={() => {
              active >= 0 && active <= 4
                ? setActive(active - 1)
                : setActive(active - 2);
            }}
          >
            <i className="fe fe-arrow-left-circle " /> Back{' '}
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default ApplicationData;
