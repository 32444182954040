import React, { useState, useContext } from 'react';
import { Card, Table } from 'react-bootstrap';
import PartnerEditModal from './ParnterEditModal';
import AdminContext from '../../../../adminContext/AdminContext';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import PartnerCategoryCard1 from './PartnerCategoryCard1'

function PartnerHistory({ partnerData, linkData }) {
  const [filterData, setFilterData] = useState();
  const [remainData, setRemainData] = useState();
  const dashboardContext = useContext(AdminContext);
  const { setHandlePartnerModal } = dashboardContext;
  let array1 = [partnerData];
  let filteredData;
  let remainingData;
  const navigate = useNavigate();

  const handleEdit = (state) => {
    filteredData = partnerData.filter((data) => {
      return data.partnerState === state;
    });
    remainingData = partnerData.filter((data) => {
      return data.partnerState !== state;
    });
    setRemainData(remainingData);
    setFilterData(filteredData);
    setHandlePartnerModal(true);
  };
  // console.log('2', filterData);

const handleDelete = (data)=>{
  let ID = linkData._id
 let url = `${process.env.REACT_APP_BACKEND_DATA}/delete-partner-details`
   
   axios
        .post(url,{ID, data})
        .then((response) => {})
        .catch((error) => {});
// console.log(data);
// console.log(remainData);
// console.log(filterData);
console.log(linkData._id, data);
navigate("/admin/partner-details/")
}


  return (
    <div>
      {partnerData &&
        array1.map((data, index) => {
          return (
            <Card
              style={{
                display:
                  linkData.partnerCategory === 'Depreciation Schedule' ||
                  linkData.partnerCategory === 'Mortgage Broker' ||
                  linkData.partnerCategory === 'Property Manager' ||
                  linkData.partnerCategory === 'Accountant'
                    ? 'none'
                    : 'block',
              }}
              key={index}
            >
              <Card.Header>
                <h2>Partner Entries</h2>
              </Card.Header>
              {data.map((details, ind) => {
                return (
                  <div key={ind}>
                    <Card.Body
                      style={{
                        display:
                          linkData.partnerCategory === 'Solicitor' ||
                          linkData.partnerCategory === 'Conveyancer'
                            ? 'block'
                            : 'none',
                      }}
                    >
                      <button
                        className="border px-2 py-0"
                        style={{
                          position: 'relative',
                          left: '90%',
                          color: '#e70505',
                          fontSize: '18px',
                        }}
                        onClick={() => handleEdit(details.partnerState)}
                      >
                        Edit
                      </button>
                      <button
                        className="border px-2 py-0"
                        style={{
                          position: 'relative',
                          left: '90%',
                          color: '#e70505',
                          fontSize: '18px',
                        }}
                        onClick={() => handleDelete(details)}
                      >
                        Delete
                      </button>
                      <div className="d-flex w-50 my-2">
                        <div style={{ width: '150px', margin: '0 4rem' }}>
                          State :
                        </div>
                        <div style={{ fontWeight: 'bold' }}>
                          {details.partnerState}
                        </div>
                      </div>
                      <div className="d-flex w-50 my-2">
                        <div style={{ width: '150px', margin: '0 4rem' }}>
                          Full Fee :
                        </div>
                        <div style={{ fontWeight: 'bold' }}>
                          {details.fullFee}
                        </div>
                      </div>
                      <div className="d-flex w-50 my-2">
                        <div style={{ width: '150px', margin: '0 4rem' }}>
                          Upfront Fee :
                        </div>
                        <div style={{ fontWeight: 'bold' }}>
                          {details.upfrontFee}
                        </div>
                      </div>
                      <hr></hr>
                    </Card.Body>
                    <Card.Body
                      className="pb-8 mt-5"
                      style={{
                        display:
                          linkData.partnerCategory ===
                          'Building and Pest Inspection'
                            ? 'block'
                            : 'none',
                      }}
                    >
                      <button
                        className="border px-2 py-0"
                        style={{
                          position: 'relative',
                          left: '90%',
                          color: '#e70505',
                          fontSize: '18px',
                        }}
                        onClick={() => handleEdit(details.partnerState)}
                      >
                        Edit
                      </button>
                      <button
                        className="border px-2 py-0"
                        style={{
                          position: 'relative',
                          left: '90%',
                          color: '#e70505',
                          fontSize: '18px',
                        }}
                        onClick={() => handleDelete(details)}
                      >
                        Delete
                      </button>
                      <div className="d-flex my-2">
                        <div style={{ width: '70px' }}>State :</div>
                        <div>{details.partnerState}</div>
                      </div>
                      <Table className="table-responsive">
                        <thead>
                          <tr>
                            <th></th>
                            <th className="">Building Inspection</th>
                            <th className="">Pest Inspection</th>
                            <th className="">
                              Combined Building & Pest Inspection{' '}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="text-center border">
                            <td>1 Bedroom --</td>
                            <td>{details.oneB}</td>
                            <td>{details.oneP}</td>
                            <td>{details.oneC} </td>
                          </tr>
                          <tr className="text-center border">
                            <td>2 Bedroom --</td>
                            <td>{details.twoB}</td>
                            <td>{details.twoP}</td>
                            <td>{details.twoC}</td>
                          </tr>
                          <tr className="text-center border">
                            <td>3 Bedroom --</td>
                            <td>{details.threeB}</td>
                            <td>{details.threeP}</td>
                            <td>{details.threeC}</td>
                          </tr>
                          <tr className="text-center border">
                            <td>4 Bedroom --</td>
                            <td>{details.fourB}</td>
                            <td>{details.fourP}</td>
                            <td>{details.fourC} </td>
                          </tr>
                          <tr className="text-center border">
                            <td>4+ Bedroom --</td>
                            <td>{details.plusFourB}</td>
                            <td>{details.plusFourP}</td>
                            <td>{details.plusFourC}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card.Body>
                  </div>
                );
              })}
            </Card>
          );
        })}
      <PartnerEditModal
        filterData={filterData && filterData}
        remainData={remainData && remainData}
        linkData={linkData}
      />
        <div className='d-none'>
          {/* <PartnerCategoryCard1/> */}
        </div>
    </div>
  );
}

export default PartnerHistory;
