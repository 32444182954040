import React from "react";
import "./howToBuy.css";
import HowToBuyHome from "../../assets/images/homePage/howToBuyHome.jpg";

const HowToBuyBlogPage = () => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <div className="howToBuyBlogPageAfterImage">
        <span>How to buy your home</span>
      </div>
      <div className="howToBuy-main-blog-after ">
        <div className="howToBuy-blog-after">
          <div className="howToBuy-blog-after-image">
            <img src={HowToBuyHome} alt="" />
          </div>

          <div className="howToBuy-blog-after-description">
            <p className="howToBuy-blog-paragraph">
              Our first home is our dream and one of our biggest decision in
              life for most of us. It's the memory we cherish for the rest of
              our lives. Even though homes are being bough for hundreds of
              years, it's still a complicated process.
            </p>
            <p className="howToBuy-blog-paragraph">
              JustNests understands it and simplifies the home buying process
              using intuitive digital dashboard where you can go through the
              whole journey from getting home loan to beyond settlement with
              ease and stress free. Memories which last all our life should be
              pleasant.
            </p>

            <h2>Let's look at the process of buying your home</h2>
            <div className="howTobuy-fist-section">
              <div className="howTobuy-points-heading">
                <span>1.</span>{" "}
                <h3>Check if you are eligible for government grants</h3>
              </div>
              <p className="howToBuy-blog-paragraph">
                If you are buying a new property, depending on the value of the
                property you might be eligible to receive government First Home
                Buyer grants. Note that these grants vary from state to state
                Depending on the value of the property, you might also be
                eligible for the Federal Governement's First Home Guarantee
                scheme Your JustNests mortgage expert will assess your situation
                and provide you with all the grants you are eligible for.
              </p>
            </div>
            <div className="howTobuy-second-section">
              <div className="howTobuy-points-heading">
                <span>1.</span> <h3>Save for the house deposit</h3>
              </div>
              <p className="howToBuy-blog-paragraph">
                As obvious as it sounds, you need a deposit to start buying a
                house. It is recommended that you save 20% of the house value,
                but ensure you have saved at least 5% of the property value.
                There are some other costs involved which would be roughly
                around 5% of the property value.
              </p>
              <h3>For a new property: Around 2% to 5% of the property value</h3>
              <ul className="howToBuy-key-points">
                <li>5% loan deposit</li>
                <li>First Home Owner Grants from Governement (FHOG)</li>
                <li>
                  Stamp duty – Not required (Depending on FHOG eligibility)
                </li>
                <li>$1600 – Solicitor / Convyancer fee</li>
                <li>$2000 other costs</li>
              </ul>
              <h3>For an existing property: Around 5% of the property value</h3>
              <ul className="howToBuy-key-points">
                <li>5% loan deposit</li>
                <li>4% Stamp duty</li>
                <li>$1600 – Solicitor / Convyancer fee</li>
                <li>$2000 other costs</li>
              </ul>
              <p className="howToBuy-blog-paragraph">
                Lenders Mortgage Insurance (LMI) would be charged by the banks
                if your deposit is less than 20%. Normally this would be part of
                the home loan so you don't have to save for it.
              </p>
            </div>
            <div className="howTobuy-third-section">
              <div className="howTobuy-points-heading">
                <span>3.</span> <h3>Get loan Pre-Approval</h3>
              </div>
              <p className="howToBuy-blog-paragraph">
                Having pre-approval in hand before going on house hunting will
                give you peace of mind that your finance is under control. You
                can shortlist your dream home with confidence.
              </p>
              <p className="howToBuy-blog-paragraph">
                With the help of JustNests specialist mortgage broker expert
                with access to more than 30 lenders, you'll get a home loan
                pre-approval. This document will give you an idea of how much
                the lenders will lend you.
              </p>
              <p className="howToBuy-blog-paragraph">
                Getting pre-approval is a quick process compared to getting full
                loan approval. It might take one day to few weeks depending on
                your situation and it will typically be valid for 3 months.
              </p>
            </div>
            <div className="howTobuy-fourth-section">
              <div className="howTobuy-points-heading">
                <span>4.</span> <h3>Start finding your dream home</h3>
              </div>
              <h4>Now it's the exciting part! Finding your dream home.</h4>
              <p className="howToBuy-blog-paragraph">
                While there are many ways to go about it, easiest and most
                convenient way is to just head to one of the below websites and
                searching for available properties in the suburbs of your
                interest.
              </p>
              <h5>
                <a href="http://Realestate.com.au" target="_blank">
                  Realestate.com.au
                </a>
              </h5>
              <h5>Domain</h5>
              <p className="howToBuy-blog-paragraph">
                Shortlist few properties, inspect them and once you are fairly
                confident to make an offer, ensure you have a
                Solicitor/Convyencer in your team. Ensure you speak to your
                JustNests mortgage broker to get the valuation of the property
                before making an offer.
              </p>
            </div>
            <div className="howTobuy-fifth-section">
              <div className="howTobuy-points-heading">
                <span>5.</span> <h3>Engage Solicitor/Conveyancer</h3>
              </div>
              <p className="howToBuy-blog-paragraph">
                When transferring ownership of the home, you must ensure there's
                no legal issue affecting the ownership of the property. There
                should not be any litigation on the property and 100% ownership
                should be transferred to you.
              </p>
              <p className="howToBuy-blog-paragraph">
                There might be complications in the legal process which would be
                better managed by a professional expert. You should chose either
                a <span>Conveyancer or a Solicitor</span> to take care of all
                legal matters for you and give you a peace of mind.
              </p>
              <p className="howToBuy-blog-paragraph">
                Just click on 'Engage Solicitor/Conveyancer in your JustNests
                dashboard, you'll be contacted by a legal expert with experience
                of settling hundreds of properties.
              </p>
              <p className="howToBuy-blog-paragraph">
                They'll represent you and speak to the seller or their agent on
                your behalf while taking care of all the other things like
                preparing and lodging legal documents, getting reports,
                calculating the adjustment of rates and taxes, manage cooling
                off period, settling your property to name some.
              </p>
              <h3>
                Know whether you want a Solicitor or Conveyancer by your side
                during your journey.
              </h3>
            </div>
            <div className="howTobuy-sixth-section">
              <div className="howTobuy-points-heading">
                <span>6.</span>{" "}
                <h3>Get Building and Pest Inspection reports</h3>
              </div>
              <p className="howToBuy-blog-paragraph">
                This is one of the most overlooked step and we have seen many
                buyers regretting not doing years down the line. You should take
                expert report on whether the property you are buying is sound.
              </p>
              <p className="howToBuy-blog-paragraph">
                Go through the report thoroughly and talk to the inspector to
                understand if there are any major defects in the property which
                might be deal breakers.
              </p>
              <p className="howToBuy-blog-paragraph">
                Minor defects which can be fixed easily can help negotiate the
                price of the property.
              </p>
              <p className="howToBuy-blog-paragraph">
                JustNests will notify the experienced inspector to visit your
                property and do the due diligence on your behalf.
              </p>
            </div>
            <div className="howTobuy-seventh-section">
              <div className="howTobuy-points-heading">
                <span>7.</span> <h3>Get full approval of loan</h3>
              </div>
              <p className="howToBuy-blog-paragraph">
                Your broker will contact the bank to get full loan approval with
                the exact amount required to buy the property.
              </p>
              <p className="howToBuy-blog-paragraph">
                Your broker will request a copy of the signed contract of sale
                to proceed with the application. They'll also help you in
                choosing the right product and package if required to benefit
                you according to your situation.
              </p>
              <p className="howToBuy-blog-paragraph">
                Once the loan gets unconditionally approved, bank will send your
                contract documents. Read, sign and return them to the bank. Bank
                will then co-ordinate with your broker to book a settlement
                date.
              </p>
            </div>
            <div className="howTobuy-eigth-section">
              <div className="howTobuy-points-heading">
                <span>8.</span> <h3>Ensure you have proper insurance</h3>
              </div>
              <p className="howToBuy-blog-paragraph">
                If you are buying a stand alone house to live in, some lenders
                will mandatory ask you to buy Building insurance effective from
                the settlement date.
              </p>
              <p className="howToBuy-blog-paragraph">
                While your bank might refer you to an Insurer, we strongly
                suggest you to shop around and see what features and price suits
                better for you.
              </p>
              <p className="howToBuy-blog-paragraph">
                If you are buying a property in the strata complex, it would
                mostly be insured by the strata management. Kindly check with
                the seller agent to get a certificate and currency and send it
                to the bank up on request. No need to buy the building insurance
                on your own if it's already covered by Strata.
              </p>
              <p className="howToBuy-blog-paragraph">
                If you are buying an investment property, although it's not
                mandatory, we strongly suggest you to buy a Landlord Insurance
                to avoid any future rent loses or damages from the tenant.
              </p>
            </div>
            <div className="howTobuy-ninth-section">
              <div className="howTobuy-points-heading">
                <span>9.</span> <h3>Apply for grants</h3>
              </div>
              <p className="howToBuy-blog-paragraph">
                Depending on the state you are in, you might be eligible for one
                or more state and federal government grants or subsidies. Your
                broker would have already factored all these things at the
                beginning of the application and will help you to apply for
                them.
              </p>
            </div>
            <div className="howTobuy-tenth-section">
              <div className="howTobuy-points-heading">
                <span>10.</span> <h3>Settle the house</h3>
              </div>
              <p className="howToBuy-blog-paragraph">
                Congratulations! This is what we are all waiting for! Your
                JustNests legal expert and bank have scheduled to settle the
                property on a date. Legal expert will ensure the title of the
                property gets transferred to your name. This mostly would be
                done electronically and you'll receive notification once
                completed.
              </p>
              <p className="howToBuy-blog-paragraph">
                Stamp duty will be paid by your legal expert during this stage.
                Although it can be done typically within 30 days post
                settlement.
              </p>
              <p className="howToBuy-blog-paragraph">
                Stamp duty varies depending on the state and purchase price, you
                can check how much you have to pay using the below calculators
              </p>
              <h3>
                Please note, you might be eligible for some exemption. Your
                JustsNests Mortgage broker will take care of it for you.
              </h3>
              <ul className="howToBuy-key-points">
                <li>
                  <a href="#" target="_blank">
                    New South Wales
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    Victoria
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    Queensland
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    South Australia
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    Western Australia
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    Australian Capital Territory
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    Tasmania
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    Northern Territory
                  </a>
                </li>
              </ul>
              <h3>
                You just have to receive the keys from the selling agent and
                move to your dream home!
              </h3>
            </div>
            <div className="howTobuy-eleventh-section">
              <div className="howTobuy-points-heading">
                <span>11.</span> <h3>Organise Property Management</h3>
              </div>
              <p className="howToBuy-blog-paragraph">
                If you have bought an investment property, ensure you have a
                reliable property manager in place from the day of settlement.
                They'll help you with the pre-settlement inspection to ensure
                everything is in order as per the agreement and will co-ordinate
                with the selling agent or current property manager to get all
                the documents required to keep the ball rolling.
              </p>
              <p className="howToBuy-blog-paragraph">
                Property managers are crucial as your trusted partner to keep
                the property in best shape with regular inspections, helping you
                chose the best tenant and ensuring you are getting right rental
                increases periodically.
              </p>
              <p className="howToBuy-blog-paragraph">
                JustNests will help you chose the best property management
                company depending on the location your property is in.
              </p>
            </div>
            <div className="howTobuy-twelfth-section">
              <div className="howTobuy-points-heading">
                <span>12.</span> <h3>Get Depreciation Schedule</h3>
              </div>
              <p className="howToBuy-blog-paragraph">
                Many people forget to do this step.If you have an Investment
                property, you can get tax reduction for the capital work and
                normal wear and tear of your property.
              </p>
              <p className="howToBuy-blog-paragraph">
                However for the principle place of residence, you can claim
                these tax deductions for the portion of the house you are using
                for the business purpose.
              </p>
              <p className="howToBuy-blog-paragraph">
                JustNests quantity surveyor will assess the deductions for you
                and provide you with a depreciation schedule. What's more, our
                expert will assess the deductions and see if it makes sense to
                get a schedule. If it's not benifitial for you, then they'll not
                charge any fees to you.
              </p>
            </div>
            <div className="howTobuy-thirteen-section">
              <div className="howTobuy-points-heading">
                <span>13.</span> <h3>Engage an Accountant</h3>
              </div>
              <p className="howToBuy-blog-paragraph">
                It's the tax time again. While personal income taxes are
                straightforward, having a property might require an expert
                Accountant with experience in managing the deductions for
                properties can save you thousands of dollars during tax time.
              </p>
              <p className="howToBuy-blog-paragraph">
                JustNests expert Accountants are property investors themselves
                who knows the best for their clients. They'll help you manage
                the taxes stressfree.
              </p>
              <p className="howToBuy-blog-paragraph">
                Well, it all might seem a long process and lots of nuances.
                That's why JustNests provides you with a simple dashboard to get
                all these services with just a few clicks. You are free to chose
                one or more of the services depending on your need.
              </p>
              <p className="howToBuy-blog-paragraph">
                We look forward to be part of your beautiful home buying
                journey!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToBuyBlogPage;
