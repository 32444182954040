import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  
const PhotoUpload = () => {
    const [selectedFile, setSelectedFile] = useState("");
    const [uploadProgress, setUploadProgress] = useState(0);
    const [Hight, setHeight] = useState()
    const [Width, setWith] = useState()

    const handleFileSelect = (e) => {
        setSelectedFile(e.target.files[0]);
    };
    const dataFile = new FormData();
    dataFile.append("img", selectedFile);
    dataFile.append("Hight", Hight);
    dataFile.append("Width", Width);
    const handleClick = async() => {


if (selectedFile != '') {
  
  
  const url = `${process.env.REACT_APP_BACKEND_DATA}/signature`
          axios
          .post(url, dataFile, {
            responseType: "arraybuffer",
            headers: {
              Accept: "application/pdf",
            
              
            },
          })
          .then((response) => {
            
          })
          .catch((error) => {});
          toast.success('Uploaded', {
            position: 'top-center',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // setSelectedFile("")
          window.location.reload();
        }else{
          toast.info('Please select file', {
            position: 'top-center',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
const [download,setdownload]= React.useState()
        const handleDownload=() => {
          
         

            axios.get(`${process.env.REACT_APP_BACKEND_DATA}/signature-download`)
              .then(response => {
                console.log(response);
                const obj= response.data
                var down = obj.data.title

           window.open(`${process.env.REACT_APP_BACKEND_DATA}/${down}`)
                
              })
              .catch(error => {
                console.log(error);
              });
            
        }
    
     

    return (
        <div>

<ToastContainer />
<div class="card">
  
  <div class="card-body">
    <h1 class="card-title">Upload Signature</h1>
   <div className="row">
    <div className="col-6">
Choose Image
            <input type="file" defaultValue={selectedFile}  style={{width:"100%"}} className='form-control' onChange={handleFileSelect} />
    </div>
    <div className="col-6">
        <div className="row">
            {/* <div className="col-6">

    Height
            <input type="number" onChange={(e)=> setHeight(e.target.value)}  className='form-control' />
            </div> */}
            <div className="col-6">
                
    Width
            <input type="number" onChange={(e)=> setWith(e.target.value)}  className='form-control' />
            </div>
        </div>
<button  className='btn btn-primary m-2 text-center'  onClick={handleClick}>Upload</button>

            <button  onClick={handleDownload} className='btn btn-primary m-2'>Download</button>
    </div>
   </div>
 
  </div>
</div>

            {/* <div>Upload Progress: {uploadProgress}%</div> */}
        </div>
    );
};

export default PhotoUpload;
