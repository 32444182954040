import React, { useState, useContext, useEffect } from "react";
import { Button, Card, Form, Modal, ModalBody } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import AdminContext from "../../../adminContext/AdminContext";
import axios from "axios";
import "./taskModal.css";
import { toast } from 'react-toastify';
import d from './img/d.png'
// for Building Inspection, Pest Inspection, Combined building and pest inspection and Depreciation schedule

console.log('Hello this is task 3');


function TaskType3() {
  const location = useLocation();
  let data = location.state.linkData[0][0];

  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState();
  const contextForCompanyName = useContext(AdminContext);
  const { handlePartnerApi } = contextForCompanyName;
  const [cancelDropdown, setCancelDropdown] = useState(false);
  const [requiredDropdown, setRequiredDropdown] = useState(false);
  const taskId = data.task_id
const type = data.type
  console.log(type);
  const callingApiForContext = async () => {
    setCompanyData(await handlePartnerApi());
  }; 
  useEffect(() => {
    callingApiForContext();
  }, []);

  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  
  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;
  
  const formattedToday = dd + '/' + mm + '/' + yyyy;


  const initialValue = {
    assignTo: data.assignTo,
    assignDate: data.assignDate,
    reportDate: "",
    progress: data.progress,
    cancelText: "",
    cancelAttachedFile: "",
    notRequiredText: "",
    notRequiredAttachedFile: "",
  };
  const [formInput, setFormInput] = useState(initialValue);
  const [file, setFile] = useState();
  const [file2, setFile2] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/application/update-dashboard-tasks`;
    const dataD = {
      _id: data._id,
      type: data.type,
      progress: formInput.progress,
      assignTo: formInput.assignTo,
      assignDate: formInput.assignDate,
      reportDate: formInput.reportDate,
      completeDate: formInput.progress === "Completed" ?  formattedToday : "N/A",
      reason: formInput.cancelText
        ? formInput.cancelText
        : formInput.notRequiredText,
    };
    axios
      .post(url, dataD, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {})
      .catch((error) => {}); 

      console.log(data._id,data.type,);
      console.log(file2,file,taskId);
    const url2 = `${process.env.REACT_APP_BACKEND_DATA}/upload`;
    const dataFile = new FormData();
    // dataFile.append("_id", data._id);
    dataFile.append("type", data.type);
    dataFile.append("taskId", taskId);
    dataFile.append("reportFile", file);
    dataFile.append("reasonFile", file2);
    axios
      .post(url2, dataFile, {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf",
          "admin-token": localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {})
      .catch((error) => {});
    navigate("/admin/dashboard");
    setFormInput(initialValue);
  };

  const handleChange = (e) => {
    setFormInput((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });
  };

  let newCompanyData =
    companyData &&
    companyData.filter((value) => {
      if (value.partnerCategory === "Building and Pest Inspection") {
        return value.companyName;
      }
    });

  let newCompanyData2 =
    companyData &&
    companyData.filter((value) => {
      if (value.partnerCategory === "Depreciation Schedule") {
        return value.companyName;
      }
    });

  const timepass = () =>
    newCompanyData2 &&
    newCompanyData2.map((data) => {
      return <option> {data.partnerName}</option>;
    });
  const timepass2 = () =>
    newCompanyData &&
    newCompanyData.map((data) => {
      return <option> {data.partnerName}</option>;
    });


    const getProducts1=async(tasktype,filetype,taskid)=>{
      console.log(tasktype,filetype,taskid)
     let result1 = await fetch(`${process.env.REACT_APP_BACKEND_DATA}/download_single/${tasktype}/${filetype}/${taskid}`)
 
     let result = await result1.json();
   
 
     if(result.data !="undefined" && result.data != null)
     {
       console.log('this is productttt 1',result.data);
       window.open(`${process.env.REACT_APP_BACKEND_DATA}/${result.data}`);
      //  console.log('this is productttt',result.data);
     }
     else
     { toast.warning("Letter is not available at this stage", {
       position: "top-center",
       autoClose: 1000,
       hideProgressBar: false,
       closeOnClick: true,
       pauseOnHover: true,
       draggable: true,
       progress: undefined,
     });
      //  console.log('this is productttt',products2);
     }  
    }
    console.log(data);
  return (
    <div className="task-type-three">
      <form onSubmit={handleSubmit}>
        <div className="task-cards-group">
          <Card>
            <h4>Task Info</h4>
            <hr />
            <div className="card-inner-div">
              <div className="task-info-heading"> Task Id :</div>{" "}
              <div style={{ marginLeft: "15px", fontWeight: "500" }}>
                {taskId}
              </div>
            </div>
            <hr />
            <div className="card-inner-div">
              <div className="task-info-heading"> Task Name : </div>
              <span style={{ marginLeft: "15px", fontWeight: "500" }}>
                {data.type}
              </span>
            </div>
            <hr />
            <div className="card-inner-div">
              <div className="task-info-heading"> Creation Date :</div>{" "}
              <span style={{ marginLeft: "15px", fontWeight: "500" }}>
                {new Date(data.createdDate).toLocaleDateString("en-IN")}
              </span>
            </div>
            <hr />
            <div className="card-inner-div">
              <div className="task-info-heading"> Status : </div>
              <span style={{ marginLeft: "15px", fontWeight: "500" }}>
                {data.progress}
              </span>
            </div>
          </Card>
          <Card>
            <h4>Customer Info</h4>
            <hr />
            <div className="card-inner-div">
              <div className="task-info-heading"> Customer Id :</div>{" "}
              <span style={{ marginLeft: "15px", fontWeight: "500" }}>
                {data.user}
              </span>
            </div>
            <hr />
            <div className="card-inner-div">
              <div className="task-info-heading"> Customer Name :</div>{" "}
              <span style={{ marginLeft: "15px", fontWeight: "500" }}>
                {data.name}
              </span>
            </div>
            <hr />
            <div className="card-inner-div">
              <div className="task-info-heading"> Property Address :</div>{" "}
              <span style={{ marginLeft: "15px", fontWeight: "500" }}>
                {data.address}
              </span>
            </div>
            <hr />
            <div className="card-inner-div">
              <div className="task-info-heading"> Property State :</div>{" "}
              <span style={{ marginLeft: "15px", fontWeight: "500" }}>
                {data.lookingToBuy == "N/A"?data.state:data.lookingToBuy}
              </span>
            </div>
          </Card>
        </div>
        <div className="task-cards-group">
          <Card style={{ height: "12rem" }}>
            <div className="form-horizontal">
              <div className="card-inner-input" style={{ fontweight: "400" }}>
                <label className="col-md-3 form-label">Assign to </label>
                <select
                  className="form-select"
                  name="assignTo"
                  onChange={handleChange}
                  value={formInput.assignTo}
                >
                  <option className="task-select-option m-2" value="">
                    {" "}
                    Category of agent
                  </option>

                  {data.type === "Depreciation Schedule"
                    ? timepass()
                    : timepass2()}
                </select>
              </div>
              <Card.Body>
                <div className=" row mb-4">
                  <label htmlFor="inputEmail3" className="col-md-3 form-label">
                    Assign Date
                  </label>
                  <div className="col-md-9">
                    <input
                      type="date"
                      className="form-control"
                      value={formInput.assignDate}
                      name="assignDate"
                      id="date"
                      placeholder="Date"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </Card.Body>
            </div>
          </Card>
          <Card>
            <div className="form-group ">
              <div className="form-label">Update Status</div>
              <div className="custom-controls-stacked">
                <label className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="progress"
                    defaultValue="To Be Started"
                    onChange={handleChange}
                  />
                  <span className="custom-control-label">To Be Started</span>
                </label>
                <label className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="progress"
                    defaultValue="Completed"
                    onChange={handleChange}
                  />
                  <span className="custom-control-label">Completed</span>
                </label>
                {/* <label className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="progress"
                    defaultValue="Fully Approved"
                    onChange={handleChange}
                  />
                  <span className="custom-control-label">Fully Approved</span>
                </label>
                <label className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="progress"
                    defaultValue="Pre Approved"
                    onChange={handleChange}
                  />
                  <span className="custom-control-label">Pre Approved</span>
                </label> */}
                <label className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="progress"
                    defaultValue="Cancelled"
                    onChange={handleChange}
                    onClick={() => setCancelDropdown(true)}
                  />
                  <span className="custom-control-label">Cancelled</span>
                </label>
                <Modal show={cancelDropdown}>
                  <Modal.Header>
                    <Modal.Title>Cancelled</Modal.Title>
                    <Button
                      onClick={() => setCancelDropdown(false)}
                      className="btn-close"
                      variant=""
                    >
                      x
                    </Button>
                  </Modal.Header>
                  <ModalBody>
                    <div>
                      <textarea
                        placeholder="Reason"
                        className="col-md-12 form-control m-2"
                        onChange={handleChange}
                        name="cancelText"
                      />
                    </div>
                    <div>
                      <input
                        className="form-control m-2"
                        type="file"
                        placeholder="Attach File"
                        onChange={(e) => setFile2(e.target.files[0])}
                        name="cancelAttachedFile"
                      />
                    </div>
                  </ModalBody>
                  <hr />
                  <div className="d-block text-end m-2">
                    <Button
                      variant="primary"
                      className="me-1"
                      onClick={() => setCancelDropdown(false)}
                    >
                      Close
                    </Button>
                    <Button
                      variant="default"
                      className="me-1"
                      onClick={() => setCancelDropdown(false)}
                    >
                      Save Changes
                    </Button>
                  </div>
                </Modal>
                <label className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="progress"
                    defaultValue="Not Required"
                    onChange={handleChange}
                    onClick={() => setRequiredDropdown(true)}
                  />
                  <span className="custom-control-label">Not Required</span>
                </label>
                {/* <Modal show={requiredDropdown}> */}
                  {/* <Modal.Header>
                    <Modal.Title>Not Required</Modal.Title>
                    <Button
                      onClick={() => setRequiredDropdown(false)}
                      className="btn-close"
                      variant=""
                    >
                      x
                    </Button>
                  </Modal.Header>
                  <ModalBody>
                    <div>
                      <textarea
                        placeholder="Reason"
                        className="col-md-12 form-control m-2"
                        name="notRequiredText"
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <input
                        className="form-control m-2"
                        type="file"
                        placeholder="Attach File"
                        name="notRequiredAttachedFile"
                        onChange={(e) => setFile2(e.target.files[0])}
                      />
                    </div>
                  </ModalBody>
                  <hr />
                  <div className="d-block text-end m-2">
                    <Button
                      variant="primary"
                      className="me-1"
                      onClick={() => setRequiredDropdown(false)}
                    >
                      Close
                    </Button>
                    <Button
                      variant="default"
                      className="me-1"
                      onClick={() => setRequiredDropdown(false)}
                    >
                      Save Changes
                    </Button>
                  </div> */}
                {/* </Modal> */}
              </div>
            </div>
          </Card>
        </div>
        <div className="task-cards-group">
          <Card>
            <Card.Header>
              <h4 className="card-title">Report Complete</h4>
            </Card.Header>
            <Card.Body>
              <div className="form-horizontal">
                <div className=" row mb-4">
                  <label htmlFor="inputEmail3" className="col-md-3 form-label">
                    Date
                  </label>
                  <div className="col-md-9">
                    <input
                      type="date" 
                      className="form-control"
                      value={formInput.reportDate}
                      name="reportDate"
                      id="date"
                      placeholder="Date"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <Form.Group>
                  <div className="form-label">Attach Report
                  <Button    onClick={() =>   getProducts1(type,"Rep",taskId) }>   <img src={d}  />  </Button>
</div>
                  <input
                    className="form-control"
                    name="attachReport"
                    type="file"
                    id="formFile"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </Form.Group>
              </div>
            </Card.Body>
          </Card>
        </div>
        <button
          className="btn btn-success btn-icon text-white"
          style={{ margin: "auto", padding: ".5rem 2rem", display: "block" }}
          type="submit"
        >
          {" "}
          UPDATE{" "}
        </button>
      </form>
    </div>
  );
}

export default TaskType3;
