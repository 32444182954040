import React from 'react'
import Table from './paymentTable/Table'

function PaymentDetails() {

  return (
    <div className='mt-6'>
      <Table/>
    </div>
  )
}

export default PaymentDetails