



// import React, { useState } from "react";
// import { toast } from "react-toastify";
// import Footer from "../../component/footer/Footer";
// import "./ContactUs.css";



// function ContactUs() {
  // const initialInput = {
  //   name: " ",
  //   email: " ",
  //   number: " ",
  //   message: " ",
  // };
  // const [formInput , setFormInput] = useState(initialInput);
  // const [error1, setError1] = useState(false);
  // const [error2, setError2] = useState(false);
  // const [error3, setError3] = useState(false); 
 
  // const [error5, setError5] = useState(false);
  // const handleChange = (e) => {
  //   setFormInput((perState) => {
  //     return { ...perState, [e.target.name]: e.target.value };
  //   });
  // };

//   const handleSubmit = async (e) => {
//     e.preventDefault();


//     console.log(formInput)

//     console.log(formInput.number.length);
    
   
//     if (formInput.name === initialInput.name) {
//       setError1(true);
//     }
//     if (formInput.email === initialInput.email) {
//       setError2(true);
//     } if (formInput.number <= initialInput.number  ) {      
//       setError3(true);
//     }if(formInput.number.length != 10){
//       setError5(true);
//     }


//     else {
//       setFormInput(initialInput);
//       //................api call
//       let result = await fetch('http://localhost:5000/sendinfo',{
//      method:'post',
     
//      headers:{
//       'Content-Type':'application/json'
//      },
//      body: JSON.stringify({formInput}),
//      })
//     result = await result.json()
//     console.log(result);


//       toast.success("Thanks for contacting Justnests, we’ll getback to you in one business day", {
//         position: "top-center",
//         autoClose: 5000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//       });
//     }
//     setTimeout(() => {
//       setError1(false);
//       setError2(false);
//       setError3(false);
//       setError4(false);
//       setError5(false);
//     }, 4000);
// }
//   return (
//     <div className="contact-us">
//       <div className="contact-us-container">
//         <div>
//           <div className="contact-us-heading">
//             <h6>Let's Contact</h6>
//             <h3>Get In Touch</h3>
//             <span className="service-span" style={{ margin: "0" }}></span>
//           </div>
//           <form onSubmit={handleSubmit}>
//            <div className="contact-us-form-group">
//              <div className="contact-us-form-list">
//                <div className="form-label">
//                  Name :
//                  <span
//                    style={{
//                      color: "red",
//                      fontSize: "13px",
//                      display: error1 ? "" : "none",
//                    }}
//                  >
//                    (Input Required)
//                  </span>
//                </div>
//                <input
//                  className="form-control"
//                  onChange={handleChange}
//                  placeholder="Name*"
//                  name="name"
//                  type="text"
//                  value={formInput.name}
//                />


//              </div>
//              <div className="contact-us-form-list">
//                <div className="form-label">
//                  Email :{" "}
//                  <span
//                    style={{
//                      color: "red",
//                      fontSize: "13px",
//                      display: error2 ? "" : "none",
//                    }}
//                  >
//                    (Input Required)
//                  </span>
//                </div>
//                <input
//                  type="email"
//                  className="form-control"
//                  onChange={handleChange}
//                  placeholder="Email*"
//                  name="email"
//                  value={formInput.email}
//                />
//              </div>
//            </div>
//            <div className="contact-us-form-group">
//              <div className="contact-us-form-list">
//                <div className="form-label">
//                  Phone Number :{" "}
//                </div>
//                  <span
//                    style={{
//                      color: "red",
//                      fontSize: "13px",
//                      display: error3 ? "" : "none",
//                    }}
//                  >
//                    (Input Required)
//                  </span>
//                  <span
//                    style={{
//                      color: "red",
//                      fontSize: "13px",
//                      display: error5 ? "" : "none",
//                    }}
//                  >
//                    (10 digits required)
//                  </span>
//                <input
//                  className="form-control"
//                  onChange={handleChange}
//                  placeholder="Number*"
//                  name="number"
//                  type="number"
//                  value={formInput.number}
//                />
//              </div>
//              {/* <div className="contact-us-form-list">
//                <div className="form-label">
//                  Subject :
//                  <span
//                    style={{
//                      color: "red",
//                      fontSize: "13px",
//                      display: error4 ? "" : "none",
//                    }}
//                  >
//                    (Input Required)
//                  </span>
//                </div>
//                <input
//                  className="form-control"
//                  onChange={handleChange}
//                  placeholder="Subject*"
//                  name="subject"
//                  type="text"
//                  value={formInput.subject}
//                />
//              </div> */}
//              </div>
//            <div className="contact-us-form-group">
//              <div className="contact-us-form-list">
//                <div className="form-label" name="message">
//                  Your Message :{" "}
//                  <span
//                    style={{
//                      color: "red",
//                      fontSize: "13px",
//                      display: error4 ? "" : "none",
//                    }}
//                  >
//                    (Input Required)
//                  </span>
//                </div>
//                <textarea
//                  className="form-control"
//                  onChange={handleChange}
//                  placeholder="Your Message"
//                 //  required
//                  name="message"
//                  rows="4"
//                  cols="150"
//                  value={formInput.message}
//                />
//              </div>
//            </div>
//            <button className="btn btn-primary contact-us-btn" type="submit" >
//              Send Message
//            </button>
//          </form>
//         </div>
//         <div></div>
//       </div>
//       <Footer />
//     </div>
//   );
// }
// }
// export default ContactUs;


import React, { useState } from "react";
import { toast } from "react-toastify";
import Footer from "../../component/footer/Footer";
import "./ContactUs.css";

function ContactUs() {
  const initialInput = {
    name: "",
    email: "",
    number: "",
  
    message: "",
  };
  const [formInput, setFormInput] = useState(initialInput);
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [error3, setError3] = useState(false); 
  const [error4, setError4] = useState(false);
  const [error5, setError5] = useState(false);
  const handleChange = (e) => {
    setFormInput((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();


    console.log(formInput)

    console.log(formInput.number.length);
    
   if (formInput.name.length != "" && formInput.email.length != "" && formInput.number.length != "" &&formInput.message.length != "") {
    

    toast.success("Thanks for contacting Justnests, we’ll getback to you in one business day", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    
    //................api call
    let result = await fetch(`${process.env.REACT_APP_BACKEND_DATA}/sendinfo`,{
      method:'post',
       
      headers:{
       'Content-Type':'application/json'
      },
      body: JSON.stringify({formInput}),
      })
     result = await result.json()
     console.log(result);
     setFormInput(initialInput);  
   } else{
    if (formInput.name === initialInput.name) {
      setError1(true);
    }
    if (formInput.email === initialInput.email) {
      setError2(true);
    } if (formInput.number <= initialInput.number  ) {      
      setError3(true);
    } if (formInput.message == initialInput.message  ) {      
      setError4(true);
    }if(formInput.number.length != 10){
      setError5(true);
    }
    }
    setTimeout(() => {
      setError1(false);
      setError2(false);
      setError3(false);
      setError4(false);
      setError5(false);
    }, 4000);

  }
  return (
    <div className="contact-us">
      <div className="contact-us-container">
        <div>
          <div className="contact-us-heading">
            <h6>Let's Contact</h6>
            <h3>Get In Touch</h3>
            <span className="service-span" style={{ margin: "0" }}></span>
            <h4 className="text-dark">
              <b>Email: hello@justnests.com.au</b>{" "}
            </h4>
            <h4 className="text-dark">
              <b>Call: 04319 43210</b>
            </h4>
          </div>
          <form onSubmit={handleSubmit}>
           <div className="contact-us-form-group">
             <div className="contact-us-form-list">
               <div className="form-label">
                 Name :
                 <span
                   style={{
                     color: "red",
                     fontSize: "13px",
                     display: error1 ? "" : "none",
                   }}
                 >
                   (Input Required)
                 </span>
               </div>
               <input
                 className="form-control"
                 onChange={handleChange}
                 placeholder="Name*"
                 name="name"
                 type="text"
                 value={formInput.name}
               />


             </div>
             <div className="contact-us-form-list">
               <div className="form-label">
                 Email :{" "}
                 <span
                   style={{
                     color: "red",
                     fontSize: "13px",
                     display: error2 ? "" : "none",
                   }}
                 >
                   (Input Required)
                 </span>
               </div>
               <input
                 type="email"
                 className="form-control"
                 onChange={handleChange}
                 placeholder="Email*"
                 name="email"
                 value={formInput.email}
               />
             </div>
           </div>
           <div className="contact-us-form-group">
             <div className="contact-us-form-list">
               <div className="form-label">
                 Phone Number :{" "}
               </div>
                 <span
                   style={{
                     color: "red",
                     fontSize: "13px",
                     display: error3 ? "" : "none",
                   }}
                 >
                   (Input Required)
                 </span>
                 <span
                   style={{
                     color: "red",
                     fontSize: "13px",
                     display: error5 ? "" : "none",
                   }}
                 >
                   (10 digits required)
                 </span>
               <input
                 className="form-control"
                 onChange={handleChange}
                 placeholder="Number*"
                 name="number"
                 type="number"
                 value={formInput.number}
               />
             </div>
             {/* <div className="contact-us-form-list">
               <div className="form-label">
                 Subject :
                 <span
                   style={{
                     color: "red",
                     fontSize: "13px",
                     display: error4 ? "" : "none",
                   }}
                 >
                   (Input Required)
                 </span>
               </div>
               <input
                 className="form-control"
                 onChange={handleChange}
                 placeholder="Subject*"
                 name="subject"
                 type="text"
                 value={formInput.subject}
               />
             </div> */}
             </div>
           <div className="contact-us-form-group">
             <div className="contact-us-form-list">
               <div className="form-label" name="message">
                 Your Message :{" "}
                 <span
                   style={{
                     color: "red",
                     fontSize: "13px",
                     display: error4 ? "" : "none",
                   }}
                 >
                   (Input Required)
                 </span>
               </div>
               <textarea
                 className="form-control"
                 onChange={handleChange}
                 placeholder="Your Message* "
                //  required
                 name="message"
                 rows="4"
                 cols="150"
                 value={formInput.message}
               />
             </div>
           </div>
           <button className="btn btn-primary contact-us-btn" type="submit" >
             Send Message
           </button>
         </form>
        </div>
        <div></div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;