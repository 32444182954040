import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

import { Link } from "react-router-dom";

import { Card, Button, Image } from "react-bootstrap";
import Table from "./PromocodeTable/Table";
import DataTables from "./UsedPromo/Table";
function Promocode() {
  const initialValue = { startingDate: "", endingDate: "" };
  const [formInput, setFormInput] = useState(initialValue);
  const initialValue1 = { startingDate: "", endingDate: "" };
  const [formInput1, setFormInput1] = useState(initialValue1);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [useddata, setusedData] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const min = Date.parse(formInput.startingDate);
    const max = Date.parse(formInput.endingDate);

    let startdate = formInput.startingDate;

    let enddate = formInput.endingDate;

    const [year1, month2, day3] = startdate.split("-");
    let start = `${day3}/${month2}/${year1}`;
    const [year4, month5, day6] = enddate.split("-");
    let end = `${day6}/${month5}/${year4}`;

    console.log(start);
    console.log(end);
    if(start != 'undefined/undefined/' && end != 'undefined/undefined/'){
        console.log("start and end");
      let newUserData = data.filter((elm) => {
      
      return (
        +elm.startDate.split("-").join("") >=
          +start.split("/").reverse().join("") &&
        // +elm.endDate.split("-").join("") >=
        +elm.endDate.split("-").join("") <= +end.split("/").reverse().join("")
        //   +end.split("/").reverse().join("")
      );
    });
    setFormInput1(initialValue1);
    
    setData1(newUserData);
    console.log(newUserData);
  }
  if(start != 'undefined/undefined/' && end == 'undefined/undefined/' ){
    console.log("start");
      let newUserData = data.filter((elm) => {
      
      return (
        +elm.startDate.split("-").join("") >=
          +start.split("/").reverse().join("") 
      );
    });
    setFormInput1(initialValue1);
    
    setData1(newUserData);
    console.log(newUserData);
  }
  if(start == 'undefined/undefined/' && end != 'undefined/undefined/' ){
    console.log("end");
      let newUserData = data.filter((elm) => {
      
      return (
        +elm.endDate.split("-").join("") <= +end.split("/").reverse().join("")

      );
    });
    setFormInput1(initialValue1);
    
    setData1(newUserData);
    console.log(newUserData);
  }

  };
  const handleSubmit1 = (e) => {
    e.preventDefault();
    const min = Date.parse(formInput1.startingDate);
    const max = Date.parse(formInput1.endingDate);

    let startdate = formInput1.startingDate;

    let enddate = formInput1.endingDate;

    const [year1, month2, day3] = startdate.split("-");
    let start = `${day3}/${month2}/${year1}`;
    const [year4, month5, day6] = enddate.split("-");
    let end = `${day6}/${month5}/${year4}`;

    console.log(start);
    console.log(end);
    let newUserData = data.filter((elm) => {
      return (
        +elm.endDate.split("-").join("") <=
          +start.split("/").reverse().join("") &&
        +elm.endDate.split("-").join("") >= +start.split("/").reverse().join("")
      );
    });
    setFormInput(initialValue);

    setData1(newUserData);
    console.log(newUserData);
  };
  
  const handleSubmit2 = (e) => {
    e.preventDefault();
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
  
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
  
    const formattedToday = dd + "/" + mm + "/" + yyyy;
    console.log(formattedToday);

    let newUserData = data.filter((row) => {
      return(+row.endDate.split("-").join("") >=
      +formattedToday.split("/").reverse().join("")
        );
    });
    setFormInput(initialValue);
    setFormInput1(initialValue1);

    setData1(newUserData);
    console.log(newUserData);
  };
  const handleSubmit3 = (e) => {
    e.preventDefault();
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
  
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
  
    const formattedToday = dd + "/" + mm + "/" + yyyy;
    console.log(formattedToday);

    let newUserData = data.filter((row) => {
      return(+row.endDate.split("-").join("") <
      +formattedToday.split("/").reverse().join("")
        );
    });
    setFormInput(initialValue);
    setFormInput1(initialValue1);

    setData1(newUserData);
    console.log(newUserData);
  };

  const handleChange = (e) => {
    e.preventDefault();

    setFormInput((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });
  };
  const handleChange1 = (e) => {
    e.preventDefault();

    setFormInput1((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });
  };
  const arr = data1.reverse();
  const fetchData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_DATA}/get-allpromo`
    );
    setData(response.data.data);
    setData1(response.data.data);
  };
  const fetchData1 = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_DATA}/get-all-usedpromo`
    );
    setusedData(response.data.data);
  };
  useEffect(() => {
    fetchData();
    fetchData1();
  }, []);
  console.log(useddata);
  return (
    <div className="mt-6">
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between">
            <Card.Title>
              <b className="mt-3">Promo Code</b>
            </Card.Title>
            <Link
              to="/admin/add-promocode"
              className="btn btn-primary btn-icon text-white pb-1"
            >
              <span>
                <i className="fe fe-plus"></i>&nbsp;
              </span>
              Add PromoCode
            </Link>
          </div>
          <hr />
        </Card.Body>

        <div className="row">
          <div className="col-12 col-md-4">
            <form onSubmit={handleSubmit}>
              <div className="text-center mb-4">
                <label htmlFor="startingDate" style={{ fontSize: "15px" }}>Start Date Between :</label>
                <div className="input-daterange">
                  <input
                    type="date"
                    className="form-control"
                    style={{ fontSize: "15px" }}
                    value={formInput.startingDate}
                    name="startingDate"
                    onChange={handleChange}
                  />
                  <div
                    // className="input-group-addon border px-2 py-3"
                    style={{ fontSize: "15px" }}
                  >
                   <label htmlFor="startingDate">End Date Between :</label> 
                  </div>
                  <input
                    type="date"
                    className="form-control"
                    style={{ fontSize: "15px" }}
                    value={formInput.endingDate}
                    name="endingDate"
                    onChange={handleChange}
                  />
                </div>
                <button
                  className="btn btn-success btn-icon text-white mt-4"
                  style={{ margin: "auto", padding: ".5rem 2rem" }}
                  onClick={handleSubmit}
                >
                  Search
                </button>
                {/* <button
                className="btn btn-primary btn-icon text-white ml-4 mt-4"
                style={{
                  margin: "auto",
                  marginLeft: "28px",
                  padding: ".5rem 2rem",
                }}
                onClick={() => window.location.reload()}
              >
                Refresh
              </button> */}
              </div>
            </form>
          </div>
          {/* <div className="col-12 col-md-4">
            <form onSubmit={handleSubmit}>
              <div className="text-center mb-4">
                <label htmlFor="startingDate">End Date Between :</label>
                <div className=" ">
                  <input
                    type="date"
                    className="form-control"
                    style={{ fontSize: "15px" }}
                    value={formInput1.startingDate}
                    name="startingDate"
                    onChange={handleChange1}
                  />
                  <div
                    // className="input-group-addon border px-2 py-3"
                    style={{ fontSize: "15px" }}
                  >
                    to
                  </div>
                  <input
                    type="date"
                    className="form-control"
                    style={{ fontSize: "15px" }}
                    value={formInput1.endingDate}
                    name="endingDate"
                    onChange={handleChange1}
                  />
                </div>
                <button
                  className="btn btn-success btn-icon text-white mt-4"
                  style={{ margin: "auto", padding: ".5rem 2rem" }}
                  onClick={handleSubmit1}
                >
                  Search
                </button>
              </div>
            </form>
          </div> */}
          <div className="col-12 col-md-4">
            <div className="text-center mb-4">
              <label htmlFor="startingDate">Search with status:</label>

              <div>
                <button
                  className="btn btn-success w-100"
                  onClick={handleSubmit2}
                >
                  Active
                </button>
              </div>
              <div>
                  
                <button className="btn btn-danger w-100 mt-5"onClick={handleSubmit3}>Expired</button>
              </div>
              <button
                className="btn btn-primary btn-icon text-white ml-4 mt-4"
                style={{
                  margin: "auto",
                  marginLeft: "28px",
                  padding: ".5rem 2rem",
                }}
                onClick={() => window.location.reload()}
              >
                Refresh
              </button>
            </div>
          </div>
        </div>
      </Card>

      <Table userData={arr} fetchData={fetchData} />

      <Card>
        Used PromoCode
        <DataTables userData={useddata.reverse()} fetchData1={fetchData1} />
      </Card>
    </div>
  );
}

export default Promocode;
