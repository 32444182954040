import React, { useContext, useState, useEffect } from 'react';
import UserApiContext from '../UserApi/UserApiContext';
import { Card, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Table from './paymentTable/Table';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import ResetUserPassword from './ResetUserPassword';
import newImage from '../../assets/images/ZZ5H.gif';
import './profile.css';

const Profile = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/login');
      Profile();
    }
  }, []);
  const [userInfo, setUserInfo] = useState([]);
  const { _id } = userInfo;

  var initialValue = {
    // name: "",
    // email: "",
    // password: "",
    // phone: "",
    name: userInfo.name,
    email: userInfo.email,
    password: userInfo.password,
    phone: userInfo.phone,
  };
  const [profile, setProfile] = useState(initialValue);
  const Authtoken = localStorage.getItem('token');
  const { loggedInUserData, updateUserInfo } = useContext(UserApiContext);
  const token = async () => {
    const newData = await loggedInUserData(Authtoken);

    setUserInfo({ ...newData });
  };

  useEffect((e) => {
    token();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      profile.phone == undefined ||
      profile.phone.length == 0 ||
      profile.phone.length == 10
    ) {
      updateUserInfo(profile, Authtoken);
      console.log(profile, Authtoken);

      setProfile({
        name: '',
        email: '',
        password: '',
        phone: '',
      });
      toast.success('Updated Successfully', {
        position: 'top-center',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      console.log(profile.phone.length);
      toast.error('Please Enter 10 digit number', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const onChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
    console.log(profile);
  };

  return (
    <>
      <Card className=" col-md-12">
        {userInfo.length === 0 ? (
          <div
            style={{
              height: '80vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img style={{ maxWidth: '7%' }} src={newImage} />
          </div>
        ) : (
          <>
            <Card.Header className="card-title">
              <h2>Customer Details</h2>
              <ResetUserPassword />
            </Card.Header>
            <Card.Body>
              <Row>
                <form onSubmit={handleSubmit}>
                  <div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-horizontal">
                        <div className=" row mb-4">
                          <label className="col-md-3 form-label">
                            Customer Name
                          </label>
                          <div className="col-md-9">
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              required
                              // placeholder={userInfo.name}
                              defaultValue={userInfo.name}
                              onChange={onChange}
                            />
                          </div>
                        </div>
                        <div className=" row mb-4">
                          <label className="col-md-3 form-label">
                            Customer ID
                          </label>
                          <div className="col-md-9">
                            {/* <input className="form-control" name="customerID" /> */}
                            {userInfo.user_id}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-horizontal">
                        <div className=" row mb-4">
                          <label
                            className="col-md-3 form-label"
                            htmlFor="example-email"
                          >
                            Email
                          </label>
                          <div className="col-md-9">
                            <input
                              type="email"
                              id="example-email"
                              name="email"
                              required
                              // placeholder={userInfo.email}
                              onChange={onChange}
                              defaultValue={userInfo.email}
                              // value={profile.email}
                              className="form-control"
                            />
                            {/* {userInfo.email} */}
                          </div>
                        </div>
                        <div className=" row mb-4">
                          <label className="col-md-3  form-label">
                            Phone Number
                          </label>
                          <div className="col-md-9">
                            <input
                              className="form-control"
                              type="text"
                              name="phone"
                              required
                              // placeholder={userInfo.phone}
                              defaultValue={userInfo.phone}
                              onChange={onChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <button
                      className="btn btn-success btn-icon text-white px-6"
                      style={{
                        margin: 'auto',
                        display: 'block',
                      }}
                      type="submit"
                    >
                      {' '}
                      Update{' '}
                    </button>
                  </div>
                </form>
              </Row>
            </Card.Body>
          </>
        )}
      </Card>
      <Card>
        <Table />
      </Card>
    </>
  );
};

export default Profile;
