import React, { useContext } from "react";
import { Button, Card, ProgressBar } from "react-bootstrap";
import userContext from "../UserContext/UserContext";
import { useNavigate } from "react-router-dom";

const PropertyPlan = (props) => {
  const context = useContext(userContext);
  const { propertyPlan } = context;
  const navigate = useNavigate()
 const navigate1=() => {
  navigate('/dashboard');
}
  return (
    <>
      <div className="d-flex flex-column  pt-4 pb-2 ">
        <Card.Header style={{ margin: "auto" }}>
          <h3>What's the plan for the property</h3>
        </Card.Header>
        <Card.Body>
          <Button
            className="mb-3 mt-6 col-md-8 block "
            style={{ margin: "auto" }}
            onClick={() => {
              props.changeState(1);
              propertyPlan("I'll live there");
            }}
          >
            I'll live there
          </Button>
          <Button
            className="mb-3 col-md-8 block "
            style={{ margin: "auto" }}
            onClick={() => {
              props.changeState(1);
              propertyPlan("Investment property");
            }}
          >
            Investment property
          </Button>
        </Card.Body>
           
          
      </div>
        <Button  className="mb-3 col-md-4 block " style={{ margin: "auto" }}  onClick={navigate1  } >Go to Dashboard </Button>
    </>
  );
};

export default PropertyPlan;
