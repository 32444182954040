import axios from "axios";
import React, { useContext, useState } from "react";
import { Button, Modal, ModalTitle, Card } from "react-bootstrap";
import AdminContext from "../../../adminContext/AdminContext";
import "./customerModal.css";
const CustomerModal = ({ customerModalData }) => {
  const adminContext = useContext(AdminContext);
  const { handleModal, setHandleModal } = adminContext;
  const [modalData, setModalData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  });
  //   console.log(customerModalData);
  const handleChange = (e) => {
    setModalData({ ...modalData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/admin/update-user/${customerModalData.id}`;
    axios
      .post(url, modalData, {
        headers: {
          Accept: "application/json",
          // "Content-Type": "application/json",
          "admin-token": localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {
        setHandleModal(false);
      })
      .catch((error) => {});
  };

  // console.log(customerModalData);
  return (
    <div>
      <Modal show={handleModal} size="lg" className="customerModal">
        <Modal.Header>
          <ModalTitle>Edit Customer Detail</ModalTitle>
          <Button
            onClick={() => setHandleModal(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>
        <Card>
          <div>
            <label>Name</label>
            <input type="text" name="name" onChange={handleChange}></input>
          </div>
          <div>
            <label>Email</label>
            <input type="text" name="email" onChange={handleChange}></input>
          </div>
          <div>
            <label>Phone</label>
            <input type="number" name="phone" onChange={handleChange}></input>
          </div>
          <div>
            <label>Change password</label>
            <input
              type="password"
              name="password"
              onChange={handleChange}
            ></input>
          </div>
        </Card>
        <Button onClick={handleSubmit}>Submit</Button>
      </Modal>
    </div>
  );
};

export default CustomerModal;
