import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./HomeBlogs.css";
import axios from "axios";
import newImage from "../../../assets/images/ZZ5H.gif";
function HomeBlogs() {
  const navigate = useNavigate();
  let blogDate = new Date();
  let newDate = blogDate.toDateString();
  const [incomingData, setIncomingData] = useState();
  const item = [];
  const callingApi = async() => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_DATA}/api/blog/get-all-blog`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIncomingData(response.data);
      })
      .catch((error) => {});
  };

  if (incomingData) {
    for (let i = 0; i <= 5; i++) {
      if (incomingData[i]) {
        item.push(incomingData[i]);
      } else {
        break;
      }
    }
  }
  useEffect(() => {
    callingApi();
  }, []);
  const convertinfImage = (data) => {
    const base64String = btoa(
      String.fromCharCode(...new Uint8Array(data.data))
      );
      return <img src={`data:image/png;base64,${base64String}`} alt="" />;
     };
    
     console.log(item);
     return (
     <div className="home-blogs-artical">
      <h2 className="home-mid-heading">Blogs and Articles</h2>
      <span className="service-span"></span>
      <p className="heading-description">
       
      </p>
      <div className="home-blog-container">
        {!item ? (
          <div
            style={{
              height: "80vh",
              position: "absolute",
              // transform: "translateX(50%)",
              width: "20%",
              overflowX : "hidden",
              overflow : "hidden",
              height: "100%",
              left: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img style={{ maxWidth: "7%" }} src={newImage} />
          </div>
        ) : (
          item.map((data, ind) => (
           
            <a href={"/just-blogs/"+data.url.replace(/\s+/g, '-') }  >
            <div
              className="home-blog-section"
              key={ind}
              // onClick={() => navigate("/blog-page")}
            >
              <img src={`https://just.justnests.com.au/${data.blogImage}`} className="img-fluid" alt="" />
              {/* {convertinfImage(data.blogImage)} */}
              <div className="blog-page-details">
                <h3 className="blog-page-title">{data.title}</h3>
                
                 <div
            className="blog-after-description"
            dangerouslySetInnerHTML={{ __html: data.description }}
          />
              </div>

               {/* <div>
                <span
                  style={{
                    width: "80%",
                    display: "block",
                    margin: "auto",
                    border: "1px solid hsl(200, 100%, 40%,0.5)",
                  }}
                ></span>
                <div style={{ fontSize: "20px" }}>
                  <p>
                    <i className="ion-person px-1" style={{ color: "gray" }} />
                    Admin
                  </p>
                </div> 
              </div>  */}

            </div></a>
          ))
        )}
      </div>
      <a href="/blog-page" >
        <button className="parent" style={{ position: "relative" }}>
          <i
            className="fa fa-arrow-right child"
            style={{ position: "absolute", top: "12px" }}
          />{" "}
          <span>Read more</span>
        </button>
      </a>
    </div>
  );
}

export default React.memo(HomeBlogs);
