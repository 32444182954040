import React, { useState, useEffect, useContext } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import AdminContext from "../../../adminContext/AdminContext";
import axios from "axios";

function EditModal({ editData, editPartnerState, incomingFormValue }) {
  let initialValue = {};
  
  let newData =
    editData &&
    editData.map((data) => {
      initialValue.fullFee = data.fullFee;
      initialValue.upfrontFee = data.upfrontFee;
      initialValue.oneB = data.oneB;
      initialValue.oneP = data.oneP;
      initialValue.oneC = data.oneC;
      initialValue.twoB = data.twoB;
      initialValue.twoP = data.twoP;
      initialValue.twoC = data.twoC;
      initialValue.threeB = data.threeB;
      initialValue.threeP = data.threeP;
      initialValue.threeC = data.threeC;
      initialValue.fourB = data.fourB;
      initialValue.fourP = data.fourP;
      initialValue.fourC = data.fourC;
      initialValue.plusFourB = data.plusFourB;
      initialValue.plusFourP = data.plusFourP;
      initialValue.plusFourC = data.plusFourC;
      initialValue.fee = "";
    });

  const [formInput, setFormInput] = useState(initialValue);

  const [incomingData, setIncomingData] = useState([]);
  const dashboardContext = useContext(AdminContext);
  const {
    handleFeeDetailsApi,
    setDummystate,
    dummystate,
    handleModal,
    setHandleModal,
  } = dashboardContext;

  const callingApiForContext = async () => {
    setIncomingData(await handleFeeDetailsApi());
  };

  useEffect(() => {
    callingApiForContext();
  }, [dummystate]);

  useEffect(() => {
    setFormInput(initialValue);
  }, [handleModal]);

  const handleChange = (e) => {
    setFormInput((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/fee-details/update-fee-details`;
    const data = {
      feeDetailType: editPartnerState[0].feeDetailType,
      partnerState: editData[0].partnerState,
      fullFee: formInput.fullFee,
      upfrontFee: formInput.upfrontFee,
      oneB: formInput.oneB,
      oneP: formInput.oneP,
      oneC: formInput.oneC,
      twoB: formInput.twoB,
      twoP: formInput.twoP,
      twoC: formInput.twoC,
      threeB: formInput.threeB,
      threeP: formInput.threeP,
      threeC: formInput.threeC,
      fourB: formInput.fourB,
      fourP: formInput.fourP,
      fourC: formInput.fourC,
      plusFourB: formInput.plusFourB,
      plusFourP: formInput.plusFourP,
      plusFourC: formInput.plusFourC,
      fee: formInput.fee,
    };
    axios
      .put(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "admin-token": localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {})
      .catch((error) => {});
    setHandleModal(false);
    setFormInput(initialValue);
    setDummystate(!dummystate);
  };

  return (
    <div>
      <form>
        <Modal
          size="lg"
          show={handleModal}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header>
            <Modal.Title>Edit Fee Details</Modal.Title>
            <Button
              onClick={() => setHandleModal(false)}
              className="btn-close"
              variant=""
            >
              x
            </Button>
          </Modal.Header>
          {incomingFormValue.type === "Solicitor" ||
          incomingFormValue.type === "Conveyancer" ? (
            <Modal.Body>
              <div className=" row mb-4">
                <div className="d-flex mb-4">
                  <div style={{ width: "200px" }}>State :</div>
                  <div style={{ fontWeight: "bold" }}>
                    {editData &&
                      editData.map((data) => {
                        return data.partnerState;
                      })}
                  </div>
                </div>
                <label className="col-md-3 form-label my-2">Full fee</label>
                <div className="col-md-9 my-2">
                  <input
                    type="number"
                    className="form-control"
                    name="fullFee"
                    value={formInput.fullFee}
                    onChange={handleChange}
                  />
                </div>
                <label className="col-md-3 form-label my-2">Upfront fee</label>
                <div className="col-md-9 my-2">
                  <input
                    type="number"
                    className="form-control"
                    name="upfrontFee"
                    value={formInput.upfrontFee}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </Modal.Body>
          ) : incomingFormValue.type === "Building Inspection" ? (
            <Modal.Body>
              <div className=" row mb-4">
                <div className="d-flex mb-4">
                  <div style={{ width: "200px" }}>State :</div>
                  <div style={{ fontWeight: "bold" }}>
                    {editData &&
                      editData.map((data) => {
                        return data.partnerState;
                      })}
                  </div>
                </div>
                <Table
                  className="text-center border table-responsive col-md-10"
                  style={{ margin: "auto" }}
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>Building Inspection</th>
                      <th>Pest Inspection</th>
                      <th>Combined Building & Pest Inspection </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1 Bedroom</td>
                      <td>
                        <input
                          className="form-control"
                          name="oneB"
                          value={formInput.oneB}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          name="oneP"
                          value={formInput.oneP}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          name="oneC"
                          value={formInput.oneC}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>2 Bedroom</td>
                      <td>
                        <input
                          className="form-control"
                          name="twoB"
                          value={formInput.twoB}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          name="twoP"
                          value={formInput.twoP}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          name="twoC"
                          value={formInput.twoC}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>3 Bedroom</td>
                      <td>
                        <input
                          className="form-control"
                          name="threeB"
                          value={formInput.threeB}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          name="threeP"
                          value={formInput.threeP}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          name="threeC"
                          value={formInput.threeC}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>4 Bedroom</td>
                      <td>
                        <input
                          className="form-control"
                          name="fourB"
                          value={formInput.fourB}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          name="fourP"
                          value={formInput.fourP}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          name="fourC"
                          value={formInput.fourC}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>4+ Bedroom</td>
                      <td>
                        <input
                          className="form-control"
                          name="plusFourB"
                          value={formInput.plusFourB}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          name="plusFourP"
                          value={formInput.plusFourP}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          name="plusFourC"
                          value={formInput.plusFourC}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Modal.Body>
          ) : incomingFormValue.type === "Depreciation Schedule" ? (
            <Modal.Body>
              <div className=" row mb-4">
                <label className="col-md-3 form-label my-2">Fee</label>
                <div className="col-md-9 my-2">
                  <input
                    type="number"
                    className="form-control"
                    name="fee"
                    value={formInput.fee}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </Modal.Body>
          ) : (
            ""
          )}

          <Modal.Footer>
            <Button
              variant="primary"
              className="me-1"
              onClick={() => setHandleModal(false)}
            >
              Close
            </Button>
            <Button
              type="submit"
              variant="default"
              className="me-1"
              onClick={handleSubmit}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      </form>
    </div>
  );
}

export default EditModal;
