import React, { useContext, useEffect, useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link ,useNavigate, useLocation } from 'react-router-dom';
import UserApiContext from '../UserApi/UserApiContext';
import { v4 as uuidv4 } from 'uuid';
import { toast } from "react-toastify";

import axios from 'axios';
import './getLoan.css';
const GetLoan = (props) => {
  const navigate = useNavigate();




  //=============================================================================================================
// Mortgage Broker
const [promodata, setPromodata] = useState(0);
const [allpromodata, setAllpromodata] = useState();
const [promoApplied, setpromoApplied] = useState("false");
const [userPromoCode, setUserPromo] = useState();


const sendusedpromodata = () => {
  let Ammount = allpromodata.discountAmount;
  let promoName = allpromodata.promoName;
  let promoCode = userPromoCode;
  let service = "Mortgage Broker";
  let custumerName = allData.name;
  let custumerId = allData.user_id;
  // let custumerId = userInfo.email;
  let companyName = allData.agencyName;
  axios
    .post(`${process.env.REACT_APP_BACKEND_DATA}/used-promo`, {
      promoCode,
      promoName,
      service,
      custumerId,
      custumerName,
      companyName,
      Ammount,
      id,
    })
    .then((response) => {})
    .catch((error) => {
      console.error(error);
    });
};


const validateuserPromo = () => {
  
    console.log(1234);

  
  let userPromo = userPromoCode;
  let userState = allData.lookingToBuy;
  let userServise = "Mortgage Broker";


  axios
    .post(`${process.env.REACT_APP_BACKEND_DATA}/verify-promo`, {
      userPromo,
      userState,
      userServise,
    })
    .then((response) => {

      console.log(response);
      if (response.data.promodata) {
        setPromodata(response.data.promodata.discountAmount);
        setpromoApplied("true");
        setAllpromodata(response.data.promodata);
        // sendusedpromodata()
      }
      if (response.data.msg == "Successfully Applied Promocode") {
        toast.success(`${response.data.msg}`, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(response.data.msg, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      
    })
    .catch((error) => {
      console.error(error);
    });

};













  //=============================================================================================================
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/login');
    }
  }, []);
  const location = useLocation();
  // const { changeprogress } = location;

  let initialValue = { message: '' };
  const [active, setActive] = useState(true); // to set the condition to change the page after submit
  const [tokendata, settokendata] = useState([]); // to get the user data
  const [message, setMessage] = useState(initialValue);
  const [allData, setAllData] = useState([]); // getting single data
  const UserApi = useContext(UserApiContext);
  const { loggedInUserData, callingTaskMailer, sendDashboardData } = UserApi; // to get the Api
  // Logged in user data
  const Authtoken = localStorage.getItem('token');
  const token = async () => {
    const newData = await loggedInUserData(Authtoken);
    settokendata({ ...newData });
  };
  useEffect(() => {
    token();
  }, []);
  // console.log(tokendata);
  // Sending the data to the server
  let id = location.state.id;

  function dateFormat(inputDate, format) {
    //parse the input date
    const date = new Date(inputDate);

    //extract the parts of the date
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    //replace the month
    format = format.replace('MM', month.toString().padStart(2, '0'));

    //replace the year
    if (format.indexOf('yyyy') > -1) {
      format = format.replace('yyyy', year.toString());
    } else if (format.indexOf('yy') > -1) {
      format = format.replace('yy', year.toString().substr(2, 2));
    }

    //replace the day
    format = format.replace('dd', day.toString().padStart(2, '0'));

    return format;
  }
  const handleSubmit = async() => {
    let newDate = new Date().toLocaleDateString();
    let todayDate = Date.parse(dateFormat(newDate, 'yyyy-MM-dd'));
    // console.log(new Date().toLocaleDateString());
    //changing date format

    // console.log("Converted date: " + dateFormat(newDate, "yyyy-MM-dd"));
    // let newDate =
    //   todayDate.getDate() +
    //   "/" +
    //   (todayDate.getMonth() + 1) +
    //   "/" +
    //   todayDate.getFullYear();

    console.log("All Data",allData);
    const newData = {
      payment: true,
      message: message.message,
      progress: 'In Progress',
      type: 'Mortgage Broker',
      state: location.state.state,
      createdDate: Date.now(),
      taskId: uuidv4(),
    };
    // setNewMailData(newData);
    const mailData = {};
    mailData.customerName = allData.name;
    mailData.requestType = newData.type;
    mailData.taskid = id;
    mailData.custId = id;
    // if(promoApplied == 'true'){

      mailData.Ammount = allpromodata != undefined ?allpromodata.discountAmount :"N/A";
    // }
    mailData.customerId = allData.user_id;
    mailData.customerEmailId = allData.email;
    mailData.customerPhoneNumber = allData.phone;
    mailData.purchaseType = allData.propertyPlan;
    mailData.propertyType = allData.propertType;
    mailData.propertyAddress = allData.address;
    if (allData.lookingToBuy != 'N/A') {
      mailData.propertyState = allData.lookingToBuy;
    } else {
      mailData.propertyState = allData.propertyState;
    }
    mailData.instructionFromCustomer = newData.message;
    await callingTaskMailer(mailData);
    if (promoApplied == "true") {
    await  sendusedpromodata()
      
    }
    sendDashboardData(newData, id);
    setMessage(initialValue);
    setActive(false);
    //calling taskApi
    // console.log(mailData);

  };

  // Saving the message data to the state
  const onChange = (e) => {
    setMessage({ ...message, [e.target.name]: e.target.value });
  };
  // const [afterState, setAfterState] = useState(true);
  // View single application data

  console.log( allData);
  const dummy = async () => {
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/application/view-single-application/${id}`;
    try {
      const response = await axios.get(url, {
        headers: {
          'auth-token': localStorage.getItem('token'),
        },
      });
      const initialValue = [];
      response.data.TASKNAME.map((newData) => {
        initialValue.push(newData);
      });
      // setGettingData(initialValue);
      setAllData(response.data);
      // console.log
    } catch (error) {}
  };
  useEffect(() => {
    dummy();
  }, []);
  return (
    <>
      {active ? (
        <Card className="mt-9 pt-7 pb-0 col-md-7">
          <Card.Header className="mx-auto" style={{ textAlign: 'center' }}>
            <h2>
              One of our expert mortgage broker will contact you in one business
              day on{' '}
            </h2>
          </Card.Header>
          <Card.Body>
            <div>
              <div className="my-3 mx-9 d-flex  align-items-center">
                <i className="fe fe-phone mx-2" style={{ fontSize: '1rem' }} />
                {tokendata.phone}
              </div>
            </div>
            <div>
              <div className="my-3 mx-9 d-flex  align-items-center">
                <i
                  className="fe fe-mail mx-2"
                  style={{ fontSize: '1rem', textTransform: 'capitalize' }}
                />
                {tokendata.email}
              </div>
            </div>
            <Card
                className="col-md-10 pt-2 pb-2"
               
              >
                <Card.Body >
                  <div className="d-flex">
                  <input
                  type="text"
                  className=" m-2 form-control"
                  onChange={(e) => setUserPromo(e.target.value)}
                  placeholder="Apply your promocode if you have any?"
                  style={{ width: "17rem" }}
                />
                <Button
                  onClick={validateuserPromo}
                style={{width:"fit-content"}}

                  className="m-2 building-inspection-button "
                >
                  Apply Promo Code
                </Button>
                  </div>

                </Card.Body>

                </Card>
                {/* <div className=""> */}
             
              {/* <div className="col-lg-6 col-md-6 col-sm-12 text-center "> */}
                
              {/* </div> */}

              {promodata ? (
                <>
                  <div className="mt-4 text-center">
                    <h3>
                      Congratulations! you have received ${promodata} cashback{" "}
                    </h3>
                    <h4>You'll be paid ${promodata} upon settlement</h4>
                  </div>
                </>
              ) : (
                ""
              )}

            <textarea
              className=" block col-md-9 mt-6 mx-auto p-2"
              
              style={{
                border: "1px solid black",borderRadius: "5px",
                height: '100px',
                boxShadow: ' 0px 0px 7px 2px #e9e7e7',
              }}
              name="message"
              type="text"
              id="mortgageMessage"
              cols="30"
              rows="10"
              placeholder="Any special message ?"
              onChange={onChange}
            ></textarea>
            <div className="mt-8" style={{ textAlign: 'center' }}>
              <button
                className="m-4 w-20 border"
                style={{
                  borderColor: ' black',
                  borderRadius: '5px',
                  padding: '9px',
                }}
                onClick={() =>
                  navigate('/application-dashboard', {
                    state: {
                      id: id,
                    },
                  })
                }
              >
                Back
              </button>
              <Button
                // className="m-4 w-25 "
                className="m-4 w-25"
                onClick={handleSubmit}
              >
                Confirm
              </Button>
            </div>
          </Card.Body>
        </Card>
      ) : (
        <Card className="mt-9 pt-7 pb-0 col-md-7">
          <Card.Header className="mx-auto" style={{ textAlign: 'center' }}>
            <h2>
              All done! Sit back and relax. Our lending expert will get in touch
              with you
            </h2>
          </Card.Header>
            <Link className="mt-4" style={{ textAlign: 'center' }} target="_blank" to={'/blog-page'}><span  className="btn btn-success btn-icon text-white mb-3" >Go to blog page</span></Link>
          <div style={{ textAlign: 'center' }} className="my-6 ">
            <button
              className="btn btn-primary btn-icon text-white mb-3  col-md-4"
              onClick={() => {
                navigate('/application-dashboard', {
                  state: {
                    id: id,
                  },
                });
              }}
            >
         Go back to Application Dashboard
            </button>
          </div>
        </Card>
      )}
    </>
  );
};

export default GetLoan;
