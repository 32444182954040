import React, { useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Button } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";

export const DataTables = ({incomingData, filter, incomingPaymentData}) => {
//  const [data, setData] =useState(filter);
var data = filter?.filter((element) => {
  if (
    element.type === "Pest Inspection" ||
    element.type === "Building and Pest Inspection" ||
    element.type === "Building Inspection"
  ) {
   
    
      if (element.progress === "Completed") {
        // console.log(element);
        return element;
    
    }
  
  }
});
// console.log(filter);
 let newData = [];
 incomingPaymentData.map((data) =>
   incomingData.map((value) =>
     data.taskId === value.taskId ? newData.push(data.paymentAmount) : 0
   )
 );

 let totalPayment = newData.reduce((acc, data) => {
   let d = parseInt(data);
   return (acc = acc + d);
 }, 0);


 function convertArrayOfObjectsToCSV(array) {
  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(data[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
   let ctr = 0;
   keys.forEach((key) => {
    if (ctr > 0) result += columnDelimiter;

    result += item[key];

    ctr++;
   });
   result += lineDelimiter;
  });

  return result;
 }

 function downloadCSV(array) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv == null) return;

  const filename = "export.csv";

  if (!csv.match(/^data:text\/csv/i)) {
   csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
 }

 const Export = ({ onExport }) => <Button type="button" className="ml-5" onClick={()=>tableToCSV()}>  Export</Button>;

 const columns = [
  {
   name: "Task ID",
   selector: (row) => [row.task_id],
   sortable: true,
  },
  {
   name: "Task Name",
   selector: (row) => [row.type],
   sortable: true,
  },
  {
   name: "Customer Name",
   selector: (row) => [row.name],
   sortable: true,
  },
  {
   name: "Company Name",
   selector: (row) => [row.assignTo],
   sortable: true,
  },
  {
   name: "Customer ID",
   selector: (row) => [row.user],
   sortable: true,
  },
  {
   name: "Status",
   selector: (row) => [row.progress],
   sortable: true,
  },
  {
   name: "Assigned Date",
   selector: (row) => (
    <div>
      {row.assignDate === undefined
        ? "N/A"
        : row.assignDate.split("-").reverse().join("/")}
    </div>
  ),
   sortable: true,
  },
  {
   name: "Completion Date",
   selector: (row) => [row.completeDate],
   sortable: true,
  },
  {
   name: "State",
   selector: (row) =>(row.state2 !== "N/A" ? row.state2 : row.state1 !== "N/A"?row.state1 :""),
   sortable: true,
  },
  {
   name: "No. Of Bedroom",
   selector: (row) => [row.bedRoomSize],
   sortable: true,
  },
  {
    name: "Fee",
    selector: (row) =>row.paymentAmount,
    sortable: true,
  },
 ];
 const tableDatas = {
  columns,
  data,
 };
 const actionsMemo = React.useMemo(() => <Button type="button" className="ml-5" onClick={()=>tableToCSV()}>  Export</Button>, []);
//  console.log(data);

 var sum =0;
 data?.map((elm)=>{
  return sum += +elm.paymentAmount
 })


 function tableToCSV() {
 
  // Variable to store the final csv data
  var csv_data = [];

  // Get each row data
  var rows = document.getElementsByClassName('ttttttt');
  console.log(rows);
  for (var i = 0; i < rows.length; i++) {

      // Get each column data
      var cols = rows[i].querySelectorAll('td,th');

      // Stores each csv row data
      var csvrow = [];
   
      for (var j = 0; j < cols.length; j++) {

          // Get the text data of each cell
          // of a row and push it to csvrow
          csvrow.push(cols[j].innerHTML);
      }

      // Combine each column value with comma
      csv_data.push(csvrow.join(","));
  }

  // Combine each row data with new line character
  csv_data = csv_data.join('\n');

  // Call this function to download csv file 
  downloadCSVFile(csv_data);

}

function downloadCSVFile(csv_data) {

  // Create CSV file object and feed
  // our csv_data into it
  var CSVFile = new Blob([csv_data], {
      type: "text/csv"
  });

  // Create to temporary link to initiate
  // download process
  var temp_link = document.createElement('a');

  // Download csv file
  temp_link.download = "Building and Pest Inspection Report.csv";
  var url = window.URL.createObjectURL(CSVFile);
  temp_link.href = url;

  // This link should not be displayed
  temp_link.style.display = "none";
  document.body.appendChild(temp_link);

  // Automatically click the link to
  // trigger download
  temp_link.click();
  document.body.removeChild(temp_link);
}


 return (
    <div>


<table className="d-none" border="1" cellspacing="0" cellpadding="10">
			<tr className="ttttttt">
				<th>Task ID</th>
				<th>Task Name</th>
				<th>Customer Name</th>
				<th>Company Name</th>
				<th>Customer ID</th>
				<th>Status </th>
				<th>Assigned Date</th>
				<th>Completion Date</th>
				<th>State</th>
				<th>No. Of Bedroom</th>
				{/* <th>State</th> */}
				<th>Fee</th>
				
			</tr>
      {
      data?.map((row)=>{

       return(
        <>
        <tr className="ttttttt">
				<td>{row.task_id}</td>
				<td>{row.type}</td>
				<td>{row.name}</td>
				<td>{row.assignTo}</td>
				<td>{row.user}</td>
				<td>{row.progress}</td>
				<td>{row.assignDate === undefined
            ? "N/A"
            : row.assignDate.split("-").reverse().join("/")}</td>
				<td>{ new Date(row.completeDate).toLocaleDateString("en-IN")}</td>
				<td>{row.state2 !== "N/A"
          ? row.state2
          : row.state1 !== "N/A"
          ? row.state1
          : ""}</td>
				<td>{row.bedRoomSize}</td>
				
				<td>{row.paymentAmount}</td>
			</tr>
       
 </>
       )
})
      }
			
		</table>

      
    <DataTableExtensions {...tableDatas}>
      <DataTable
        actions={actionsMemo}
        columns={columns}
        data={data}
        pagination
        highlightOnHover
        responsive
        dense
      />
    </DataTableExtensions>
    <div
      className="form-control"
      style={{ textAlign: "end", fontSize: "20px" }}
    >
      Fee :{" "}
      <span style={{ fontWeight: "bold" }}> ${sum}</span>
    </div>
  </div>
 );
};
