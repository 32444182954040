import React, { useContext } from "react";

import userContext from "../UserContext/UserContext";
import { Button, Card } from "react-bootstrap";
const ShortListedProperty = (props) => {
  const context = useContext(userContext);
  const { propertyShortlisted } = context;

  return (
    <div className="d-flex flex-column pt-4 pb-2">
      <Card.Header style={{ margin: "auto" }}>
        <h3>Have you already short-listed a property?</h3>
      </Card.Header>
      <Button
        className="mb-1 mt-3 col-md-6 block "
        style={{ margin: "auto" }}
        onClick={() => {
          props.changeState(1);
          propertyShortlisted("yes");
        }}
      >
        Yes
      </Button>
      <Button
        className="mb-1 mt-3 col-md-6 block "
        style={{ margin: "auto" }}
        onClick={() => {
          props.changeState(2);
          propertyShortlisted("Not yet");
        }}
      >
        Not yet
      </Button>
    </div>
  );
};

export default ShortListedProperty;
