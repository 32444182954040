import React, { useContext, useState, useEffect } from 'react';
import Table from './partnerDetailsTable/Table';
import { Link } from 'react-router-dom';
import { Card, Breadcrumb } from 'react-bootstrap';
import AdminContext from '../../adminContext/AdminContext';
import newImage from '../../../assets/images/ZZ5H.gif';

function PartnerDetails() {
  let initialValue = {
    companyName: '',
    p_id: '',
    email: '', 
    phoneNumber: '',
  };
  const [formInput, setFormInput] = useState(initialValue);
  const [incomingData, setincomingData] = useState([]);
  const [userData, setUserData] = useState(incomingData);
  const partnerApiContext = useContext(AdminContext);
  const { handlePartnerApi } = partnerApiContext;
  const callingApiForContext = async () => {
    setincomingData(await handlePartnerApi());
  };

  const onChange = (e) => {
    setFormInput((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });
  };


  let filterData;

  const refreshPage = () => {

   
      window.location.reload();
  

  };

  const handleSubmit = (e) => {
    if (filterData || formInput.companyName != '') {
      if (filterData != undefined) {
        filterData = filterData.filter((element) => {
          return element.companyName
            .toUpperCase()
            .includes(formInput.companyName.toUpperCase());
        });
      } else {
        filterData = incomingData.filter((element) => {
          return element.companyName
            .toUpperCase()
            .includes(formInput.companyName.toUpperCase());
        });
      }
      setUserData(filterData);
      return;
    }
    if (filterData || formInput.p_id !== '') {
      if (filterData !== undefined) {
        filterData = filterData.filter((element) => {
          return element.p_id
            .toUpperCase()
            .includes(formInput.p_id.toUpperCase());
        });
      } else {
        filterData = incomingData.filter((element) => {
          return element.p_id
            .toUpperCase()
            .includes(formInput.p_id.toUpperCase());
        });
      }
      setUserData(filterData);
      return;
    }
    if (filterData || formInput.email !== '') {
      if (filterData !== undefined) {
        filterData = filterData.filter((element) => {
          return element.partnerEmail.includes(formInput.email);
        });
      } else {
        filterData = incomingData.filter((element) => {
          return element.partnerEmail.includes(formInput.email);
        });
      }
      setUserData(filterData);
      return;
    }

   
    //..........................................................
    if (filterData || formInput.phoneNumber !== '') {
      if (filterData !== undefined) {
        filterData = filterData.filter((element) => {
          return element.partnerNumber
            .toUpperCase()
            .includes(formInput.phoneNumber.toUpperCase());
        });
      } else {
        filterData = incomingData.filter((element) => {
          return element.partnerNumber
            .toUpperCase()
            .includes(formInput.phoneNumber.toUpperCase());
        });
      }
      setUserData(filterData);
      return;
    }
// ...............................................................
          
  };

  useEffect(() => {
    callingApiForContext();
  }, [window]);
  useEffect(() => {
    callingApiForContext();
    handleSubmit();
  },[]);
  useEffect(() => {
    if (
      formInput.companyName ||
      formInput.phoneNumber ||
      formInput.email ||
      formInput.p_id
    )
      handleSubmit();
  }, [formInput]);

  return (
    <>
      <div>
        <div className="page-header mx-3">
          <div>
            <h1 className="page-title text-light">Partner Details</h1>
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item className="breadcrumb-item" href="#">
                Partner Details
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className="breadcrumb-item active breadcrumds"
                aria-current="page"
              >
                Data Table
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="ms-auto pageheader-btn">
            <Link
              to="/admin/add-partner"
              className="btn btn-primary btn-icon text-white me-3"
            >
              <span>
                <i className="fe fe-plus"></i>&nbsp;
              </span>
              Add Account
            </Link>
          </div>
        </div>
        <Card>
          <Card.Header>
            <h2>Search</h2>
          </Card.Header>
          <div className="col-md-12 card-wrapper">
            <div className=" row mb-4" style={{ marginLeft: '2px' }}>
              <label htmlFor="companyName" className="col-md-12 form-label">
                Company Name
              </label>
              <div className="col-md-10">
                <input
                  style={{ background: '#cef0ef' }}
                  type="text"
                  className="form-control"
                  name="companyName"
                  id="companyName"
                  onChange={onChange}
                />
              </div>
            </div>
            <div className=" row mb-4" style={{ marginLeft: '2px' }}>
              <label htmlFor="partnerId" className="col-md-12 form-label">
                Partner ID
              </label>
              <div className="col-md-10">
                <input
                  style={{ background: '#cef0ef' }}
                  name="p_id"
                  className="form-control"
                  id="partnerId"
                  onChange={onChange}
                />
              </div>
            </div>
            <div className=" row mb-4" style={{ marginLeft: '2px' }}>
              <label htmlFor="email" className="col-md-12 form-label">
                Email ID
              </label>
              <div className="col-md-10">
                <input
                  style={{ background: '#cef0ef' }}
                  type="email"
                  name="email"
                  className="form-control"
                  id="email"
                  onChange={onChange}
                />
              </div>
            </div>
            <div className=" row mb-4" style={{ marginLeft: '2px' }}>
              <label htmlFor="phoneNumber" className="col-md-12 form-label">
                Phone Number
              </label>
              <div className="col-md-10">
                <input
                  style={{ background: '#cef0ef' }}
                  type="number"
                  name="phoneNumber"
                  className="form-control"
                  id="phoneNumber"
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-6">
                {/* <button
                  onClick={handleSubmit}
                  className="btn btn-success btn-icon text-white col-md-3 mr-xl-90"
                  style={{
                    margin: '2rem auto 0 auto',
                    padding: '.5rem 2rem',
                    display: 'flex',
                  }}
                  type="submit"
                >
                  Search
                </button> */}
              </div>
              <div className="col-6">
                <button
                  onClick={refreshPage}
                  className="btn btn-primary btn-icon text-white col-md-3 ml-xl-90"
                  style={{
                    margin: '2rem auto 0 auto',
                    padding: '.5rem 2rem',
                    display: 'flex',
                  }}
                  type="reset"
                >
                 
                  Reset
                </button>
              </div>
            </div>
          </div>
        </Card>
        <Table
          userData={
            formInput.companyName === '' &&
            formInput.p_id === '' &&
            formInput.email === '' &&
            formInput.phoneNumber === ''
              ? incomingData
              : userData
          }
        /> 
      </div>
    </>
  );
}

export default PartnerDetails;
