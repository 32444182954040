import React from "react";
import { Card } from "react-bootstrap";
const BuildingPestSummary = (props) => {
  return (
    <>
      {" "}
      <div>
        <Card.Header>
          <h3>Summary</h3>
        </Card.Header>
        <Card.Body>
          <div className="d-flex mb-4">
            <div className="m-auto" style={{ width: "30%" }}>
              Services&
            </div>
            <div style={{ width: "60%" }}>{props.name}</div>
          </div>
          <div className="d-flex mb-4">
            <div className="m-auto" style={{ width: "30%" }}>
              Total:
            </div>
            <div className="p-1 border" style={{ width: "60%" }}>
              ${props.price}
            </div>
          </div>
          <div className="d-flex mb-4">
            <div className="m-auto" style={{ width: "30%" }}>
              Fee Payable Now :
            </div>
            <div className="p-1 border" style={{ width: "60%" }}>
              ${props.price}
            </div>
          </div>
        </Card.Body>
      </div>
    </>
  );
};

export default BuildingPestSummary;
