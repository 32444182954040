import React ,{useState,useEffect}from 'react'
import axios from 'axios'
import Table from './Table'

function Accountingrequest() {
    const [ accountdata,setaccountinData] = useState()
    const fetchData1 = async () => {
        // console.log(123123212);
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_DATA}/get-all-accounting-info`
        );
        setaccountinData(response.data.data);
      };
      useEffect(() => {
    
       
        fetchData1();
      }, []);
      console.log(accountdata);
  return (
    <div>
        <div className="card">
            <h3>Accounting Request</h3>
        </div>
        <Table userData={accountdata&&accountdata.reverse()} fetchData1={fetchData1}/>
    </div>
  )
}

export default Accountingrequest