import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";

function ResetUserPassword() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const initialValue = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const [passwordToggle1, setPasswordToggle1] = useState(false);
  const [passwordToggle2, setPasswordToggle2] = useState(false);
  const [passwordToggle3, setPasswordToggle3] = useState(false);
  const [error, setError] = useState(false);
  const [formInput, setFormInput] = useState(initialValue);

  const handleChange = (e) => {
    setFormInput((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formInput.oldPassword === "") {
      toast.error("Field is required", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (formInput.newPassword !== formInput.confirmPassword) {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 5000);
    } else {
      const url = `${process.env.REACT_APP_BACKEND_DATA}/api/auth/update`;
      const data = {
        oldPassword: formInput.oldPassword,
        newPassword: formInput.newPassword,
      }
      
      axios
        .post(url, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
        })
        // .then((response) => {})
        .then(({ data }) => {
          toast.error(data.err, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          toast.success(data.msg, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          
          // navigate("/")
        })
        .catch((error) => {});

      setFormInput(initialValue);
      setError(false);
      setShow(false);
    }
  };

  return (
    <div>
      <Button variant="primary" onClick={handleShow}>
        Reset Password
      </Button>

      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>Reset Password</Modal.Title>
          <Button
            onClick={() => setShow(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label">
            Old Password
            <div className="d-flex form-control">
              <input
                className="w-100"
                type={passwordToggle3 ? "text" : "password"}
                placeholder="Enter old password"
                name="oldPassword"
                value={formInput.oldPassword}
                onChange={handleChange}
              />
              <i
                onClick={() => setPasswordToggle3(!passwordToggle3)}
                className={
                  passwordToggle3 ? "fe fe-eye p-1" : "fe fe-eye-off p-1"
                }
              />
            </div>
          </label>
          <label className="form-label">
            New Password
            <div className="d-flex form-control">
              <input
                className="w-100"
                type={passwordToggle1 ? "text" : "password"}
                placeholder="Enter new password"
                name="newPassword"
                value={formInput.newPassword}
                onChange={handleChange}
              />
              <i
                onClick={() => setPasswordToggle1(!passwordToggle1)}
                className={
                  passwordToggle1 ? "fe fe-eye p-1" : "fe fe-eye-off p-1"
                }
              />
            </div>
          </label>
          <label className="form-label mb-5">
            Confirm Password
            <div className="d-flex form-control">
              <input
                className="w-100"
                type={passwordToggle2 ? "text" : "password"}
                placeholder="Enter new password"
                name="confirmPassword"
                value={formInput.confirmPassword}
                onChange={handleChange}
              />
              <i
                onClick={() => setPasswordToggle2(!passwordToggle2)}
                className={
                  passwordToggle2 ? "fe fe-eye p-1" : "fe fe-eye-off p-1"
                }
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                fontSize: "14px",
                color: "#8f3128",
                opacity: error ? "1" : "0",
              }}
            >
              Enter valid password
            </div>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="default"
            className="me-1"
            type="submit"
            onClick={handleSubmit}
          >
            Save Changes
          </Button>
          <Button
            variant="primary"
            className="me-1"
            onClick={() => setShow(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ResetUserPassword;
































// import React, { useState } from "react";
// import { Button, Modal } from "react-bootstrap";
// import { toast } from "react-toastify";
// import axios from "axios";

// function ResetUserPassword() {
//   const [show, setShow] = useState(false);
//   const handleShow = () => setShow(true);
//   const initialValue = {
//     oldPassword: "",
//     newPassword: "",
//     confirmPassword: "",
//   };
//   const [passwordToggle1, setPasswordToggle1] = useState(false);
//   const [passwordToggle2, setPasswordToggle2] = useState(false);
//   const [passwordToggle3, setPasswordToggle3] = useState(false);
//   const [error, setError] = useState(false);
//   const [formInput, setFormInput] = useState(initialValue);

//   const handleChange = (e) => {
//     setFormInput((perState) => {
//       return { ...perState, [e.target.name]: e.target.value };
//     });
//   };
// ---------------------------------------------------
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (formInput.oldPassword === "") {
//       toast.error("Field is required", {
//         position: "top-center",
//         autoClose: 2000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//       });


// --------------------------------------------------------

    // }else if (formInput.oldPassword !== password) {
    //     toast.error("Enter your valid password", {
    //       position: "top-center",
    //       autoClose: 2000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });




//     } else if (formInput.newPassword !== formInput.confirmPassword) {
//       setError(true);
//       setTimeout(() => {
//         setError(false);
//       }, 5000);
//     } else {
//       const url = `${process.env.REACT_APP_BACKEND_DATA}/api/auth/update`;
//       const data = {
//         oldPassword: formInput.oldPassword,
//         newPassword: formInput.newPassword,
//       };
//       axios
//         .post(url, data, {
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             "auth-token": localStorage.getItem("token"),
//           },
//         })
//         .then((response) => {})
//         .catch((error) => {});

//       setFormInput(initialValue);
//       setError(false);
//       setShow(false);
//     }
//   };

//   return (
//     <div>
//       <Button variant="primary" onClick={handleShow}>
//         Reset Password
//       </Button>

//       <Modal show={show}>
//         <Modal.Header>
//           <Modal.Title>Reset Password</Modal.Title>
//           <Button
//             onClick={() => setShow(false)}
//             className="btn-close"
//             variant=""
//           >
//             x
//           </Button>
//         </Modal.Header>
//         <Modal.Body>
//           <label className="form-label">
//             Old Password
//             <div className="d-flex form-control">
//               <input
//                 className="w-100"
//                 type={passwordToggle3 ? "text" : "password"}
//                 placeholder="Enter old password"
//                 // required
//                 name="oldPassword"
//                 value={formInput.oldPassword}
//                 onChange={handleChange}
//               />
//               <i
//                 onClick={() => setPasswordToggle3(!passwordToggle3)}
//                 className={
//                   passwordToggle3 ? "fe fe-eye p-1" : "fe fe-eye-off p-1"
//                 }
//               />
//             </div>
//           </label>
//           <label className="form-label">
//             New Password
//             <div className="d-flex form-control">
//               <input
//                 className="w-100"
//                 type={passwordToggle1 ? "text" : "password"}
//                 placeholder="Enter new password"
//                 name="newPassword"
//                 value={formInput.newPassword}
//                 onChange={handleChange}
//               />
//               <i
//                 onClick={() => setPasswordToggle1(!passwordToggle1)}
//                 className={
//                   passwordToggle1 ? "fe fe-eye p-1" : "fe fe-eye-off p-1"
//                 }
//               />
//             </div>
//           </label>
//           <label className="form-label mb-5">
//             Confirm Password
//             <div className="d-flex form-control">
//               <input
//                 className="w-100"
//                 type={passwordToggle2 ? "text" : "password"}
//                 placeholder="Enter new password"
//                 name="confirmPassword"
//                 value={formInput.confirmPassword}
//                 onChange={handleChange}
//               />
//               <i
//                 onClick={() => setPasswordToggle2(!passwordToggle2)}
//                 className={
//                   passwordToggle2 ? "fe fe-eye p-1" : "fe fe-eye-off p-1"
//                 }
//               />
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 flexDirection: "row-reverse",
//                 fontSize: "14px",
//                 color: "#8f3128",
//                 opacity: error ? "1" : "0",
//               }}
//             >
//               Enter valid password
//             </div>
//           </label>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button
//             variant="default"
//             className="me-1"
//             type="submit"
//             onClick={handleSubmit}
//           >
//             Save Changes
//           </Button>
//           <Button
//             variant="primary"
//             className="me-1"
//             onClick={() => setShow(false)}
//           >
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// }

// export default ResetUserPassword;
