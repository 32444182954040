import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import UserApiContext from '../UserApi/UserApiContext';
import newImage from '../../assets/images/ZZ5H.gif';
import './userDashboard.css';
const UserDashboard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/login');
    }
  }, []);
  const Usercontext = useContext(UserApiContext);
  const [tokendata, settokendata] = useState([]);
  const { creatingApplication, viewApplication, loggedInUserData } =
    Usercontext;
  // getting all the applications
  const [application, setApplication] = useState([]);
  const callingApplication = async () => {
    setApplication(await viewApplication());
  };
  useEffect(() => {
    callingApplication();
  }, []);
// console.log(application);
  // to get the logged in user data
  const Authtoken = localStorage.getItem('token');
  const token = async () => {
    const newData = await loggedInUserData(Authtoken);

    settokendata({ ...newData });
  };
  useEffect(() => {
    token();
  }, []);
  // creating blank appliction
  // const createapp = () => {
  //   creatingApplication();
  // };
  return (
    <>
      {/* {console.log('hello', application)} */}
      {tokendata &&
      tokendata.length === 0 &&
      application &&
      application.length === 0 ? (
        <div
          style={{
            height: '80vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img style={{ maxWidth: '7%' }} src={newImage} />
        </div>
      ) : (
        <div className="d-flex flex-column align-items-center  pb-8 mt-4">
          <Card.Header className=" mb-5">
            <h1
              style={{
                color: 'white',
                fontWeight: '200',
                fontSize: '3rem',
                textAlign: 'center',
              }}
              className="user-dashboard-header"
            >
              Hello{' '}
              <span
                style={{
                  textTransform: 'capitalize',
                  fontWeight: '400',
                  color: 'rgb(181 237 253)',
                }}
              >
                {tokendata.name}
              </span>
              , Welcome to JustNests
            </h1>
          </Card.Header>
          <Card.Body className="w-100">
            <div className="newApplication text-center">
              <div>
                <button
                  onClick={() => {
                    navigate('/application-data', {
                      state: { type: 'application-data' },
                    });
                    // createapp();
                  }}
                  className=" m-2 button user-dashboard-start-application"
                  style={{
                    width: '200px',
                    height: '180px',

                    color: 'white',
                    textTransform: 'capitalize',
                    fontSize: '1.4rem',
                    background: '#017697',

                    borderRadius: '4px',
                  }}
                >
                  <h4 className="btn btn-success mt-0"> New House</h4>
                  <br />
                  <br />
                  Start new application
                </button>
              </div>
              <div className="button view-application-card">
                {application &&
                  application.map((elem, ind) => {
                    return (
                      <>
                        <div className="border  box user-dashboard-view-application">
                          <h6
                            style={{
                              background: '#017697',
                              padding: '6px 0px',
                              color: '#fff',
                            }}
                            className="mt-0"
                          >
                            {' '}
                            {elem.address == 'N/A'
                              ? elem.lookingToBuy
                              : elem.address}
                          </h6>
                          <button
                            // className=" mt-1"
                            key={ind}
                            onClick={() =>
                              elem.propertyPlan == 'N/A'
                                ? navigate('/application-data', {
                                    state: {
                                      id: elem._id,
                                      type: 'view-application',
                                    },
                                  })
                                : navigate('/application-dashboard', {
                                    state: { id: elem._id },
                                  })
                            }
                          >
                            View application
                          </button>
                          <p className='mt-3' style={ {fontSize: "12px",}}>{elem.application_id}</p>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </Card.Body>
        </div>
      )}
    </>
  );
};

export default UserDashboard;
