import React from "react";

const DeprecationAddress = (props) => {
  return (
    <div
      className="property-address mt-7 border"
      //   style={{ border: "1px solid black" }}
    >
      <div className="text-center my-4">
        <h4 style={{ fontSize: "2rem" }}> Property address</h4>
      </div>
      <div className="m-3">
        <div className="pt-2 pb-2 text-center">
          <span style={{ fontSize: "1.7rem" }}>{props.propertyAddress}</span>
        </div>
      </div>
    </div>
  );
};

export default DeprecationAddress;
