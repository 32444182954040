import React, { useContext, useEffect, useState, Buffer } from "react";
import { Card } from "react-bootstrap";
import AdminContext from "../../adminContext/AdminContext";
import EditBlog from "./EditBlog";
import "../blog/blog.css";
import axios from "axios";
import PhotoUpload from "./PhotoUpload";



const GetBlog = (props) => {
  const allEmail = props.data

  const [allBlogData, setAllBlogData] = useState([]);
  const admincontext = useContext(AdminContext);
  const { getAllBlog, setHandleModal } = admincontext;
  const [dummy, setDummy] = useState();
  const [initialValue, setInitialValue] = useState([
    {
      title: "",
      description: "",
      _id: "",
      category: "",
    },
  ]);

  const [dataUri, setDataUri] = useState("");

  //   const newData = (file) => {
  //     if (!file) {
  //       setDataUri("");
  //     }

  //     fileToDataUri(file).then((dataUri) => {
  //       setDataUri(dataUri);
  //     });
  //   };

  //   const downloadImage = (type) => {
  //     const url = `${process.env.REACT_APP_BACKEND_DATA}/download-image?fileName=${type}`;
  //     axios
  //       .get(url, { responseType: "blob" })
  //       .then((response) => {
  //         newData(response.data);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   };

  const getAllBlogData = async () => {
    setAllBlogData(await getAllBlog());
  };

  useEffect(() => {
    getAllBlogData();
  }, [props.changeState]);
  const handleEdit = (title, description, _id, category) => {
    setHandleModal(true);
    setInitialValue({
      title,
      description,
      _id,
      category,
    });
    setShowModal(!showModal);
  };

  console.log(initialValue)
  const narr = []
  const [showModal, setShowModal] = useState(false);
   var arrrr = allEmail.map(element => {
    // console.log(element.category != "Signature");
    if (element.category != "Signature") {
      narr.push(element) 
    }
  });
  console.log(narr);
  return (
    <div>
      
      {
     
     narr &&
     narr.map((item, ind) => (
          <Card key={ind} className="card-blog-card  ">
            <div className="card-blog-body">
              <div className="card-blog-image">
              <h1> {`${item.category}`.toUpperCase()}</h1>
              </div>
              <div className="card-blog-details">
                <div className="admin-card-blog-title">{item.title}</div>
  
                <div
                  className="admin-card-blog-description  "
           
                  dangerouslySetInnerHTML={{ __html:  item.description }}
                />
              </div>
              <div className="blog-delete-and-edit">
                <span>
                  {" "}
                  <i
                    className="zmdi zmdi-edit"
                    style={{
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleEdit(
                        item.title,
                        item.description,
                        item._id,
                       item.category
                      );
                    }}
                  />
                </span>
              
              </div>
            </div>
          </Card>
        ))}
      <PhotoUpload/>
      {showModal ? (
        <EditBlog
          title={initialValue.title}
          description={initialValue.description}
          _id={initialValue._id}
          setChangeState={props.setChangeState}
          category={initialValue.category}
          changeState={props.changeState}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default GetBlog;
