import { Link } from "react-router-dom";

import MoneyImage from "../../assets/images/homePage/money.jpg";
import Footer from "../../component/footer/Footer";
import HomeBlogs from "../../component/home-component/homeBlogs/HomeBlogs";
import Services from "../../component/home-component/services/Services";
import Testimonial from "../../component/home-component/testimonials/Testimonial";
import Slider from "react-slick";
import Easy from "../../assets/images/homePage/easy.png";
import Convenient from "../../assets/images/homePage/convenient.png";
import Badge from "../../assets/images/homePage/badge.png";
import Shop from "../../assets/images/homePage/shop.png";
import JustNestsCustomerJourney from "../../assets/images/homePage/JustNests-Customer-Journey.jpg";
import "./Home.css";
import React,{useState,useEffect} from "react";
import HomeTop from "./HomeTop";
import TestimonialCarousel from "../../component/home-component/testimonials/Testiminialcaroudel";

const Home = () => {
 
  return (
    <div className="home-container" style={{ backgroundColor: "white" }}>
     
      <HomeTop />
    
     
      <div className="home-mid-section-1">
        <div className="home-mid-aside-1">
          <img src={JustNestsCustomerJourney} alt="About Image" />
        </div>
        <div className="home-mid-aside-2">
          <div>
            <h2 className="home-mid-section-heading">
             Why Use Just<span style={{ color: "#008eb5" }}>nests</span>?
            </h2>
            <div className="home-mid-section-body">
              <span>
                <span className="home-mid-section-body-image">
                  <img src={Badge} />
                </span>
                <span className="home-mid-section-body-heading">Top rated</span>
                <span className="home-mid-section-body-paragraph">
                  One of the best business experts to help you
                </span>
              </span>
              <span>
                <span className="home-mid-section-body-image">
                  <img src={Shop} />
                </span>
                <span className="home-mid-section-body-heading">
                  One stop 
                </span>
                <span className="home-mid-section-body-paragraph">
                  You don't have to run around. We'll connect you with everyone
                  you need
                </span>
              </span>
              <span>
                <span className="home-mid-section-body-image">
                  <img src={Convenient} />
                </span>
                <span className="home-mid-section-body-heading">
                  Convenient
                </span>
                <span className="home-mid-section-body-paragraph">
                  Do everything from you touch and see the magic happen
                </span>
              </span>
              <span>
                <span className="home-mid-section-body-image">
                  <img src={Easy} />
                </span>
                <span className="home-mid-section-body-heading">Easy</span>
                <span className="home-mid-section-body-paragraph">
                  No need to send the same information to everyone, we'll do it
                  for you
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Services />
      <div className="home-mid-section2">
        <div className="home-mid-section-aside">
          <img src={MoneyImage} />
        </div>
        <div className="home-mid-section-aside downPadding-home-mid">
          <h2 className="home-top-title">
            How Just
            <span style={{ color: "var(--blueButton)" }}>nests</span> Works
          </h2>
          <div className="home-mid-section-aside-body">
            <div className="home-mid-section-2">
              <span className="home-mid-section-list-number bulet-mar">
                <span>1</span>
              </span>
              <span>
                <div className="home-mid-section-aside-heading">
                  Tell us what you are looking to buy
                </div>
                <div className="home-mid-section-aside-paragraph">
                  Provide details about what you are looking to buy
                </div>
              </span>
            </div>
            <div className="home-mid-section-2">
              <span className="home-mid-section-list-number bulet-mar">
                <span>2</span>
              </span>
              <span>
                <div className="home-mid-section-aside-heading">
                  Visit dashboard{" "}
                </div>
                <div className="home-mid-section-aside-paragraph">
                  Choose required services to see the magic happen{" "}
                </div>
              </span>
            </div>
            <div className="home-mid-section-3">
              <span className="home-mid-section-list-number bulet-mar">
                <span>3</span>
              </span>
              <span>
                <div className="home-mid-section-aside-heading">
                  Experts will contact you
                </div>
                <div className="home-mid-section-aside-paragraph">
                  Depending on what services you have requested, experts will
                  contact you{" "}
                </div>
                <ul style={{ listStyleType: "none" }}>
                  <li>Mortgage expert will sort out your Finance</li>
                  <li>Legal expert will take care of your legal process</li>
                  <li>Building and Pest report will be provided to you</li>
                  <li>
                    Property management or Depreciation schedule or an
                    Accounting - You'll have one of the best expert of
                    everything at the click of a button
                  </li>
                </ul>
              </span>
            </div>
          </div>
          <Link to="/register" style={{ marginBottom: "1rem !important" }}>
            <button className="btn btn-outline-primary button">
              {" "}
              Get Started for free
            </button>
          </Link>
        </div>
      </div>
      {/* <Testimonial /> */}
<TestimonialCarousel/>
      <HomeBlogs />

      <Footer />
    </div>
  );
};

export default Home;
