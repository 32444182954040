import React, { useEffect, useRef, useState } from "react";
import "./App.css";
// import { Search, GpsFixed } from "@material-ui/icons";

const apiKey = "AIzaSyA4SevNv3t7rPZiN2JJ5z4SMlcNpjewx3M";
const mapApiJs = "https://maps.googleapis.com/maps/api/js";
const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";

// load google map api js

function loadAsyncScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src,
    });
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  });
}

const extractAddress = (place) => {
  const address = {
    city: "",
    state: "",
    zip: "",
    country: "",
    plain() {
      const city = this.city ? this.city + ", " : "";
      const zip = this.zip ? this.zip + ", " : "";
      const state = this.state ? this.state + ", " : "";
      return city + zip + state + this.country;
    },
  };

  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach((component) => {
    const types = component.types;
    const value = component.long_name;

    if (types.includes("locality")) {
      address.city = value;
    }

    if (types.includes("administrative_area_level_2")) {
      address.state = value;
    }

    if (types.includes("postal_code")) {
      address.zip = value;
    }

    if (types.includes("country")) {
      address.country = value;
    }
  });

  return address;
};

const AutoComplete = () => {
  // const [address, setAddress] = React.useState("");
  // const [coordinates, setCoordinates] = React.useState({
  //   lat: null,
  //   lng: null,
  // });

  // const handleSelect = async (value) => {
  //   const results = await geocodeByAddress(value);
  //   const latLng = await getLatLng(results[0]);
  //   setAddress(value);
  //   setCoordinates(latLng);
  // };
  // const [newValue, setNewValue] = useState("");
  // console.log(newValue);
  // const [newAddress, setnewAddress] = useState(newValue);
  // const setData = (e) => {
  //   console.log(e.target.value);
  // };

  const searchInput = useRef(null);
  const [address, setAddress] = useState({});

  // init gmap script
  const initMapScript = () => {
    // if script already loaded
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  };

  // do something on address change
  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    setAddress(extractAddress(place));
  };

  // init autocomplete
  const initAutocomplete = () => {
    if (!searchInput.current) return;

    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current
    );
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete)
    );
  };

  const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
    const url = `${geocodeJson}?key=${apiKey}&latlng=${lat},${lng}`;
    searchInput.current.value = "Getting your location...";
    fetch(url)
      .then((response) => response.json())
      .then((location) => {
        const place = location.results[0];
        const _address = extractAddress(place);
        setAddress(_address);
        searchInput.current.value = _address.plain();
      });
  };

  const findMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        reverseGeocode(position.coords);
      });
    }
  };

  // load map script after mounted
  useEffect(() => {
    initMapScript().then(() => initAutocomplete());
  }, []);

  return (
    // <div>
    //   <PlacesAutocomplete
    //     value={address}
    //     onChange={setAddress}
    //     onSelect={handleSelect}
    //   >
    //     {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
    //       <div>
    //         <p>Latitude: {coordinates.lat}</p>
    //         <p>Longitude: {coordinates.lng}</p>

    //         <input
    //           onChange={(e) => {
    //             setnewAddress({ [e.target.name]: e.target.value });
    //           }}
    //           name="address"
    //           {...getInputProps({ placeholder: "Type address" })}
    //         />

    //         <div>
    //           {loading ? <div>...loading</div> : null}

    //           {suggestions.map((suggestion) => {
    //             const style = {
    //               backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
    //             };

    //             return (
    //               <div {...getSuggestionItemProps(suggestion, { style })}>
    //                 <div
    //                   onClick={(e) => {
    //                     console.log(e.target.value);
    //                   }}
    //                 >
    //                   {suggestion.description}
    //                 </div>
    //               </div>
    //             );
    //           })}
    //         </div>
    //       </div>
    //     )}
    //   </PlacesAutocomplete>
    // </div>
    <>
      <div className="App">
        <div>
          <div className="search">
            <span>se</span>
            <input
              ref={searchInput}
              type="text"
              placeholder="Search location...."
            />
            <button onClick={findMyLocation}>GP</button>
          </div>

          <div className="address">
            <p>
              City: <span>{address.city}</span>
            </p>
            <p>
              State: <span>{address.state}</span>
            </p>
            <p>
              Zip: <span>{address.zip}</span>
            </p>
            <p>
              Country: <span>{address.country}</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AutoComplete;
