import React from "react";
import * as datatable from "./TableFormat";
import { Row,Card, Col} from "react-bootstrap";

function Table({userData, formInput, handleClick}) {
 return (
  <div>
   <Row className=" row-sm">
    <Col lg={12}>
     <Card>
      <Card.Body>
       <div className="table-responsive">
        <datatable.DataTables userData={userData} formInput={formInput} handleClick={handleClick}/>
       </div>
      </Card.Body>
     </Card>
    </Col>
   </Row>
  </div>
 );
}

export default Table;
