import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Form, Col } from "react-bootstrap";
import { Card, Button, Image } from "react-bootstrap";

function Updatepromo() {
  const navigate = useNavigate()
  const [promoName, setPromoName] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [service, setService] = useState("");

  const [discountAmount, setDiscountAmount] = useState("");
  const [voucherNumber, setVoucherNumber] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [notes, setNotes] = useState("");
  const applicablestate = [];
  //for state
  // const [applicablestate, setapplicablestate] = useState([]);
  const location = useLocation();

  console.log(location);
  const Id = location.state.Id._id;
  const oldval = location.state.Id.applicablestate;

  const [olddata, setOlddata] = useState();
  const arr = olddata?.applicablestate
  console.log(arr);
  useEffect(() => {
    dummy();
  }, []);

  const dummy = async () => {
    const url = `${process.env.REACT_APP_BACKEND_DATA}/get-single/${Id}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      });

      setOlddata(response.data.data);

      // console.log
    } catch (error) {}
  };
  console.log("log", olddata);
  const handleChange = (event) => {
    const { value } = event.target;
    if (applicablestate.includes(value)) {
      setapplicablestate(applicablestate.filter((v) => v !== value));
    } else {
      setapplicablestate([...applicablestate, value]);
    }
  };
  ///..........................
  // console.log(applicablestate);
  const handleSubmit = async(e) => {
    //   console.log(applicablestate);
    e.preventDefault();
    await axios
      .put(`${process.env.REACT_APP_BACKEND_DATA}/update-promocode`, {
        promoName,
        promoCode,
        service,
        Id,
        discountAmount,
        voucherNumber,
        startDate,
        applicablestate,
        endDate,
        notes,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
      setTimeout(() => {
        
        navigate("/admin/promocode")
      }, 1000);
  };
  
  console.log(oldval);
  const [checked, setChecked] = useState({
    NSW: oldval.includes("NSW"),
    SA: oldval.includes("SA"),
    ACT: oldval.includes("ACT"),
    TAS: oldval.includes("TAS"),
    WA: oldval.includes("WA"),
    QLD: oldval.includes("QLD"),
    NT: oldval.includes("NT"),
    VIC: oldval.includes("VIC"),
    All: oldval.includes("All"),
  });

  if (checked.ACT === true) {
    applicablestate.push("ACT");
  }
  if (checked.SA === true) {
    applicablestate.push("SA");
  }
  if (checked.VIC === true) {
    applicablestate.push("VIC");
  }
  if (checked.NT === true) {
    applicablestate.push("NT");
  }
  if (checked.TAS === true) {
    applicablestate.push("TAS");
  }
  if (checked.NSW === true) {
    applicablestate.push("NSW");
  }
  if (checked.WA === true) {
    applicablestate.push("WA");
  }
  if (checked.QLD === true) {
    applicablestate.push("QLD");
  }
  if (checked.All === true) {
    applicablestate.push("All");
  }

  const handleOnChange = (state) => {

    setChecked({ ...checked, [state]: !checked[state] });
  };
  const [t,setf]=useState(false)
const allstate =()=>{
  setf(!t)
  if(t){

    const alldata= ["ACT","NT","NSW","VIC","QLD","SA","WA","TAS","All"]
    console.log(alldata);
    applicablestate.push(...alldata);
  }else{
    applicablestate.splice(0, applicablestate.length);
  }
console.log(applicablestate);
}
  return (
    <div className="mt-6">
      {olddata ? (
        <Card>
          <Card.Body>
            <Card.Title>
              <b>Update Promo Code</b>
            </Card.Title>
            <hr />

            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="promoName">Promo Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="promoName"
                      defaultValue={olddata.promoName}
                      onChange={(e) => setPromoName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="promoCode">Promo Code:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="promoCode"
                      defaultValue={olddata.promoCode}
                      onChange={(e) => setPromoCode(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect2">
                      Applicable services
                    </label>
                    <select
                      onChange={(e) => setService(e.target.value)}
                      className="form-control"
                      id="exampleFormControlSelect2"
                      defaultValue={olddata.service}
                    >
                      <option value={""}>Select services</option>
                      <option value={"Mortgage Broker"}>Mortgage Broker</option>
                      <option value={"Engage Solicitor / Conveyancer"}>
                        Engage Solicitor / Conveyancer
                      </option>
                      <option value={"Receive Building / Pest"}>
                        Receive Building / Pest
                      </option>
                      <option value={"Receive Depreciation Schedule"}>
                        Receive Depreciation Schedule
                      </option>
                      <option value={"Engage Property Manager"}>
                        Engage Property Manager
                      </option>
                      <option value={"Engage Accountant"}>
                        Engage Accountant
                      </option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="discountAmount">Discount Amount:</label>
                    <input
                      type="number"
                      className="form-control"
                      id="discountAmount"
                      defaultValue={olddata.discountAmount}
                      onChange={(e) => setDiscountAmount(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="voucherNumber">Number of Vouchers:</label>
                    <input
                      type="number"
                      className="form-control"
                      id="voucherNumber"
                      defaultValue={olddata.voucherNumber}
                      onChange={(e) => setVoucherNumber(e.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="startDate">Start Date:</label>

                    <input
                      type="date"
                      className="m-1 form-control mr-2"
                      id="startDate"
                      defaultValue={olddata.startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                    <label htmlFor="endDate">End Date:</label>

                    <input
                      type="date"
                      className=" m-1 form-control"
                      id="endDate"
                      defaultValue={olddata.endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                  <div className="form-label">Service State</div>
                  <div className="custom-controls-stacked mt-3 mb-6 text-center">
                    <div className="d-block">
                      <label className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          name="checkbox1"
                          value={"NSW"}
                          checked={checked.All? true:checked.NSW}
                          onChange={() => {
                            handleOnChange("NSW");
                            checked.All &&
                            setChecked({
                              NSW: false,
                              VIC: true,
                              QLD: true,
                              SA: true,
                              WA: true,
                              TAS: true,
                              ACT: true,
                              NT: true,
                              All: false,
                            });}
                        }
                          className="custom-control-input"
                        />
                        <span className="custom-control-label">NSW</span>
                      </label>
                      <label className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          name="checkbox2"
                          value={"VIC"}
                          checked={checked.All? true:checked.VIC}
                          onChange={() =>
                            {

                              handleOnChange("VIC");
                              checked.All &&
                                  setChecked({
                                    NSW: true,
                                    VIC: false,
                                    QLD: true,
                                    SA: true,
                                    WA: true,
                                    TAS: true,
                                    ACT: true,
                                    NT: true,
                                    All: false,
                                  });
                            }
                        
                            }
                          className="custom-control-input"
                        />
                        <span className="custom-control-label">VIC</span>
                      </label>
                    </div>
                    <div className="d-block">
                      <label className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          name="checkbox3"
                          value={"QLD"}
                          checked={checked.All? true:checked.QLD}
                          onChange={() => 
                            {

                              handleOnChange("QLD");
                              checked.All &&
                                  setChecked({
                                    NSW: true,
                                    VIC: true,
                                    QLD: false,
                                    SA: true,
                                    WA: true,
                                    TAS: true,
                                    ACT: true,
                                    NT: true,
                                    All: false,
                                  });
                            }
                        
                          }
                          className="custom-control-input"
                        />
                        <span className="custom-control-label">QLD</span>
                      </label>

                      <label className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          name="checkbox4"
                          value={"SA"}
                          checked={checked.All? true:checked.SA}
                          onChange={() =>
                            {

                              handleOnChange("SA");
                              checked.All &&
                                  setChecked({
                                    NSW: true,
                                    VIC: true,
                                    QLD: true,
                                    SA: false,
                                    WA: true,
                                    TAS: true,
                                    ACT: true,
                                    NT: true,
                                    All: false,
                                  });
                            }
                        }
                          className="custom-control-input"
                        />
                        <span className="custom-control-label">SA</span>
                      </label>
                    </div>
                    <div className="d-block">
                      <label className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          name="checkbox5"
                          value={"WA"}
                          checked={checked.All? true:checked.WA}
                          onChange={() =>
                            {
                               handleOnChange("WA");
                               checked.All &&
                                  setChecked({
                                    NSW: true,
                                    VIC: true,
                                    QLD: true,
                                    SA: true,
                                    WA: false,
                                    TAS: true,
                                    ACT: true,
                                    NT: true,
                                    All: false,
                                  });

                             }
                        }
                          className="custom-control-input"
                        />
                        <span className="custom-control-label">WA</span>
                      </label>

                      <label className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          name="checkbox6"
                          value={"TAS"}
                          checked={checked.All?true:checked.TAS}
                          onChange={() =>
                            {

                              handleOnChange("TAS");
                              checked.All &&
                                  setChecked({
                                    NSW: true,
                                    VIC: true,
                                    QLD: true,
                                    SA: true,
                                    WA: true,
                                    TAS: false,
                                    ACT: true,
                                    NT: true,
                                    All: false,
                                  });
                            } 
                        }
                          className="custom-control-input"
                        />
                        <span className="custom-control-label">TAS</span>
                      </label>
                    </div>
                    <div className="d-block">
                      <label className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          name="checkbox9"
                          value={"ACT"}
                          onChange={() =>{
                            
                            handleOnChange("ACT");
                            checked.All &&
                                  setChecked({
                                    NSW: true,
                                    VIC: true,
                                    QLD: true,
                                    SA: true,
                                    WA: true,
                                    TAS: true,
                                    ACT: false,
                                    NT: true,
                                    All: false,
                                  });
                                } 
                              }
                              checked={checked.All? true: checked.ACT}
                          className="custom-control-input"
                        />
                        <span className="custom-control-label">ACT</span>
                      </label>
                      <label className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          name="checkbox7"
                          value={"NT"}
                          checked={checked.All? true:checked.NT}
                          onChange={() =>{

                            handleOnChange("NT");
                            checked.All &&
                            setChecked({
                              NSW: true,
                              VIC: true,
                              QLD: true,
                              SA: true,
                              WA: true,
                              TAS: true,
                              ACT: true,
                              NT: false,
                              All: false,
                            });
                          } 
                          }
                        
                          className="custom-control-input"
                        />
                        <span className="custom-control-label">NT</span>
                      </label>
                      <label className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          name="checkbox7"
                          value={"All"}
                          checked={checked.All}
                          onChange={() => 
                            {

                              handleOnChange("All");
                              checked.All &&
                              setChecked({
                                NSW: false,
                                    VIC: false,
                                    QLD: false,
                                    SA: false,
                                    WA: false,
                                    TAS: false,
                                    ACT: false,
                                    NT: false,
                                    All: false,
                              });
                            } 
                            }
                        
                        // onClick={()=>allstate()}

                          className="custom-control-input"
                        />
                        <span className="custom-control-label">ALL</span>
                      </label>
                      
                    </div>
                  </div>
                </div>
             <div className="container">
             <label htmlFor="">Notes</label>
              <textarea className="form-control " defaultValue={olddata.notes} onChange={(e)=>setNotes(e.target.value)}></textarea>
             </div>
              </div>

              <div className="d-flex justify-content-center mt-3">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>

            </form>
          </Card.Body>
        </Card>
      ) : (
        "loading"
      )}
    </div>
  );
}

export default Updatepromo;
