import React from "react";
import "./privacyAndPolicy.css";
import { Card, Button, Row, Modal } from "react-bootstrap";
import Footer from "../../component/footer/Footer";
const PrivacyAndPolicy = () => {
  return (
    <div>
      <Card className="privacy-policy-main-div">
        <h1 className="privacy-policy-main-heading">Privacy Policy</h1>
        <h2 className="privacy-policy-second-main-heading">
          Just Nests Pty Ltd (JustNests)
        </h2>
        <div className="privacy-policy-body">
          <div className="privacy-policy-bold-heading">Privacy Policy</div>
          <p className="inline-privacy-policy-paragraph">
            The JustNests Privacy policy outlines how JustNests can collect,
            use, hold and disclose the information collected from you and Your
            responsibilities and obligations on the JustNests Platform.
          </p>
          <p className="inline-privacy-policy-paragraph">
            By accessing{" "}
            <a href="https://www.justnests.com.au">www.justnests.com.au</a> you
            accept and agree to the Terms and Conditions of JustNests and
            acknowledge that your personal data may be collected, held, used and
            disclosed in accordance with this Privacy Policy.
          </p>
          <div className="privacy-policy-points">
            <div className="privacy-policy-first-point">
              <div className="privacy-policy-bold-heading">
                <span>1</span> Collection of Your Personal Data
              </div>
              <p className="privacy-policy-bold-heading">
                JustNests collects your personal data when you register with
                JustNests. This includes:
              </p>
              <ul className="privacy-policy-key-points">
                <li>your name, phone number, email address</li>
                <li>State where you would be purchasing the property</li>
                <li>Address of the property you would be purchasing</li>
                <li>Seller agent's name, agency, email ID, phone number</li>
              </ul>
              <div className="inline-privacy-policy-paragraph">
                JustNests may also receive and record data from certain third
                parties, such as social media sites that you connect to your
                account.
              </div>
            </div>
            <div className="privacy-policy-second-point">
              <div className="privacy-policy-bold-heading">
                <span>2</span> How we use your Personal Data
              </div>
              <p className="privacy-policy-bold-heading">
                JustNests may use the information we collect for the following
                purposes:
              </p>
              <ul className="privacy-policy-section-points">
                <li>
                  <span>2.1</span> Identification and authorisation – In order
                  to allow you to access the website and services
                </li>
                <li>
                  <span>2.2</span> Providing information to the Service
                  providers – In order for them to contact you and provide the
                  requested service
                </li>
                <li>
                  <span>2.3</span> To inform you about any upcoming improvements
                  to services, new features, promotions – We only contact you
                  for marketing purposes with your consent
                </li>
                <li>
                  <span>2.4</span> Data analysis – We may collate information to
                  do analysis on the performance of services, customer's
                  preferred services for marketing and service or product
                  improvements
                </li>
              </ul>
            </div>
            <div className="privacy-policy-third-point">
              <div className="privacy-policy-bold-heading">
                <span>3</span> How We Share Your Data
              </div>
              <ul className="privacy-policy-section-points">
                <li>
                  <span>3.1</span> Service Providers - We share your personal
                  data with service providers that provide services to you
                </li>
                <li>
                  <span>3.2</span> Data Analytics Partners – We may use third
                  party analytics or plugins who might store certain data
                </li>
                <li>
                  <span>3.3</span> Legal and Compliance - We may share personal
                  data to respond to legal process or provide information to
                  government, legal, regulatory bodies as required by law.
                </li>
                <li>
                  <span>3.4</span> Sale or Transfer of Business. In the event
                  that our business is sold whether by merger, sale or in the
                  event of bankruptcy, your personal data may be one of the
                  assets sold or merged in connection with the transaction.
                </li>
              </ul>
            </div>
            <div className="privacy-policy-fourth-point">
              <div className="privacy-policy-bold-heading">
                <span>4</span> Security
              </div>
              <p className="inline-privacy-policy-paragraph">
                Your account is secured by your chosen password. We take
                reasonable steps to ensure your personal data is protected from
                unauthorised access, use and disclosure, but we cannot provide
                absolute guarantee of the security of the data or that our IT
                infrastructure and systems would be 100% immune to viruses, data
                breaches or hacker interceptions. While we ensure best practices
                are followed to secure the information, we can not and do not
                guarantee that information you send from your computer or mobile
                to us over the internet will be protected by any form of
                encryption. You agree to transfer your personal data to
                JustNests keeping all the risks in mind and understand JustNests
                is not responsible for the risks associated with transfer or
                storage of personal information. Your account password is set by
                you and you are completely responsible for maintaining the
                privacy and security of the password in turn your account
                information.
              </p>
              <p className="inline-privacy-policy-paragraph">
                Some of your information will be shared with the service
                providers to help you receive service. JustNests will be not
                responsible for the data management and security from service
                providers.
              </p>
            </div>
            <div className="privacy-policy-fifth-point">
              <div className="privacy-policy-bold-heading">
                <span>5</span> International Transfers
              </div>
              <p className="inline-privacy-policy-paragraph">
                We do not use offshore personnel for the purposes providing
                services or sales or services. We will not transfer your data
                outside Australia for the purpose of providing you services.
              </p>
              <p className="inline-privacy-policy-paragraph">
                Web hosting providers might have servers outside Australia and
                might store information outside Australia. Your information will
                be subjected to the laws and regulations of the country it is
                stored in.
              </p>
            </div>
            <div className="privacy-policy-sixth-point">
              <div className="privacy-policy-bold-heading">
                <span>6</span> Cookies and Similar Technologies
              </div>
              <p className="inline-privacy-policy-paragraph">
                When you visit the JustNests web pages, our systems will record
                the IP address, time, visited page. We might also collect some
                information like the website you used to visit the JustNests web
                page. These are all non personal information. Cookies are
                customisable and you can disable or delete them in your web
                browser.
              </p>
              <p className="inline-privacy-policy-paragraph">
                This information is generally used to analyse the website
                traffic, information, tend analysis, potential marketing or
                improvements to services.
              </p>
            </div>
            <div className="privacy-policy-seventh-point">
              <div className="privacy-policy-bold-heading">
                <span>7</span> Information from Third Parties
              </div>
              <p className="inline-privacy-policy-paragraph">
                JustNests website may contain some linkes to Third-Party
                Websites. For e.g Service provider. When you visit those third-
                party website, you are subjected to their terms and conditions
                and privacy policies. JustNests is not responsible for how they
                manage privacy of your information. Third- Party Websites are
                responsible for informing you about their own privacy practices.
              </p>
            </div>
            <div className="privacy-policy-eight-point">
              <div className="privacy-policy-bold-heading">
                <span>8</span> Advertising and tracking
              </div>
              <p className="inline-privacy-policy-paragraph">
                JustNests may advertise on social media and third- party
                website. Those social media platforms and third- party websites
                might use cookies and similar technologies to collect
                information about you. You are subjected to their privacy
                statements and terms & conditions while you are using their
                platforms.
              </p>
            </div>
            <div className="privacy-policy-ninth-point">
              <div className="privacy-policy-bold-heading">
                <span>9</span> E-consent
              </div>
              <p className="inline-privacy-policy-paragraph">
                By providing your email address and then clicking submit on any
                JustNests online form, you consent to receiving emails from us
                to the email address provided. You may withdraw your consent to
                receiving emails at any time by contacting us.
              </p>
            </div>
            <div className="privacy-policy-tenth-point">
              <div className="privacy-policy-bold-heading">
                <span>10</span> Storage
              </div>
              <p className="inline-privacy-policy-paragraph">
                We may store your information in electronic storage such as
                Cloud which can be accessed anywhere using internet. Cloud
                storage space may be located anywhere even outside Australia.
                With the change in website hosting and storage service
                providers, it is not practicable to know in which country your
                information might be stored. The rules and regulations of the
                country in which your information is stored applies to your
                data. We only use reputed hosting and storage providers to
                ensure best practices are followed in storing and accessing the
                information. However, it is important to remember that the use
                of email and the internet may not always be secure, even with
                the best practices in place.
              </p>
            </div>
            <div className="privacy-policy-eleventh-point">
              <div className="privacy-policy-bold-heading">
                <span>11</span> Complaints and further information
              </div>
              <p className="inline-privacy-policy-paragraph">
                If you have a complaint about how we handle your personal
                information, or services in general we want to hear from you.
                You are always welcome to contact us to let us know about your
                complaint at
                <span style={{ fontWeight: "bold", color: "black" }}>
                  {" "}
                  hello@justnests.com.au
                </span>
              </p>
            </div>
            <div className="privacy-policy-twelth-point">
              <div className="privacy-policy-bold-heading">
                <span>12</span>Changes to our Privacy Policy
              </div>
              <p className="inline-privacy-policy-paragraph">
                Our policies are constantly reviewed to keep them relevant and
                up to date with the addition of new services, market trends,
                legal, compliance and regulatory changes. Updated privacy
                policies, terms & conditions will be updated in JustNests
                website. Your information management will be automatically
                subject to the updated Privacy Policy available in our Website.
              </p>
            </div>
          </div>
        </div>
      </Card>
      <Footer />
    </div>
  );
};

export default PrivacyAndPolicy;
