import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import { Button } from 'react-bootstrap';
import 'react-data-table-component-extensions/dist/index.css';
import axios from 'axios';

export const DataTables = ({ usersData }) => {
  // console.log(usersData);
  const [data, setData] = React.useState([]);
  const [linkData, setLinkData] = useState([]);
  let todayDate = new Date();
  let newDate =
    todayDate.getDate() +
    '/' +
    (todayDate.getMonth() + 1) +
    '/' +
    todayDate.getFullYear();

  useEffect(() => setData(usersData), [usersData]);

  const logClick = (dataId) => {
    let ndata = data.filter((ele) => {
      if (ele.taskId == dataId) {
        return ele;
      }
    });
    let freshData = linkData.push(ndata);
    setLinkData(freshData);
  };

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <Button type="button" className="ml-5" onClick={()=>tableToCSV()}>  Export</Button>
  );

  const columns = [
    {
      name: 'Task ID',
      selector: (row) => [row.task_id],
      sortable: true,
    },
    {
      name: ' Task Name',
      selector: (row) => [row.type],
      sortable: true,
    },
    {
      name: 'Customer Name',
      selector: (row) => [row.name],
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => [row.progress],
      sortable: true,
    },
    {
      name: ' Assigned To',
      selector: (row) => [row.assignTo],
      sortable: true,
    },
    {
      name: 'Assigned Date',
      selector: (row) => (
        <div>
          {row.assignDate === undefined
            ? 'N/A'
            : row.assignDate.split('-').reverse().join('/')}
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Creation Date',
      selector: (row) => new Date(row.createdDate).toLocaleDateString('en-IN'),
      sortable: true,
    },
    {
      name: 'Completion Date',
      selector: (row) => (
        <div>{row.type =="Mortgage Broker" ?row.completeDate === undefined ?"N/A":row.completeDate : row.completeDate === undefined ?"N/A": row.completeDate}</div>
      ),
      sortable: true,
    },
  ];
  const tableDatas = {
    columns,
    data,
  };
  const actionsMemo = React.useMemo(
    () => <Button type="button" className="ml-5" onClick={()=>tableToCSV()}>  Export</Button>,
    []
  );














  function tableToCSV() {
 
    // Variable to store the final csv data
    var csv_data = [];
  
    // Get each row data
    var rows = document.getElementsByClassName('trepx');
    console.log(rows);
    for (var i = 0; i < rows.length; i++) {
  
        // Get each column data
        var cols = rows[i].querySelectorAll('td,th');
  
        // Stores each csv row data
        var csvrow = [];
     
        for (var j = 0; j < cols.length; j++) {
  
            // Get the text data of each cell
            // of a row and push it to csvrow
            csvrow.push(cols[j].innerHTML);
        }
  
        // Combine each column value with comma
        csv_data.push(csvrow.join(","));
    }
  
    // Combine each row data with new line character
    csv_data = csv_data.join('\n');
  
    // Call this function to download csv file 
    downloadCSVFile(csv_data);
  
  }
  
  function downloadCSVFile(csv_data) {
  
    // Create CSV file object and feed
    // our csv_data into it
    var CSVFile = new Blob([csv_data], {
        type: "text/csv"
    });
  
    // Create to temporary link to initiate
    // download process
    var temp_link = document.createElement('a');
  
    // Download csv file
    temp_link.download = "Customer Details.csv";
    var url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;
  
    // This link should not be displayed
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);
  
    // Automatically click the link to
    // trigger download
    temp_link.click();
    document.body.removeChild(temp_link);
  }
  return (
    <>
  <table className='d-none'  border="1" cellSpacing="0" cellPadding="10">
  
  <tr className="trepx">
      <th className="thexp">Task ID</th>
      <th className="thexp">Task Name</th>
      <th className="thexp">Customer Name</th>
     
      <th className="thexp">Status </th>
      <th className="thexp">Assigned To</th>
      <th className="thexp">Assigned Date</th>
      <th className="thexp">Creation Date</th>
      <th className="thexp">Completion Date</th>
  </tr>
{
  data.map((elm)=>{
    return(

      <tr className='trepx'>
       <td>{elm.task_id}</td>
       <td>{elm.type}</td>
       <td>{elm.name}</td>
       <td>{elm.progress}</td>
       <td>{elm.assignTo}</td>
       <td>{elm.assignDate === undefined
            ? 'N/A'
            : elm.assignDate.split('-').reverse().join('/')}</td>
       <td>{ new Date(elm.createdDate).toLocaleDateString('en-IN')}</td>
       <td>{elm.type =="Mortgage Broker" ?elm.completeDate === undefined ?"N/A":elm.completeDate : elm.completeDate === undefined ?"N/A": elm.completeDate}</td>
      

    </tr>
            )
  })
}

    </table>
   
    <DataTableExtensions {...tableDatas}>
      <DataTable
        actions={actionsMemo}
        columns={columns}
        data={data}
        pagination
        highlightOnHover
        dense
        responsive
      />
    </DataTableExtensions>
        </>
  )
};
