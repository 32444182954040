import React, { useState } from "react";
import { toast } from "react-toastify";
import Footer from "../../component/footer/Footer";
import "./accounting.css";
import axios from "axios";
function Accounting() {

  const [promodata, setPromodata] = useState(0);
  const [allpromodata, setAllpromodata] = useState();
  const [promoApplied, setpromoApplied] = useState("false");
  const [userPromoCode, setUserPromo] = useState();
  
  
  const sendusedpromodata = () => {
    console.log(allpromodata);
    let promoName = allpromodata.promoName;
    let promoCode = userPromoCode;
    let service = "Engage Accountant";
    let custumerName = formInput.name;
    let custumerId = "N/A";
    let Ammount = allpromodata.discountAmount;
    // let custumerId = userInfo.email;
    console.log(1234567890);
    let companyName = "N/A";
    axios
      .post(`${process.env.REACT_APP_BACKEND_DATA}/used-promo`, {
        promoCode,
        promoName,
        service,
        custumerId,
        custumerName,
        companyName,
        Ammount,
      })
      .then((response) => {})
      .catch((error) => {
        console.error(error);
      });
  };
  
  
  const validateuserPromo = (e) => {
    e.preventDefault()
    console.log(selectedValue);
    let userPromo = userPromoCode;
    let userState = selectedValue;
    let userServise = "Engage Accountant";
    
  
    axios
      .post(`${process.env.REACT_APP_BACKEND_DATA}/verify-promo`, {
        userPromo,
        userState,
        userServise,
      })
      .then((response) => {
  
        console.log(response);
        if (response.data.promodata) {
          setPromodata(response.data.promodata.discountAmount);
          setpromoApplied("true");
          setAllpromodata(response.data.promodata);
          console.log(123, promoApplied);
          // sendusedpromodata()
        }
        if (response.data.msg == "Successfully Applied Promocode") {
          toast.success(`${response.data.msg}`, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(response.data.msg, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
//---------------------------------------------------------  

  var [selectedValue, setSelectedValue] = useState("");

  const handleChange1 = (event) => {
    setSelectedValue(event.target.value);
  };
  const initialInput = {
    name: "",
    email: "",
    number: "",
    amount: promodata,
    couponCode: userPromoCode,
    message: "",
  };
  var [formInput, setFormInput] = useState(initialInput);
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [error3, setError3] = useState(false);
  const [error4, setError4] = useState(false);
  const [error5, setError5] = useState(false);
  const [error6, setError6] = useState(false);
  const handleChange = (e) => {
    setFormInput((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(formInput);

    console.log(formInput.number.length);

    if (
      formInput.name.length != "" &&
      formInput.email.length != "" &&
      formInput.number.length != "" &&
      selectedValue.length != "" &&
      formInput.message.length != ""
    ) {
      toast.success(
        "Thanks for contacting Justnests, we’ll getback to you in one business day",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      //................api cal
      console.log("selectedValue", selectedValue);
      formInput = { ...formInput, selectedValue ,"amount":promodata, "couponCode":userPromoCode};
      let result = await fetch(
        `${process.env.REACT_APP_BACKEND_DATA}/accounting-info`,
        {
          method: "post",

          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ formInput }),
        }
      );
      result = await result.json();
      if(promoApplied == "true"){
        sendusedpromodata()

      }
      setSelectedValue("")
      setFormInput(initialInput);
      // window.location.reload();
      console.log(result);
    } else {
      if (formInput.name === initialInput.name) {
        setError1(true);
      }
      if (formInput.email === initialInput.email) {
        setError2(true);
      }
      if (formInput.email === initialInput.email) {
        setError2(true);
      }
      if (formInput.number <= initialInput.number) {
        setError3(true);
      }
      if (formInput.message == initialInput.message) {
        setError4(true);
      }
      if (selectedValue == "") {
        setError6(true);
      }
      if (formInput.number.length != 10) {
        setError5(true);
      }
    }
    setTimeout(() => {
      setError1(false);
      setError2(false);
      setError3(false);
      setError4(false);
      setError5(false);
      setError6(false);
    }, 4000);
  };
  return (
    <div className="contact-us1">
      <div className="contact-us-container1">
        <div className="row">
          <div className="contact-us-heading1">
            <h6>Let's Contact</h6>
            <h3>Accounting / Tax Return</h3>
            <span className="service-span" style={{ margin: "0" }}></span>

            <h4 className="text-dark">
              <b>Email: hello@justnests.com.au</b>{" "}
            </h4>
            <h4 className="text-dark">
              <b>Call: 04319 43210</b>
            </h4>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <form >
              <div className="contact-us-form-group">
                <div className="contact-us-form-list">
                  <div className="form-label">
                    Name :
                    <span
                      style={{
                        color: "red",
                        fontSize: "13px",
                        display: error1 ? "" : "none",
                      }}
                    >
                      (Input Required)
                    </span>
                  </div>
                  <input
                    className="form-control"
                    onChange={handleChange}
                    placeholder="Name*"
                    name="name"
                    type="text"
                    value={formInput.name}
                  />
                </div>
                <div className="contact-us-form-list">
                  <div className="form-label">
                    Email :{" "}
                    <span
                      style={{
                        color: "red",
                        fontSize: "13px",
                        display: error2 ? "" : "none",
                      }}
                    >
                      (Input Required)
                    </span>
                  </div>
                  {/* {selectedValue} */}
                  <input
                    type="email"
                    className="form-control"
                    onChange={handleChange}
                    placeholder="Email*"
                    name="email"
                    value={formInput.email}
                  />
                </div>
              </div>
              <div className="contact-us-form-group">
                <div className="contact-us-form-list">
                  <div className="form-label">Phone Number : </div>
                  <span
                    style={{
                      color: "red",
                      fontSize: "13px",
                      display: error3 ? "" : "none",
                    }}
                  >
                    (Input Required)
                  </span>
                  <span
                    style={{
                      color: "red",
                      fontSize: "13px",
                      display: error5 ? "" : "none",
                    }}
                  >
                    (10 digits required)
                  </span>
                  <input
                    className="form-control"
                    onChange={handleChange}
                    placeholder="Number*"
                    name="number"
                    type="number"
                    value={formInput.number}
                  />
                </div>
                <div className="contact-us-form-list">
                  <div className="form-label">
                    State :
                    <span
                      style={{
                        color: "red",
                        fontSize: "13px",
                        display: error6 ? "" : "none",
                      }}
                    >
                      (Input Required)
                    </span>
                  </div>
                  <select
                    className="form-control selectheight"
                    value={selectedValue}
                    onChange={handleChange1}
                  >
                    <option value="">Select State Here* </option>
                    <option value="NSW">NSW</option>
                    <option value="ACT">ACT</option>
                    <option value="QLD">QLD</option>
                    <option value="SA">SA</option>
                    <option value="WA">WA</option>
                    <option value="VIC">VIC</option>
                    <option value="TAS">TAS</option>
                    <option value="NT">NT</option>
                  </select>
                  {/* <input
                 className="form-control"
                 onChange={handleChange}
                 placeholder="Subject*"
                 name="subject"
                 type="text"
                 value={formInput.subject}
               /> */}
                </div>
              </div>
              <div className="contact-us-form-group">
                <div className="contact-us-form-list">
                  <div className="form-label" name="message">
                    What are you looking for ?{" "}
                    <span
                      style={{
                        color: "red",
                        fontSize: "13px",
                        display: error4 ? "" : "none",
                      }}
                    >
                      (Input Required)
                    </span>
                  </div>
                  <textarea
                    className="form-control"
                    onChange={handleChange}
                    placeholder="Your Message* "
                    //  required
                    name="message"
                    rows="4"
                    cols="150"
                    value={formInput.message}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <input
                    type="text"
                    placeholder="Enter promo code"
                    onChange={(e) => setUserPromo(e.target.value)}
                    className="form-control m-3"
                  />
                </div>
                <div className="col-6 ">
                  <button className="btn btn-info  m-3 " style={{height: "40px"}} onClick={validateuserPromo}>
                       Apply Promo
                  </button>
                </div>
                {promoApplied =="true" ? (
                <>
                  <div className="card mt-4 text-center">
                    <h4 style={{color:"green"}}>
                      Congratulations! you have received ${promodata} discount{" "}
                    </h4>
                    <h4>Your Accountant will reduce fees  by ${promodata}</h4>
                  </div>
                </>
              ) : (
                ""
              )}
              </div>
              <button className="btn btn-primary contact-us-btn" onClick={handleSubmit} type="submit">
                Send Message
              </button>
            </form>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 ">
            <span className="bottum-border-primary">Our Services:</span>
            <div className="w-75 card m-5">
              <ol>
                <h4 className="text-info" style={{ fontSize: "24px" }}>
                  Individual Solutions:
                </h4>
                <li>Tax returns</li>
                <li>Foreign Income & Transfer from overseas</li>
                <li>Superannuation Funds</li>
                <li>Home/Investment loans</li>
                <li>Property development projects</li>
              </ol>
            </div>
            <div className="m-5"></div>
            <div className="w-75 card m-5 " style={{}}>
              <ol>
                <h4 className="text-info" style={{ fontSize: "24px" }}>
                  Business Solutions:
                </h4>
                <li>Tax Planning & Financial Advice</li>
                <li>Company/ Trust Financials & Tax Return</li>
                <li>Book keeping Services</li>
                <li>Business Consultation</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Accounting;
