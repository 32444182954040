import React, { useState } from 'react';
import AdminContext from './AdminContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AdminState = (props) => {
  const navigate = useNavigate();
  const handleDashboardApi = () => {
    return axios
      .get(
        `${process.env.REACT_APP_BACKEND_DATA}/api/application/all-application`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'admin-token': localStorage.getItem('admin-token'),
          },
        }
      )
      .then((response) => {
        console.log('>>>>>>>', response.data);
        const table = [];
        var count = 0;
        response.data.reverse().map((user, index) => {
          // if (user.email == 'sameer@mail.com') {
          //   console.log('ddddeee', user);
          // }
          user.TASKNAME.map((task) => {
            count++;
            task._id = user._id;
            task.address = user.address;
            task.agencyName = user.agencyName;
            task.agentEmail = user.agentEmail;
            task.agentName = user.agentName;
            task.agentPhone = user.agentPhone;
            task.phone = user.phone;
            task.email = user.email;
            task.date = user.date;
            task.state1 = user.propertyState;
            task.state2 = user.lookingToBuy;
            task.lookingToBuy = user.lookingToBuy;
            task.propertType = user.propertType;
            task.propertyPlan = user.propertyPlan;
            task.propertyShortlisted = user.propertyShortlisted;
            // task.user = user.user;
            task.user = user.user_id;

            task.name = user.name;
            task.completionDate = user.completionDate;
            task.application_id = user.application_id;
            table.push(task);
          });
        });
        return table;
      })
      .catch((error) => {});
  };

  const handlePartnerApi = () => {
    return axios
      .get(
        `${process.env.REACT_APP_BACKEND_DATA}/api/partners/get-partner-details`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'admin-token': localStorage.getItem('admin-token'),
          },
        }
      )
      .then((response) => {
        return response.data.reverse();
      })
      .catch((error) => {});
  };

  const adminLogin = async (item) => {
    const { email, password } = item;
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/admin/admin-login`;
    const data = { email, password };
    axios
      .post(url, data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        // Save the aut token and redirect
        localStorage.setItem('admin-token', data.adminAuthtoken);
        navigate('/admin/dashboard');
        toast.success('Login successfull', {
          position: 'top-center',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        const formError = err.response.data.errors[0].msg;

        toast.error('Please fill correct credentials', {
          position: 'top-center',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleFeeDetailsApi = () => {
    return axios
      .get(
        `${process.env.REACT_APP_BACKEND_DATA}/api/fee-details/get-fee-details`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'admin-token': localStorage.getItem('admin-token'),
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {});
  };
  const handlePaymentDetailsApi = () => {
    return axios
      .get(
        `${process.env.REACT_APP_BACKEND_DATA}/api/payment-history/all-payment-history`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'admin-token': localStorage.getItem('admin-token'),
          },
        }
      )
      .then((response) => {
        console.log(response);
        return response.data;
      })
      .catch((error) => {});
  };
  const [legal, setLegal] = useState({
    revenue: 0,
    payable: 0,
    netIncome: 0,
  });
  const [buildingAndPest, setBuildingAndPest] = useState({
    revenue: 0,
    payable: 0,
    netIncome: 0,
  });
  const [depreciationSchedule, setDepreciationSchedule] = useState({
    revenue: 0,
    payable: 0,
    netIncome: 0,
  });

  const getAllBlog = () => {
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/blog/get-all-blog`;
    return axios
      .get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'admin-token': localStorage.getItem('admin-token'),
        },
      })
      .then((response) => response.data);
  };

  const getAllHowToBuy = () => {
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/how-to-buy/get-how-to-buy-house`;
    return axios
      .get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'admin-token': localStorage.getItem('admin-token'),
        },
      })
      .then((response) => response.data);
  };

  const [dummystate, setDummystate] = useState(false);
  const [handleModal, setHandleModal] = useState(false);
  const [handlePartnerModal, setHandlePartnerModal] = useState(false);
  // Legal Payment
  const [stateLegalRevenueDetails, setStateLegalRevenueDetails] = useState();
  const [stateLegalPayableDetails, setStateLegalPayableDetails] = useState();
  const [stateLegalNetIncomeDetails, setStateLegalNetIncomeDetails] =
    useState();
  // Building and pest
  const [stateBuildingRevenueDetails, setStateBuildingRevenueDetails] =
    useState();
  const [stateBuildingPayableDetails, setStateBuildingPayableDetails] =
    useState();
  const [stateBuildingNetIncomeDetails, setStateBuildingNetIncomeDetails] =
    useState();
  //Deprication schedule
  const [stateDepricationRevenueDetails, setStateDepricationRevenueDetails] =
    useState();
  const [stateDepricationPayableDetails, setStateDepricationPayableDetails] =
    useState();
  const [
    stateDepricationNetIncomeDetails,
    setStateDepricationNetIncomeDetails,
  ] = useState();

  useState();

  return (
    <AdminContext.Provider
      value={{
        getAllHowToBuy,
        getAllBlog,
        adminLogin,
        handleDashboardApi,
        handlePartnerApi,
        handleFeeDetailsApi,
        handlePaymentDetailsApi,
        dummystate,
        setDummystate,
        handleModal,
        setHandleModal,
        handlePartnerModal,
        setHandlePartnerModal,
        setLegal,
        setBuildingAndPest,
        setDepreciationSchedule,
        legal,
        buildingAndPest,
        depreciationSchedule,
        //Legal
        stateLegalRevenueDetails,
        setStateLegalRevenueDetails,
        stateLegalPayableDetails,
        setStateLegalPayableDetails,
        stateLegalNetIncomeDetails,
        setStateLegalNetIncomeDetails,
        // buildingAndPest
        stateBuildingRevenueDetails,
        setStateBuildingRevenueDetails,
        stateBuildingPayableDetails,
        setStateBuildingPayableDetails,
        stateBuildingNetIncomeDetails,
        setStateBuildingNetIncomeDetails,
        //Deprication
        stateDepricationRevenueDetails,
        setStateDepricationRevenueDetails,
        stateDepricationPayableDetails,
        setStateDepricationPayableDetails,
        stateDepricationNetIncomeDetails,
        setStateDepricationNetIncomeDetails,
      }}
    >
      <ToastContainer />
      {props.children}
    </AdminContext.Provider>
  );
};

export default AdminState;
