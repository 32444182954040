import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import Edit from '.././ApplicationDashboard/edit.png'
import { Link, useLocation, useNavigate } from "react-router-dom";
import UserApiContext from "../UserApi/UserApiContext";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import Updateaddress from "../ApplicationDashboard/Updateaddress";
const DepreciationSchedule = () => {
  const navigate = useNavigate();
  const [allData, setAllData] = useState([]); // getting single data
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, []);
  const UserApi = useContext(UserApiContext);
  let initialValue = { message: "" };
  const { sendDashboardData, loggedInUserData, callingTaskMailer, feeDetails } =
    UserApi;
  const [message, setMessage] = useState(initialValue);
  const [ADDshow,setADDShow] = useState(false)
  const location = useLocation();

  let id = location.state.id;
  let state1 = location.state.state;
  console.log(state1);
  var fees1 = location.state.Depreciation[3].fee;
  console.log("allData", allData);
  const [active, setActive] = useState(true);
  const [promodata, setPromodata] = useState(0);
  const [allpromodata, setAllpromodata] = useState();
  const [promoApplied, setpromoApplied] = useState("false");
  const [userPromoCode, setUserPromo] = useState();
  
   var fees = fees1 - promodata;
  const [userInfo, setUserInfo] = useState([]);

  const Authtoken = localStorage.getItem("token");
  const token = async () => {
    const newData = await loggedInUserData(Authtoken);

    setUserInfo(newData);
  };
  useEffect((e) => {
    token();
  }, []);
  console.log(allData);
  let todayDate = Date.parse(new Date());
  const depreciationSchedule = (item) => {
    const newData = {
      message: item.applicationType.message,
      name: userInfo.name,
      email: userInfo.email,
      phone: userInfo.phone,
      progress: "In Progress",
      type: item.applicationType.type,
      state: item.applicationType.selectState,
      paymentAmount: fees,
      createdDate: item.applicationType.createdDate,
      newData: item.applicationType.task_id,
    };
    sendDashboardData(newData, id);

    setActive(false);

    // calling mail api
    const mailData = {};
    mailData.customerName = allData.name;
    mailData.requestType = newData.type;
    mailData.taskid = newData.newData;
    mailData.custId = id;
    mailData.Ammount = allpromodata != undefined ?allpromodata.discountAmount :"N/A";

    mailData.customerId = allData.user_id;
    mailData.customerEmailId = allData.email;
    mailData.customerPhoneNumber = allData.phone;
    mailData.purchaseType = allData.propertyPlan;
    mailData.propertyType = allData.propertType;
    mailData.propertyAddress = allData.address;
    if (allData.lookingToBuy != "N/A") {
      mailData.propertyState = allData.lookingToBuy;
    } else {
      mailData.propertyState = allData.propertyState;
    }
    mailData.instructionFromCustomer = newData.message;
    callingTaskMailer(mailData);
    if (promoApplied == "true") {
      sendusedpromodata();
    }
  };

  const sendusedpromodata = () => {
    console.log(778987);
    let promoName = allpromodata.promoName;
    let promoCode = userPromoCode;
    let service = "Receive Depreciation Schedule";
    let custumerName = userInfo.name;
    let custumerId = allData.user_id;
    let Ammount = allpromodata.discountAmount;
    // let custumerId = userInfo.email;
    let companyName = allData.agencyName;
    axios
      .post(`${process.env.REACT_APP_BACKEND_DATA}/used-promo`, {
        promoCode,
        promoName,
        service,
        custumerId,
        custumerName,
        companyName,
        Ammount,
        id,
      })
      .then((response) => {})
      .catch((error) => {
        console.error(error);
      });
  };
  const dummy = async () => {
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/application/view-single-application/${id}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      });
      const initialValue = [];
      response.data.TASKNAME.map((newData) => {
        initialValue.push(newData);
      });
      // setGettingData(initialValue);
      setAllData(response.data);
      // console.log
    } catch (error) {}
  };

  useEffect(() => {
    dummy();
  }, [promodata]);

  const validateuserPromo = () => {
    let userPromo = userPromoCode;
    let userState = state1;
    let userServise = "Receive Depreciation Schedule";
    let name = userInfo.name;
    let email = userInfo.email;
    let phone = userInfo.phone;

    axios
      .post(`${process.env.REACT_APP_BACKEND_DATA}/verify-promo`, {
        userPromo,
        userState,
        userServise,
        name,
        id,
        email,
        phone,
      })
      .then((response) => {
        // console.log(response.data.promodata.discountAmount);
        if (response.data.promodata) {
          setPromodata(response.data.promodata.discountAmount);
          setpromoApplied("true");
          setAllpromodata(response.data.promodata);
          // sendusedpromodata()
        }
        if (response.data.msg == "Successfully Applied Promocode") {
          toast.success(`${response.data.msg}`, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(response.data.msg, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const [Cadress,setCaddress] = useState()
  return (
    <>
      {active ? (
        <div>
          <Card className="col-md-9">
            <h4 className="text-center mt-5">
              Property:{" "}{Cadress}
              {/* <span>{allData.address != "N/A" ? allData.address : state1}</span>{" "} */}
             <button onClick={()=>setADDShow(true)}> <img src={Edit} width={25} alt="" /></button> 
              
              <div className="card m-4">

              {  ADDshow &&  <Updateaddress setCaddress={setCaddress}/>}
              </div>
              <div className="d-none">

               <Updateaddress setCaddress={setCaddress}/>
              </div>


              {/* <i
                className="fe fe-edit-2"
                style={{ color: "blue", cursor: "pointer" }}
              /> */}
            </h4>
            <div
              className="border col-md-8"
              style={{ margin: "40px auto", background: "#eaedf1" }}
            >
              <Card.Header className="text-center block ">
                <h3>Depreciation Schedule Report</h3>
              </Card.Header>
              <Card.Body>
                <div className="text-center block">
                  <span
                    style={{
                      fontSize: "40px",
                      fontWeight: "500",
                      color: "#406d8d",
                    }}
                  >
                    ${fees1}{" "}
                  </span>
                  INC GST
                </div>
                <ul className="mt-4">
                  <li>Inc</li>
                  <li>
                    <i className="fe fe-check-circle mx-1" />
                    Maximum Deductions
                  </li>
                  <li>
                    <i className="fe fe-check-circle mx-1" />
                    ATO Compliant
                  </li>
                  <li>
                    <i className="fe fe-check-circle mx-1" />
                    Valid for 40 Years
                  </li>
                </ul>
              </Card.Body>
            </div>

            {/* <Card> */}

            <div className="d-flex justify-content-center">
              {/* <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center"> */}
                <input
                  type="text"
                  className=" m-2 form-control"
                  onChange={(e) => setUserPromo(e.target.value)}
                  placeholder="Apply your promocode if you have any?"
                  style={{ width: "17rem" }}
                />
              {/* </div> */}
              {/* <div className="col-lg-6 col-md-6 col-sm-12 text-center "> */}
                <Button
                  onClick={validateuserPromo}
                  className="m-2 building-inspection-button "
                >
                  Apply Promo Code
                </Button>
              {/* </div> */}
  </div>
  <div>
  
              {promodata ? (
                <>
                  <div className="mt-4 text-center">
                    <h3>
                      Congratulations! you have received ${promodata} discount{" "}
                    </h3>
                    <h4>New Depreciation Schedule cost is &{fees}</h4>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            {/* ../////////////////.. */}

            <div style={{ textAlign: "center" }}>
              <textarea
                placeholder="Any Special Instruction to Surveyor’ ?"
                className=" col-md-10 mt-5 mb-5"
                name="text"
                style={{ border: "1px solid black" }}
                id="solicitorConveyancer"
              ></textarea>{" "}
            </div>
            <div style={{ textAlign: "center" ,fontSize: "smaller"}}>
<b>Disclamer:</b> The cost is indicative only, the expert will provide an accurate quote upon contact which might be slightly lower or higher than what is displayed here.
            </div>
            <div className="text-center">
              <button
                className="m-4 building-inspection-button border"
                style={{ borderRadius: "5px", padding: "9px" }}
                onClick={() =>
                  navigate("/application-dashboard ", {
                    state: {
                      id: id,
                    },
                  })
                }
              >
                Go Back
              </button>
              <Button
                className="m-4 building-inspection-button "
                onClick={() => {
                  // handleSubmit();
                  depreciationSchedule({
                    id: id,
                    type: "deprecationAddress",
                    applicationType: {
                      applicationType: "deprecationScheduler",
                      type: "Depreciation Schedule",
                      price: fees,
                      message: message.message,
                      selectState: location.state.state,
                      createdDate: todayDate,
                      taskId: uuidv4(),
                    },
                  });
                }}
              >
                Confirm
              </Button>
            </div>
          </Card>
        </div>
      ) : (
        <Card className="mt-9 pt-7 pb-0 col-md-7">
          <Card.Header className="mx-auto" style={{ textAlign: "center" }}>
            <h2>
              All done! Sit back and relax. Our surveyor expert will get in
              touch with you
            </h2>
          </Card.Header>
          <Link
            className="mt-4"
            style={{ textAlign: "center" }}
            target="_blank"
            to={"/blog-page"}
          >
            <span className="btn btn-success btn-icon text-white mb-3">
              Go to blog page
            </span>
          </Link>
          <div style={{ textAlign: "center" }} className="my-6">
            <button
              className="btn btn-primary btn-icon text-white mb-4 "
              style={{ fontSize: "1rem" }}
              onClick={() => {
                navigate("/application-dashboard", {
                  state: {
                    id: id,
                  },
                });
              }}
            >
              Go back to Application Dashboard
            </button>
          </div>
        </Card>
      )}
    </>
  );
};

export default DepreciationSchedule;
