import React, { useState } from "react";
import { Dropdown, Navbar, Container } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import JustNests from "../../assets/images/justNest-logo/Justnests.png";
import UserApiContext from "../../User/UserApi/UserApiContext";
import "./Header.css";

export function Header() {
  const [locationPath, setlocationPath] = useState(true);
  const location = useLocation();
  const pathData = location.pathname.includes("/admin/");
  // useEffect(() => {
  //   if (pathData) {
  //     setlocationPath(true);
  //   } else {
  //     setlocationPath(false);
  //   }
  // }, [pathData]);

  function Fullscreen() {
    if (
      (document.fullScreenElement && document.fullScreenElement === null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  //dark-mode
  const Darkmode = () => {
    document.querySelector(".app").classList.toggle("dark-mode");
  };
  // responsivesearch
  const responsivesearch = () => {
    document.querySelector(".header-search").classList.toggle("show");
  };
  //swichermainright
  const swichermainright = () => {
    document.querySelector(".demo_changer").classList.toggle("active");
    document.querySelector(".demo_changer").style.right = "0px";
  };
  //Navigation
  const navigate = useNavigate();
  // calling Api

  const authToken = localStorage.getItem("token");
  const adminAuth = localStorage.getItem("admin-token");

  return (
    <Navbar
      expand="md"
      className="app-header header sticky"
      style={{ position: "relative", position: "sticky" }}
    >
      <Container fluid className="main-container">
        {locationPath && (
          <div className="d-flex m-3" style={{ flexDirection: "row-reverse" }}>
            <Link to="/">
              {adminAuth ? (
                ""
              ) : (
                <img
                  className="img-responsive nav-main-logo"
                  src={JustNests}
                  alt={JustNests}
                  style={{
                    height: "60px",
                    position: "absolute",
                    left: "5%",
                  }}
                />
              )}
            </Link>
            <div className="d-block nav-container-component-group">
              <div
                className="navbar-nav nav-component"
                style={{ fontSize: "16px" }}
              >
                {localStorage.getItem("admin-token") ? (
                  <div>
                    <img
                      className="img-responsive nav-main-logo-2 header-logo"
                      src={JustNests}
                      alt={JustNests}
                      style={{
                        height: "50px",
                        position: "absolute",
                        left: "10%",
                      }}
                    />
                    <div className="d-flex navbar-top-list header-items">
                      <Link to="/admin/setting"> Setting</Link>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex navbar-top-list">
                    <Link
                      className="nav-item nav-link active mx-2"
                      style={{ color: "#008EB5" }}
                      to="/about-us"
                    >
                      About Us <span className="sr-only">(current)</span>
                    </Link>
                    <Link
                      className="nav-item nav-link mx-2"
                      style={{ color: "#008EB5" }}
                      to="/contact-us"
                    >
                      Contact Us
                    </Link>
                    <Link
                      className="nav-item nav-link mx-2"
                      style={{ color: "#008EB5" }}
                      to="/accounting"
                    >
                      Accounting
                    </Link>
                    {!authToken && (
                      <Link
                        className="nav-item nav-link mx-2"
                        style={{ color: "#008EB5" }}
                        to="/register"
                      >
                        Create Account
                      </Link>
                    )}
                    {authToken && <Link to="/profile"> Profile</Link>}

                    {authToken && <Link to="/dashboard">Dashboard</Link>}

                    {/* {authToken && <Link to="/need-help">Need help?</Link>} */}
                  </div>
                )}
{ authToken || adminAuth ? ''
           : <Link className="navbar-top-list" to="/login">
           Sign in
         </Link>
                }


                {  adminAuth ? 
           <Link  onClick={() => {
            localStorage.clear();
            navigate("/admin");
          }}         className="navbar-top-list" to="/admin">Sign out</Link>
           : ''     }
                {  authToken ? 
           <Link  onClick={() => {
            localStorage.clear();
            navigate("/login");
          }}         className="navbar-top-list" to="/login">Sign out</Link>
           : ''     }


                <Dropdown className=" d-md-flex profile-1 nav-dropdown">
                  <Dropdown.Toggle
                    className="nav-link profile leading-none d-flex px-1 nav-dropdown-icon"
                    variant=""
                  >
                    <div>
                      <i
                        className="fa fa-reorder"
                        style={{
                          padding: "15px",
                          borderRadius: "50px",
                          boxShadow: "2px 2px 5px  gray",
                        }}
                      ></i>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="dropdown-menu dropdown-menu-right dropdown-menu-arrow pb-2"
                    style={{ margin: 0, overflow: "hidden" }}
                  >
                    <div className="dropdown-divider m-0"></div>

                    {localStorage.getItem("admin-token") ? (
                      <div>
                        <Dropdown.Item className="nav-item nav-link active mx-2">
                          <Link
                            to="./admin/setting"
                            style={{ position: "relative", right: "" }}
                          >
                            Setting
                          </Link>
                        </Dropdown.Item>
                      </div>
                    ) : (
                      <div>
                        <Dropdown.Item
                          className="nav-item nav-link active mx-2"
                          style={{ color: "#008EB5" }}
                          onClick={() => navigate("/about-us")}
                        >
                          About Us <span className="sr-only">(current)</span>
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="nav-item nav-link mx-2"
                          style={{ color: "#008EB5" }}
                          onClick={() => navigate("/contact-us")}
                        >
                          Contact Us
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="nav-item nav-link mx-2"
                          style={{ color: "#008EB5" }}
                          onClick={() => navigate("/accounting")}
                        >
                          Accounting
                        </Dropdown.Item>
                        {!authToken && (
                          <Dropdown.Item
                            className="nav-item nav-link mx-2"
                            style={{ color: "#008EB5" }}
                            onClick={() => navigate("/register")}
                          >
                            Create Account
                          </Dropdown.Item>
                        )}
                        {authToken && (
                          <Dropdown.Item
                            className="nav-item nav-link mx-2"
                            style={{ color: "#008EB5" }}
                            onClick={() => navigate("/profile")}
                          >
                            {" "}
                            Profile
                          </Dropdown.Item>
                        )}

                        {authToken && (
                          <Dropdown.Item
                            className="nav-item nav-link mx-2"
                            style={{ color: "#008EB5" }}
                            onClick={() => navigate("/dashboard")}
                          >
                            Dashboard
                          </Dropdown.Item>
                        )}

                        {/* {authToken && (
                          <Dropdown.Item
                            className="nav-item nav-link mx-2"
                            style={{ color: "#008EB5" }}
                            onClick={() => navigate("/contact-us")}
                          >
                            Need help?
                          </Dropdown.Item>
                        )} */}
                      </div>
                    )}


           { adminAuth ? ( adminAuth ? (
                  
                      <Dropdown.Item
                        className="nav-item nav-link mx-3"
                        style={{
                          color: "#008EB5",
                          borderTop: "1px solid #eaedf1",
                          padding: "5px 100px 15px 30px",
                          margin: "0px 0 0px 20px",
                          top: "10px",
                        }}
                        onClick={() => {
                          localStorage.clear();
                          navigate("/admin");
                        }}                    
                      >
                        Sign out
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item
                        className="nav-item nav-link mx-3"
                        style={{
                          color: "#008EB5",
                          borderTop: "1px solid #eaedf1",
                          padding: "5px 100px 20px 30px",
                          margin: "0px 0 0px 20px",
                          top: "10px",
                        }}
                        onClick={() => navigate("/login")}
                      >
                        Sign in
                      </Dropdown.Item>
                    )) :


                   (authToken  ? (
                      <Dropdown.Item
                        className="nav-item nav-link mx-3"
                        style={{
                          color: "#008EB5",
                          borderTop: "1px solid #eaedf1",
                          padding: "5px 100px 15px 30px",
                          margin: "0px 0 0px 20px",
                          top: "10px",
                        }}
                        onClick={() => {
                          localStorage.clear();
                          navigate("/");
                        }}                    
                      >
                        Sign out
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item
                        className="nav-item nav-link mx-3"
                        style={{
                          color: "#008EB5",
                          borderTop: "1px solid #eaedf1",
                          padding: "5px 100px 20px 30px",
                          margin: "0px 0 0px 20px",
                          top: "10px",
                        }}
                        onClick={() => navigate("/login")}
                      >
                        Sign in
                      </Dropdown.Item>
                    )
                   )
                    }

                  



                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        )}
        <div className="d-none align-items-center p-1">
          <div className="responsive-logo">
            <Link
              to={`${process.env.PUBLIC_URL}/dashboard/`}
              className="header-logo"
            >
              <img
                src={require("../../assets/images/justNest-logo/jn-app-1.png")}
                className="mobile-logo logo-1"
                alt="logo"
              />
              <img
                src={require("../../assets/images/justNest-logo/jn-app-1.png")}
                className="mobile-logo dark-logo-1"
                alt="logo"
              />
            </Link>
          </div>
          <Link
            className="logo-horizontal "
            to={`${process.env.PUBLIC_URL}/dashboard/`}
          >
            <img
              src={require("../../assets/images/justNest-logo/jn-app-1.png")}
              className="header-brand-img desktop-logo"
              alt="logo"
            />
            <img
              src={require("../../assets/images/justNest-logo/jn-app-1.png")}
              className="header-brand-img light-logo1"
              alt="logo"
            />
          </Link>

          <div className="d-flex order-lg-2 ms-auto header-right-icons">
            <Navbar.Toggle
              aria-controls="navbarScroll"
              className="navresponsive-toggler d-lg-none ms-auto"
              type="button"
            >
              <span className="navbar-toggler-icon fe fe-more-vertical text-dark"></span>
            </Navbar.Toggle>

            <div className="navbar navbar-collapse responsive-navbar p-0">
              <Navbar.Collapse
                className="navbar-collapse"
                id="navbarSupportedContent-4"
              >
                <div className="d-flex order-lg-2">
                  <div className="dropdown d-block d-lg-none">
                    <Link
                      to="#"
                      className="nav-link icon"
                      onClick={() => responsivesearch()}
                    >
                      <i className="fe fe-search"></i>
                    </Link>
                    <div className="dropdown-menu header-search dropdown-menu-start">
                      <div className="input-group w-100 p-2 border">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search...."
                        />
                        <div className="input-group-text btn btn-primary">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* {authToken && (
                    <div className="dropdown d-md-flex">
                      <Link
                        to="#"
                        className="nav-link icon theme-layout nav-link-bg layout-setting"
                        onClick={() => Darkmode()}
                      >
                        <span className="dark-layout">
                          <i className={`fe ${"fe-moon"}`}></i>
                        </span>
                        <span className="light-layout">
                          <i className={`fe ${"fe-sun"}`}></i>
                        </span>
                      </Link>
                    </div>
                  )} */}
                  {authToken && (
                    <div className="dropdown d-md-flex">
                      <Link
                        to="#"
                        className="nav-link icon full-screen-link nav-link-bg"
                        onClick={Fullscreen}
                      >
                        <i className="fe fe-minimize fullscreen-button"></i>
                      </Link>
                    </div>
                  )}
                </div>
              </Navbar.Collapse>
            </div>
          </div>
        </div>
      </Container>
    </Navbar>
  );
}

export default Header;
