import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import axios from "axios";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

import { Card, Button, Image } from "react-bootstrap";
function DataTables({ data ,fetchData1}) {

  const navigate =  useNavigate()
   const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = dd + "/" + mm + "/" + yyyy;
  console.log(formattedToday);
  const actionsMemo = React.useMemo(() => (
    <Button type="button" className="ml-5" onClick={() => tableToCSV()}>
      {" "}
      Export
    </Button>
  ));

  const navi = (id)=>{
    navigate('/admin/update-promocode', {
      state: { Id:id },
    })
  }
  const columns = [
    {
      name: "Promo Name",
      selector: (row) => [row.promoName],
      sortable: true,
    },
    {
      name: "Promo Code",
      selector: (row) => [row.promoCode],
      sortable: true,
    },
    {
      name: "Service",
      selector: (row) => [row.service],
      sortable: true,
    },
   
   
    {
      name: "Custumer Id",
      selector: (row) =>row.custumerId,
      sortable: true,
    },
   
    {
      name: "Custumer Name",
      selector: (row) => [row.custumerName],
      sortable: true,
    },
    {
      name: "Task Id",
      selector: (row) => [row.taskId],
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => [row.Ammount],
      sortable: true,
    },
    {
      name: "Company Name",
      selector: (row) => [row.companyName],
      sortable: true,
    },
    {
      name: "Redeem Date",
      selector: (row) =>new Date(row.redeemDate).toLocaleDateString('en-IN'),
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => <button className="btn btn-sm btn-danger" onClick={()=>handledelete(row._id)}>Delete</button>,
      sortable: true,
    },
  ];

  const handledelete = async (id) => {
  
   axios.delete(`${process.env.REACT_APP_BACKEND_DATA}/delete-usedpromo/${id}`)
    fetchData1()
 

  };
  function tableToCSV() {
    // Variable to store the final csv data
    var csv_data = [];

    // Get each row data
    var rows = document.getElementsByClassName("tttt");
    console.log(rows);
    for (var i = 0; i < rows.length; i++) {
      // Get each column data
      var cols = rows[i].querySelectorAll("td,th");

      // Stores each csv row data
      var csvrow = [];

      for (var j = 0; j < cols.length; j++) {
        // Get the text data of each cell
        // of a row and push it to csvrow
        csvrow.push(cols[j].innerHTML);
      }

      // Combine each column value with comma
      csv_data.push(csvrow.join(","));
    }

    // Combine each row data with new line character
    csv_data = csv_data.join("\n");

    // Call this function to download csv file
    downloadCSVFile(csv_data);
  }
  function downloadCSVFile(csv_data) {
    // Create CSV file object and feed
    // our csv_data into it
    var CSVFile = new Blob([csv_data], {
      type: "text/csv",
    });

    // Create to temporary link to initiate
    // download process
    var temp_link = document.createElement("a");

    // Download csv file
    temp_link.download = " Usedpromocodes.csv";
    var url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;

    // This link should not be displayed
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);

    // Automatically click the link to
    // trigger download
    temp_link.click();
    document.body.removeChild(temp_link);
  }
  return (
    <>
      <div>
        <DataTable
          actions={actionsMemo}
          columns={columns}
          data={data} 
          pagination
          highlightOnHover
          dense
          responsive
        />
        Total used Promocodes:- {data.length}
      </div>
      <table className="d-none" border="1" cellSpacing="0" cellPadding="10">
        <tr className="tttt">
          <th className="thexp">Promo Name</th>
          <th className="thexp">Promocode</th>
          <th className="thexp">Service</th>
          <th className="thexp">Custumer Name </th>
          <th className="thexp">Task Id</th>
          <th className="thexp">Amount</th>
          <th className="thexp">Company Name</th>
          <th className="thexp">Redeem Date</th>
        </tr>
        {data?.map((row) => {
          return (
            <>
              <tr className="tttt">
                <td>{row.promoName}</td>
                <td>{row.promoCode}</td>
                <td>{row.service}</td>
                <td>{row.custumerId}</td>
                <td>{row.taskId}</td>
                <td>{row.Ammount}</td>
                <td>{row.companyName}</td>
                <td>{row.redeemDate}</td>
              </tr>
            </>
          );
        })}
      </table>
    </>
  );
}

export default DataTables;
