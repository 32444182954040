import React, { useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Button } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";
import AdminContext from "../../../../../adminContext/AdminContext";
import {CSVLink, CSVDownload} from 'react-csv';
export const DataTables = ({
  incomingData,
  incomingPaymentData,
  incomingPartnerData,
}) => {
  const [data, setData] = React.useState();
  const adminContext = useContext(AdminContext);
console.log(incomingData);
  let {
    stateBuildingRevenueDetails,
    setStateBuildingRevenueDetails,
    stateBuildingPayableDetails,
    setStateBuildingPayableDetails,
    stateBuildingNetIncomeDetails,
    setStateBuildingNetIncomeDetails,
  } = adminContext;

  let NetPayableTOPartner = 0;
  let NetpaymentAmount = 0;

  let newpartnerDetails = 0;
  let newpaymentDetail = 0;
  let newNetIncome = 0;
  let settingData = (data1, data2) => {
    newpaymentDetail = newpaymentDetail + data1;
    newpartnerDetails = newpartnerDetails + data2;
    newNetIncome = newNetIncome + data1 - data2;
    setStateBuildingRevenueDetails(newpaymentDetail);
    setStateBuildingPayableDetails(newpartnerDetails);
    setStateBuildingNetIncomeDetails(newNetIncome);
  };

  let newIncomingPartnerData =
    incomingPartnerData &&
    incomingPartnerData.map((data) => {
      return data.partnerServiceStateDetails.map((value) => {
        return value;
      });
    });

  let newPartnerDetails = [];
  newIncomingPartnerData.map((newData) => {
    return newData.map((elem) => {
      newPartnerDetails.push(elem);
    });
  });

  useEffect(() => {
    setData(incomingData);
  });

  function convertArrayOfObjectsToCSV(array) {
    let result;
  
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }
console.log(newPartnerDetails);

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    // <Button onClick={(e) => onExport(e.target.value)}>Export</Button>
    <Button type="button" className="ml-5" onClick={()=>tableToCSV()}>  Export</Button>
  );

  const columns = [
    {
      name: "Task ID",
      selector: (row) => [row.task_id],
      sortable: true,
    },
    {
      name: "Task Name",
      selector: (row) => [row.type],
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (row) => [row.name],
      sortable: true,
    },
    {
      name: "Customer ID",
      selector: (row) => [row.user],
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => [row.progress],
      sortable: true,
    },
    {
      name: "Assigned Date",
      selector: (row) => (
        <div>
          {row.assignDate === undefined
            ? "N/A"
            : row.assignDate.split("-").reverse().join("/")}
        </div>
      ),
      sortable: true,
    },



    {
      name: "Completion Date",
      selector: (row) => row.completeDate,
      sortable: true,
    },
    // {
    //   name: "Completion Date",
    //   selector: (row) => new Date(row.createdDate).toLocaleDateString("en-IN"),
    //   sortable: true,
    // },
    {
      name: "State",
      selector: (row) =>
        row.state2 !== "N/A"
          ? row.state2
          : row.state1 !== "N/A"
          ? row.state1
          : "",
      sortable: true,
    },
    {
      name: "No. Of Bedroom",
      selector: (row) => [row.bedRoomSize],
      sortable: true,
    },
    {
      name: "Customer Payment",
      selector: (row) => row.paymentAmount,
        // incomingPaymentData
        //   .filter((data) => {
        //     return row.taskId === data.taskId;
        //   })
        //   .map((filteredData) => {
        //     NetpaymentAmount = filteredData.paymentAmount;
        //     return filteredData.paymentAmount;
        //   }),
      sortable: true,
    },
    {
      name: "Payable to Partner",
      selector: (row) =>
        newPartnerDetails.map((data) => {
          if (
        
            row.state === data.partnerState &&
            row.bedRoomSize === "One" &&
            row.type === "Pest Inspection"
          ) {
            NetPayableTOPartner = data.oneP;
            return data.oneP;
          }
          if (
            
            row.state === data.partnerState &&
            row.bedRoomSize === "Two" &&
            row.type === "Pest Inspection"
          ) {
            NetPayableTOPartner = data.twoP;
            return data.twoP;
          }
          if (
            
            row.state === data.partnerState &&
            row.bedRoomSize === "Three" &&
            row.type === "Pest Inspection"
          ) {
            NetPayableTOPartner = data.threeP;
            return data.threeP;
          }
          if (
           
            row.state === data.partnerState &&
            row.bedRoomSize === "Four" &&
            row.type === "Pest Inspection"
          ) {
            NetPayableTOPartner = data.fourP;
            return data.fourP;
          }
          if (
           
            row.state === data.partnerState &&
            row.bedRoomSize === "PlusFour" &&
            row.type === "Pest Inspection"
          ) {
            NetPayableTOPartner = data.plusFourP;
            return data.plusFourP;
          }
          if (
          
            row.state === data.partnerState &&
            row.bedRoomSize === "One" &&
            row.type === "Building Inspection"
          ) {
            NetPayableTOPartner = data.oneB;
            return data.oneB;
          }
          if (
           
            row.state === data.partnerState &&
            row.bedRoomSize === "Two" &&
            row.type === "Building Inspection"
          ) {
            NetPayableTOPartner = data.twoB;
            return data.twoB;
          }
          if (
           
            row.state === data.partnerState &&
            row.bedRoomSize === "Three" &&
            row.type === "Building Inspection"
          ) {
            NetPayableTOPartner = data.threeB;
            return data.threeB;
          }
          if (
           
            row.state === data.partnerState &&
            row.bedRoomSize === "Four" &&
            row.type === "Building Inspection"
          ) {
            NetPayableTOPartner = data.fourB;
            return data.fourB;
          }
          if (
           
            row.state === data.partnerState &&
            row.bedRoomSize === "PlusFour" &&
            row.type === "Building Inspection"
          ) {
            NetPayableTOPartner = data.plusFourB;
            return data.plusFourB;
          }
          if (
           
            row.state === data.partnerState &&
            row.bedRoomSize === "One" &&
            row.type === "Building and Pest Inspection"
          ) {
            NetPayableTOPartner = data.oneC;
            return data.oneC;
          }
          if (
         
            row.state === data.partnerState &&
            row.bedRoomSize === "Two" &&
            row.type === "Building and Pest Inspection"
          ) {
            NetPayableTOPartner = data.twoC;
            return data.twoC;
          }
          if (
           
            row.state === data.partnerState &&
            row.bedRoomSize === "Three" &&
            row.type === "Building and Pest Inspection"
          ) {
            NetPayableTOPartner = data.threeC;
            return data.threeC;
          }
          if (
           
            row.state === data.partnerState &&
            row.bedRoomSize === "Four" &&
            row.type === "Building and Pest Inspection"
          ) {
            NetPayableTOPartner = data.fourC;
            return data.fourC;
          }
          if (
           
            row.state === data.partnerState &&
            row.bedRoomSize === "PlusFour" &&
            row.type === "Building and Pest Inspection"
          ) {
            NetPayableTOPartner = data.plusFourC;
            return data.plusFourC;
          }
        }),
      sortable: true,
    },
    {
      name: "Net Income",
      selector: (row) => {
        settingData(parseInt(row.paymentAmount), parseInt(NetPayableTOPartner));
        return parseInt(row.paymentAmount) - parseInt(NetPayableTOPartner);
      },
      sortable: true,
    },
  ];
  const tableDatas = {
    columns,
    data,
  };
  const actionsMemo = React.useMemo(
    () =>
    // <CSVLink data={incomingData} ><span className="btn btn-primary">Export</span></CSVLink>,
    <Button type="button" className="ml-5" onClick={()=>tableToCSV()}>  Export</Button>,
    []
  );
  function tableToCSV() {
 
    // Variable to store the final csv data
    var csv_data = [];
  
    // Get each row data
    var rows = document.getElementsByClassName('tre');
    console.log(rows);
    for (var i = 0; i < rows.length; i++) {
  
        // Get each column data
        var cols = rows[i].querySelectorAll('td,th');
  
        // Stores each csv row data
        var csvrow = [];
        for (var j = 0; j < cols.length; j++) {
  
            // Get the text data of each cell
            // of a row and push it to csvrow
            csvrow.push(cols[j].innerHTML);
        }
  
        // Combine each column value with comma
        csv_data.push(csvrow.join(","));
    }
  
    // Combine each row data with new line character
    csv_data = csv_data.join('\n');
  
    // Call this function to download csv file 
    downloadCSVFile(csv_data);
  
  }
  
  function downloadCSVFile(csv_data) {
  
    // Create CSV file object and feed
    // our csv_data into it
    var CSVFile = new Blob([csv_data], {
        type: "text/csv"
    });
  
    // Create to temporary link to initiate
    // download process
    var temp_link = document.createElement('a');
  
    // Download csv file
    temp_link.download = "Building and Pest Inspection.csv";
    var url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;
  
    // This link should not be displayed
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);
  
    // Automatically click the link to
    // trigger download
    temp_link.click();
    document.body.removeChild(temp_link);
  }
  return (
    <>
 
      <DataTableExtensions {...tableDatas}>
        <DataTable
          actions={actionsMemo}
          columns={columns}
          data={data}
          pagination
          highlightOnHover
          responsive
          dense
        />
      </DataTableExtensions>
      <div className="form-control d-flex " style={{ justifyContent: "end" }}>
        <div className="mx-5" style={{ fontWeight: "bold" }}>
          Revenue: $ {stateBuildingRevenueDetails}
        </div>
        <div className="mx-5" style={{ fontWeight: "bold" }}>
          Payable: $ {stateBuildingPayableDetails}
        </div>
        <div className="mx-5" style={{ fontWeight: "bold" }}>
          Net Income: $ {stateBuildingNetIncomeDetails}
        </div>
      </div>

      <table className="d-none" border="1" cellspacing="0" cellpadding="10">
			<tr className="tre">
				<th>Task ID</th>
				<th>Task Name</th>
				<th>Customer Name</th>
				<th>Customer ID</th>
				<th>Status </th>
				<th>Assigned Date</th>
				<th>Completion Date</th>
				<th>State</th>
				<th>No. Of Bedroom</th>
				<th>State</th>
				<th>Customer Payment</th>
				<th>Payable to Partner</th>
				<th>Net Income</th>
			</tr>
      {
      data?.map((row)=>{

       return(
        <>
        <tr className="tre">
				<td>{row.task_id}</td>
				<td>{row.type}</td>
				<td>{row.name}</td>
				<td>{row.user}</td>
				<td>{row.progress}</td>
				<td>{row.assignDate === undefined
            ? "N/A"
            : row.assignDate.split("-").reverse().join("/")}</td>
				<td>{row.completeDate}</td>
				<td>{row.state2 !== "N/A"
          ? row.state2
          : row.state1 !== "N/A"
          ? row.state1
          : ""}</td>
				<td>{row.bedRoomSize}</td>
				<td>{  newPartnerDetails.map((data) => {
          if (
        
            row.state === data.partnerState &&
            row.bedRoomSize === "One" &&
            row.type === "Pest Inspection"
          ) {
            NetPayableTOPartner = data.oneP;
            return data.oneP;
          }
          if (
            
            row.state === data.partnerState &&
            row.bedRoomSize === "Two" &&
            row.type === "Pest Inspection"
          ) {
            NetPayableTOPartner = data.twoP;
            return data.twoP;
          }
          if (
            
            row.state === data.partnerState &&
            row.bedRoomSize === "Three" &&
            row.type === "Pest Inspection"
          ) {
            NetPayableTOPartner = data.threeP;
            return data.threeP;
          }
          if (
           
            row.state === data.partnerState &&
            row.bedRoomSize === "Four" &&
            row.type === "Pest Inspection"
          ) {
            NetPayableTOPartner = data.fourP;
            return data.fourP;
          }
          if (
           
            row.state === data.partnerState &&
            row.bedRoomSize === "PlusFour" &&
            row.type === "Pest Inspection"
          ) {
            NetPayableTOPartner = data.plusFourP;
            return data.plusFourP;
          }
          if (
          
            row.state === data.partnerState &&
            row.bedRoomSize === "One" &&
            row.type === "Building Inspection"
          ) {
            NetPayableTOPartner = data.oneB;
            return data.oneB;
          }
          if (
           
            row.state === data.partnerState &&
            row.bedRoomSize === "Two" &&
            row.type === "Building Inspection"
          ) {
            NetPayableTOPartner = data.twoB;
            return data.twoB;
          }
          if (
           
            row.state === data.partnerState &&
            row.bedRoomSize === "Three" &&
            row.type === "Building Inspection"
          ) {
            NetPayableTOPartner = data.threeB;
            return data.threeB;
          }
          if (
           
            row.state === data.partnerState &&
            row.bedRoomSize === "Four" &&
            row.type === "Building Inspection"
          ) {
            NetPayableTOPartner = data.fourB;
            return data.fourB;
          }
          if (
           
            row.state === data.partnerState &&
            row.bedRoomSize === "PlusFour" &&
            row.type === "Building Inspection"
          ) {
            NetPayableTOPartner = data.plusFourB;
            return data.plusFourB;
          }
          if (
           
            row.state === data.partnerState &&
            row.bedRoomSize === "One" &&
            row.type === "Building and Pest Inspection"
          ) {
            NetPayableTOPartner = data.oneC;
            return data.oneC;
          }
          if (
         
            row.state === data.partnerState &&
            row.bedRoomSize === "Two" &&
            row.type === "Building and Pest Inspection"
          ) {
            NetPayableTOPartner = data.twoC;
            return data.twoC;
          }
          if (
           
            row.state === data.partnerState &&
            row.bedRoomSize === "Three" &&
            row.type === "Building and Pest Inspection"
          ) {
            NetPayableTOPartner = data.threeC;
            return data.threeC;
          }
          if (
           
            row.state === data.partnerState &&
            row.bedRoomSize === "Four" &&
            row.type === "Building and Pest Inspection"
          ) {
            NetPayableTOPartner = data.fourC;
            return data.fourC;
          }
          if (
           
            row.state === data.partnerState &&
            row.bedRoomSize === "PlusFour" &&
            row.type === "Building and Pest Inspection"
          ) {
            NetPayableTOPartner = data.plusFourC;
            return data.plusFourC;
          }
        })}</td>
				<td>{parseInt(row.paymentAmount) - parseInt(NetPayableTOPartner)}</td>
			</tr>
      
       {/* <td>Total</td>
       <td> Revenue: ${" "}{stateBuildingRevenueDetails}
           </td>
       <td> Payable: ${" "}{stateBuildingPayableDetails}
          </td>
       <td>Net Income: ${" "}{stateBuildingNetIncomeDetails}
         
           </td> */}
  
 </>
       )
})
      }
			
		</table>
    </>
  );
};
