import React from "react";
import { useLocation } from "react-router-dom";
import Footer from "../../component/footer/Footer";
import HomeBlogs from "../../component/home-component/homeBlogs/HomeBlogs";
import "./blogAfter.css";

const BlogAfter = () => {
  const location = useLocation();
  console.log(location);
  const { img, title, description } = location.state;
 
  
  return (
    <div style={{ backgroundColor: "white" }}>
      <div className="blogPageAfterImage ">
        <span>Blog</span>
      </div>
      <div className="main-blog-after ">
        <div className="blog-after">
          <div className="blog-after-title">{title}</div>
          <div className="blog-after-imge">
            <img src={img} className="img-fluid rounded-2" alt="" />
          </div>

          <div
            className="blog-after-description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
      <HomeBlogs/>
     <Footer/>
    </div>
  );
};

export default BlogAfter;
