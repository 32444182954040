import React, { useContext, useState, useEffect } from "react";
import { Button, Modal, ModalBody, Table } from "react-bootstrap";
import AdminContext from "../../../adminContext/AdminContext";
import axios from "axios";
// import newImage from "../../../assets/images/ZZ5H.gif";
import newImage from "../../../../assets/images/ZZ5H.gif";
const EditBlog = (props) => {
  // console.log(title);

  // console.log(description);
  const adminContext = useContext(AdminContext);
  const { handleModal, setHandleModal } = adminContext;
  let initialValue = {
    title: props.title,
    description: props.description,
    _id: props._id,
  };

  const [blogEditData, setBlogEditData] = useState(initialValue);

  const [file, setFile] = useState();
  const onChange = (e) => {
    setBlogEditData((perState) => {
      // console.log(e.target.name);
      return { ...perState, [e.target.name]: e.target.value };
    });
  };
  const dataFile = new FormData();
  if (blogEditData.title) {
    dataFile.append("title", blogEditData.title);
  }
  if (blogEditData.description) {
    dataFile.append("description", blogEditData.description);
  }
  if (blogEditData._id) {
    dataFile.append("_id", blogEditData._id);
  }
  if (file) {
    dataFile.append("blogImage", file);
  }

  // useEffect(() => {
  //   setBlogEditData(initialValue);
  // }, []);
  const onSubmit = (e) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/blog/update-blog`;
    const data = dataFile;
    axios
      .put(url, data, {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf",
          "admin-token": localStorage.getItem("admin-token"),
        },
      })
      .then((data) => {})
      .catch((error) => {});
    setHandleModal(false);
    props.setChangeState(!props.changeState);
  };
  return (
    <>
      {(props.title && props.title.length === 0) ||
      (props.description && props.description.length === 0) ? (
        <div
          style={{
            // height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <img style={{ maxWidth: "7%" }} src={newImage} />
        </div>
      ) : (
        <div>
          <form>
            <Modal
              size="xl"
              show={handleModal}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header>
                <Modal.Title>Edit Blog Page</Modal.Title>
                <Button
                  onClick={() => setHandleModal(false)}
                  className="btn-close"
                  variant=""
                >
                  x
                </Button>
              </Modal.Header>
              <Modal.Body>
                <div className="card-blog-modal-body">
                  <form onSubmit={onSubmit}>
                    <div className="form-group green-border-focus">
                      <label htmlFor="exampleFormControlTextarea5">Title</label>
                      <input
                        className="form-control"
                        name="title"
                        value={blogEditData.title}
                        onChange={onChange}
                      ></input>
                    </div>
                    <div className="form-group green-border-focus">
                      <label htmlFor="exampleFormControlTextarea5">
                        Description
                      </label>
                      <textarea
                        className="form-control"
                        name="description"
                        value={blogEditData.description}
                        onChange={onChange}
                        id="exampleFormControlTextarea5"
                        rows="3"
                      ></textarea>
                    </div>
                    <div>
                      <input
                        className="form-control my-2"
                        type="file"
                        placeholder="Attach Image"
                        onChange={(e) => setFile(e.target.files[0])}
                        name="cancelAttachedFile"
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-success btn-icon text-white px-5"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </Modal.Body>
            </Modal>
          </form>
        </div>
      )}
    </>
  );
};

export default EditBlog;
