import React, { useState, useEffect } from "react";
import axios from "axios";

function Testimonial() {
  const [file, setFile] = useState(null);
  const [name, setName] = useState("");
  const [star, setStar] = useState('');
  const [description, setDescription] = useState("");
  const [testimonialData, settestimonialData] = useState("");

  const dummy = async () => {
      console.log("testimonialData", testimonialData);
      const url = `${process.env.REACT_APP_BACKEND_DATA}/get-all-testimonials`;
    try {
      const response = await axios.get(url, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      });

      settestimonialData(response.data.data);

      // console.log
    } catch (error) {}
};
useEffect(() => {
  dummy();
}, []);
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleStarChange = (e) => {
    setStar(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", name);
    formData.append("stars", star);
    formData.append("description", description);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DATA}/upload-testimonials`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
      window.location.reload()
    } catch (error) {
      console.log(error);
    }
  };

  const handledelete1 = async (id) => {
  
    console.log(id);
    try{
    
       axios.delete(`${process.env.REACT_APP_BACKEND_DATA}/delete-testimonials/${id}`)
      .then((res)=> {
        console.log(res);
      })
      dummy();
    }catch (e) {
    
      console.log('',e)
    }
      };
  return (
    <>
      <form className="card" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-6">
          <label htmlFor="file">File:</label>
          <input
            type="file"
            id="file"
            className="form-control"
            onChange={handleFileChange}
          />
          </div>
          <div className="col-6">
          <label htmlFor="file">Stars:</label>
          <select
            
            
            className="form-control"
            onChange={handleStarChange}
          >
          <option value={"1"}>Select Stars</option>
          <option value={"1"}>1 Star</option>
          <option value={"2"}>2 Stars</option>
          <option value={"3"}>3 Stars</option>
          <option value={"4"}>4 Stars</option>
          <option value={"5"}>5 Stars</option>
          </select>
          </div>
        </div>
        <div>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            className="form-control"
            onChange={handleNameChange}
          />
        </div>
        <div>
          <label htmlFor="description">Description:</label>
          <textarea
            id="description"
            value={description}
            className="form-control"
            onChange={handleDescriptionChange}
          />
        </div>
        <div className="m-3 text-center">
          <button className="btn btn-success" type="submit">
            Submit
          </button>
        </div>
      </form>
      <div className="row">
        {testimonialData
          ? testimonialData.map((elm) => {
              return (
                <>
                  <div className="col-12 col-md-4">
                    <div class="card">
                        <div className="text-end">
                            <button className="text-end btn btn-sm btn-danger" onClick={()=>handledelete1(elm._id)}>Delete</button>
                        </div>
                      <img
                        src={
                          process.env.REACT_APP_BACKEND_DATA + "/" + elm.file
                        }
                        class="card-img-top rounded-circle mx-auto d-block"
                        alt="..."
                      />
                      <div class="card-body text-center">
                        <h5 class="card-title">{elm.name}</h5>
                        {/* <p class="card-text">{elm.description}</p> */}
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          : ""}
      </div>
    </>
  );
}

export default Testimonial;
