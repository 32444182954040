import UserApiContext from './UserApiContext';
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserApiState = (props) => {
  const [toggle, setToggle] = useState(true);

  const navigate = useNavigate();
  // get the Loged in user information

  const loggedInUserData = async (token) => {
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/auth/getuser`;
    try {
      const { data } = await axios.get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'auth-token': token,
        },
      });
      return data;
    } catch (err) {
      toast('An unexpected error occurred.');
    }
  };

  // Register new user in the database
  const registerNewUser = (credentials) => {
    let { name, phone, email, password } = credentials;
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/auth/createuser`;
    const data = { name, phone, email, password };

    axios
      .post(url, data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        // Save the aut token and redirect
        localStorage.setItem('token', data.authtoken);
        navigate('/dashboard');
        toast.success('Register successfull', {
          position: 'top-center',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        const url = `${process.env.REACT_APP_BACKEND_DATA}/api/password/register-mail`;
        let { name, phone, email, password } = credentials;
        const newData = { name, email };
        axios
          .post(url, newData)
          .then(({ data }) => {})
          .catch((err) => {});
      })
      .catch((err) => {
        //if email already exists
        if (err.response.data.email) {
          toast.warning(err.response.data.email, {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // alert(err.response.data.email);
        }
        // if data is incorrect
        else {
          const formError = err.response.data.message;

          formError.map((elem, ind) => {
            toast.info(elem.msg, {
              position: 'top-center',
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            //  alert(elem.msg);
          });
        }
      });
  };

  //Login the user

  const logInuser = async (credentials) => {
    const { email, password } = credentials;
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/auth/login`;
    const data = { email, password };
    axios
      .post(url, data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        // Save the aut token and redirect
        localStorage.setItem('token', data.authtoken);
        navigate('/dashboard');
        toast.success('Login successfull', {
          position: 'top-center',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        // if data is incorrect

        toast.error('Please fill correct credentials', {
          position: 'top-center',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  // Sendind data to the server to update the user information
  const updateUserInfo = (updatedData, token) => {
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/auth/update`;
    const data = updatedData;
    axios
      .post(url, data, {
        headers: {
          Accept: 'application/json',
          // "Content-Type": "application/json",
          'auth-token': token,
        },
      })
      .then(({ data }) => {})
      .catch((err) => {
        toast(err);
      });
  };
  // Creating new application
  const [newApplication, setNewApplication] = useState([]);

  const creatingApplication = async () => {
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/application/create-application`;
    axios
      .get(url, {
        headers: {
          Accept: 'application/json',
          'auth-token': localStorage.getItem('token'),
        },
      })
      .then(({ data }) => {
        setNewApplication({ ...data });
        // viewApplication();
      })
      .catch((error) => {
        toast(error);
      });
  };
  // Sending user information like address and other information

  const userApplicationData = (userData, id) => {
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/application/application-data/${newApplication._id}`;
    const data = userData;
    // console.log(id);

    axios
      .post(url, data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'auth-token': localStorage.getItem('token'),
        },
      })
      .then(({ data }) => {
        // creatingApplication();
        // viewApplication();
        // console.log(data._id);
        // navigate('/application-dashboard', {
        //   state: { id: data._id },
        // })
      })
      .catch((error) => {});
  };
  // Sending application dashboard data to the server
  const sendDashboardData = (userData, id) => {
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/application/application-task-names/${id}`;
    const data = userData;
    axios
      .post(url, data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'auth-token': localStorage.getItem('token'),
        },
      })
      .then(({ data }) => {})
      .catch((error) => {});
  };
  // Get all the view application data
  const viewApplication = () => {
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/application/view-application`;

    return axios
      .get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'auth-token': localStorage.getItem('token'),
        },
      })
      .then(({ data }) => {
        navigate('/dashboard');
        return data;
      })
      .catch((err) => {});
  };

  // Calling fee Details api
  const feeDetails = () => {
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/fee-details/get-fee-details`;
    return axios
      .get(url, {
        headers: {
          Accept: 'application/json',
          'auth-token': localStorage.getItem('token'),
        },
      })
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {});
  };

  const callingTaskMailer = ({
    customerName,
    requestType,
    taskid,
    customerId,
    Ammount,
    custId,
    customerEmailId,
    customerPhoneNumber,
    purchaseType,
    propertyType,
    propertyAddress,
    propertyState,
    instructionFromCustomer,
  }) => {
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/task/mail/task-mail`;
    const data = {
      customerName,
      requestType,
      taskid,
      custId,
      customerId,
      Ammount,
      customerEmailId,
      customerPhoneNumber,
      purchaseType,
      propertyType,
      propertyAddress,
      propertyState,
      instructionFromCustomer,
    };
    console.log(data);
    return axios
      .post(url, data, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {});
  };

  const [userModalHandler, setUserModalHandler] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);

  return (
    <UserApiContext.Provider
      value={{
        loggedInUserData,
        registerNewUser,
        logInuser,
        userApplicationData,
        newApplication,
        sendDashboardData,
        creatingApplication,
        viewApplication,
        updateUserInfo,
        setToggle,
        toggle,
        feeDetails,
        userModalHandler,
        setUserModalHandler,
        cancelModal,
        setCancelModal,
        callingTaskMailer,
      }}
    >
      <ToastContainer />
      {props.children}
    </UserApiContext.Provider>
  );
};

export default UserApiState;
