import React, { useContext, useState, useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import BuildingPestSummary from "./BuildingPestSummary";
import OnlyAddress from "./OnlyAddress";
import PaymentPropertyAddress from "./PaymentPropertyAddress";
import UserApiContext from "../UserApi/UserApiContext";
import axios from "axios";
import SolicitorSummary from "./SolicitorSummary";

const LegalPayment = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, []);

  const UserApi = useContext(UserApiContext);
  const { sendDashboardData, loggedInUserData } = UserApi;
  const [tokendata, settokendata] = useState([]);

  const location = useLocation();
  const id = location.state.id;

  const type = location.state.type;
  const applicationType = location.state.applicationType;
  const todayDate = new Date();
  let newDate =
    todayDate.getDate() +
    "/" +
    (todayDate.getMonth() + 1) +
    "/" +
    todayDate.getFullYear();

  let initialValue = {
    order_id: "",
    taskId: "",
    taskName: "",
    amount: "",
    paymentDate: "",
    receipt: "",
  };
  const [userInfo, setUserInfo] = useState([]);

  const Authtoken = localStorage.getItem("token");
  const token = async () => {
    const newData = await loggedInUserData(Authtoken);

    setUserInfo(newData);
  };
  useEffect((e) => {
    token();
  }, []);

  const [paymentDetails, setPaymentDetails] = useState(initialValue);
  // TO send data to the server for solicitor And Conveyancer
console.log(applicationType);
  const solicitorAndConveyancerSubmit = (item) => {
    const newData = {
      payment: item,
      message: applicationType.message,
      name: userInfo.name,
      email: userInfo.email,
      phone: userInfo.phone,
      progress: "In Progress",
      state: applicationType.selectState,
      type: applicationType.type,
      createdDate: applicationType.createdDate,
      taskId: applicationType.taskId,
    };

    sendDashboardData(newData, id);
    navigate("/application-dashboard", {
      state: { id: id },
    });
  };
  // TO send data to the server for bilding and pest
  const buildingAndPestSubmit = (item) => {
    const newData = {
      payment: item,
      message: applicationType.message,
      name: userInfo.name,
      email: userInfo.email,
      phone: userInfo.phone,
      progress: "In Progress",
      type: applicationType.type,
      state: applicationType.selectState,
      createdDate: applicationType.createdDate,
      bedRoomSize: applicationType.bedRoomSize,
      taskId: applicationType.taskId,
    };
    sendDashboardData(newData, id);
    navigate("/application-dashboard", {
      state: { id: id },
    });
  };
  // TO send data to the server for deprication Schedule

  const depreciationSchedule = (item) => {
    const newData = {
      payment: item,
      message: applicationType.message,
      name: userInfo.name,
      email: userInfo.email,
      phone: userInfo.phone,
      progress: "In Progress",
      type: applicationType.type,
      state: applicationType.selectState,

      createdDate: applicationType.createdDate,
      taskId: applicationType.taskId,
    };
    sendDashboardData(newData, id);
    navigate("/application-dashboard", {
      state: { id: id },
    });
  };
  // Setting Route for the razor pay payment

  //creating amount vriable to save the different kind of amount
  let amount = "";
  if (applicationType.applicationType === "solicitorAndConveyancer") {
    amount = applicationType.upfrontFee;
  } else if (applicationType.applicationType === "buildingAndPest") {
    amount = applicationType.price;
  } else if (applicationType.applicationType === "deprecationScheduler") {
    amount = applicationType.price;
  }
  //---end

  const initPayment = (data) => {
    const options = {
      key: "",
      amount: amount,
      currency: data.currency,
      name: applicationType.type,
      descripton: "Test Transaction",
      order_id: data.id,
      handler: async (response) => {
        try {
          const verifyUrl = `${process.env.REACT_APP_BACKEND_DATA}/api/payment/verify`;
          const { data } = await axios.post(verifyUrl, response);

          if (data.message === true) {
            submitPaymentHistory();
          }
          if (
            applicationType.type === "Solicitor" ||
            applicationType.type === "Conveyancer"
          ) {
            if (data.message === true) {
              solicitorAndConveyancerSubmit(data.message);
            }
          } else if (applicationType.applicationType === "buildingAndPest") {
            if (data.message === true) {
              buildingAndPestSubmit(data.message);
            }
          } else if (
            applicationType.applicationType === "deprecationScheduler"
          ) {
            if (data.message === true) {
              depreciationSchedule(data.message);
            }
          }
        } catch (error) {}
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };
  const handleClick = async () => {
    try {
      const orderUrl = `${process.env.REACT_APP_BACKEND_DATA}/api/payment/orders`;
      const { data } = await axios.post(orderUrl, { amount: amount });

      let newid = data.data.id;
      let newAmount = data.data.amount;
      initialValue.order_id = newid;
      initialValue.taskName = location.state.applicationType.type;
      initialValue.amount = newAmount / 100;
      initialValue.paymentDate = newDate;
      initialValue.taskId = applicationType.taskId;
      initialValue.receipt = "";

      initPayment(data.data);
    } catch (error) {}
  };
  // end setting route

  //getting loged in use details

  const findAddress = async () => {
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/application/view-single-application/${id}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      });

      settokendata(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    findAddress();
  }, []);

  // Creating payment history

  function submitPaymentHistory() {
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/payment-history/create-payment`;
    axios
      .post(url, initialValue, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then(({ data }) => {})
      .catch((error) => {});
  }
  // End of payment history

  // sending payment details to the server

  return (
    <>
      <Card style={{ height: "100%" }}>
        <div className="d-flex">
          <div className="col-md-6">
            <Card.Header>
              <h3>Choose your Payment method</h3>
            </Card.Header>
            <Card.Body>
              <div className="border">
                <div
                  style={{
                    height: "20rem",
                    width: "100%",
                    border: "1px solid black",
                    marginBottom: "1rem",
                  }}
                ></div>
                <input type="checkbox" className="display-inline mx-2" />
                <span>By making payment I agree to Just Nest</span>
                <Button
                  onClick={handleClick}
                  className="block col-md-5 "
                  style={{ margin: "10px auto" }}
                >
                  Make payment
                </Button>
              </div>
              <Button
                className="mt-2 block col-md-5 mt-6 "
                style={{ margin: "0 auto" }}
                onClick={() =>
                  navigate("/application-dashboard", {
                    state: {
                      id: id,
                    },
                  })
                }
              >
                Back
              </Button>
            </Card.Body>
          </div>
          <div className="col-md-6">
            {applicationType.applicationType === "buildingAndPest" ? (
              <BuildingPestSummary
                name="Combined Building Pest Report"
                price={applicationType.price}
              />
            ) : applicationType.applicationType === "deprecationScheduler" ? (
              <BuildingPestSummary
                name=" Depreciation"
                price={applicationType.price}
              />
            ) : applicationType.applicationType ===
              "solicitorAndConveyancer" ? (
              <SolicitorSummary
                fullFees={applicationType.fullFee}
                UpFrontFees={applicationType.upfrontFee}
              />
            ) : (
              ""
            )}
            {type === "paymentPropertyAddress" ? (
              <div>
                <PaymentPropertyAddress
                  propertyAddress={tokendata.address}
                  sellerAgent={tokendata.agentName}
                  SellerAgentPhone={tokendata.agentPhone}
                  SellerAgentEmail={tokendata.agentEmail}
                />
              </div>
            ) : (
              ""
            )}
            {type === "deprecationAddress" ? (
              <div>
                <OnlyAddress propertyAddress={tokendata.address} />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Card>
    </>
  );
};
export default LegalPayment;
