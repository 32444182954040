import React, { useState, useLayoutEffect, useContext } from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import 'react-data-table-component-extensions/dist/index.css';
import CustomerModal from '../CustomerModal/CustomerModal';
import AdminContext from '../../../adminContext/AdminContext';

export const DataTables = ({ filterdata }) => {
  const [data, setData] = React.useState([]);
  // console.log(filterdata);
  const adminContext = useContext(AdminContext);
  const { handleModal, setHandleModal } = adminContext;
  useLayoutEffect(() => {
    setData(filterdata);
  }, [filterdata]);
  const navigate = useNavigate();
  const [customerModalData, setCustomerModalData] = useState({
    name: '',
    phone: '',
    email: '',
    id: '',
  });
  const showUserEditModal = (name, phone, email, id) => {
    setHandleModal(true);
    setCustomerModalData({
      name,
      phone,
      email,
      id,
    });
  };
  const columns = [
    {
      name: 'Name',
      selector: (row) => [row.name],
      sortable: true,
    },
    {
      name: 'Phone',
      selector: (row) => [row.phone],
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => [row.email],
      sortable: true,
    },
    {
      name: 'Customer Id',
      selector: (row) => [row.user_id],
      sortable: true,
    },
    {
      name: '',
      selector: (row) => [
        <span
          onClick={() =>
            showUserEditModal(row.name, row.phone, row.email, row._id)
          }
        >
          <i
            className="zmdi zmdi-edit"
            style={{
              fontSize: '20px',
              cursor: 'pointer',

              marginLeft: '1rem',
            }}
          />
        </span>,
      ],
      sortable: true,
    },
  ];
  const tableDatas = {
    columns,
    data,
  };
  return (
    <>
      <CustomerModal customerModalData={customerModalData} />
      <DataTableExtensions {...tableDatas}>
        <DataTable
          columns={columns}
          data={data}
          pagination
          highlightOnHover
          responsive
          dense
        />
      </DataTableExtensions>
    </>
  );
};
