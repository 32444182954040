import React, { useState, useRef, useContext, useEffect } from "react";
import { Card } from "react-bootstrap";
import axios from "axios";
import edit from "./edit.png";
import { toast } from "react-toastify";
// import ReactDOM from 'react-dom';
// import $ from 'jquery';
import { Link, useLocation, useNavigate } from "react-router-dom";
import UserApiContext from "../UserApi/UserApiContext";
import UserModal from "../UserModal/UserModal";
import CancelModal from "../UserModal/CancelModal";
import newImage from "../../assets/images/ZZ5H.gif";
import "./applicationDashboard.css";
import Updateaddress from "./Updateaddress";
import { v4 as uuidv4 } from "uuid";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ApplicationDashboard = () => {
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [requiredDropdown, setRequiredDropdown] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show1, setShow1] = useState(true);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, []);

  const userApiContext = useContext(UserApiContext);
  const { feeDetails, setUserModalHandler, setCancelModal } = userApiContext;

  const location = useLocation();
  let id = "";

  if (location.state) {
    id = location.state.id;
  }
  const [gettingData, setGettingData] = useState([]);
  const [allData, setAllData] = useState([]);

  const [address, setAddress] = useState({
    address: "",
  });

  // allData &&
  // allData.map((item) => {
  //   console.log(item);
  // });
  //.................................agent details...............................................
  const [ref2, setRef] = useState("");
  const [AgentName, setAgentName] = useState(allData.agentName);
  const [AgentEmail, setAgentEmail] = useState(allData.agentEmail);
  const [AgencyName, setAgencyName] = useState(allData.agencyName);
  const [AgentNumber, setAgentNumber] = useState(allData.agentNumber);
  const AgentDetails = async () => {
    let objid = allData._id;

    // if (!AgentNumber == undefined && AgentNumber.length != (10))  {
    //   console.log(">>>>>>>>>>>>>>>><<<<<<<<<<<<<<<", objid ,AgencyName,AgentName,AgentEmail,AgentNumber);
    //   toast.error('Enter 10 digit number', {
    //     position: 'top-center',
    //     autoClose: 1000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });

    if (
      (AgentNumber && AgentNumber.length >= 1 && AgentNumber.length < 10) ||
      (AgentNumber && AgentNumber.length > 10)
    ) {
      toast.error("Enter 10 digits number", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      if (AgentNumber && AgentNumber.charAt(0) == "0") {
        let result = await fetch(
          `${process.env.REACT_APP_BACKEND_DATA}/agent-details`,
          {
            method: "post",

            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              objid,
              AgencyName,
              AgentName,
              AgentEmail,
              AgentNumber,
            }),
          }
        );
        result = await result.json();
        toast.success("Updated successfully", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        handleClose();
        window.location.reload();
      } else {
        a;
        toast.error("Enter 10 digits number starting with 0", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  //----------------------------------------------------------------------------------

  const collectData = async () => {
    let objid = allData._id;
    console.log("obj id", objid);
    let result = await fetch(
      `${process.env.REACT_APP_BACKEND_DATA}/customer-updateaddress`,
      {
        method: "post",

        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ address, objid }),
      }
    );
    result = await result.json();
    // console.log(result);
  };

  //---------------------------------------------------------------------------------

  const [changeLoader, setChangingLoder] = useState(false);
  console.log("123456789876543223456789", id);
  const dummy = async () => {
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/application/view-single-application/${id}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      });
      const initialValue = [];
      response.data.TASKNAME.map((newData) => {
        initialValue.push(newData);
      });
      setGettingData(initialValue);
      setChangingLoder(true);
      setAllData(response.data);

      // console.log
    } catch (error) {}
  };

  let pestInspectionData;

  allData.TASKNAME &&
    allData.TASKNAME.map((item) => {
      if (item.type === "Building and Pest Inspection") {
        pestInspectionData = "Building and Pest Inspection";
      }
      if (item.type === "Building Inspection") {
        pestInspectionData = "Building Inspection";
      }
      if (item.type === "Pest Inspection") {
        pestInspectionData = "Pest Inspection";
      }
    });
  // const [tasknew , settasknew] = useState([])
  // allData.TASKNAME &&

  //   allData.TASKNAME.map((item) => {
  //     settasknew(item.task_id)

  //   });

  // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>',tasknew);
  const [allfeeDetails, setAllFeeDetails] = useState([]);
  const getFeeDetails = async () => {
    setAllFeeDetails(await feeDetails());
  };
  const newObject = {
    mortgageBroker: "Not Started",
    solicitorAndconveyancer: "Not Started",
    buildingInspection: "Not Started",
    propertyManager: "Not Started",
    depreciationSchedule: "Not Started",
    accountant: "Not Started",
    buyersAdvocate: "Not Started",
  };
  gettingData.filter((data) => {
    if (data.type == "Mortgage Broker") {
      newObject.mortgageBroker = data.progress;
    } else if (data.type == "Solicitor") {
      newObject.solicitorAndconveyancer = data.progress;
    } else if (data.type == "Buyers Advocate") {
      newObject.buyersAdvocate = data.progress;
    } else if (data.type == "Conveyancer") {
      newObject.solicitorAndconveyancer = data.progress;
    } else if (
      data.type == "Building Inspection" ||
      data.type == "Pest Inspection" ||
      data.type == "Building and Pest Inspection"
    ) {
      newObject.buildingInspection = data.progress;
    } else if (data.type == "Property Manager") {
      newObject.propertyManager = data.progress;
    } else if (data.type == "Depreciation Schedule") {
      newObject.depreciationSchedule = data.progress;
    } else if (data.type == "Accountant") {
      newObject.accountant = data.progress;
    }
  });

  // const [notreq , setNotreq] = useState({

  //   progress: 'Not Required'

  // })
  function dateFormat(inputDate, format) {
    //parse the input date
    const date = new Date(inputDate);

    //extract the parts of the date
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    //replace the month
    format = format.replace("MM", month.toString().padStart(2, "0"));

    //replace the year
    if (format.indexOf("yyyy") > -1) {
      format = format.replace("yyyy", year.toString());
    } else if (format.indexOf("yy") > -1) {
      format = format.replace("yy", year.toString().substr(2, 2));
    }

    //replace the day
    format = format.replace("dd", day.toString().padStart(2, "0"));

    return format;
  }
  //................................Not Req Mortgage....................................
  let newDate = new Date().toLocaleDateString();
  let todayDate = Date.parse(dateFormat(newDate, "yyyy-MM-dd"));
  const notreq = {
    // message: "",
    progress: "Not Required",
    type: "Mortgage Broker",
    // state: location.state.state,
    createdDate: Date.now(),
    // taskId: uuidv4(),
  };
  const sendDashboardData1 = () => {
    console.log(id, notreq);
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/application/application-task-names/${id}`;

    axios
      .post(url, notreq, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then(({ data }) => {})
      .catch((error) => {});
  };
  //............................................................................
  //................................Not Req Mortgage....................................
  let newDate9 = new Date().toLocaleDateString();
  let todayDate9 = Date.parse(dateFormat(newDate, "yyyy-MM-dd"));
  const notreq9 = {
    // message: "",
    progress: "Not Required",
    type: "Buyers Advocate",
    // state: location.state.state,
    createdDate: Date.now(),
    // taskId: uuidv4(),
  };
  const sendDashboardData9 = () => {
    console.log(id, notreq9);
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/application/application-task-names/${id}`;

    axios
      .post(url, notreq9, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then(({ data }) => {})
      .catch((error) => {});
  };
  //............................................................................
  //................................Not Req Solicitor....................................

  const notreq2 = {
    // message: "",
    progress: "Not Required",
    type: "Solicitor",
    // state: location.state.state,
    createdDate: Date.now(),
    // taskId: uuidv4(),
  };
  const sendDashboardData2 = () => {
    console.log(id, notreq);
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/application/application-task-names/${id}`;

    axios
      .post(url, notreq2, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then(({ data }) => {})
      .catch((error) => {});
  };
  //............................................................................
  //................................Not Req Pest Inspection....................................
  const notreq3 = {
    // message: "",
    progress: "Not Required",
    type: "Pest Inspection",
    // state: location.state.state,
    createdDate: Date.now(),
    // taskId: uuidv4(),
  };
  const sendDashboardData3 = () => {
    console.log(id, notreq3);
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/application/application-task-names/${id}`;

    axios
      .post(url, notreq3, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then(({ data }) => {})
      .catch((error) => {});
  };
  //............................................................................
  //................................Not Req Property Manager....................................
  const notreq4 = {
    // message: "",
    progress: "Not Required",
    type: "Property Manager",
    // state: location.state.state,
    createdDate: Date.now(),
    // taskId: uuidv4(),
  };
  const sendDashboardData4 = () => {
    console.log(id, notreq4);
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/application/application-task-names/${id}`;

    axios
      .post(url, notreq4, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then(({ data }) => {})
      .catch((error) => {});
  };
  //............................................................................
  //................................Not Req Depreciation Schedule....................................
  const notreq5 = {
    // message: "",
    progress: "Not Required",
    type: "Depreciation Schedule",
    // state: location.state.state,
    createdDate: Date.now(),
    // taskId: uuidv4(),
  };
  const sendDashboardData5 = () => {
    console.log(id, notreq);
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/application/application-task-names/${id}`;

    axios
      .post(url, notreq5, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then(({ data }) => {})
      .catch((error) => {});
  };
  //............................................................................
  //................................Not Req acountant....................................
  const notreq6 = {
    // message: "",
    progress: "Not Required",
    type: "Accountant",
    // state: location.state.state,
    createdDate: Date.now(),
    // taskId: uuidv4(),
  };
  const sendDashboardData6 = () => {
    console.log(id, notreq6);
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/application/application-task-names/${id}`;

    axios
      .post(url, notreq6, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then(({ data }) => {})
      .catch((error) => {});
  };
  //............................................................................

  //..........................................................................................
  //  Finding state and creating new array inside that array we are pushing the object acoording to the state

  const statusChange = async (ty) => {
    let objid = id;
    let type = ty;
    // console.log('from send api',details);
    console.log("obj id", objid);

    let result = await fetch(`${process.env.REACT_APP_BACKEND_DATA}/notreq`, {
      method: "post",

      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ notreq, objid, type }),
    });
    result = await result.json();
    // console.log(result);
  };
  const deleteStatus = async (ty) => {
    let objid = id;
    let type = ty;
    // console.log('from send api',details);
    console.log("obj id", objid);

    let result = await fetch(
      `${process.env.REACT_APP_BACKEND_DATA}/deleteStatus`,
      {
        method: "post",

        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ notreq, objid, type }),
      }
    );
    result = await result.json();
    window.location.reload();
    // console.log(result);
  };
  //..........................................................................................

  let findState = "";
  if (allData.propertyState == "N/A") {
    findState = allData.lookingToBuy;
  }
  if (allData.lookingToBuy == "N/A") {
    findState = allData.propertyState;
  }

  let newData = [];
  const newArrayObj = () => {
    {
      allfeeDetails &&
        allfeeDetails.map((data) => {
          data.feeDetails.map((item) => {
            if (item.partnerState == findState) {
              return newData.push(item);
            }
          });
        });
    }
  };

  newArrayObj();
  useEffect(() => {
    dummy();
    console.log("i am call");
    getFeeDetails();
  }, []);
  const [category, setCategory] = useState("");

  const changeCategory = (data, progress) => {
    setCategory(data);

    if (progress == "Cancelled" || progress == "Not Required") {
      setCancelModal(true);
    } else {
      setUserModalHandler(true);
    }
  };

  const handleUpdate = () => {
    collectData();
    toast.success("Updated successfully", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  // console.log(collectData);
  // console.log(allData);

  const onCh = (e) => {
    setAddress(e.target.value);
    console.log(e.target.value);
  };
  console.log(allData);
  const agentEmail = allData.agentEmail;
  // console.log(requiredDropdown);
  console.log(123456789, AgentName, AgentEmail, AgentNumber);
  return (
    <>
      {changeLoader ? (
        <div>
          <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>Agent Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Agent name
              <input
                className="form-control"
                defaultValue={
                  allData.agentName == "N/A" ? "" : allData.agentName
                }
                name="AgentName"
                onChange={(e) => {
                  setAgentName(e.target.value);
                }}
                type="text"
              />
              Agent email
              <input
                className="form-control"
                defaultValue={
                  allData.agentEmail == "N/A" ? "" : allData.agentEmail
                }
                name="AgentEmail"
                onChange={(e) => {
                  setAgentEmail(e.target.value);
                }}
                type="text"
              />
              Agent number
              <input
                className="form-control"
                defaultValue={
                  allData.agentPhone == "N/A" ? "" : allData.agentPhone
                }
                onChange={(e) => {
                  setAgentNumber(e.target.value);
                }}
                type="number"
                name="AgentNumber"
              />
              Agency name
              <input
                className="form-control"
                defaultValue={
                  allData.agencyName == "N/A" ? "" : allData.agencyName
                }
                onChange={(e) => {
                  setAgencyName(e.target.value);
                }}
                type="text"
                name="agencyName"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  AgentDetails();
                }}
              >
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
          <h1
            className=" d-block text-center"
            style={{
              paddingTop: "2rem",
              paddingBottom: "2rem",
              color: "rgb(181 237 253)",
              fontWeight: "500",
              fontSize: "2.3rem",
              textDecoration: "underline",
            }}
          >
            Application Dashboard
          </h1>

          <div className="application-dashboard-container  mx-auto my-4">
            <Button variant="success" onClick={handleShow}>
              Agent Details <img src={edit} alt="" style={{ width: "30px" }} />
            </Button>
            <div
              className="all-card-style mb-4  mx"
              style={{ minHeight: "130px", fontSize: "18px" }}
            >
              <div className="d-flex application-dashboard-container-div">
                {/* {console.log('hello', gettingData)} */}

                {show2 ? (
                  <span style={{ width: "90%", textAlign: "center" }}>
                    <Updateaddress />

                    {/* {gettingData[0]['state']} */}
                  </span>
                ) : null}
                {show1 ? (
                  <span style={{ width: "90%", textAlign: "center" }}>
                    <h1 className="h11">
                      {" "}
                      {allData.address != "N/A"
                        ? allData.address
                        : allData.lookingToBuy}
                    </h1>
                    <button
                      className="pencil"
                      onClick={() => {
                        setShow2(true), setShow1(false);
                      }}
                    >
                      {" "}
                      <img className="pencil" src={edit} alt="" />{" "}
                    </button>
                  </span>
                ) : null}
              </div>
            </div>
          </div>

          <div className="application-dashboard-container  mx-auto my-4">
            <div className="all-card-style mb-4" style={{ fontSize: "18px" }}>
              <div
                className="d-flex application-dashboard-container-div"
                style={{
                  color:
                    newObject.mortgageBroker == "Completed" ? "white" : "black",
                }}
              >
                <span style={{ width: "70%" }}>Get Home Loan</span>

                <a
                  onClick={() => {
                    newObject.mortgageBroker == "Not Started"
                      ? navigate("/get-loan", {
                          state: {
                            id: id,
                            state: findState,
                          },
                        })
                      : "";
                    newObject.mortgageBroker == "Cancelled"
                      ? changeCategory(
                          "Mortgage Broker",
                          newObject.mortgageBroker
                        )
                      : "";
                    newObject.mortgageBroker == "Completed"
                      ? changeCategory(
                          "Mortgage Broker",
                          newObject.mortgageBroker
                        )
                      : "";
                    newObject.mortgageBroker == "In Progress"
                      ? changeCategory(
                          "Mortgage Broker",
                          newObject.mortgageBroker
                        )
                      : "";
                    newObject.mortgageBroker == "Pre Approved"
                      ? changeCategory(
                          "Mortgage Broker",
                          newObject.mortgageBroker
                        )
                      : "";
                    newObject.mortgageBroker == "Fully Approved"
                      ? changeCategory(
                          "Mortgage Broker",
                          newObject.mortgageBroker
                        )
                      : "";
                  }}
                  style={{
                    width: "30%",
                    height:"fit-content",

                    textAlign: "center",
                    backgroundColor:
                      newObject.mortgageBroker == "Not Started"
                        ? "#b5d7ef"
                        : newObject.mortgageBroker == "In Progress"
                        ? "#d5dd84"
                        : newObject.mortgageBroker == "Pre Approved"
                        ? "#61b0a4"
                        : newObject.mortgageBroker == "Fully Approved"
                        ? "#11ED20"
                        : newObject.mortgageBroker == "Not Required"
                        ? "#e68855"
                        : newObject.mortgageBroker == "Completed"
                        ? "#4ab46c"
                        : newObject.mortgageBroker == "To Be Started"
                        ? "#89888a"
                        : newObject.mortgageBroker == "Cancelled"
                        ? "#db4e0d"
                        : "",
                  }}
                >
                  {newObject.mortgageBroker}
                </a>
              </div>

              {newObject.mortgageBroker == "Not Started" ? (
                <button
                  style={{
                    color: "#FFF",
                    float: "right",
                    margin: "1px  ",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    window.location.reload();
                    sendDashboardData1();
                    statusChange("Mortgage Broker");
                  }}
                >
                  Mark as Not Required
                </button>
              ) : (
                ""
              )}
              {newObject.mortgageBroker == "Not Required" ? (
                <button
                  style={{
                    color: "#FFF",
                    float: "right",
                    margin: "1px  ",
                    fontSize: "12px",
                  }}
                  onClick={() => deleteStatus("Mortgage Broker")}
                >
                  Start again
                </button>
              ) : (
                ""
              )}
            </div>
            {/* //........................................ start.......................................// */}
           {/* <h5 className="text-light"> Buyer's Advocate</h5> */}
            <div className="all-card-style mb-4" style={{ fontSize: "18px" }}>
              <div
                className="d-flex application-dashboard-container-div"
                style={{
                  color:
                    newObject.buyersAdvocate == "Completed" ? "white" : "black",
                }}
              >
                <span style={{ width: "70%" }}>Free call with a property finder/negotiator</span>

                <a
                  onClick={() => {
                    newObject.buyersAdvocate == "Not Started"
                      ? navigate("/buyers-advocate", {
                          state: {
                            id: id,
                            state: findState,
                          },
                        })
                      : "";
                    newObject.buyersAdvocate == "Cancelled"
                      ? changeCategory(
                          "Buyers Advocate",
                          newObject.buyersAdvocate
                        )
                      : "";
                    newObject.buyersAdvocate == "Completed"
                      ? changeCategory(
                          "Buyers Advocate",
                          newObject.buyersAdvocate
                        )
                      : "";
                  }}
                  style={{
                    width: "30%",
                    height:"fit-content",

                    textAlign: "center",
                    backgroundColor:
                      newObject.buyersAdvocate == "Not Started"
                        ? "#b5d7ef"
                        : newObject.buyersAdvocate == "Not Required"
                        ? "#e68855"
                        : newObject.buyersAdvocate == "Completed"
                        ? "#4ab46c"
                        :""
                  }}
                >
                  {newObject.buyersAdvocate}
                </a>
              </div>

              {newObject.buyersAdvocate == "Not Started" ? (
                <button
                  style={{
                    color: "#FFF",
                    float: "right",
                    margin: "1px  ",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    window.location.reload();
                    sendDashboardData9();
                    statusChange("Buyers Advocate");
                  }}
                >
                  Mark as Not Required
                </button>
              ) : (
                ""
              )}
              {newObject.buyersAdvocate == "Not Required" ? (
                <button
                  style={{
                    color: "#FFF",
                    float: "right",
                    margin: "1px  ",
                    fontSize: "12px",
                  }}
                  onClick={() => deleteStatus("Buyers Advocate")}
                >
                  Start again
                </button>
              ) : (
                ""
              )}
            </div>

            {/* //.....................................end..........................................// */}


            <div className="all-card-style" style={{ fontSize: "18px" }}>
              <div
                className="d-flex application-dashboard-container-div"
                style={{
                  color:
                    newObject.solicitorAndconveyancer == "Completed"
                      ? "white"
                      : "black",
                }}
              >
                <span style={{ width: "70%" }}>
                  Engage Solicitor/ Conveyancer
                </span>
                <a
                  style={{
                    width: "30%",
                    height:"fit-content",
                    textAlign: "center",
                    backgroundColor:
                      newObject.solicitorAndconveyancer == "Not Started"
                        ? "#b5d7ef"
                        : newObject.solicitorAndconveyancer == "In Progress"
                        ? "#d5dd84"
                        : newObject.solicitorAndconveyancer == "Pre Approved"
                        ? "#61b0a4"
                        : newObject.solicitorAndconveyancer == "Completed"
                        ? "#4ab46c"
                        : newObject.solicitorAndconveyancer == "Cancelled"
                        ? "#db4e0d"
                        : newObject.solicitorAndconveyancer == "Fully Approved"
                        ? "#7b9c72"
                        : newObject.solicitorAndconveyancer == "Not Required"
                        ? "#e68855"
                        : newObject.solicitorAndconveyancer == "To Be Started"
                        ? "#89888a"
                        : "",
                  }}
                  onClick={() => {
                    newObject.solicitorAndconveyancer == "Not Started"
                      ? navigate("/solicitor-conveyancer", {
                          state: {
                            id: id,
                            solicitor: newData[0],
                            conveyancer: newData[1],
                            state: findState,
                          },
                        })
                      : "";
                    newObject.solicitorAndconveyancer == "Cancelled"
                      ? changeCategory(
                          "solicitorAndconveyancer",
                          newObject.solicitorAndconveyancer
                        )
                      : "";
                    newObject.solicitorAndconveyancer == "In Progress"
                      ? changeCategory(
                          "solicitorAndconveyancer",
                          newObject.solicitorAndconveyancer
                        )
                      : "";
                    newObject.solicitorAndconveyancer == "Completed"
                      ? changeCategory(
                          "solicitorAndconveyancer",
                          newObject.solicitorAndconveyancer
                        )
                      : "";
                  }}
                >
                  {newObject.solicitorAndconveyancer}
                </a>
              </div>
              {newObject.solicitorAndconveyancer == "Not Started" ? (
                <button
                  style={{
                    color: "#FFF",
                    float: "right",
                    margin: "1px  ",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    window.location.reload();
                    sendDashboardData2();
                    statusChange("Solicitor");
                  }}
                >
                  Mark as Not Required
                </button>
              ) : (
                ""
              )}
              {newObject.solicitorAndconveyancer == "Not Required" ? (
                <button
                  style={{
                    color: "#FFF",
                    float: "right",
                    margin: "1px  ",
                    fontSize: "12px",
                  }}
                  onClick={() => deleteStatus("Solicitor")}
                >
                  Start again
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="application-dashboard-container  mx-auto my-4">
            <div className="all-card-style  mb-4" style={{ fontSize: "18px" }}>
              <div
                className="d-flex application-dashboard-container-div"
                style={{
                  color:
                    newObject.buildingInspection == "Completed"
                      ? "white"
                      : "black",
                }}
              >
                <span style={{ width: "70%" }}>
                  Receive Building/Pest Inspection Report
                </span>
                <a
                  style={{
                    width: "30%",
                    height:"fit-content",

                    textAlign: "center",
                    backgroundColor:
                      newObject.buildingInspection == "Not Started"
                        ? "#b5d7ef"
                        : newObject.buildingInspection == "In Progress"
                        ? "#d5dd84"
                        : newObject.buildingInspection == "Completed"
                        ? "#4ab46c"
                        : newObject.buildingInspection == "Fully Approved"
                        ? "#7b9c72"
                        : newObject.buildingInspection == "Pre Approved"
                        ? "#61b0a4"
                        : newObject.buildingInspection == "Not Required"
                        ? "#e68855"
                        : newObject.buildingInspection == "To Be Started"
                        ? "#89888a"
                        : newObject.buildingInspection == "Cancelled"
                        ? "#db4e0d"
                        : "",
                  }}
                  onClick={() => {
                    newObject.buildingInspection == "Not Started"
                      ? navigate("/building-pest-inspection", {
                          state: {
                            id: id,
                            buildingPest: newData[2],
                            state: findState,
                          },
                        })
                      : changeCategory(
                          pestInspectionData,
                          newObject.buildingInspection
                        );
                    newObject.solicitorAndconveyancer == "Completed"
                      ? changeCategory(
                          pestInspectionData,
                          newObject.buildingInspection
                        )
                      : "";
                  }}
                >
                  {newObject.buildingInspection}
                </a>
              </div>
              {newObject.buildingInspection == "Not Started" ? (
                <button
                  style={{
                    color: "#FFF",
                    float: "right",
                    margin: "1px  ",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    window.location.reload();
                    sendDashboardData3();
                    statusChange("Building Inspection");
                  }}
                >
                  Mark as Not Required
                </button>
              ) : (
                ""
              )}
              {newObject.buildingInspection == "Not Required" ? (
                <button
                  style={{
                    color: "#FFF",
                    float: "right",
                    margin: "1px  ",
                    fontSize: "12px",
                  }}
                  onClick={() => deleteStatus("Pest Inspection")}
                >
                  Start again
                </button>
              ) : (
                ""
              )}
            </div>
            {/* <div className="application-dashboard-container  mx-auto my-4"> */}
            {allData.propertType == "Strata Property" ? (
              ""
            ) : (
              // <div
              //   className="all-card-style  mb-4"
              //   style={{ backgroundColor: '#8a8a8a', fontSize: '18px' }}
              // >
              //   <div className="d-flex application-dashboard-container-div">
              //     <Link
              //       to="/blog-single/%20Why%20do%20I%20need%20Landlord%20Insurance"
              //       target="_blank"
              //       className="text-center"
              //       style={{ margin: 'auto', color: 'white' }}
              //     >
              //       Ensure to have Landlord Insurance
              //     </Link>
              //   </div>
              // </div>
              <div
                className="all-card-style  mb-4"
                style={{ backgroundColor: "#8a8a8a", fontSize: "18px" }}
              >
                <div className="d-flex application-dashboard-container-div">
                  <a
                    target="_blank"
                    href="/just-blogs/why-do-i-need-building-or-home-insurance"
                    className="text-center"
                    style={{ margin: "auto", color: "white" }}
                  >
                    Ensure to have Building Insurance
                  </a>
                </div>
              </div>
            )}
            {allData.propertyPlan == "I'll live there" ? (
              ""
            ) : (
              <div className="all-card-style" style={{ fontSize: "18px" }}>
                <div
                  className="d-flex application-dashboard-container-div"
                  style={{
                    color:
                      newObject.propertyManager == "Completed"
                        ? "white"
                        : "black",
                  }}
                >
                  <span style={{ width: "70%" }}>Assign Property Manager</span>
                  <a
                    style={{
                      width: "30%",
                    height:"fit-content",

                      textAlign: "center",
                      backgroundColor:
                        newObject.propertyManager == "Not Started"
                          ? "#b5d7ef"
                          : newObject.propertyManager == "In Progress"
                          ? "#d5dd84"
                          : newObject.propertyManager == "Pre Approved"
                          ? "#61b0a4"
                          : newObject.propertyManager == "Completed"
                          ? "#4ab46c"
                          : newObject.propertyManager == "Fully Approved"
                          ? "#7b9c72"
                          : newObject.propertyManager == "Not Required"
                          ? "#e68855"
                          : newObject.propertyManager == "To Be Started"
                          ? "#89888a"
                          : newObject.propertyManager == "Cancelled"
                          ? "#db4e0d"
                          : "",
                    }}
                    onClick={() => {
                      newObject.propertyManager == "Not Started"
                        ? navigate("/property-manager", {
                            state: {
                              id: id,
                              state: findState,
                            },
                          })
                        : "";
                      newObject.propertyManager == 'Completed'?
                      changeCategory(
                        'Property Manager',
                        newObject.propertyManager
                        ) : ""
                      //
                    }}
                  >
                    {newObject.propertyManager}
                  </a>
                </div>
                {newObject.propertyManager == "Not Started" ? (
                  <button
                    style={{
                      color: "#FFF",
                      float: "right",
                      margin: "1px  ",
                      fontSize: "12px",
                    }}
                    onClick={() => {
                      window.location.reload();
                      sendDashboardData4();
                      statusChange("Property Manager");
                    }}
                  >
                    Mark as Not Required
                  </button>
                ) : (
                  ""
                )}
                {newObject.propertyManager == "Not Required" ? (
                  <button
                    style={{
                      color: "#FFF",
                      float: "right",
                      margin: "1px  ",
                      fontSize: "12px",
                    }}
                    onClick={() => deleteStatus("Property Manager")}
                  >
                    Start again
                  </button>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
          {/* //.................................Landlord......................... */}
          {allData.propertyPlan === "I'll live there" ? (
            ""
          ) : (
            <div className="application-dashboard-container  mx-auto application-dashboard-last-div">
              <div
                className="all-card-style  mb-4"
                style={{ backgroundColor: "#8a8a8a", fontSize: "18px" }}
              >
                <div className="d-flex application-dashboard-container-div">
                  <a
                    href="/just-blogs/why-do-i-need-landlord-insurance"
                    target="_blank"
                    className="text-center"
                    style={{ margin: "auto", color: "white" }}
                  >
                    Ensure to have Landlord Insurance
                  </a>
                </div>
              </div>
            </div>
          )}
          {/* //................................................... */}
          <div className="application-dashboard-container   mx-auto my-4">
            <div className="all-card-style  mb-4" style={{ fontSize: "18px" }}>
              <div
                className="d-flex application-dashboard-container-div"
                style={{
                  color:
                    newObject.depreciationSchedule == "Completed"
                      ? "white"
                      : "black",
                }}
              >
                <span style={{ width: "70%" }}>
                  Receive Depreciation schedule
                </span>
                <a
                  style={{
                    width: "30%",
                    height:"fit-content",
                    textAlign: "center",
                    backgroundColor:
                      newObject.depreciationSchedule == "Not Started"
                        ? "#b5d7ef"
                        : newObject.depreciationSchedule == "In Progress"
                        ? "#d5dd84"
                        : newObject.depreciationSchedule == "Pre Approved"
                        ? "#61b0a4"
                        : newObject.depreciationSchedule == "Completed"
                        ? "#4ab46c"
                        : newObject.depreciationSchedule == "Fully Approved"
                        ? "#7b9c72"
                        : newObject.depreciationSchedule == "Not Required"
                        ? "#e68855"
                        : newObject.depreciationSchedule == "To Be Started"
                        ? "#89888a"
                        : newObject.depreciationSchedule == "Cancelled"
                        ? "#db4e0d"
                        : "",
                  }}
                  onClick={() => {
                    newObject.depreciationSchedule == "Not Started"
                      ? navigate("/depreciation-schedule", {
                          state: {
                            id: id,
                            Depreciation: allfeeDetails,
                            state: findState,
                          },
                        })
                      : "";
                    newObject.depreciationSchedule == "Cancelled"
                      ? changeCategory(
                          "Depreciation Schedule",
                          newObject.depreciationSchedule
                        )
                      : "";
                    newObject.depreciationSchedule == "Completed"
                      ? changeCategory(
                          "Depreciation Schedule",
                          newObject.depreciationSchedule
                        )
                      : "";

                    newObject.depreciationSchedule == "In Progress"
                      ? changeCategory(
                          "Depreciation Schedule",
                          newObject.depreciationSchedule
                        )
                      : "";
                  }}
                >
                  {newObject.depreciationSchedule}
                </a>
              </div>
              {newObject.depreciationSchedule == "Not Started" ? (
                <button
                  style={{
                    color: "#FFF",
                    float: "right",
                    margin: "1px  ",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    window.location.reload();
                    sendDashboardData5();
                    statusChange("Depreciation Schedule");
                  }}
                >
                  Mark as Not Required
                </button>
              ) : (
                ""
              )}
              {newObject.depreciationSchedule == "Not Required" ? (
                <button
                  style={{
                    color: "#FFF",
                    float: "right",
                    margin: "1px  ",
                    fontSize: "12px",
                  }}
                  onClick={() => deleteStatus("Depreciation Schedule")}
                >
                  Start again
                </button>
              ) : (
                ""
              )}
            </div>
            <div className="all-card-style" style={{ fontSize: "18px" }}>
              <div
                className="d-flex application-dashboard-container-div"
                style={{
                  color:
                    newObject.accountant == "Completed" ? "white" : "black",
                }}
              >
                <span style={{ width: "70%" }}>Engage Accountant</span>
                <a
                  style={{
                    width: "30%",
                    textAlign: "center",
                    backgroundColor:
                      newObject.accountant == "Not Started"
                        ? "#b5d7ef"
                        : newObject.accountant == "In Progress"
                        ? "#d5dd84"
                        : newObject.accountant == "Completed"
                        ? "#4ab46c"
                        : newObject.accountant == "Pre Approved"
                        ? "#61b0a4"
                        : newObject.accountant == "Fully Approved"
                        ? "#7b9c72"
                        : newObject.accountant == "Not Required"
                        ? "#e68855"
                        : newObject.accountant == "To Be Started"
                        ? "#89888a"
                        : newObject.accountant == "Cancelled"
                        ? "#db4e0d"
                        : "",
                  }}
                  onClick={() => {
                    newObject.accountant == "Not Started"
                      ? navigate("/engage-accountant", {
                          state: {
                            id: id,
                            state: findState,
                          },
                        })
                      : changeCategory(
                        "Accountant",
                        newObject.accountant
                      )
                  }}
                >
                  {newObject.accountant}
                </a>
              </div>
              {newObject.accountant == "Not Started" ? (
                <button
                  style={{
                    color: "#FFF",
                    float: "right",
                    margin: "1px  ",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    window.location.reload();
                    sendDashboardData6();
                    statusChange("Accountant");
                  }}
                >
                  Mark as Not Required
                </button>
              ) : (
                ""
              )}
              {newObject.accountant == "Not Required" ? (
                <button
                  style={{
                    color: "#FFF",
                    float: "right",
                    margin: "1px  ",
                    fontSize: "12px",
                  }}
                  onClick={() => deleteStatus("Accountant")}
                >
                  Start again
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* <div className="application-dashboard-container  mx-auto application-dashboard-last-div"> */}

          {/* {allData.propertyPlan === "I'll live there" ? (
              ""
            ) : (
              <Card
                className=" p-6 user-dashboard-landlord-insurance"
                style={{
                  fontSize: "1.4rem",
                  backgroundColor: "rgb(149 169 167)",
                }}
              >
                <Link
                  to="/landlord-insurance"
                  className="text-center"
                  style={{ margin: "auto", color: "white" }}
                >
                  Ensure to have Landlord Insurance
                </Link>
              </Card>
            )} */}
          {/* {allData.propertType != "Non Strata property" ? (
              <Card
                className=" p-6 user-dashboard-landlord-insurance"
                style={{
                  fontSize: "1.4rem",
                  backgroundColor: "rgb(149 169 167)",
                }}
              >
                <Link
                  to="/landlord-insurance"
                  className="text-center"
                  style={{ margin: "auto", color: "white" }}
                >
                  Ensure to have Landlord Insurance
                </Link>
              </Card>
            ) : (
              <Card
                className=" p-6 user-dashboard-building-insurance"
                style={{
                  fontSize: "1.4rem",
                  backgroundColor: "rgb(149 169 167)",
                }}
              >
                <Link
                  to="/building-insurance"
                  className="text-center"
                  style={{ margin: "auto", color: "white" }}
                >
                  Ensure to have Building Insurance
                </Link>
              </Card> */}
          {/* )} */}
          {/* </div> */}
          <UserModal
            allData={allData && allData.TASKNAME}
            category={category && category}
          />
          <CancelModal
            allData={allData && allData.TASKNAME}
            category={category && category}
          />
        </div>
      ) : (
        <div
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img style={{ maxWidth: "7%" }} src={newImage} />
        </div>
      )}
    </>
  );
};

export default ApplicationDashboard;
