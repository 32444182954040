import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Testimonial from './Testimonial';

function TestimonialCarousel() {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_DATA}/get-all-testimonials`)
      .then(response => {
        setTestimonials(response.data.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
console.log(testimonials);

  return (
    <>
    {
        testimonials?<Testimonial testimonials={testimonials} ></Testimonial>:""
    }
    </>
    
    // <Slider {...settings}>
    //   {testimonials?testimonials.map(testimonial => (
    //     <div key={testimonial.id} className="testimonial-card">
    //       <div className="testimonial-image">
    //         <img src={process.env.REACT_APP_BACKEND_DATA+"/"+testimonial.file} alt={testimonial.name} width={50} style={{ borderRadius: '50%' }} />
    //       </div>
    //       <div className="testimonial-details">
    //         <h3 className="testimonial-name" style={{ borderRadius: '50%' }}>{testimonial.name}</h3>
    //         <p className="testimonial-description">{testimonial.description}</p>
    //       </div>
    //     </div>
    //   )):""}
    // </Slider>
  );
}

export default TestimonialCarousel;