import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Button } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";

export const DataTables = ({ incomingData, incomingPaymentData ,filter}) => {
//  const [data, setData] = React.useState(incomingData);
var data = filter?.filter((element) => {
  if (element.type === "Depreciation Schedule") {
    
    
      if (element.progress === "Completed") {
        // console.log(element);
        return element;
    
    }
 
  }
});

 let newData = [];
 incomingPaymentData.map((data) =>
   incomingData.map((value) =>
     data.taskId === value.taskId ? newData.push(data.paymentAmount) : 0
   )
 );

 let totalPayment = newData.reduce((acc, data) => {
   let d = parseInt(data);
   return (acc = acc + d);
 }, 0);

 function convertArrayOfObjectsToCSV(array) {
  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(data[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
   let ctr = 0;
   keys.forEach((key) => {
    if (ctr > 0) result += columnDelimiter;

    result += item[key];

    ctr++;
   });
   result += lineDelimiter;
  });

  return result;
 }

 function downloadCSV(array) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array);
  console.log(array);
  if (csv == null) return;

  const filename = "export.csv";

  if (!csv.match(/^data:text\/csv/i)) {
   csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
 }

 const Export = ({ onExport }) => <Button type="button" className="ml-5" onClick={()=>tableToCSV()}>  Export</Button>;

 const columns = [
  {
   name: "Task ID",
   selector: (row) => [row.task_id],
   sortable: true,
  },
  {
   name: "Customer Name",
   selector: (row) => [row.name],
   sortable: true,
  },
  {
   name: "Customer ID",
   selector: (row) => [row.user],
   sortable: true,
  },
  {
    name: "Company Name",
    selector: (row) => [row.assignTo],
    sortable: true,
   },
  {
   name: "Status",
   selector: (row) => [row.progress],
   sortable: true,
  },
 
  {
   name: "Assigned Date",
   selector: (row) => (
    <div>
      {row.assignDate === undefined
        ? "N/A"
        : row.assignDate.split("-").reverse().join("/")}
    </div>
  ),
   sortable: true,
  },
  {
   name: "Completion Date",
   selector: (row) =>row.completeDate ,
   sortable: true,
  },
  {
   name: "Fee",
   selector: (row) =>row.paymentAmount,
   sortable: true,
  },
 ];
 const tableDatas = {
  columns,
  data,
 };
 const actionsMemo = React.useMemo(() => <Button type="button" className="ml-5" onClick={()=>tableToCSV()}>  Export</Button>, []);

 var sum =0;
 data?.map((elm)=>{
 return sum += +elm.paymentAmount
 })
//  console.log(data);
function tableToCSV() {
 
  // Variable to store the final csv data
  var csv_data = [];

  // Get each row data
  var rows = document.getElementsByClassName('tttttt');
  console.log(rows);
  for (var i = 0; i < rows.length; i++) {

      // Get each column data
      var cols = rows[i].querySelectorAll('td,th');

      // Stores each csv row data
      var csvrow = [];
   
      for (var j = 0; j < cols.length; j++) {

          // Get the text data of each cell
          // of a row and push it to csvrow
          csvrow.push(cols[j].innerHTML);
      }

      // Combine each column value with comma
      csv_data.push(csvrow.join(","));
  }

  // Combine each row data with new line character
  csv_data = csv_data.join('\n');

  // Call this function to download csv file 
  downloadCSVFile(csv_data);

}

function downloadCSVFile(csv_data) {

  // Create CSV file object and feed
  // our csv_data into it
  var CSVFile = new Blob([csv_data], {
      type: "text/csv"
  });

  // Create to temporary link to initiate
  // download process
  var temp_link = document.createElement('a');

  // Download csv file
  temp_link.download = "Depreciation Report.csv";
  var url = window.URL.createObjectURL(CSVFile);
  temp_link.href = url;

  // This link should not be displayed
  temp_link.style.display = "none";
  document.body.appendChild(temp_link);

  // Automatically click the link to
  // trigger download
  temp_link.click();
  document.body.removeChild(temp_link);
}

 return (
    <div>


<table className="d-none" border="1" cellSpacing="0" cellPadding="10">
  
  <tr className="tttttt">
      <th className="thexp">Task ID</th>
      {/* <th className="thexp">Task Type</th> */}
      <th className="thexp">Custumer Name</th>
      <th className="thexp">Custumer ID</th>
      <th className="thexp">Company Name </th>
      {/* <th className="thexp">Order ID</th> */}
      <th className="thexp">Status</th>
      <th className="thexp">Assigned Date</th>
      <th className="thexp">Completion Date </th>
      <th className="thexp">Fee</th>
     
     

  </tr>
  {
    data?.map((row)=>{
      return(
        <>
        <tr className="tttttt">
            <td>{row.task_id}</td>
            {/* <td>{row.type}</td> */}
            <td>{row.name}</td>
            <td>{row.user}</td>
            <td>{row.assignTo}</td>
            {/* <td>{row.task_id}</td> */}
            <td>{row.progress}</td>
            <td>{row.assignDate === undefined
            ? "N/A"
            : row.assignDate.split("-").reverse().join("/")}</td>
            
            <td>
              { row.completeDate === undefined || row.completeDate === "N/A"  
            ? "N/A" 
            : row.completeDate
            }
              {/* { row.completeDate === undefined || row.completeDate === "N/A"  
            ? "N/A" 
            : new Date(row.completeDate).toLocaleDateString('en-In')
            } */}
            </td>

            <td>{row.paymentAmount}</td>
           

        </tr>
        </>
      )
    })
  }

</table>

    <DataTableExtensions {...tableDatas}>
      <DataTable
        actions={actionsMemo}
        columns={columns}
        data={data}
        pagination
        highlightOnHover
        responsive
        dense
      />
    </DataTableExtensions>
    <div
      className="form-control"
      style={{ textAlign: "end", fontSize: "20px" }}
    >
     Fee:{" "}
      <span style={{ fontWeight: "bold" }}> ${sum}</span>
    </div>
  </div>
 );
};
