import React from "react";
import { Card } from "react-bootstrap";
const Summary = ({ fullFees, UpFrontFees }) => {
  return (
    <div>
      <div>
        <Card.Header>
          <h3>Summary</h3>
        </Card.Header>
        <Card.Body>
          <div className="d-flex mb-4">
            <div className="m-auto" style={{ width: "30%" }}>
              Services:
            </div>
            <div className="p-1 border" style={{ width: "60%" }}>
              Solicitor
            </div>
          </div>
          <div className="d-flex mb-4">
            <div className="m-auto" style={{ width: "30%" }}>
              Total:
            </div>
            <div className="p-1 border" style={{ width: "60%" }}>
              $ {fullFees}
            </div>
          </div>
          <div className="d-flex mb-4">
            <div className="m-auto" style={{ width: "30%" }}>
              Fee Payable Suring Settlement :
            </div>
            <div className="p-1 border" style={{ width: "60%" }}>
              $ {fullFees - UpFrontFees}
            </div>
          </div>
          <div className="d-flex mb-4">
            <div className="m-auto" style={{ width: "30%" }}>
              Fee Payable Now :
            </div>
            <div className="p-1 border" style={{ width: "60%" }}>
              $ {UpFrontFees}
            </div>
          </div>
        </Card.Body>
      </div>
    </div>
  );
};

export default Summary;
