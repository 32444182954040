import React, { useState } from "react";
import Footer from "../../component/footer/Footer";
import "./About.css";
import { Link } from "react-router-dom";
function AboutUs() {
  const [openOption, setOpenOption] = useState(false);

  return (
    <>
      <div className="about-us">
        <div className="about-us-heading">
          <h6>Let Us Introduce</h6>
          <h2>About Our Company</h2>
          <span className="service-span"></span>
        </div>
        <div className="about-us-container">
          <div className="about-us-container-inner1">
            <img
              src="https://themes.hibootstrap.com/levix/wp-content/uploads/2022/02/about-2.png"
              alt="Image"
            />
          </div>
          <div className="about-us-container-inner2">
            <h3>
              JustNests connecting you with everyone you need to buy your dream
              home!
            </h3>
            <p>
              JustNests empowers users across Australia to use our digital
              platform to the convenience of finding the best service providers
              under one roof and go through their home buying journey without
              any stress.
            </p>
            <div>
              <h3>Our trusted experts</h3>
              <p>
                We work with a selected trusted service providers to help you in
                your home buying journey.
              </p>
              <p>
                We are a platform designed to succeed when our customers
                succeed, so we have a vested interest in the quality of the
                experts in our platform, as well as the manner in which they
                connect and help the customers.
              </p>
              <h3>Who we are</h3>
              <p>
                JustNests team are passionate and committed in ensuring all
                Australians are able to go through their home buying journey
                without stress and access the best experts with the convenience
                of few clicks.
              </p>
              <p>
                JustNests founders having extensive expertise in technology,
                property market being the investors, accountant, property agent,
                mortgage broker, they have gone through the complicated process
                themselves for years before coming up with the JustNests
                platform to help themselves and all other home buyers in
                Australia.
              </p>
            </div>

            <Link
              className="about-us-button"
              to="/login"
              style={{ marginBottom: "1rem !important" }}
            >
              <button className="btn btn-outline-primary button">
                {" "}
                Get Started
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AboutUs;
