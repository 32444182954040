import React, { useContext } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { tableDataItems } from "./TableData";
import { Button } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";
import AdminContext from "../../../../../adminContext/AdminContext";

export const DataTables = (props) => {
  
  //   const [data, setData] = React.useState(tableDataItems);
  const dashboardContext = useContext(AdminContext);
  const {
    stateLegalRevenueDetails,
    stateLegalPayableDetails,
    stateLegalNetIncomeDetails,
    stateBuildingRevenueDetails,
    stateBuildingPayableDetails,
    stateBuildingNetIncomeDetails,
    stateDepricationRevenueDetails,
    stateDepricationPayableDetails,
    stateDepricationNetIncomeDetails,
  } = dashboardContext;

  const data = [
    {
      partner: "Legal",
      RevenueDetails: stateLegalRevenueDetails,
      PayableDetails: stateLegalPayableDetails,
      NetIncomeDetails: stateLegalNetIncomeDetails,
    },
    {
      partner: "Building and Pest",
      RevenueDetails: stateBuildingRevenueDetails,
      PayableDetails: stateBuildingPayableDetails,
      NetIncomeDetails: stateBuildingNetIncomeDetails,
    },
    {
      partner: "Depreciation Schedule",
      RevenueDetails: stateDepricationRevenueDetails,
      PayableDetails: stateDepricationPayableDetails,
      NetIncomeDetails: stateDepricationNetIncomeDetails,
    },
  ];

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <Button onClick={(e) => onExport(e.target.value)}>Export</Button>
  );

  const columns = [
    {
      name: "Partner",
      selector: (row) => [row.partner],
      sortable: true,
    },
    {
      name: "Revenue",
      selector: (row) => [row.RevenueDetails],
      sortable: true,
    },
    {
      name: "Payable",
      selector: (row) => [row.PayableDetails],
      sortable: true,
    },
    {
      name: "Net Income",
      selector: (row) => [row.NetIncomeDetails],
      sortable: true,
    },
  ];
  const tableDatas = {
    columns,
    data,
  };
  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(data)} />,
    []
  );

  let legalRev = localStorage.getItem("legalRev");
  let legalPayable = localStorage.getItem("legalPayable");
  let legalNet = localStorage.getItem("legalNet");

  let DepRev = localStorage.getItem("DepRev");
  let DepPayable = localStorage.getItem("DepPayable");
  let DepNet = localStorage.getItem("DepNet");

 var  data3 = [
  legalRev,
  legalPayable,
  legalNet,
  DepRev,
  DepPayable,
  DepNet
]
let deprev = data[1].RevenueDetails;
 let deppaya = data[1].PayableDetails;
 let depnet = data[1].NetIncomeDetails;
//  console.log(data);


 function tableToCSV() {
 
  // Variable to store the final csv data
  var csv_data = [];

  // Get each row data
  var rows = document.getElementsByClassName('trrr');
  for (var i = 0; i < rows.length; i++) {

      // Get each column data
      var cols = rows[i].querySelectorAll('td,th');

      // Stores each csv row data
      var csvrow = [];
      for (var j = 0; j < cols.length; j++) {

          // Get the text data of each cell
          // of a row and push it to csvrow
          csvrow.push(cols[j].innerHTML);
      }

      // Combine each column value with comma
      csv_data.push(csvrow.join(","));
  }

  // Combine each row data with new line character
  csv_data = csv_data.join('\n');

  // Call this function to download csv file 
  downloadCSVFile(csv_data);

}

function downloadCSVFile(csv_data) {

  // Create CSV file object and feed
  // our csv_data into it
  var CSVFile = new Blob([csv_data], {
      type: "text/csv"
  });

  // Create to temporary link to initiate
  // download process
  var temp_link = document.createElement('a');

  // Download csv file
  temp_link.download = "Summary.csv";
  var url = window.URL.createObjectURL(CSVFile);
  temp_link.href = url;

  // This link should not be displayed
  temp_link.style.display = "none";
  document.body.appendChild(temp_link);

  // Automatically click the link to
  // trigger download
  temp_link.click();
  document.body.removeChild(temp_link);
}
  return (
    <>
   
	
  
  <Button type="button" className="ml-5" onClick={()=>tableToCSV()}>  Export</Button>
  <br />
  <br />
  <table className="pt-5"border="1" cellSpacing="0" cellPadding="10" style={{    minWidth: "99%"}} >
    <tr  className="trrr">
      <th>Partner</th>
      <th>Revenue</th>
      <th>Payable</th>
      <th>Net Income</th>
    </tr>
    
    <tr className="trrr">
      <td>Legal</td>
      <td>{legalRev}</td>
      <td>{legalPayable}</td>
      <td>{legalNet}</td>
    </tr>
    <tr className="trrr">
      <td>Depreciation Schedule</td>
      <td>{DepRev}</td>
      <td>{DepPayable}</td>
      <td>{DepNet}</td>
    </tr>
    <tr className="trrr">
      <td>Building and Pest</td>
      <td>{deprev}</td>
      <td>{deppaya}</td>
      <td>{depnet}</td>
    </tr>
    
    <tr className="trrr d-none">
      <td>Total</td>
      <td> Revenue: ${" "}
          {+legalRev +
            +stateBuildingRevenueDetails +
            +DepRev}</td>
      <td> Payable: ${" "}
          {+legalPayable +
            +stateBuildingPayableDetails +
            +DepPayable}</td>
      <td>Net Income: ${" "}
          {+legalNet + +depnet + +DepNet}</td>
    </tr>
  </table>

      {/* <DataTableExtensions {...tableDatas}>
        <DataTable
          actions={actionsMemo}
          columns={columns}
          data={data}
          pagination
          highlightOnHover
          responsive
          dense
        />
      </DataTableExtensions> */}
      <div className="form-control d-flex mt-2 " style={{border:"none"}}>
      <hr />
        <div className="mx-5" style={{ fontWeight: "bold", fontSize: "20px" }}>
          Total -
        </div>
        <div className="mx-9" style={{ alignSelf: "end", fontWeight: "bold" }}>
          Revenue: ${" "}
          {+legalRev +
            +stateBuildingRevenueDetails +
            +DepRev}
        </div>
        <div className="mx-9" style={{ alignSelf: "end", fontWeight: "bold" }}>
          Payable: ${" "}
          {+legalPayable +
            +stateBuildingPayableDetails +
            +DepPayable}
        </div>
        <div  style={{ alignSelf: "end", fontWeight: "bold" }}>
          Net Income: ${+legalNet + +depnet + +DepNet}
        </div>
      </div>
      <hr />
    </>
  );
};
