import React from "react";
import { useLocation ,useParams} from "react-router-dom";
import "./blogAfter.css";
import {useSearchParams} from "react-router-dom";
import Footer from "../../component/footer/Footer";
import HomeBlogs from '../../component/home-component/homeBlogs/HomeBlogs'
import axios from "axios";
const BlogSingle   = (props) => {
  const[data1,setData1] = React.useState(" ")
  
  const location = useLocation();
  React.useEffect(() => {
    async function fetchData() {
      const url = await location.pathname.substring(12,).replace('just blogs/',''); 
      console.log(url);
      console.log(123456);
      await axios.post(`${process.env.REACT_APP_BACKEND_DATA}/getsingle-blog`, {url})
      .then(response => setData1(response.data))
      .catch(error => console.log(error));
    }
    
    fetchData()

  }, []);
  console.log(location);
  console.log(data1);
  return (
    <>
      <div style={{ backgroundColor: "white" }}>
    <div className="blogPageAfterImage ">
      <span>Blog</span>
    </div>
   {data1?
    <div className="main-blog-after ">
      <div className="blog-after">
        <div className="blog-after-title">{data1.title}</div>

     <div className="">
          <img src={`https://just.justnests.com.au/${data1.blogImage}`} className="img-fluid rounded-3" alt="not " />
        </div>

        <div
          className="blog-after-description"
          dangerouslySetInnerHTML={{ __html: data1.description }}
        />
      </div>
      
    </div>:""
  }
    <HomeBlogs/>
    <Footer/>
  </div>
    
    
    </>
  );
};

export default BlogSingle;
