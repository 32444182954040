import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';

function AddAccount() {
  const initialValue = {
    oldPassword: '',
    email: '',
    newPassword: '',
    confirmPassword: '',
  };
  const [passwordToggle1, setPasswordToggle1] = useState(false);
  const [passwordToggle2, setPasswordToggle2] = useState(false);
  const [error, setError] = useState(false);
  const [formInput, setFormInput] = useState(initialValue);

  const handleChange = (e) => {
    setFormInput((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (formInput.newPassword === formInput.confirmPassword) {
      const url = `${process.env.REACT_APP_BACKEND_DATA}/api/admin/register-admin`;
      const data = {
        oldPassword: formInput.oldPassword,
        password: formInput.newPassword,
        email: formInput.email,
      };
      axios
        .post(url, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'admin-token': localStorage.getItem('admin-token'),
          },
        })
        .then((response) => {
          // console.log('>>', response);
          toast.success('Successfully Added', {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((error) => {});
      setFormInput(initialValue);
      setError(false);
    } else if (formInput.oldPassword === '') {
      toast.error('Please fill right credentials', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (formInput.newPassword !== formInput.confirmPassword) {
      toast.error('Password not match', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 5000);
    }
  };
  console.log(formInput);
  return (
    <div>
      <Card className="col-md-5 mt-9">
        <Card.Header>
          <h3>Add New Account</h3>
        </Card.Header>
        <Card.Body>
          <label className="form-label">
            New Account Email
            <input
              className="form-control"
              type="email"
              placeholder="Enter new email"
              name="email"
              value={formInput.email}
              onChange={handleChange}
            />
          </label>
          <label className="form-label">
            New Password
            <div className="d-flex form-control">
              <input
                className="w-100"
                type={passwordToggle1 ? 'text' : 'password'}
                placeholder="Enter new password"
                name="newPassword"
                value={formInput.newPassword}
                onChange={handleChange}
              />
              <i
                onClick={() => setPasswordToggle1(!passwordToggle1)}
                className={
                  passwordToggle1 ? 'fe fe-eye p-1' : 'fe fe-eye-off p-1'
                }
              />
            </div>
          </label>
          <label className="form-label mb-5">
            Confirm Password
            <div className="d-flex form-control">
              <input
                className="w-100"
                type={passwordToggle2 ? 'text' : 'password'}
                placeholder="Enter new password"
                name="confirmPassword"
                value={formInput.confirmPassword}
                onChange={handleChange}
              />
              <i
                onClick={() => setPasswordToggle2(!passwordToggle2)}
                className={
                  passwordToggle2 ? 'fe fe-eye p-1' : 'fe fe-eye-off p-1'
                }
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                fontSize: '14px',
                color: '#8f3128',
                opacity: error ? '1' : '0',
              }}
            >
              Enter valid password
            </div>
          </label>
          <button
            // type="submit ,reset"
            type="reset"
            onClick={handleSubmit}
            className="btn btn-success col-md-5 d-block m-auto"
          >
            Submit
          </button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default AddAccount;
