import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import UserApiContext from "../UserApi/UserApiContext";
var FileDownload = require("js-file-download");

function CancelModal({ allData, category }) {
  const userApiContext = useContext(UserApiContext);
  const { cancelModal, setCancelModal } = userApiContext;
  const [filteredData, setFilteredData] = useState([]);

  let solicitorAndconveyancer = "";
  if (category === "solicitorAndconveyancer") {
    allData.map((data) => {
      if (data.type === "Conveyancer") {
        return (solicitorAndconveyancer = "Conveyancer");
      }
      if (data.type === "Solicitor") {
        return (solicitorAndconveyancer = "Solicitor");
      }
    });
  }
  
  const fliteringDataForModal = () => {
    setFilteredData(
      allData &&
        allData.filter((data) => {
          if (data.type === category) {
            return data;
          } else if (data.type === solicitorAndconveyancer) {
            return data;
          }
        })
    );
  };
  const downloadReport = (type) => {
    if (type) {
      const url = `${process.env.REACT_APP_BACKEND_DATA}/download-report?fileName=${type}`;
      axios
        .get(url, {
          responseType: "arraybuffer",
          headers: {
            Accept: "application/pdf",
            "auth-token": localStorage.getItem("token"),
          },
        })
        .then((response) => {
          FileDownload(response.data, "filename.pdf");
        })
        .catch((error) => {});
      } else {
        toast.warning("Letter is not available at this stage", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  
  useEffect(() => {
    fliteringDataForModal();
  }, [cancelModal]);
//..................................................................................
const [products2,setProducts2]=useState([])

const getProducts1 = async (data)=>{
  let taskid = data.task_id
  let tasktype = data.type
  let progress = data.progress
  console.log(tasktype,taskid ,progress)
  let result1 = await fetch(`${process.env.REACT_APP_BACKEND_DATA}/download_single/${tasktype}/${progress}/${taskid}`)

 let result = await result1.json();


       if(result.data)
           {
             console.log('this is productttt 1',result.data);
                window.open(`${process.env.REACT_APP_BACKEND_DATA}/${result.data}`);
                       console.log('this is productttt',result.data);
              }
              else
               { toast.warning("Letter is not available at this stage", {
                position: "top-center",
              autoClose: 1000,
                     hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
               progress: undefined,
              });
            }  
            console.log('this is productttt',products2);
}
  return (
    <Modal show={cancelModal}>
      <Modal.Header>
        <Button
          onClick={() => setCancelModal(false)}
          className="btn-close"
          variant=""
        >
          x
        </Button>
      </Modal.Header>
      {filteredData &&
        filteredData.map((value, index) => (
          <Modal.Body key={index}>
            <h3
              style={{
                backgroundColor: "#f5f5f5",
                border: "1px solid #e7e7e7",
                borderRadius: "4px",
                textAlign: "center",
                padding: "10px 0",
              }}
            >
              Cancellation details
            </h3>
            <div className="d-flex m-1">
              <div style={{ width: "20%" }}>Status :</div>
              <div style={{ width: "80%" }}>{value.progress}</div>
            </div>
            <div className="d-flex m-1">
              <div style={{ width: "20%" }}>Reason :</div>
              <div style={{ overflow: "scroll", width: "80%" }}>
                {value.reason}
              </div>
            </div>
            <Button
              variant="success"
              className="btn d-block mx-auto mt-6"
              onClick={() => getProducts1(value)}
            >
              Download Document
            </Button>
          </Modal.Body>
        ))}
      <Modal.Footer>
        <Button
          variant="primary"
          className="me-1"
          onClick={() => setCancelModal(false)}
        >
          Go back to Application Dashboard
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CancelModal;
