import React, { useContext, useEffect, useState, Buffer } from "react";
import { Card } from "react-bootstrap";
import AdminContext from "../../adminContext/AdminContext";
import EditBlog from "./howToBuyEdit/EditHowToBuy";
import "./howToBuy.css";
import axios from "axios";

// const fileToDataUri = (file) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onload = (event) => {
//       resolve(event.target.result);
//     };
//     reader.readAsDataURL(file);
//   });
const GetBlog = (props) => {
  const [allBuy, setAllBuy] = useState([]);
  const admincontext = useContext(AdminContext);
  const { getAllHowToBuy, setHandleModal } = admincontext;
  const [dummy, setDummy] = useState();
  const [initialValue, setInitialValue] = useState([
    {
      title: "",
      description: "",
    },
  ]);

  const [dataUri, setDataUri] = useState("");

  //   const newData = (file) => {
  //     if (!file) {
  //       setDataUri("");
  //     }

  //     fileToDataUri(file).then((dataUri) => {
  //       setDataUri(dataUri);
  //     });
  //   };

  //   const downloadImage = (type) => {
  //     const url = `${process.env.REACT_APP_BACKEND_DATA}/download-image?fileName=${type}`;
  //     axios
  //       .get(url, { responseType: "blob" })
  //       .then((response) => {
  //         newData(response.data);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   };

  const getAllhowtoBuy = async () => {
    setAllBuy(await getAllHowToBuy());
  };

  useEffect(() => {
    getAllhowtoBuy();
  }, [props.changeState]);
  const handleEdit = (title, description, _id) => {
    setHandleModal(true);
    setInitialValue({
      title,
      description,
      _id,
    });
    setShowModal(!showModal);
  };

  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      {allBuy &&
        allBuy.map((item, ind) => (
          <Card key={ind} className="buy-blog-card">
            <div className="buy-blog-body">
              <div className="buy-delete-and-edit">
                <div className="admin-card-buy-title">{item.title}</div>
                <span>
                  {" "}
                  <i
                    className="zmdi zmdi-edit"
                    style={{
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleEdit(item.title, item.description, item._id);
                    }}
                  />
                </span>
              </div>
              <div className="buy-blog-image">
                {
                  <img
                    src={`data:image;base64,${btoa(
                      String.fromCharCode(
                        ...new Uint8Array(item.howToImage.data)
                      )
                    )}`}
                    alt=""
                  />
                }
              </div>

              <div className="admin-card-buy-description">
                {item.description}
              </div>
            </div>
          </Card>
        ))}
      {showModal ? (
        <EditBlog
          title={initialValue.title}
          description={initialValue.description}
          _id={initialValue._id}
          setChangeState={props.setChangeState}
          changeState={props.changeState}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default GetBlog;
