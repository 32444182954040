import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "./BlogPage.css";
import newImage from "../../assets/images/ZZ5H.gif";

function BlogMore({ incomingData }) {
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 9;

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(incomingData && incomingData.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(incomingData && incomingData.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % incomingData.length;
    setItemOffset(newOffset);
    scrollToTop()
  };

  return (
    <div>
      {!currentItems ? (
        <div
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img style={{ maxWidth: "7%" }} src={newImage} />
        </div>
      ) : (
        <div>
          <div className="blogPageImage">
            <span>Checkout the latest blog </span>
          </div>
          <div className="blog-page">
            <div className="blog-heading">
              <h6>News & Blog</h6>
              <h2>Featured News And Insights</h2>
              <span className="service-span"></span>
            </div>

            <div className="blog-page-main-card">
              <div className="blog-page-card ">
                {currentItems.map((elm) => {
                  return (
                    <>
                      <a href={"/just-blogs/" + elm.url.replace(/\s+/g, "-")}>
                        <div className="blog-page-single-card">
                          <div className="card-blog">
                            <div className="blog-img-section">
                              <img src={`https://just.justnests.com.au/${elm.blogImage}`} alt="" />
                            </div>

                            <h4 className="card-blog-title">{elm.title}</h4>
                            <div className="card-blog-information">
                              <p
                                className="card-blog-description"
                                dangerouslySetInnerHTML={{
                                  __html: elm.description,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </a>
                    </>
                  );
                })}
              </div>
            </div>

            <div className="react-pagination text-center">
              <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                containerClassName="pagination d-flex align-items-center p-1"
                pageLinkClassName="page-num"
                nextLinkClassName="page-num"
                previousLinkClassName="page-num"
                activeLinkClassName="active"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BlogMore;
