import React, { useEffect } from "react";
import Sidebar from "../component/SideBar/SideBar";
import { Routes, Route, useNavigate } from "react-router-dom";
import {
  Dashboard,
  PartnerDetails,
  FeeDetails,
  CustomerDetails,
  PaymentDetails,
  EmailDetails,
  Reports,
} from "./pages/adminRoute";
import TaskType1 from "./pages/dashboard/taskModal/TaskType1";
import TaskType2 from "./pages/dashboard/taskModal/TaskType2";
import TaskType3 from "./pages/dashboard/taskModal/TaskType3";
import TaskType4 from "./pages/dashboard/taskModal/TaskType4";
import AddPartner from "./pages/partnerDetails/forAddingPartner/AddPartner";
import AdminState from "./adminContext/AdminState";
import SettingPage from "./pages/settingPage/SettingAccount";
import AddAccount from "./pages/settingPage/AddAccount";
import EditAccount from "./pages/settingPage/EditAccount";
import RemoveAccount from "./pages/settingPage/RemoveAccount";
import Blog from "./pages/blog/Blog";
import HowToBuy from "./pages/howToBuy/HowToBuy";
import Promocode from "./pages/promocode/Promocode";
import Addpromo from "./pages/promocode/Addpromocode/Addpromo";
import Updatepromo from "./pages/promocode/Addpromocode/Updatepromo";
import Testimonial from "./pages/Testimonial/Testimonial";
import Accountingrequest from "./pages/AccountingRequests/Accountingrequest";
function AdminMain() {
  const navigate = useNavigate();

  const openCloseSidebar = () => {
    document.querySelector(".app").classList.toggle("sidenav-toggled");
  };

  useEffect(() => {
    if (!localStorage.getItem("admin-token")) {
      navigate("/admin");
    }
  }, []);
  return (
    <div>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="app-wrapper">
          <div className="side-app">
            <i
              className="fa fa-th-list sidebar-icon"
              onClick={() => openCloseSidebar()}
              style={{ cursor: "pointer" }}
            />
            <AdminState>
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/promocode" element={<Promocode />} />
                <Route path="/add-promocode" element={<Addpromo />} />
                <Route path="/add-testimonials" element={<Testimonial/>} />
                <Route path="/update-promocode" element={<Updatepromo/>} />
                <Route path="/partner-details" element={<PartnerDetails />} />
                <Route path="/fee-details/*" element={<FeeDetails />} />
                <Route path="/customer-details" element={<CustomerDetails />} />
                <Route path="/payment-details" element={<PaymentDetails />} />
                <Route path="/email-details" element={<EmailDetails />} />
                <Route path="/reports/*" element={<Reports />} />
                <Route path="/setting" element={<SettingPage />} />
                <Route path="/setting-add-account" element={<AddAccount />} />
                <Route path="/setting-edit-account" element={<EditAccount />} />
                <Route path="/accounting-requests" element={<Accountingrequest  />} />
                <Route
                  path="/setting-remove-account"
                  element={<RemoveAccount />}
                />
                {/* For Dashbord */}
                <Route path="/mortgage-broker" element={<TaskType1 />} />
                <Route path="/solicitor" element={<TaskType2 />} />
                <Route path="/conveyancer" element={<TaskType2 />} />
                <Route path="/building-inspection" element={<TaskType3 />} />
                <Route path="/pest-inspection" element={<TaskType3 />} />
                <Route
                  path="/combined-building-pest-inspection"
                  element={<TaskType3 />}
                />
                <Route path="/property-manager" element={<TaskType4 />} />
                <Route path="/depreciation-schedule" element={<TaskType3 />} />
                <Route path="/accountant" element={<TaskType4 />} />
                <Route path="/Buyers-Advocate" element={<TaskType4 />} />
                {/* For Partner */}
                <Route path="/add-partner/*" element={<AddPartner />} />
                {/* For Blog */}
                <Route path="/blog" element={<Blog />} />
                {/* For how to buy house */}
                <Route path="/how-to-buy-house" element={<HowToBuy />} />
              </Routes>
            </AdminState>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminMain;
