import React, { useContext, useEffect, useState } from "react";
import LegalTable from "./legalTable/LegalTable";
import DepreciationTable from "./depreciationTable/DepreciationTable";
import BuildingAndPestTable from "./buildingAndPestTable/buildingPestTable";
import SummaryTable from "./summary/SummaryTable";
import AdminContext from "../../../../adminContext/AdminContext";
import newImage from "../../../../../assets/images/ZZ5H.gif";
import { Card } from "react-bootstrap";

function RevenueTable() {
  const initialValue = { startingDate: "", endingDate: "" };
  const [formInput, setFormInput] = useState(initialValue);

  const [incomingData, setIncomingData] = useState([]);
  const [incomingPaymentData, setIncomingPaymentData] = useState([]);
  const [incomingPartnerData, setIncomingPartnerData] = useState([]);
  const dashboardContext = useContext(AdminContext);
  const { handleDashboardApi, handlePaymentDetailsApi, handlePartnerApi } =
    dashboardContext;

  // setUsersData(table);
  const callingApiForContext = async () => {
    setIncomingData(await handleDashboardApi());
    setIncomingPaymentData(await handlePaymentDetailsApi());
    setIncomingPartnerData(await handlePartnerApi());
  };

  useEffect(() => {
    callingApiForContext();
  }, []);

  const handleChange = (e) => {
    setFormInput((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormInput(initialValue);
  };
// console.log(incomingPaymentData);
  let filteredDataSolicitorConveyancer = incomingData.filter((element) => {
    if (element.type === "Solicitor" || element.type === "Conveyancer") {
      return element;
    }
  });
  let filteredPaymentDataSolicitorConveyancer = incomingPaymentData.filter(
    (element) => {
      if (
        element.taskName === "Solicitor" ||
        element.taskName === "Conveyancer"
      ) {
        return element;
      }
    }
  );
  let filteredPartnerDataSolicitorConveyancer = incomingPartnerData.filter(
    (element) => {
      if (
        element.partnerCategory === "Solicitor" ||
        element.partnerCategory === "Conveyancer"
      ) {
        return element;
      }
    }
  );
  let filteredDataInspection = incomingData.filter((element) => {
    if (
      element.type === "Pest Inspection" ||
      element.type === "Building and Pest Inspection" ||
      element.type === "Building Inspection"
    ) {
      return element;
    }
  });
  let filteredPaymentDataInspection = incomingPaymentData.filter((element) => {
    if (
      element.taskName === "Pest Inspection" ||
      element.taskName === "Building and Pest Inspection" ||
      element.taskName === "Building Inspection"
    ) {
      return element;
    }
  });
  let filteredPartnerDataInspection = incomingPartnerData.filter((element) => {
    if (
      element.partnerCategory === "Pest Inspection" ||
      element.partnerCategory === "Building and Pest Inspection" ||
      element.partnerCategory === "Building Inspection"
    ) {
      return element;
    }
  });
  let filteredDataDepreciation = incomingData.filter((element) => {
    if (element.type === "Depreciation Schedule") {
      return element;
    }
  });
  let filteredPaymentDataDepreciation = incomingPaymentData.filter(
    (element) => {
      if (element.taskName === "Depreciation Schedule") {
        return element;
      }
    }
  );
  let filteredPartnerDataDepreciation = incomingPartnerData.filter(
    (element) => {
      if (element.partnerCategory === "Depreciation Schedule") {
        return element;
      }
    }
  );
  function tableToCSV() {

    // Variable to store the final csv data
    var csv_data = [];

    // Get each row data
    var rows = document.getElementsByTagName('tr');
    for (var i = 0; i < rows.length; i++) {

      // Get each column data
      var cols = rows[i].querySelectorAll('td,th');

      // Stores each csv row data
      var csvrow = [];
      for (var j = 0; j < cols.length; j++) {

        // Get the text data of each cell
        // of a row and push it to csvrow
        csvrow.push(cols[j].innerHTML);
      }

      // Combine each column value with comma
      csv_data.push(csvrow.join(","));
    }

    // Combine each row data with new line character
    csv_data = csv_data.join('\n');

    // Call this function to download csv file
    downloadCSVFile(csv_data);

  }

  function downloadCSVFile(csv_data) {

    // Create CSV file object and feed
    // our csv_data into it
    CSVFile = new Blob([csv_data], {
      type: "text/csv"
    });

    // Create to temporary link to initiate
    // download process
    var temp_link = document.createElement('a');

    // Download csv file
    temp_link.download = "GfG.csv";
    var url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;

    // This link should not be displayed
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);

    // Automatically click the link to
    // trigger download
    temp_link.click();
    document.body.removeChild(temp_link);
  }
  return (
    <>
      {incomingData.length === 0 ? (
        <div
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img style={{ maxWidth: "7%" }} src={newImage} />
        </div>
      ) : (
        <div>
          <Card className="mt-4">
            <Card.Body>
              <form onSubmit={handleSubmit}>
                <div className="col-md-5 mb-4">
                  <label htmlFor="startingDate">Creation Date Between :</label>
                  <div className="input-group input-daterange">
                    <input
                      type="date"
                      className="form-control"
                      value={formInput.startingDate}
                      name="startingDate"
                      onChange={handleChange}
                    />
                    <div
                      className="input-group-addon border px-2 py-3"
                      style={{ fontSize: "15px", background: "#f2f2f2" }}
                    >
                      to
                    </div>
                    <input
                      type="date"
                      className="form-control"
                      value={formInput.endingDate}
                      name="endingDate"
                      onChange={handleChange}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-success btn-icon text-white mt-4"
                    style={{ margin: "auto", padding: ".5rem 2rem" }}
                  >
                    Search
                  </button>
                </div>
              </form>
            </Card.Body>
          </Card>
          <LegalTable
            incomingData={filteredDataSolicitorConveyancer}
            incomingPaymentData={filteredPaymentDataSolicitorConveyancer}
            incomingPartnerData={filteredPartnerDataSolicitorConveyancer}
          />
          <BuildingAndPestTable
            incomingData={filteredDataInspection}
            incomingPaymentData={filteredPaymentDataInspection}
            incomingPartnerData={filteredPartnerDataInspection}
          />
          <DepreciationTable
            BuildingAndPestTable
            incomingData={filteredDataDepreciation}
            incomingPaymentData={filteredPaymentDataDepreciation}
            incomingPartnerData={filteredPartnerDataDepreciation}
          />
          <SummaryTable />
        </div>
      )}
    </>
  );
}

export default RevenueTable;
