import React from "react";
import * as datatable from "./TableFormat";
import { Row, Card, Col } from "react-bootstrap";

function Table({incomingData, incomingPaymentData, incomingPartnerData}) {
 return (
  <div>
   <Row className=" row-sm">
    <Col lg={12}>
     <Card  className="mt-4">
     <Card.Header>
       <h3>Depreciation Schedule</h3>
      </Card.Header>
      <Card.Body>
       <div className="table-responsive">
        <datatable.DataTables incomingData={incomingData} incomingPaymentData={incomingPaymentData} incomingPartnerData={incomingPartnerData}/>
       </div>
      </Card.Body>
     </Card>
    </Col>
   </Row>
  </div>
 );
}

export default Table;
