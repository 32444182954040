import React, { useContext, useEffect, useState } from "react";
import { Button, Modal,Card } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import "./emailDetails.css";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import axios from "axios";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Gettemp from "./GetBlog";
import { toast } from 'react-toastify';
function EmailDetails() {
  const [allTemplate, setAllTemplate] = useState([]);
  const [changeState, setChangeState] = useState(false);
  const [emailTemp, setEmailTemp] = useState({
    category: "",
    // description: '',
    title: "",
  });

  let editorState = EditorState.createEmpty();
  const [description, setDescription] = useState(editorState);
  const onEditorStateChange = (editorState) => {
   console.log(editorState);  
    setDescription(editorState);
  };

  const onChange = (e) => {
    
    console.log("gg");
    setEmailTemp((perk) => {
      return { ...perk, [e.target.name]: e.target.value };
    });
  };
  const onChange1 = () => {

    let ind= document.getElementById('ind').value
    let temp =[];
    
    allTemplate.map((item) => {
       if (item.category == ind) {
         temp = item;
       }
     });
    
     setEmailTemp({
       category: temp.category,
       description:  temp.description ,
       title: temp.title,
     });
    console.log("gg");
  };
  
  const createEmailTemplate = () => {
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/email/email-template`;
    const data = {
      title: emailTemp.title,
      description: emailTemp.description.value,
      category: emailTemp.category,
    };
    axios
      .post(url, data, {
        headers: {
          "admin-token": localStorage.getItem("admin-token"),
        },
      })
      .then(({ data }) => {
        setEmailTemp({
          category: "",
          description: "",
          title: "",
        });
      })
      .catch((error) => {
        console.log(error);
      });
      toast.success('Updated successfully', {
        position: 'top-center',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      window.location.reload()  
  };

  console.log(allTemplate);
  const allEmailTemplate = () => {
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/email/get-all-template`;
    axios
      .get(url, {
        headers: {
          "admin-token": localStorage.getItem("admin-token"),
        },
      })
      .then(({ data }) => {
        setAllTemplate(data);
       
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    
    
    // onEditorStateChange()

    allEmailTemplate();
  }, [ ]);
 
  console.log(emailTemp);
console.log(allTemplate);
  return (
    // <div className="mt-6">


    //   <Card>
    //     <Card.Header>
    //       <h2>Configure Email</h2>
    //     </Card.Header>
    //     <Card.Body>
    //       <div className=" row mb-4">
    //         <label htmlFor="inputName" className="col-md-1 form-label">
    //           Email
    //         </label>

    //         <div className="col-md-5">
    //           <select
    //             name="category"
    //             id="ind"
    //             className="form-select"
    //             onChange={onChange}
    //             onClick={onChange1}
                
    //           >
    //             <option>Select Category</option>
    //             <option value="signUpMail">Sign up Confirmation</option>
    //             <option value="resetPassword">Reset Password</option>
    //             <option value="Mortgage Broker">
    //               Mortgage Broker will contact you
    //             </option>
    //             <option value="Depreciation Schedule">
    //               Deprication Scheduled
    //             </option>
    //             <option value="Conveyancer">Solicitor / Conveyencer</option>
    //             <option value="Building Inspection">
    //               Building / Pest Inspection
    //             </option>

    //             <option value="Property Manager">Property Manager</option>
    //             <option value="Accountant">Engage Accountant</option>
    //           </select>
    //         </div>
    //       </div>
    //       <div className="emailDetailTitle row mb-4">
    //         <label htmlFor="inputName" className="col-md-1 form-label">
    //           Title
    //         </label>
    //         <input
    //           className="col-md-7"
    //           onChange={onChange}
    //           value={emailTemp ? emailTemp.title : ''}
    //           type="text"
    //           name="title"
    //           style={{ boxShadow: "0 0 5px 2px rgb(207, 204, 204)" }}
    //         />
    //       </div>
    //       {/* <input type='text' value={emailTemp.description}></input> */}
    //       <Accordion>
    //   <Accordion.Item>
  
    //     <div dangerouslySetInnerHTML={{__html: emailTemp.description == "[object HTMLTextAreaElement]" ? "" : emailTemp.description }} />
 
    
        
    //   </Accordion.Item>
    //   </Accordion>
    //       <div className="emailDetailbody">
    //         <label className="form-label mt-5">Body</label>
    //         <Editor
    //           editorState={description}
    //           toolbarClassName="toolbarClassName"
    //           wrapperClassName="wrapperClassName"
    //           editorClassName="editorClassName"
    //           value={emailTemp.description }
    //           onEditorStateChange={onEditorStateChange}
           

         

    //         />
    //         <textarea
    //           style={{ display: "none" }}
    //           disabled
    //           ref={(val) => (emailTemp.description = val)}
    //           value={draftToHtml(convertToRaw(description.getCurrentContent()))}
    //         />
    //       </div>
    //     </Card.Body>
    //     <div>
    //       <div style={{ fontSize: "1.3rem", marginBottom: "1rem" }}>
    //         Please select all category to edit your template{" "}
    //       </div>
    //       <div style={{ fontSize: "1rem", marginBottom: "1rem" }}>
    //         <div>{"{customerName}"}</div>
    //         <div>{"{requestType}"}</div>
    //         <div>{"{taskid}"}</div>
    //         <div>{"{customerId}"}</div>
    //         <div>{"{customerEmailId}"}</div>
    //         <div>{"{customerPhoneNumber}"}</div>
    //         <div>{"{purchaseType}"}</div>
    //         <div>{"{propertyType}"}</div>
    //         <div>{"{propertyAddress}"}</div>
    //         <div>{"{propertyState}"}</div>
    //         <div>{"{instructionFromCustomer}"}</div>
    //       </div>
    //     </div>
    //     <Button onClick={createEmailTemplate}>Submit</Button>
    //   </Card>
     <div className="mt-6">
      <Gettemp data ={allTemplate} setChangeState={setChangeState} changeState={changeState} />
    </div>
  );
}

export default EmailDetails;
