import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import { Button } from 'react-bootstrap';
import 'react-data-table-component-extensions/dist/index.css';
import { Link } from 'react-router-dom';
import axios from 'axios';

export const DataTables = ({ usersData , filterData, sendData }) => {
  
  // console.log(filterData);
  // let i = filterData == undefined ? 0 : filterData.length;
  const [data, setData] = React.useState( usersData);
  const [linkData, setLinkData] = useState([]);
  let todayDate = new Date();
  let newDate =
    todayDate.getDate() +
    '/' +
    (todayDate.getMonth() + 1) +
    '/' +
    todayDate.getFullYear();

  useEffect(() => setData(usersData), [usersData]);

  const logClick = (dataId) => {
    let ndata = usersData.filter((ele) => {
      if (ele.task_id == dataId) {
        return ele;
      }
    });
    let freshData = linkData.push(ndata);
    setLinkData(freshData);
  };

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  const handleDelete = (value) => {
    let deleteData = usersData.filter((element) => {
      return element.task_id !== value;
    });
    setData(deleteData);
    let requestDelete = data.filter((element) => {
              return element.task_id == value;
    });
console.log(requestDelete)
    const url1 = `${process.env.REACT_APP_BACKEND_DATA}/api/application/delete-dashboard-tasks`;

    const data1 = {
      _id: requestDelete[0]._id,
      task_id: requestDelete[0].task_id,
    };
console.log(data1);
    axios
      .put(url1, data1, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'admin-token': localStorage.getItem('admin-token'),
        },
      })
      .then(function (response) {
        sendData(filterData+1)
      });

  };

  function downloadCSV(array) {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }

  const Export = ({ onExport }) => (
   <Button type="button" className="ml-5" onClick={()=>tableToCSV()}>  Export</Button>
  );
 

  const columns = [
    {
      name: 'Application ID',
      selector: (row) => [row.application_id || ''],
      sortable: true,
    },
    {
      name: 'Task ID',
      selector: (row) => [row.task_id || ''],
      sortable: true,
    },
    {
      name: ' Task Name',
      selector: (row) => [row.type],
      sortable: true,
    },
    {
      name: 'Customer Name',
      selector: (row) => [row.name],
      sortable: true,
    },
    {
      name: 'Customer ID',
      selector: (row) => [row.user || ''],
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => [row.progress],
      sortable: true,
    },
    {
      name: ' Assigned To',
      selector: (row) => [row.assignTo ],
      sortable: true,
    },
    {
      name: 'Assigned Date',
      selector: (row) => (
        <div>
          {row.assignDate === undefined
            ? 'N/A'
            : row.assignDate.split('-').reverse().join('/')}
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Creation Date',
      selector: (row) =>    new Date(row.createdDate).toLocaleDateString('en-IN'),
      sortType:(a,b)=>{
        return new Date(a.createdDate).toLocaleDateString('en-IN') - new Date(b.createdDate).toLocaleDateString('en-IN')
      },
      sortable: true,
    },
    {
      name: 'Completion Date',
      selector: (row) =>row.type =="Mortgage Broker" ? row.completeDate === undefined ?"N/A":row.completeDate : row.type =="Building Inspection" ? row.completeDate === undefined ?"N/A" : row.completeDate : row.type =="Depreciation Schedule" ? row.completeDate === undefined ?"N/A":row.completeDate : row.type =="Conveyancer" ?row.completeDate === undefined ?"N/A":row.completeDate : row.completeDate === undefined ?"N/A": row.completeDate,
      sortType:(a,b)=>{
        return new Date(a.createdDate).toLocaleDateString('en-IN') - new Date(b.createdDate).toLocaleDateString('en-IN')
      },
      sortable: true,
    },
    {
      name: 'Action',
      selector: (row) => [
        <Link
          onClick={() => logClick(row.task_id)}
          to={
            row.type === 'Mortgage Broker'
              ? '/admin/mortgage-broker'
              : row.type === 'Solicitor'
              ? '/admin/solicitor'
              : row.type === 'Conveyancer'
              ? '/admin/conveyancer'
              : row.type === 'Building Inspection'
              ? '/admin/building-inspection'
              : row.type === 'Pest Inspection'
              ? '/admin/pest-inspection'
              : row.type === 'Building and Pest Inspection'
              ? '/admin/combined-building-pest-inspection'
              : row.type === 'Property Manager'
              ? '/admin/property-manager'
              : row.type === 'Depreciation Schedule'
              ? '/admin/depreciation-schedule'
              : row.type === 'Accountant'
              ?'/admin/accountant'
              : row.type === 'Buyers Advocate'
              ?'/admin/Buyers-Advocate'
              : ''
          }
          state={{ linkData }}
        >
          <i
            className="zmdi zmdi-edit"
            style={{
              fontSize: '20px',
              cursor: 'pointer',
            }}
          />
        {/* {console.log(59659599 ,row)} */}
        </Link>,
        <i
          className="zmdi zmdi-delete"
          style={{
            fontSize: '20px',
            cursor: 'pointer',
            color: '#b73232',
            margin: '0 0 0 30px',
          }}
          onClick={() => handleDelete(row.task_id)}
        />,
      ],
    },
  ];
  const tableDatas = {
    columns,
    usersData,
  };
  const actionsMemo = React.useMemo( () => <Button type="button" className="ml-5" onClick={()=>tableToCSV()}>  Export</Button>,
  // <Export onExport={() => downloadCSV(data)} />,
  []
);
function tableToCSV() {
 
  // Variable to store the final csv data
  var csv_data = [];

  // Get each row data
  var rows = document.getElementsByTagName('tr');
  console.log(rows);
  for (var i = 0; i < rows.length; i++) {

      // Get each column data
      var cols = rows[i].querySelectorAll('td,th');

      // Stores each csv row data
      var csvrow = [];
      for (var j = 0; j < cols.length; j++) {

          // Get the text data of each cell
          // of a row and push it to csvrow
          csvrow.push(cols[j].innerHTML);
      }

      // Combine each column value with comma
      csv_data.push(csvrow.join(","));
  }

  // Combine each row data with new line character
  csv_data = csv_data.join('\n');

  // Call this function to download csv file 
  downloadCSVFile(csv_data);

}

function downloadCSVFile(csv_data) {

  // Create CSV file object and feed
  // our csv_data into it
  var CSVFile = new Blob([csv_data], {
      type: "text/csv"
  });

  // Create to temporary link to initiate
  // download process
  var temp_link = document.createElement('a');

  // Download csv file
  temp_link.download = "Dashboard.csv";
  var url = window.URL.createObjectURL(CSVFile);
  temp_link.href = url;

  // This link should not be displayed
  temp_link.style.display = "none";
  document.body.appendChild(temp_link);

  // Automatically click the link to
  // trigger download
  temp_link.click();
  document.body.removeChild(temp_link);
}
console.log(usersData,8888888);
  return (
    <>
    <table className='d-none' border="1" cellspacing="0" cellpadding="10">
			<tr>
				<th>Application ID</th>
				<th>Task ID</th>
				<th>Task Name</th>
				<th>Customer Name</th>
				<th>Customer ID</th>
				<th>Status </th>
				<th>Assigned Date</th>
				<th>Assigned To</th>
				<th>Creation Date</th>
				<th>Completion Date</th>
				
			</tr>
      {usersData.map((row)=>{
// console.log(row);
      return (
         <tr>
				<td>{row.application_id}</td>
				<td>{row.task_id}</td>
				<td>{row.type}</td>
				<td>{row.name}</td>
				<td>{row.user}</td>
				<td>{row.progress}</td>
				<td>{row.assignTo}</td>
				<td>{row.assignDate === undefined
            ? 'N/A'
            : row.assignDate.split('-').reverse().join('/')}</td>
				<td>{ new Date(row.createdDate).toLocaleDateString('en-IN')}</td>

				<td>

       {row.type =="Mortgage Broker" ? row.completeDate === undefined ?"N/A":row.completeDate : row.type =="Building Inspection" ? row.completeDate === undefined ?"N/A" : row.completeDate : row.type =="Depreciation Schedule" ? row.completeDate === undefined ?"N/A":row.completeDate : row.type =="Conveyancer" ?row.completeDate === undefined ?"N/A":row.completeDate : row.completeDate === undefined ?"N/A": row.completeDate}

        </td>

          {/* {row.type =="Mortgage Broker" ? row.completeDate === undefined ?"N/A":row.completeDate : row.type =="Building Inspection" ? row.completeDate === undefined ?"N/A" : row.completeDate : row.type =="Depreciation Schedule" ? row.completeDate === undefined ?"N/A":row.completeDate : row.type =="Conveyancer" ?row.completeDate === undefined ?"N/A":row.completeDate : row.completeDate === undefined ?"N/A": row.completeDate}</td> */}
			</tr>)
      })}
			
		</table>
      {/* <button onClick={()=> window.location.reload()} className="btn btn-primary btn-icon text-white mt-4"  style={{ margin: '1rem auto 0 auto', padding: '.5rem 1rem', display: 'inline-flex', float: 'right',}} type="reset">Refresh{'   '}</button> */}
    {/* <DataTableExtensions {...tableDatas}> */}
    
      <DataTable
        actions={actionsMemo }
      
        columns={columns}
        data={usersData}
        pagination
        highlightOnHover
        dense
        responsive
      />
    {/* </DataTableExtensions> */}
        </>
  );
};
