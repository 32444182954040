import React, { useContext } from "react";

import userContext from "../UserContext/UserContext";
import { Button, Card } from "react-bootstrap";

const TypeOfProperty = (props) => {
  const context = useContext(userContext);
  const { propertType } = context;

  return (
    <>
      <div className="d-flex flex-column pt-4 pb-2">
        <Card.Header style={{ margin: "auto" }}>
          <h3 className="text-center">What type of property are you buying?</h3>
        </Card.Header>
        <Card.Body>
          <Button
            className="mb-1 mt-3 col-md-7 block "
            style={{ margin: "auto" }}
            onClick={() => {
              props.changeState(1);
              propertType("Non Strata property");
            }}
          >
            Non Strata property
          </Button>
          <Button
            className="mb-1 mt-3 col-md-7 block "
            style={{ margin: "auto" }}
            onClick={() => {
              props.changeState(1);
              propertType("Strata Property");
            }}
          >
            Strata Property
          </Button>
          <Button
            className="mb-1 mt-3 col-md-7 block "
            style={{ margin: "auto" }}
            onClick={() => {
              props.changeState(1);
              propertType("New Build");
            }}
          >
            New Build
          </Button>
        </Card.Body>
      </div>
    </>
  );
};

export default TypeOfProperty;
