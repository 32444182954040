export const MENUITEMS = [
  {
    menutitle: "MAIN",
    Items: [
      {
        path: `/admin/dashboard`,
        type: "link",
        active: true,
        title: "Task Dashboard",
        icon: "home",
      },
      {
        path: `/admin/partner-details`,
        type: "link",
        active: false,
        title: "Partner Details",
        icon: "users",
      },
      {
        path: `/admin/fee-details`,
        type: "link",
        active: false,
        title: "Fee Details",
        icon: "dollar-sign",
      },
      {
        path: `/admin/customer-details`,
        type: "link",
        active: false,
        title: "Customer Details",
        icon: "layers",
      },
      {
        path: `/admin/payment-details`,
        type: "link",
        active: false,
        title: "Payment Details",
        icon: "credit-card",
      },
      {
        path: `/admin/email-details`,
        type: "link",
        active: false,
        title: "Email Details",
        icon: "mail",
      },
      {
        path: `/admin/reports`,
        type: "link",
        active: false,
        title: "Reports",
        icon: "paperclip",
      },
      {
        path: `/admin/blog`,
        type: "link",
        active: false,
        title: "Blogs",
        icon: "bold",
      },
      {
        path: `/admin/promocode`,
        type: "link",
        active: false,
        title: "Promo Code",
        icon: "box",
      },
      {
        path: `/admin/add-testimonials`,
        type: "link",
        active: false,
        title: "Testimonials",
        icon: "box",
      },
      {
        path: `/admin/accounting-requests`,
        type: "link",
        active: false,
        title: "Accounting Requests",
        icon: "box",
      },
    ],
  },
];
