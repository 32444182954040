import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Footer.css";
function Footer() {
  const navigate = useNavigate();

  return (
    <div className="footer-page">
      <div className="footer">
        <div className="footer-container">
          <ul style={{ listStyleType: "none" }}>
            <Link to="/contact-us" style={{ color: "white" }}>
              <li>
                <span
                  style={{
                    borderLeft: "6px solid #008eb5",
                    margin: "0 15px",
                    fontWeight: "500",
                  }}
                />
                <span>Contact Us</span>
              </li>
            </Link>
            <Link to="/about-us" style={{ color: "white" }}>
              <li>
                <span
                  style={{
                    borderLeft: "6px solid #008eb5",
                    margin: "0 15px",
                    fontWeight: "500",
                  }}
                />
                <span>About Us</span>
              </li>
            </Link>
            <Link to="/F-A-Q" style={{ color: "white" }}>
              <li>
                <span
                  style={{
                    borderLeft: "6px solid #008eb5",
                    margin: "0 15px",
                    fontWeight: "500",
                  }}
                />
                <span>FAQs</span>
              </li>
            </Link>
          </ul>
        </div>
        <div className="footer-container">
          <ul style={{ listStyleType: "none" }}>
            <li onClick={() => navigate("/how-it-works")}>
              <span
                style={{
                  borderLeft: "6px solid #008eb5",
                  margin: "0 15px",
                  fontWeight: "500",
                }}
              />
              <span>How It Works</span>
            </li>
            <li onClick={() => navigate("/terms-and-conditions")}>
              <span
                style={{
                  borderLeft: "6px solid #008eb5",
                  margin: "0 15px",
                  fontWeight: "500",
                }}
              />
              <span>Term of Use</span>
            </li>
            <li onClick={() => navigate("/privacy-policy")}>
              <span
                style={{
                  borderLeft: "6px solid #008eb5",
                  margin: "0 15px",
                  fontWeight: "500",
                }}
              />
              <span>Privacy Policy</span>
            </li>
          </ul>
        </div>
        <div className="footer-container">
          <ul style={{ listStyleType: "none" }}>
            <li 
            // onClick={() => navigate("/need-help")}
            >
              <span
                style={{
                  borderLeft: "6px solid #008eb5",
                  margin: "0 15px",
                  fontWeight: "500",
                  color: "white"
                }}
              />
              <span ><a style={{color:'white'}} href="tel:0431943210">Call: 04319 43210</a></span>
            </li>
            <li>
              <span
                style={{
                  borderLeft: "6px solid #008eb5",
                  margin: "0 15px",
                  fontWeight: "500",
                }}
              />
          
              <a href="mailto:hello@justnests.com.au"> <span style={{color:'white'}}>Email: hello@justnests.com.au</span></a>
            </li>
            <li className="footer-social-icons">
             <a target="_blank" href="https://www.facebook.com/justnests/"><i className="icon-social-facebook" /></a> 
             <a target="_blank" href="https://www.instagram.com/justnests/"><i className="icon-social-instagram" /></a> 
            </li>
          </ul>
        </div>
      </div>
      <div>
        <p>Copyright ©2022-23 Justnests. Designed By <a style={{color: 'white'}} href="https://bytelogicindia.com/" target="_blank">Bytelogic Technologies</a></p>
      </div>
    </div>
  );
}
export default React.memo(Footer);
