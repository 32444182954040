import React, { useState, useContext } from "react";
import { Card, Table } from "react-bootstrap";
import AdminContext from "../../../adminContext/AdminContext";
import EditModal from "./EditModal";

function FeeHistory({ incomingData, formInput }) {
  const [editData, setEditData] = useState();
  const [editPartnerState, seteditPartnerState] = useState();
  const dashboardContext = useContext(AdminContext);
  const { setHandleModal } = dashboardContext;

  let newList;
  let forEditList;
  function handleEdit(state) {
    newList = incomingData.filter((data) => {
      return data.feeDetailType === formInput.type;
    });
   
    seteditPartnerState(newList);
    let editList = incomingData
      .filter((data) => {
        return data.feeDetailType === formInput.type;
      })
      .map((value) => {
        forEditList = value.feeDetails.filter((details) => {
          return details.partnerState === state;
        });
        setEditData(forEditList);
      });
    setHandleModal(true);
  }

  return (
    <div>
      <Card
        className="pb-8 my-5"
        style={{ display: formInput.type === "Solicitor" ? "block" : "none" }}
      >
        <Card.Header>
          <h2>Fee Entry for Solicitor</h2>
        </Card.Header>
        {incomingData.map((data) => {
          if (data.feeDetailType === "Solicitor") {
            return data.feeDetails.map((details, index) => {
              return (
                <Card.Body key={index}>
                  <button
                    className="border px-2 py-0"
                    style={{
                      position: "relative",
                      left: "90%",
                      color: "#008eb5",
                      fontSize: "18px",
                    }}
                    onClick={() => handleEdit(details.partnerState)}
                  >
                    Edit
                  </button>
                  <div className="d-flex">
                    <div style={{ width: "150px", margin: "0 2rem" }}>
                      State :
                    </div>
                    <div style={{ fontWeight: "bold" }}>
                      {details.partnerState}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div style={{ width: "150px", margin: "0 2rem" }}>
                      Full Fee :
                    </div>
                    <div style={{ fontWeight: "bold" }}>{details.fullFee}</div>
                  </div>
                  <div className="d-flex">
                    <div style={{ width: "150px", margin: "0 2rem" }}>
                      Upfront Fee :
                    </div>
                    <div style={{ fontWeight: "bold" }}>
                      {details.upfrontFee}
                    </div>
                  </div>
                  <hr></hr>
                </Card.Body>
              );
            });
          }
        })}
      </Card>
      <Card
        className="pb-8 my-5"
        style={{ display: formInput.type === "Conveyancer" ? "block" : "none" }}
      >
        <Card.Header>
          <h2>Fee Entry for Conveyancer</h2>
        </Card.Header>
        {incomingData.map((data) => {
          if (data.feeDetailType === "Conveyancer") {
            return data.feeDetails.map((details, index) => {
              return (
                <Card.Body key={index}>
                  <button
                    className="border px-2 py-0"
                    style={{
                      position: "relative",
                      left: "90%",
                      color: "#008eb5",
                      fontSize: "18px",
                    }}
                    onClick={() => handleEdit(details.partnerState)}
                  >
                    Edit
                  </button>
                  <div className="d-flex">
                    <div style={{ width: "150px", margin: "0 2rem" }}>
                      State :
                    </div>
                    <div style={{ fontWeight: "bold" }}>
                      {details.partnerState}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div style={{ width: "150px", margin: "0 2rem" }}>
                      Full Fee :
                    </div>
                    <div style={{ fontWeight: "bold" }}>{details.fullFee}</div>
                  </div>
                  <div className="d-flex">
                    <div style={{ width: "150px", margin: "0 2rem" }}>
                      Upfront Fee :
                    </div>
                    <div style={{ fontWeight: "bold" }}>
                      {details.upfrontFee}
                    </div>
                  </div>
                  <hr></hr>
                </Card.Body>
              );
            });
          }
        })}
      </Card>
      <Card
        style={{
          display: formInput.type === "Building Inspection" ? "block" : "none",
        }}
      >
        <Card.Header>
          <h2>Fee Entry for Building and Pest Inspection</h2>
        </Card.Header>
        {incomingData.map((data) => {
          if (data.feeDetailType === "Building Inspection") {
            return data.feeDetails.map((data, index) => {
              return (
                <Card.Body key={index} className="pb-8 mt-5">
                  <button
                    className="border px-2 py-0"
                    style={{
                      position: "relative",
                      left: "90%",
                      color: "#008eb5",
                      fontSize: "18px",
                    }}
                    onClick={() => handleEdit(data.partnerState)}
                  >
                    Edit
                  </button>
                  <div className="d-flex my-2">
                    <div style={{ width: "70px" }}>State :</div>
                    <div>{data.partnerState}</div>
                  </div>
                  <Table className="table-responsive">
                    <thead>
                      <tr>
                        <th></th>
                        <th className="">Building Inspection</th>
                        <th className="">Pest Inspection</th>
                        <th className="">
                          Combined Building & Pest Inspection{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-center border">
                        <td>1 Bedroom --</td>
                        <td>{data.oneB}</td>
                        <td>{data.oneP}</td>
                        <td>{data.oneC} </td>
                      </tr>
                      <tr className="text-center border">
                        <td>2 Bedroom --</td>
                        <td>{data.twoB}</td>
                        <td>{data.twoP}</td>
                        <td>{data.twoC}</td>
                      </tr>
                      <tr className="text-center border">
                        <td>3 Bedroom --</td>
                        <td>{data.threeB}</td>
                        <td>{data.threeP}</td>
                        <td>{data.threeC}</td>
                      </tr>
                      <tr className="text-center border">
                        <td>4 Bedroom --</td>
                        <td>{data.fourB}</td>
                        <td>{data.fourP}</td>
                        <td>{data.fourC} </td>
                      </tr>
                      <tr className="text-center border">
                        <td>4+ Bedroom --</td>
                        <td>{data.plusFourB}</td>
                        <td>{data.plusFourP}</td>
                        <td>{data.plusFourC}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              );
            });
          }
        })}
      </Card>
      <Card
        style={{
          display:
            formInput.type === "Depreciation Schedule" ? "block" : "none",
        }}
      >
        <Card.Header>
          <h2>Fee Entry for Depreciation Schedule</h2>
        </Card.Header>
        {incomingData.map((data, index) => {
          if (data.feeDetailType === "Depreciation Schedule") {
            return (
              <Card.Body className="pb-8" key={index}>
                <button
                  className="border px-2 py-0"
                  style={{
                    position: "relative",
                    left: "90%",
                    color: "#008eb5",
                    fontSize: "18px",
                  }}
                  onClick={() => handleEdit(data.partnerState)}
                >
                  Edit
                </button>
                <div className="d-flex ">
                  <div style={{ width: "150px", margin: "0 2rem" }}>Fee :</div>
                  <div style={{ fontWeight: "bold" }}>{data.fee}</div>
                </div>
                <hr />
              </Card.Body>
            );
          }
        })}
      </Card>
      <EditModal
        handleEdit={handleEdit}
        editData={editData}
        editPartnerState={editPartnerState}
        incomingFormValue={formInput}
      />
    </div>
  );
}

export default FeeHistory;
