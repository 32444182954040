import React, { useState } from 'react';
import { Button, Card, Row } from 'react-bootstrap';

// for Solicitor, Conveyancer and property manager
function CategoryCard1({ formInput, importFormData }) {
  const initialValue = { partnerState: '', fullFee: '', upfrontFee: '' };
  const [formValue, setFormValue] = useState(initialValue);
  const [listInput, setListInput] = useState([]);
  const handleChange = (e) => {
    setFormValue({ ...formValue, companyName: formInput.companyName });
    setFormValue((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });
  };

  function handleRemove(state) {
    let newList = listInput.filter((data) => {
      return data.partnerState !== state;
    });
    setListInput(newList);
  }

  function handleAdd(e) {
    e.preventDefault();
    let array1 = listInput.concat(formValue);
    importFormData(array1);
    setListInput(array1);
    setFormValue(initialValue);
  }

  return (
    <div>
      <Card className="pb-7 my-5">
        <Card.Body>
          <form onSubmit={handleAdd}>
            <Row>
              <div className="col-lg-6 col-md-12">
                <div className="form-horizontal">
                  <div className=" row mb-4">
                    <label className="col-md-3 form-label">State</label>
                    <div className="col-md-9">
                      <select
                        className="form-select"
                        name="partnerState"
                        onChange={handleChange}
                        value={formValue.partnerState}
                      >
                        <option value="">Select State</option>
                        <option
                          className={formInput.checkbox1 ? '' : 'd-none'}
                          value="NSW"
                        >
                          NSW
                        </option>
                        <option
                          className={formInput.checkbox2 ? '' : 'd-none'}
                          value="VIC"
                        >
                          VIC
                        </option>
                        <option
                          className={formInput.checkbox3 ? '' : 'd-none'}
                          value="QLD"
                        >
                          QLD
                        </option>
                        <option
                          className={formInput.checkbox4 ? '' : 'd-none'}
                          value="SA"
                        >
                          SA
                        </option>
                        <option
                          className={formInput.checkbox5 ? '' : 'd-none'}
                          value="WA"
                        >
                          WA
                        </option>
                        <option
                          className={formInput.checkbox6 ? '' : 'd-none'}
                          value="TAS"
                        >
                          TAS
                        </option>
                        <option
                          className={formInput.checkbox7 ? '' : 'd-none'}
                          value="NT"
                        >
                          NT
                        </option>
                        <option
                          className={formInput.checkbox9 ? '' : 'd-none'}
                          value="ACT"
                        >
                          ACT
                        </option>
                        <option
                          className={formInput.checkbox8 ? '' : 'd-none'}
                          value="ALL"
                        >
                          ALL
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className=" row mb-4">
                    <label className="col-md-3 form-label">Full fee</label>
                    <div className="col-md-9">
                      <input
                        required
                        type="number"
                        className="form-control"
                        name="fullFee"
                        onChange={handleChange}
                        value={formValue.fullFee}
                      />
                    </div>
                  </div>
                  <div className=" row mb-4">
                    <label className="col-md-3 form-label">Up-front Fee</label>
                    <div className="col-md-9">
                      <input
                        required
                        type="number"
                        className="form-control"
                        name="upfrontFee"
                        onChange={handleChange}
                        value={formValue.upfrontFee}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Row>
            <div className="block text-center"></div>
            <Button className=" col-md-2 h-10 mx-2" type="submit">
              Add
            </Button>
          </form>
        </Card.Body>
      </Card>
      {listInput.map((data, index) => {
        return (
          <Card key={index} className="pb-8 my-5">
            <div>
              <button
                className="border px-2 py-0"
                style={{
                  position: 'relative',
                  left: '90%',
                  color: '#e70505',
                  fontSize: '18px',
                }}
                onClick={() => handleRemove(data.partnerState)}
              >
                X
              </button>
              <div className=" d-flex">
                <div style={{ width: '150px', margin: '0 2rem' }}>State :</div>
                <div>{data.partnerState}</div>
              </div>
              <div className=" d-flex">
                <div style={{ width: '150px', margin: '0 2rem' }}>
                  Full Fee :
                </div>
                <div>{data.fullFee}</div>
              </div>
              <div className=" d-flex">
                <div style={{ width: '150px', margin: '0 2rem' }}>
                  Upfront Fee :
                </div>
                <div>{data.upfrontFee}</div>
              </div>
            </div>
          </Card>
        );
      })}
    </div>
  );
}

export default CategoryCard1;
