import React from "react";
import * as datatable from "./TableFormat";
import { Row,Card, Col} from "react-bootstrap";

function Table1({filterData, usersData}) {
 return (
  <div>
   <Row className=" row-sm">
    <Col lg={12}>
     <Card >
        <Card.Header><h3 className="card-title">Task History</h3></Card.Header>
      <Card.Body>
       <div className="table-responsive">
        <datatable.DataTables filterData={filterData} usersData={usersData}/>
       </div>
      </Card.Body>
     </Card>
    </Col>
   </Row>
  </div>
 );
}

export default Table1;
