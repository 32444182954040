import React, { useState, useEffect } from "react";
import Avtar1 from "../../../assets/images/homePage/avtar-1.png";
import Slider from "react-slick";
import Amit from "./testimonialspics/Amit.jpg";
import Yogita from "./testimonialspics/Yogita.jpg";
import Gajanan from "./testimonialspics/Gajanan.jpg";
import Laukik from "./testimonialspics/Laukik.jpg";
function Testimonial({ testimonials }) {
  console.log("testimonials", testimonials);
  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="home-bottom-testimonials-1">
      <h2 className="home-mid-heading"> Testimonials </h2>
      <span className="service-span"></span>
      <p className="testimonial-heading-description">
        This is why our customers love us!
      </p>
      <div className="testimonial-slider px-3">
        <Slider {...settings1}>

          {
            testimonials?.map((elm)=>{
              return(
                <>
                <div className="home-slider-group">
            <img src={process.env.REACT_APP_BACKEND_DATA+"/"+elm.file} alt={Avtar1} />
            <h4>{elm.name}</h4>
            <p>
              {elm.description}
            </p>
            <div>
              {
                elm.stars == 1?
                <i
                  className="fa fa-star"
                  style={{ color: "#ebc705", margin: "0 2px" }}
                />:""
                
              }
              {
                elm.stars == 2?
                <>
                <i
                  className="fa fa-star"
                  style={{ color: "#ebc705", margin: "0 2px" }}
                  />
                <i
                  className="fa fa-star"
                  style={{ color: "#ebc705", margin: "0 2px" }}
                  />
                  </>
                
                :""
                
              }
              {
                elm.stars == 3?
                <>
                <i
                  className="fa fa-star"
                  style={{ color: "#ebc705", margin: "0 2px" }}
                  />
                <i
                  className="fa fa-star"
                  style={{ color: "#ebc705", margin: "0 2px" }}
                  />
                <i
                  className="fa fa-star"
                  style={{ color: "#ebc705", margin: "0 2px" }}
                  />
                  </>
                
                :""
                
              }
              {
                elm.stars == 4?
                <>
                <i
                  className="fa fa-star"
                  style={{ color: "#ebc705", margin: "0 2px" }}
                  />
                <i
                  className="fa fa-star"
                  style={{ color: "#ebc705", margin: "0 2px" }}
                  />
                <i
                  className="fa fa-star"
                  style={{ color: "#ebc705", margin: "0 2px" }}
                  />
                <i
                  className="fa fa-star"
                  style={{ color: "#ebc705", margin: "0 2px" }}
                  />
                  </>
                
                :""
                
              }
              {
                elm.stars == 5?
                <>
                <i
                  className="fa fa-star"
                  style={{ color: "#ebc705", margin: "0 2px" }}
                  />
                <i
                  className="fa fa-star"
                  style={{ color: "#ebc705", margin: "0 2px" }}
                  />
                <i
                  className="fa fa-star"
                  style={{ color: "#ebc705", margin: "0 2px" }}
                  />
                <i
                  className="fa fa-star"
                  style={{ color: "#ebc705", margin: "0 2px" }}
                  />
                <i
                  className="fa fa-star"
                  style={{ color: "#ebc705", margin: "0 2px" }}
                  />
                  </>
                
                :""
                
              }
              
            </div>
          </div>
                </>
              )
            })
          }
          {/* <div className="home-slider-group">
            <img src={Laukik} alt={Avtar1} />
            <h4>Laukik</h4>
            <p>
              Tushar was great with my tax return. Got more return than I
              expected! Recommending to all my friends. Thanks Tushar!
            </p>
            <div>
              <i
                className="fa fa-star"
                style={{ color: "#ebc705", margin: "0 2px" }}
              />
              <i
                className="fa fa-star"
                style={{ color: "#ebc705", margin: "0 2px" }}
              />
              <i
                className="fa fa-star"
                style={{ color: "#ebc705", margin: "0 2px" }}
              />
              <i
                className="fa fa-star"
                style={{ color: "#ebc705", margin: "0 2px" }}
              />
              <i
                className="fa fa-star"
                style={{ color: "#ebc705", margin: "0 2px" }}
              />
            </div>
          </div> */}
          {/* <div className="home-slider-group">
            <img src={Gajanan} alt={Avtar1} />
            <h4>Gajanan</h4>
            <p>
              I was referred by a friend and it's great. I used use a different
              accountant, through Justnests I have got faster and better return.
              Tushar is highly knowledgable. HIghly recommended.
            </p>
            <div>
              <i
                className="fa fa-star"
                style={{ color: "#ebc705", margin: "0 2px" }}
              />
              <i
                className="fa fa-star"
                style={{ color: "#ebc705", margin: "0 2px" }}
              />
              <i
                className="fa fa-star"
                style={{ color: "#ebc705", margin: "0 2px" }}
              />
              <i
                className="fa fa-star"
                style={{ color: "#ebc705", margin: "0 2px" }}
              />
            </div>
          </div>
          <div className="home-slider-group">
            <img src={Yogita} alt={Avtar1} />
            <h4>Yogita</h4>
            <p>
              I used Justnests services for my investment property. it was super
              simple, I got mortgage, conveyancer and depreciation schedule from
              their website. All were great and saved alot of my time. Would
              definitely use them for the next purchase.
            </p>
            <div>
              <i
                className="fa fa-star"
                style={{ color: "#ebc705", margin: "0 2px" }}
              />
              <i
                className="fa fa-star"
                style={{ color: "#ebc705", margin: "0 2px" }}
              />
              <i
                className="fa fa-star"
                style={{ color: "#ebc705", margin: "0 2px" }}
              />
              <i
                className="fa fa-star"
                style={{ color: "#ebc705", margin: "0 2px" }}
              />
            </div>
          </div> */}
          {/* <div className="home-slider-group">
            <img src={Amit} hight="100px" alt={Avtar1} />
            <h4>Amit</h4>
            <p>
              I have been getting my business and personal tax return done from
              Tushar from many years. It's been amazing to have a trustworthy
              and efficient accountant by my side. Recommended to all my friends
            </p>
            <div>
              <i
                className="fa fa-star"
                style={{ color: "#ebc705", margin: "0 2px" }}
              />
              <i
                className="fa fa-star"
                style={{ color: "#ebc705", margin: "0 2px" }}
              />
              <i
                className="fa fa-star"
                style={{ color: "#ebc705", margin: "0 2px" }}
              />
              <i
                className="fa fa-star"
                style={{ color: "#ebc705", margin: "0 2px" }}
              />
              <i
                className="fa fa-star"
                style={{ color: "#ebc705", margin: "0 2px" }}
              />
            </div>
          </div> */}
        </Slider>
      </div>
    </div>
  );
}

export default React.memo(Testimonial);
