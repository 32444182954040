import React from "react";
import { Card } from "react-bootstrap";
import Footer from "../../component/footer/Footer";
import "./faq.css";

const FAQ = () => {
  return (
    <div>
      <Card className="faq-main-div">
        <h1 className="faq-main-heading">Frequently Asked Questions</h1>
        <h2 className="faq-second-main-heading">
          Just Nests Pty Ltd (JustNests)
        </h2>
        <div className="faq-body">
          <div className="qaBody">
            <div className="faq-question">
              <span>Q.</span> What services can I get in JustNests?
            </div>
            <div className="faq-answer">
              <span>A.</span>Using JustNests platform you can receive one or
              more of the below services{" "}
              <ul>
                <li>
                  <span>1.</span> Mortgage Broker
                </li>
                <li>
                  <span>2.</span> Solicitor or Conveyancer
                </li>
                <li>
                  <span>3.</span> Building and Pest Inspector
                </li>
                <li>
                  <span>4.</span> Depreciation Scheduler
                </li>
                <li>
                  <span>5.</span> Property Manager
                </li>
                <li>
                  <span>6.</span> Accountant
                </li>
              </ul>
            </div>
          </div>
          <div className="qaBody">
            <div className="faq-question">
              <span>Q.</span> Do I have to get all the services of JustNests?
            </div>
            <div className="faq-answer">
              <span>A.</span>No, you can chose only the services you require
            </div>
          </div>
          <div className="qaBody">
            <div className="faq-question">
              <span>Q.</span> Is JustNests free to use?
            </div>
            <div className="faq-answer">
              <span>A.</span>Yes, you will not be charged to use JustNests
              platform.
            </div>
          </div>
          <div className="qaBody">
            <div className="faq-question">
              <span>Q.</span>How do I pay for the services?
            </div>
            <div className="faq-answer">
              <span>A.</span>You'll have to directly pay the service provider
              for the services . you use. Mortgage broker will not change for
              the services.
            </div>
          </div>
          <div className="qaBody">
            <div className="faq-question">
              <span>Q.</span>In what states does JustNests services?
            </div>
            <div className="faq-answer">
              <span>A.</span>JustNests experts provide services all over
              Australia
            </div>
          </div>
          <div className="qaBody">
            <div className="faq-question">
              <span>Q.</span>How will I get contacted by the service providers?
            </div>
            <div className="faq-answer">
              <span>A.</span> Your chosen service provider will contact you by
              email and/or phone
            </div>
          </div>
          <div className="qaBody">
            <div className="faq-question">
              <span>Q.</span>Why do I need to provide my Phone number?
            </div>
            <div className="faq-answer">
              <span>A.</span> This is to help the service provider you have
              chosen to contact . . you
            </div>
          </div>
          <div className="qaBody">
            <div className="faq-question">
              <span>Q.</span>Why do I need to provide property address?
            </div>
            <div className="faq-answer">
              <span>A.</span> This is to ensure the right service provider
              servicing the area is chosen to help you
            </div>
          </div>
          <div className="qaBody">
            <div className="faq-question">
              <span>Q.</span> How to see the progress of the services I have
              chosen
            </div>
            <div className="faq-answer">
              <span>A.</span> Visit the dashboard to see the progress of the
              task and if click on . the task you have chosen, you can see the
              details of the task
            </div>
          </div>
          <div className="qaBody">
            <div className="faq-question">
              <span>Q.</span> How do I cancel a task I have requested?
            </div>
            <div className="faq-answer">
              <span>A.</span> If the services provider has already contacted you
              then please inform your service provider to cancel the request. To
              update the status of the request, please contact JustNests support
              –{" "}
              <a href="https://hello@justnests.com.au" target="_blank">
                hello@justnests.com.au
              </a>
            </div>
          </div>
          <div className="qaBody">
            <div className="faq-question">
              <span>Q.</span> How do I request support for the services?
            </div>
            <div className="faq-answer">
              <span>A.</span> Please email{" "}
              <a href="https://hello@justnests.com.au" target="_blank">
                {" "}
                hello@justnests.com.au.
              </a>{" "}
              One of our friendly representative will contact you as soon as
              possible.
            </div>
          </div>
        </div>
      </Card>
      <Footer />
    </div>
  );
};

export default FAQ;
