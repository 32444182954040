import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Button } from "react-bootstrap";

export const DataTables = ({ incomingData }) => {
  const [data, setData] = React.useState(incomingData);

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

    const Export = ({ onExport }) => (
      <Button onClick={(e) => onExport(e.target.value)}>Export</Button>
    );
  const columns = [
    {
      name: "Task ID",
      selector: (row) => [row.task_id],
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (row) => [row.name],
      sortable: true,
    },
    {
      name: "Customer ID",
      selector: (row) => [row.user],
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => [row.progress],
      sortable: true,
    },
    {
      name: "Assigned Date",
      selector: (row) => (
        <div>
          {row.assignDate === undefined
            ? "N/A"
            : row.assignDate.split("-").reverse().join("/")}
        </div>
      ),
      sortable: true,
    },
    {
      name: "State",
      selector: (row) =>
        row.state2 !== "N/A"
          ? row.state2
          : row.state1 !== "N/A"
          ? row.state1
          : "",
      sortable: true,
    },
  ];
  const tableDatas = {
    columns,
    data,
  };
  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(data)} />,
    []
  );
  return (
    <DataTableExtensions {...tableDatas}>
      <DataTable
        actions={actionsMemo}
        columns={columns}
        data={data}
        pagination
        highlightOnHover
        responsive
        dense
      />
    </DataTableExtensions>
  );
};
