import React, { useState } from 'react';
import { Card, Table, Col, Button } from 'react-bootstrap';

function CategoryCard2({ formInput, importFormData }) {
  // console.log('llll', formInput);
  const initialValue = {
    partnerState: '',
    oneB: '',
    twoB: '',
    threeB: '',
    fourB: '',
    plusFourB: '',
    oneP: '',
    twoP: '',
    threeP: '',
    fourP: '',
    plusFourP: '',
    oneC: '',
    twoC: '',
    threeC: '',
    fourC: '',
    plusFourC: '',
  };
  const [formValue, setFormValue] = useState(initialValue);
  const [listInput, setListInput] = useState([]);

  const handleChange = (e) => {
    setFormValue({ ...formValue, companyName: formInput.companyName });
    setFormValue((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });
  };

  function handleAdd(e) {
    e.preventDefault();
    let array1 = [...listInput, formValue];
    importFormData(array1);
    setListInput(array1);
    setFormValue(initialValue);
  }

  function handleRemove(state) {
    let newList = listInput.filter((data) => {
      return data.partnerState !== state;
    });
    setListInput(newList);
  }

  const Datacard = [
    {
      ID: '1 Bedroom Fee',
      BUILDINGINSPECTION: (
        <input
          className="form-control"
          type="number"
          name="oneB"
          value={formValue.oneB}
          onChange={handleChange}
        />
      ),
      PESTINSPECTION: (
        <input
          className="form-control"
          type="number"
          name="oneP"
          value={formValue.oneP}
          onChange={handleChange}
        />
      ),
      COMBINED: (
        <input
          className="form-control"
          type="number"
          name="oneC"
          value={formValue.oneC}
          onChange={handleChange}
        />
      ),
    },
    {
      ID: '2 Bedroom Fee',
      BUILDINGINSPECTION: (
        <input
          className="form-control"
          name="twoB"
          value={formValue.twoB}
          type="number"
          onChange={handleChange}
        />
      ),
      PESTINSPECTION: (
        <input
          className="form-control"
          type="number"
          name="twoP"
          value={formValue.twoP}
          onChange={handleChange}
        />
      ),
      COMBINED: (
        <input
          className="form-control"
          type="number"
          name="twoC"
          value={formValue.twoC}
          onChange={handleChange}
        />
      ),
    },
    {
      ID: '3 Bedroom Fee',
      BUILDINGINSPECTION: (
        <input
          className="form-control"
          name="threeB"
          value={formValue.threeB}
          type="number"
          onChange={handleChange}
        />
      ),
      PESTINSPECTION: (
        <input
          className="form-control"
          type="number"
          name="threeP"
          value={formValue.threeP}
          onChange={handleChange}
        />
      ),
      COMBINED: (
        <input
          className="form-control"
          type="number"
          name="threeC"
          value={formValue.threeC}
          onChange={handleChange}
        />
      ),
    },
    {
      ID: '4 Bedroom Fee',
      BUILDINGINSPECTION: (
        <input
          className="form-control"
          name="fourB"
          type="number"
          value={formValue.fourB}
          onChange={handleChange}
        />
      ),
      PESTINSPECTION: (
        <input
          className="form-control"
          type="number"
          name="fourP"
          value={formValue.fourP}
          onChange={handleChange}
        />
      ),
      COMBINED: (
        <input
          className="form-control"
          type="number"
          name="fourC"
          value={formValue.fourC}
          onChange={handleChange}
        />
      ),
    },
    {
      ID: '4+ Bedroom Fee',
      BUILDINGINSPECTION: (
        <input
          className="form-control"
          name="plusFourB"
          value={formValue.plusFourB}
          type="number"
          onChange={handleChange}
        />
      ),
      PESTINSPECTION: (
        <input
          className="form-control"
          type="number"
          name="plusFourP"
          value={formValue.plusFourP}
          onChange={handleChange}
        />
      ),
      COMBINED: (
        <input
          className="form-control"
          type="number"
          name="plusFourC"
          value={formValue.plusFourC}
          onChange={handleChange}
        />
      ),
    },
  ];

  return (
    <div>
      <Card className="pb-6 mt-5">
        <Card.Body>
          <form onSubmit={handleAdd}>
            <div>
              <div className=" col-md-12">
                <div className="form-horizontal">
                  <div className=" row mb-4">
                    <label className="col-md-3 form-label">State</label>
                    <div className="col-md-9">
                      <select
                        className="form-select"
                        name="partnerState"
                        onChange={handleChange}
                        value={formValue.partnerState}
                      >
                        <option value="">Select State</option>
                        <option
                          className={
                            formInput.checkbox1 || formInput.checkbox8
                              ? ''
                              : 'd-none'
                          }
                          value="NSW"
                        >
                          NSW
                        </option>
                        <option
                          className={
                            formInput.checkbox2 || formInput.checkbox8
                              ? ''
                              : 'd-none'
                          }
                          value="VIC"
                        >
                          VIC
                        </option>
                        <option
                          className={
                            formInput.checkbox3 || formInput.checkbox8
                              ? ''
                              : 'd-none'
                          }
                          value="QLD"
                        >
                          QLD
                        </option>
                        <option
                          className={
                            formInput.checkbox4 || formInput.checkbox8
                              ? ''
                              : 'd-none'
                          }
                          value="SA"
                        >
                          SA
                        </option>
                        <option
                          className={
                            formInput.checkbox5 || formInput.checkbox8
                              ? ''
                              : 'd-none'
                          }
                          value="WA"
                        >
                          WA
                        </option>
                        <option
                          className={
                            formInput.checkbox6 || formInput.checkbox8
                              ? ''
                              : 'd-none'
                          }
                          value="TAS"
                        >
                          TAS
                        </option>
                        <option
                          className={
                            formInput.checkbox7 || formInput.checkbox8
                              ? ''
                              : 'd-none'
                          }
                          value="NT"
                        >
                          NT
                        </option>
                        <option
                          className={
                            formInput.checkbox8 || formInput.checkbox9
                              ? ''
                              : 'd-none'
                          }
                          value="ACT"
                        >
                          ACT
                        </option>
                        {/* <option
                          className={formInput.checkbox8 ? '' : 'd-none'}
                          value="ALL"
                        >
                          ALL
                        </option> */}
                      </select>
                    </div>
                  </div>
                  <div>
                    <Col
                      style={{
                        display: `${
                          formValue.partnerState === '' ? 'none' : 'block'
                        }`,
                      }}
                    >
                      <div style={{ margin: '2rem' }}>
                        <h3 className="card-title mb-1">
                          Fee Charge For Every State
                        </h3>
                      </div>
                      <Table className="text-center border table-responsive col-md-10">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Building Inspection</th>
                            <th>Pest Inspection</th>
                            <th>Combined Building & Pest Inspection </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Datacard.map((list, index) => (
                            <tr key={index}>
                              <td>{list.ID}</td>
                              <td>{list.BUILDINGINSPECTION}</td>
                              <td>{list.PESTINSPECTION} </td>
                              <td>{list.COMBINED}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <Button type="submit" className=" col-md-2 h-10 block">
                        Add
                      </Button>
                    </Col>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Card.Body>
      </Card>
      {listInput.map((data, index) => {
        return (
          <Card className="pb-8 mt-5" key={index}>
            <button
              className="border px-2 py-0 w-6"
              style={{
                position: 'relative',
                left: '90%',
                color: '#e70505',
                fontSize: '18px',
              }}
              onClick={() => handleRemove(data.partnerState)}
            >
              X
            </button>
            <div key={index}>
              <div className="d-flex my-2">
                <div style={{ width: '70px' }}>State :</div>
                <div>{data.partnerState}</div>
              </div>
              <Table className="table-responsive">
                <thead>
                  <tr>
                    <th></th>
                    <th className="">Building Inspection</th>
                    <th className="">Pest Inspection</th>
                    <th className="">Combined Building & Pest Inspection </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-center border">
                    <td>1 Bedroom --</td>
                    <td>{data.oneB}</td>
                    <td>{data.oneP}</td>
                    <td>{data.oneC} </td>
                  </tr>
                  <tr className="text-center border">
                    <td>2 Bedroom --</td>
                    <td>{data.twoB}</td>
                    <td>{data.twoP}</td>
                    <td>{data.twoC}</td>
                  </tr>
                  <tr className="text-center border">
                    <td>3 Bedroom --</td>
                    <td>{data.threeB}</td>
                    <td>{data.threeP}</td>
                    <td>{data.threeC}</td>
                  </tr>
                  <tr className="text-center border">
                    <td>4 Bedroom --</td>
                    <td>{data.fourB}</td>
                    <td>{data.fourP}</td>
                    <td>{data.fourC} </td>
                  </tr>
                  <tr className="text-center border">
                    <td>4+ Bedroom --</td>
                    <td>{data.plusFourB}</td>
                    <td>{data.plusFourP}</td>
                    <td>{data.plusFourC}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Card>
        );
      })}
    </div>
  );
}

export default CategoryCard2;
