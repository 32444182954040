import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import Table1 from './taskHistoryTable/Table';
import Table2 from './paymentTable/Table';
import Table3 from './customerDetailsTable/Table';
import AdminContext from '../../adminContext/AdminContext';
import newImage from '../../../assets/images/ZZ5H.gif';
import axios from 'axios';
import CustomerModal from './CustomerModal/CustomerModal';
function CustomerDetails() {
  const initialValue = {
    customerName: '',
    customerID: '',
    email: '', 
    phoneNumber: '',
  };
  const [formInput, setFormInput] = useState(initialValue);
  const [usersData, setUsersData] = useState([]);
  const [forFilter, setForFilter] = useState(usersData);
  const dashboardContext = useContext(AdminContext);
  const { handleDashboardApi, handleModal } = dashboardContext;
  const [newFormInput, setNewFormInput] = useState({
    customerName: '',
    customerID: '',
    email: '',
    phoneNumber: '',
  });
  const [counter, setCounter] = useState(0);
  // setUsersData(table);
  // console.log(usersData);
  // console.log(usersData);
  const callingApiForContext = async () => {
    setUsersData(await handleDashboardApi());
  };
  // console.log(usersData);
  useEffect(() => {
    callingApiForContext();
  }, []);
  const refreshPage = () => {

   
    window.location.reload();


};
  const handleSubmit = () => {
    // event.preventDefault();
    let filterData;
    // console.log('formData', filterData);
    if (filterData || formInput.customerName !== '') {
      if (filterData !== undefined) {
        filterData = filterData.filter((element) => {
          return element.name
            .toUpperCase()
            .includes(formInput.customerName.toUpperCase());
        });
      } else {
        filterData = usersData.filter((element) => {
          return element.name
            .toUpperCase()
            .includes(formInput.customerName.toUpperCase());
        });
      }
      setForFilter(filterData);
      return;
    } 

    if (filterData || formInput.customerID !== '') {
      if (filterData !== undefined) {
        filterData = filterData.filter((element) => {
          return element.user.includes(formInput.customerID);
        });
      } else {
        filterData = usersData.filter((element) => {
          return (
            element.user_id && element.user_id.includes(formInput.customerID)
          );
        });
      }
      setForFilter(filterData);
      return;
    }
    if (filterData || formInput.email !== '') {
      if (filterData !== undefined) {
        filterData = filterData.filter((element) => {
          return element.email
            .toUpperCase()
            .includes(formInput.email.toUpperCase());
        });
      } else {
        filterData = usersData.filter((element) => {
          return element.email
            .toUpperCase()
            .includes(formInput.email.toUpperCase());
        });
      }
      setForFilter(filterData);
      return;
    }
    if (filterData || formInput.phoneNumber !== '') {
      if (filterData !== undefined) {
        filterData = filterData.filter((element) => {
          return element.phone
            .toUpperCase()
            .includes(formInput.phoneNumber.toUpperCase());
        });
      } else {
        filterData = usersData.filter((element) => {
          return element.phone
            .toUpperCase()
            .includes(formInput.phoneNumber.toUpperCase());
        });
      }
      setForFilter(filterData);
      return;
    }
  };

  const handleChange = (e) => {
    // console.log('e', e.target.name, e.target.value);
    setFormInput((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });
    setNewFormInput((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });

    setCounter(counter + 1);
  };
  const [queryData, setQueryData] = useState([]);

  const handleUserDetailsApi = () => {
    return axios
      .get(`${process.env.REACT_APP_BACKEND_DATA}/api/auth/get-all-users`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'admin-token': localStorage.getItem('admin-token'),
        },
      })
      .then((response) => {
        setQueryData(response.data);
        return response.data;
      })
      .catch((error) => {});
  };

  useLayoutEffect(() => {
    handleUserDetailsApi();
  }, [handleModal]);

  const customerDetailsSearch = (data) => {
    console.log(data[0], formInput);
    if (formInput.customerName != '') {
      return data.filter((item) => {
        return item.name
          .toUpperCase()
          .includes(formInput.customerName.toUpperCase());
      });
    } else if (formInput.customerID != '') {
      return data.filter((item) => {
        return (
          item.user_id &&
          item.user_id
            .toUpperCase()
            .includes(formInput.customerID.toUpperCase())
        );
      });
    } else if (formInput.email != '') {
      return data.filter((item) => {
        return item.email.toUpperCase().includes(formInput.email.toUpperCase());
      });
    } else if (formInput.phoneNumber != '') {
      return data.filter((item) => {
        return item.phone.includes(formInput.phoneNumber);
      });
    } else {
      return data;
    }
  };

  const taskDetailsSearch = (data) => {
    if (formInput.customerName != '') {
      return data.filter((item) => {
        return item.name.includes(formInput.customerName);
      });
    } else if (formInput.customerID !== '') {
      return data.filter((item) => {
        return item._id.includes(formInput.customerID);
      });
    } else if (formInput.phoneNumber !== '') {
      return data.filter((item) => {
        return item.phone == formInput.phoneNumber;
      });
    } else if (formInput.email !== '') {
      return data.filter((item) => {
        item.email.includes(formInput.email);
      });
    } else {
      return data;
    }
  };
  // console.log(customerDetailsSearch(queryData));
  useEffect(() => {
    handleSubmit();
  }, [counter]);
  return (
    <>
      {queryData.length === 0 ? (
        <div
          style={{
            height: '80vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img style={{ maxWidth: '7%' }} src={newImage} />
        </div>
      ) : (
        <div className="mt-6">
          <Card className="mb-5">
            <Card.Header>
              <h2 className="card-title">Customer Details</h2>
            </Card.Header>
            <Card.Body>
              <Row>
                <form>
                  <div className="card-wrapper">
                    <Col sm={6} md={6}>
                      <Form.Group>
                        <Form.Label>Customer Name</Form.Label>
                        <input
                         style={{ background: '#cef0ef' }}
                          type="text"
                          className="form-control"
                          name="customerName"
                          // value={formInput.customerName}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6} md={6}>
                      <Form.Group>
                        <Form.Label>Customer ID</Form.Label>
                        <input
                         style={{ background: '#cef0ef' }}
                          type="text"
                          className="form-control"
                          name="customerID"
                          // value={formInput.customerID}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </div>
                  <div className="card-wrapper">
                    <Col sm={6} md={6}>
                      <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <input
                         style={{ background: '#cef0ef' }}
                          type="email"
                          className="form-control"
                          name="email"
                          // value={formInput.email}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6} md={6}>
                      <Form.Group>
                        <Form.Label>Phone Number</Form.Label>
                        <input
                         style={{ background: '#cef0ef' }}
                          type="number"
                          className="form-control"
                          name="phoneNumber"
                          // value={formInput.phoneNumber}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </div>
                  <div className="col-6">
                <button
                  onClick={refreshPage}
                  className="btn btn-primary btn-icon text-white col-md-3 ml-xl-90"
                  style={{
                    margin: '2rem auto 0 auto',
                    padding: '.5rem 2rem',
                    display: 'flex',
                  }}
                  type="reset"
                >
                 
                  Reset
                </button>
              </div>
                  {/* <button
                    className="btn btn-success btn-icon text-white col-md-3"
                    style={{
                      margin: '2rem auto 0 auto',
                      padding: '.5rem 2rem',
                      display: 'block',
                    }}
                    type="submit"
                  >
                    Search
                  </button> */}
                </form>
              </Row>
            </Card.Body>
          </Card>
          {/* <CustomerEditDetail /> */}
          <Table3 filterdata={customerDetailsSearch(queryData)} />
          <Table1 usersData={taskDetailsSearch(usersData)} />
          <Table2 />
          {/* <CustomerModal /> */}
        </div>
      )}
    </>
  );
}

export default CustomerDetails;
