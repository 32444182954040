import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import "react-data-table-component-extensions/dist/index.css";

export const DataTables = ({ userData }) => {
  const [data, setData] = useState(userData);
  const [block, setBlock] = useState(false);
  const [linkData, setLinkData] = useState([]);
  useEffect(() => setData(userData), [userData]);

  const logClick = (_Id) => {
    let ndata = data.filter((ele) => {
      if (ele._id == _Id) {
        return ele;
      }
    });
    let freshData = linkData.push(ndata);
    setLinkData(freshData);
  };

  const handleDelete = (value) => {
    let deleteData = data.filter((element) => {
      return element._id != value;
    });
    setData(deleteData);

    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/partners/delete-partner-details`;
    axios({
      url: url,
      method: "delete",
      data: { _id: value },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "admin-token": localStorage.getItem("admin-token"),
      },
    }).then(function (response) {});
  };

  const handleblock = () => {
    setBlock(!block);
  };
  var count = 0;

  // -----------------------------------------------------

  // function convertArrayOfObjectsToCSV(array) {
  //   let result;

  //   const columnDelimiter = ",";
  //   const lineDelimiter = "\n";
  //   const keys = Object.keys(data[0]);

  //   result = "";
  //   result += keys.join(columnDelimiter);
  //   result += lineDelimiter;

  //   array.forEach((item) => {
  //     let ctr = 0;
  //     keys.forEach((key) => {
  //       if (ctr > 0)

  //       result += columnDelimiter;

  //       result += item[key];

  //       ctr++;
  //     });
  //     result += lineDelimiter;
  //   });

  //   return result;
  // }
  // --------------------------------------------------------------

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "Partner Details.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <Button type="button" className="ml-5" onClick={() => tableToCSV()}>
      {" "}
      Export
    </Button>
  );

  // function downloadCSV(array) {
  //   const link = document.createElement("a");
  //   let csv = convertArrayOfObjectsToCSV(array);
  //   if (csv == null) return;

  //   const filename = "export.csv";

  //   if (!csv.match(/^data:text\/csv/i)) {
  //     csv = `data:text/csv;charset=utf-8,${csv}`;
  //   }

  //   link.setAttribute("href", encodeURI(csv));
  //   link.setAttribute("download", filename);
  //   link.click();
  // }

  // const Export = ({ onExport }) => (
  //   <Button onClick={(e) => onExport(e.target.value)}>Export</Button>
  // );

  const columns = [
    {
      name: "Partner ID",
      selector: (row) => [row.p_id],
      sortable: true,
    },
    {
      name: " Category",
      selector: (row) => [row.partnerCategory],
      sortable: true,
    },
    {
      name: "Company Name",
      selector: (row) => [row.companyName],
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => [row.partnerNumber],
      sortable: true,
    },

    {
      name: "Service State",
      selector: (row) => [
        row.partnerState == null ? " " : row.partnerState + " ",
      ],
      // == "NSW,VIC,QLD,SA,WA,TAS,NT" ?"All" :row.partnerState  + ' '],
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => [
        <Link
          onClick={() => logClick(row._id)}
          to={"/admin/add-partner"}
          state={linkData}
        >
          <i
            className="zmdi zmdi-edit"
            style={{
              fontSize: "20px",
              cursor: "pointer",
            }}
          />
        </Link>,
        <i
          className="zmdi zmdi-delete"
          style={{
            fontSize: "20px",
            cursor: "pointer",
            color: "#b73232",
            margin: "0 20px 0 20px",
          }}
          onClick={() => handleDelete(row._id)}
        />,
      ],
    },
  ];
  // const tableDatas = {
  //   columns,
  //   data,
  // };
  // const actionsMemo = React.useMemo(
  //   () => <Export onExport={() => downloadCSV(data)} />,
  //   []
  // );

  const tableDatas = {
    columns,
    data,
  };
  const actionsMemo = React.useMemo(() => (
    <Button type="button" className="ml-5" onClick={() => tableToCSV()}>
      {" "}
      Export
    </Button>
  ));

  function tableToCSV() {
    // Variable to store the final csv data
    var csv_data = [];

    // Get each row data
    var rows = document.getElementsByClassName("tttt");
    console.log(rows);
    for (var i = 0; i < rows.length; i++) {
      // Get each column data
      var cols = rows[i].querySelectorAll("td,th");

      // Stores each csv row data
      var csvrow = [];

      for (var j = 0; j < cols.length; j++) {
        // Get the text data of each cell
        // of a row and push it to csvrow
        csvrow.push(cols[j].innerHTML);
      }

      // Combine each column value with comma
      csv_data.push(csvrow.join(","));
    }

    // Combine each row data with new line character
    csv_data = csv_data.join("\n");

    // Call this function to download csv file
    downloadCSVFile(csv_data);
  }

  function downloadCSVFile(csv_data) {
    // Create CSV file object and feed
    // our csv_data into it
    var CSVFile = new Blob([csv_data], {
      type: "text/csv",
    });

    // Create to temporary link to initiate
    // download process
    var temp_link = document.createElement("a");

    // Download csv file
    temp_link.download = "Partner Details.csv";
    var url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;

    // This link should not be displayed
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);

    // Automatically click the link to
    // trigger download
    temp_link.click();
    document.body.removeChild(temp_link);
  }
  return (
    <>
      <table className="d-none" border="1" cellSpacing="0" cellPadding="10">
        <tr className="tttt">
          <th className="thexp">Partner ID</th>
          <th className="thexp">Category</th>
          <th className="thexp">Customer Name</th>
          <th className="thexp">Phone Number </th>
          <th className="thexp">Service State</th>
        </tr>
        {data?.map((row) => {
          return (
            <>
              <tr className="tttt">
                <td>{row.p_id}</td>
                <td>{row.partnerCategory}</td>
                <td>{row.companyName}</td>
                <td>{row.partnerNumber}</td>
                <td>
                  {row.partnerState == null ? " " : row.partnerState + " "}
                </td>
              </tr>
            </>
          );
        })}
      </table>

      <DataTableExtensions {...tableDatas}>
        <DataTable
          actions={actionsMemo}
          columns={columns}
          data={data}
          pagination
          highlightOnHover
          dense
          responsive
        />
      </DataTableExtensions>
    </>
  );
};
