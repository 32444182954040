import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";

export const DataTables = () => {
  const [data, setData] = React.useState([]);

  const handleFeeDetailsApi = () => {
    return axios
      .get(
        `${process.env.REACT_APP_BACKEND_DATA}/api/payment-history/all-payment-history`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "admin-token": localStorage.getItem("admin-token"),
          },
        }
      )
      .then((response) => {
        setData(response.data);
        return response.data;
      })
      .catch((error) => {});
  };

  useEffect(() => {
    handleFeeDetailsApi();
  }, []);

  const downloadTxtFile = (value) => {
    let download = data.filter((ele) => {
      return ele.TASKID === value;
    });

    let downloads = JSON.stringify(download);

    const element = document.createElement("a");
    const file = new Blob([downloads], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "myFile.txt";
    document.body.appendChild(element);
    element.click();
  };

  const navigate = useNavigate();

  function ExportFunction(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = ExportFunction(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <div
      className="exportFunction"
      onClick={(e) => onExport(e.target.value)}
      style={{ color: "blue", cursor: "pointer" }}
    >
      Download
    </div>
  );
  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(data)} />,
    []
  );

  const columns = [
    {
      name: "Task ID",
      selector: (row) => [row.taskId],
      sortable: true,
    },
    {
      name: " Task Name",
      selector: (row) => [row.taskName],
      sortable: true,
    },
    {
      name: "Payment Amount",
      selector: (row) => [row.paymentAmount],
      sortable: true,
    },
    {
      name: "Payment Date",
      selector: (row) => [row.paymentDate],
      sortable: true,
    },
    {
      name: "Receipt",
      selector: (row) => [
        <Button onClick={() => downloadTxtFile(row.TASKID)}>Download</Button>,
      ],
      sortable: true,
    },
  ];
  const tableDatas = {
    columns,
    data,
  };
  return (
    <DataTableExtensions {...tableDatas}>
      <DataTable
        columns={columns}
        data={data}
        pagination
        highlightOnHover
        responsive
        dense
      />
    </DataTableExtensions>
  );
};
