import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import AdminContext from '../../../../adminContext/AdminContext';
import axios from 'axios';

function ParnterEditModal({ filterData, linkData, remainData }) {

  let initialValue = {};
  let newData =
    filterData &&
    filterData.map((data) => {
      initialValue.partnerState = data.partnerState;
      initialValue.fullFee = data.fullFee;
      initialValue.upfrontFee = data.upfrontFee;
      initialValue.oneB = data.oneB;
      initialValue.oneP = data.oneP;
      initialValue.oneC = data.oneC;
      initialValue.twoB = data.twoB;
      initialValue.twoP = data.twoP;
      initialValue.twoC = data.twoC;
      initialValue.threeB = data.threeB;
      initialValue.threeP = data.threeP;
      initialValue.threeC = data.threeC;
      initialValue.fourB = data.fourB;
      initialValue.fourP = data.fourP;
      initialValue.fourC = data.fourC;
      initialValue.plusFourB = data.plusFourB;
      initialValue.plusFourP = data.plusFourP;
      initialValue.plusFourC = data.plusFourC;
    });
    const [stateval,setstateval]= useState()
  const [formInput, setformInput] = useState(initialValue);
  const [incomingData, setIncomingData] = useState([]);
  const dashboardContext = useContext(AdminContext);
  const navigate = useNavigate();
  const {
    handlePartnerApi,
    setHandlePartnerModal,
    handlePartnerModal,
    setDummystate,
    dummystate,
  } = dashboardContext;

  const callingApiForContext = async () => {
    setIncomingData(await handlePartnerApi());
  };

  useEffect(() => {
    callingApiForContext();
  }, [dummystate]);

  useEffect(() => {
    setformInput(initialValue);
  }, [handlePartnerModal]);

  const handleChange = (e) => {
    setformInput((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });
  };
  
  const handleSubmit = (e) => {
    
    console.log(formInput.fullFee);
    console.log(formInput.upfrontFee);
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/partners/update-single-partner-state`;
    const dataD = {
      _id: linkData._id,
      partnerState: stateval?stateval: filterData[0].partnerState,
      upfrontFee: formInput.upfrontFee,
      fullFee: formInput.fullFee
    };
    console.log(dataD);
    axios
      .post(url, dataD, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'admin-token': localStorage.getItem('admin-token'),
        },
      })
      .then((response) => {
        navigate("/admin/partner-details") 
      })
      .catch((error) => {});

    const url1 = `${process.env.REACT_APP_BACKEND_DATA}/api/partners/update-partner-details`;
    const data = {
      _id: linkData._id,
      partnerName: linkData.partnerName,
      companyName: linkData.companyName,
      partnerEmail: linkData.partnerEmail,
      partnerNumber: linkData.partnerNumber,
      partnerAddress: linkData.partnerAddress,
      partnerCategory: linkData.partnerCategory,
      fee: linkData.fee,
      // partnerState: [
      //   linkData.partnerState[0],
      //   linkData.partnerState[1],
      //   linkData.partnerState[2],
      //   linkData.partnerState[3],
      //   linkData.partnerState[4],
      //   linkData.partnerState[5],
      //   linkData.partnerState[6],
      //   linkData.partnerState[7],
      // ],
      partnerServiceStateDetails: remainData.concat(formInput),
    };
    axios
      .put(url1, data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'admin-token': localStorage.getItem('admin-token'),
        },
      })
      .then((response) => {
        navigate("/admin/partner-details")
      })
      .catch((error) => {});

    setHandlePartnerModal(false);
    setformInput(initialValue);
    setDummystate(!dummystate);
  };

  const handleChanger =(e) =>{
    setformInput((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });
    setstateval(e.target.value)
    console.log(e.target.value);
  }
  console.log(formInput);
  return (
    <div>
      <form>
        <Modal show={handlePartnerModal} size="lg">
          <Modal.Header>
            <Modal.Title>Edit Fee Partner Entry</Modal.Title>
            <Button
              onClick={() => setHandlePartnerModal(false)}
              className="btn-close"
              variant=""
            >
              x
            </Button>
          </Modal.Header>

          {linkData.partnerCategory === 'Solicitor' ||
          linkData.partnerCategory === 'Conveyancer' ? (
            <Modal.Body>
              <div className=" row mb-4">
                <div className="d-flex mb-4">
                  <div style={{ width: '200px' }}>State :</div>
                  <div style={{ fontWeight: 'bold' }}>
                    {filterData &&
                      filterData.map((data) => {
                        return data.partnerState;
                      })}
                  </div>
                  <select name="partnerState"  value={formInput.partnerState} onChange={handleChanger}>
                         <option >Select State</option>
                         <option name="partnerState" value="NSW">NSW</option>
                         <option name="partnerState" value="QLD">QLD</option>
                         <option name="partnerState" value="WA">WA</option>
                         <option name="partnerState" value="NT">NT</option>
                         <option name="partnerState" value="VIC">VIC</option>
                         <option name="partnerState" value="SA">SA</option>
                         <option name="partnerState" value="TAS">TAS</option>     
                         <option name="partnerState" value="ACT">ACT</option>     
                 </select>
                </div>
                <label className="col-md-3 form-label my-2">Full fee</label>
                <div className="col-md-9 my-2">
                  <input
                    type="number"
                    className="form-control"
                    name="fullFee"
                    value={formInput.fullFee}
                    onChange={handleChange}
                  />
                </div>
                <label className="col-md-3 form-label my-2">Upfront fee</label>
                <div className="col-md-9 my-2">
                  <input
                    type="number"
                    className="form-control"
                    name="upfrontFee"
                    value={formInput.upfrontFee}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </Modal.Body>
          ) : linkData.partnerCategory === 'Building and Pest Inspection' ? (
            <Modal.Body>
              <div className=" row mb-4">
                <div className="d-flex mb-4">
                  <div style={{ width: '100px' }}>State :</div>
                  <div style={{ fontWeight: 'bold' }}>
                    {filterData &&
                      filterData.map((data) => {
                        return data.partnerState;
                      })}
                  </div>
                  <select name="partnerState"  value={formInput.partnerState} onChange={handleChanger}>
                         <option >Select State</option>
                         <option name="partnerState" value="NSW">NSW</option>
                         <option name="partnerState" value="QLD">QLD</option>
                         <option name="partnerState" value="WA">WA</option>
                         <option name="partnerState" value="NT">NT</option>
                         <option name="partnerState" value="VIC">VIC</option>
                         <option name="partnerState" value="SA">SA</option>
                         <option name="partnerState" value="TAS">TAS</option>     
                 </select>
                </div>
                <Table
                  className="text-center border table-responsive col-md-10"
                  style={{ margin: 'auto' }}
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>Building Inspection</th>
                      <th>Pest Inspection</th>
                      <th>Combined Building & Pest Inspection </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1 Bedroom</td>
                      <td>
                        <input
                          className="form-control"
                          name="oneB"
                          value={formInput.oneB}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          name="oneP"
                          value={formInput.oneP}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          name="oneC"
                          value={formInput.oneC}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>2 Bedroom</td>
                      <td>
                        <input
                          className="form-control"
                          name="twoB"
                          value={formInput.twoB}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          name="twoP"
                          value={formInput.twoP}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          name="twoC"
                          value={formInput.twoC}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>3 Bedroom</td>
                      <td>
                        <input
                          className="form-control"
                          name="threeB"
                          value={formInput.threeB}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          name="threeP"
                          value={formInput.threeP}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          name="threeC"
                          value={formInput.threeC}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>4 Bedroom</td>
                      <td>
                        <input
                          className="form-control"
                          name="fourB"
                          value={formInput.fourB}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          name="fourP"
                          value={formInput.fourP}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          name="fourC"
                          value={formInput.fourC}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>4+ Bedroom</td>
                      <td>
                        <input
                          className="form-control"
                          name="plusFourB"
                          value={formInput.plusFourB}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          name="plusFourP"
                          value={formInput.plusFourP}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          name="plusFourC"
                          value={formInput.plusFourC}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Modal.Body>
          ) : (
            ''
          )}
          <Modal.Footer>
            <Button
              variant="primary"
              className="me-1"
              onClick={() => setHandlePartnerModal(false)}
            >
              Close
            </Button>
           
            <Button
              type="submit"
              variant="default"
              className="me-1"
              onClick={handleSubmit}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      </form>
    </div>
  );
}

export default ParnterEditModal;
