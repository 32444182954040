import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Button } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";
import { CSVLink, CSVDownload } from "react-csv";

export const DataTables = (props) => {
  // console.log(props);
  // console.log(props.formInput);
  const [formInput, setFormInput] = useState(props.filter);

  // console.log(formInput);
  const [incomingData, setincomingData] = useState(props.incomingData);
  const [data, setData] = useState(incomingData);
  // setData(8)
  // let  t = parseInt(  data[4].approval.amount) +5
  const calcSums = (someArray) =>
    someArray.reduce((sums, val) => {
      Object.keys(val).forEach(
        (v) =>
          (sums[`sum-${v}`] = !sums[`sum-${v}`]
            ? val[v]
            : sums[`sum-${v}`] + val[v])
      );
      return sums;
    }, {});

  const sumArr = incomingData.approval
    ? incomingData.approval.reduce((a, b) => a + b.some, 0)
    : 0;

  let newData = 0;
  newData =
    data.approval &&
    data
      .map((data) => {
        return data.amount;
      })
      .reduce((acc, data) => {
        let d = parseInt(data);
        return (acc = acc + d);
      }, 0);
  const [settingFullApproval, setSettingFullApproval] = useState([]);

  let fullApproval = settingFullApproval;

  function convertArrayOfObjectsToCSV(array) {
    let result;
    console.log(settingFullApproval);
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  // setSettingFullApproval()
  function downloadCSV(array) {
    const link = document.createElement("a");

    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    console.log(csv);
    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <>
      <Button type="button" className="ml-5" onClick={() => tableToCSV()}>
        {" "}
        Export
      </Button>
      {/* <CSVLink data={newObj1} ><span className="btn btn-primary">Export</span></CSVLink> */}
      {/* <Button onClick={(e) => onExport(newObj1)}>Export</Button> */}
    </>
  );

  // console.log(arr)
  const arr = [];
  let fullammount = 0;
  const columns = [
    {
      name: "Task ID",
      selector: (row) => [row.task_id],
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (row) => [row.name],
      sortable: true,
    },
    {
      name: "Customer ID",
      selector: (row) => [row.user],
      sortable: true,
    },
    {
      name: "Company Name",
      selector: (row) => [row.assignTo],
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => [row.progress],
      sortable: true,
    },
    {
      name: "Assigned Date",
      selector: (row) => (
        <div>
          {row.assignDate === undefined
            ? "N/A"
            : row.assignDate.split("-").reverse().join("/")}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Completion Date",
      selector: (row) =>
        row.completeDate === undefined || row.completeDate === "N/A"
          ? "N/A"
          : row.completeDate,
      // (
      //   <div>
      //   {row.completeDate != ""

      //     ? new Date(row.completeDate).toLocaleDateString('en-In') : "N/A"}

      // </div>
      // ),
      //  new Date(row.completeDate).toLocaleDateString('en-In'),

      sortable: true,
    },
    {
      name: "Full Approval Amount",
      selector: (row) => {
        arr.push(parseInt(row.approval && row.approval.amount));

        setSettingFullApproval(
          fullammount + parseInt(row.approval && row.approval.amount)
        );
        //  console.log('gh',arr);
        return row.approval && row.approval.amount;
      },

      sortable: true,
    },
  ];

  const tdata1 = props.filter;
  // console.log(tdata1);
  const arr1 = [tdata1];
  const tableDatas = {
    columns,
    data,
  };

  let filteredDataMortgage = tdata1?.filter((element) => {
    // console.log(element.approval)
    
    if (element.approval) {
      
      return element;
    }
  });

  // const index = incomingData.indexOf(4)

  // let newdata = incomingData.filter((element) => {

  //   if (element.approval) {
  //     return element.approval
  //   }
  // }).map((element) =>{
  //    let elm = element.approval
  //    let [nelm] = [...elm]
  //    console.log(nelm);
  // })

  // console.log(newdata);
  // return items.filter(item => {
  //    let date = new Date(item.created_at);
  //    return date >= start && date <= end;
  // }

  // let outputObj =[]
  // let recursive = (obj,name) => {
  //   for (let key in obj){
  //     if(typeof obj[key] == "object"){
  //       recursive(obj[key],name+"_"+key)
  //     }else{
  //       outputObj[name+"_"+key]=obj[key]
  //     }
  //   }
  // }

  // recursive(tdata1,"new")

  // console.log(result)
  // console.log(ik);
  const newObj1 = [];
  let newObj = {};
  newObj1.push(newObj);
  const getFlattenObj = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] == "object") {
        getFlattenObj(obj[key]);
      } else {
        newObj[key] = obj[key];
      }
    });
  };

  getFlattenObj(tdata1 ?? 0);
  // console.log(newObj1);
  const actionsMemo = React.useMemo(
    () => (
      <Button type="button" className="ml-5" onClick={() => tableToCSV()}>
        {" "}
        Export
      </Button>
    ),
    []
  );
  //  console.log(filteredDataMortgage);
  let sum = 0;
  Object.keys(filteredDataMortgage ?? 0).forEach(function (key, index) {
    //  console.log(incomingData[key]['approval']['amount']);
    //  if (filteredDataMortgage.approval != "") {

    sum += +filteredDataMortgage[key]["approval"]["amount"];
    //  }
  });

  function tableToCSV() {
    // Variable to store the final csv data
    var csv_data = [];

    // Get each row data
    var rows = document.getElementsByClassName("tttt");
    console.log(rows);
    for (var i = 0; i < rows.length; i++) {
      // Get each column data
      var cols = rows[i].querySelectorAll("td,th");

      // Stores each csv row data
      var csvrow = [];

      for (var j = 0; j < cols.length; j++) {
        // Get the text data of each cell
        // of a row and push it to csvrow
        csvrow.push(cols[j].innerHTML);
      }

      // Combine each column value with comma
      csv_data.push(csvrow.join(","));
    }

    // Combine each row data with new line character
    csv_data = csv_data.join("\n");

    // Call this function to download csv file
    downloadCSVFile(csv_data);
  }

  function downloadCSVFile(csv_data) {
    // Create CSV file object and feed
    // our csv_data into it
    var CSVFile = new Blob([csv_data], {
      type: "text/csv",
    });

    // Create to temporary link to initiate
    // download process
    var temp_link = document.createElement("a");

    // Download csv file
    temp_link.download = "Mortage.csv";
    var url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;

    // This link should not be displayed
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);

    // Automatically click the link to
    // trigger download
    temp_link.click();
    document.body.removeChild(temp_link);
  }

  return (
    <div>
      <table className="d-none" border="1" cellSpacing="0" cellPadding="10">
        <tr className="tttt">
          <th className="thexp">Task ID</th>
          <th className="thexp">Customer Name</th>
          <th className="thexp">Custumer ID</th>
          <th className="thexp">Company Name </th>
          <th className="thexp">Status</th>
          <th className="thexp">Assigned Date</th>
          <th className="thexp">Completion Date</th>
          <th className="thexp">Full Approval</th>
          {/* <th className="thexp">Total Approval Amount:</th>      */}
        </tr>
        {filteredDataMortgage?.map((row) => {
          return (
            <>
              <tr className="tttt">
                <td>{row.task_id}</td>
                <td>{row.name}</td>
                <td>{row.user}</td>
                <td>{row.assignTo}</td>
                <td>{row.progress}</td>
                <td>
                  {row.assignDate === undefined
                    ? "N/A"
                    : row.assignDate.split("-").reverse().join("/")}
                </td>
                <td>
                  {row.completeDate === undefined || row.completeDate === "N/A"
                    ? "N/A"
                    : row.completeDate}
                </td>
                <td>{row.approval && row.approval.amount}</td>
                {/* <td>Total Approval Amount:{sum}</td> */}
              </tr>
            </>
          );
        })}
      </table>

      {/* <DataTableExtensions {...tableDatas}> */}
      <DataTable
        actions={actionsMemo}
        columns={columns}
        data={filteredDataMortgage}
        pagination
        highlightOnHover
        responsive
        dense
      />
      {/* </DataTableExtensions> */}
      <div
        className="form-control"
        style={{ textAlign: "end", fontSize: "20px" }}
      >
        Total Approval Amount:{" "}
        <span style={{ fontWeight: "bold" }}> ${sum ? sum : 0} </span>
      </div>
    </div>
  );
};
