import UserContext from "./UserContext";
import { useState } from "react";
const UserState = (props) => {
  const [applicationData, setApplicationData] = useState({
    propertyPlan: "",
    propertType: "",
    propertyShortlisted: "",
  });

  const propertyPlan = (data) => {
    setApplicationData({ ...applicationData, propertyPlan: data });
  };
  const propertType = (data) => {
    setApplicationData({ ...applicationData, propertType: data });
  };
  const propertyShortlisted = (data) => {
    setApplicationData({ ...applicationData, propertyShortlisted: data });
  };

  return (
    <UserContext.Provider
      value={{
        propertyPlan,
        propertType,
        propertyShortlisted,
        applicationData,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;
