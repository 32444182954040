import React, { useState, useContext } from 'react';
import { Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
// const [credentials, setcredentials] = useState(initialValue);

import UserApiContext from '../UserApi/UserApiContext';
// import * as custompagesswitcherdata from "../SwitherData/Custompagesswitcherdata"
export default function Register() {
  const context = useContext(UserApiContext);
  const { registerNewUser } = context;
  const [error, setError] = useState(false);
  const [credentials, setcredentials] = useState({
    name: '',
    phone: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (credentials.password === '' || credentials.confirmPassword === '') {
      // toast.error("The new Password and confirmation password do not match", {
      //   position: "top-center",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
    } else if (credentials.password !== credentials.confirmPassword) {
      toast.error('Password and confirm password do not match', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setError(true);

      setTimeout(() => {
        setError(false);
      }, 5000);
    } else {
      if (credentials.phone.charAt(0) == "0") {
        
        registerNewUser(credentials);
      }else{

        toast.error('Enter the valid 10 digits number starting with 0', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  const onChange = (e) => {
    setcredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  return (
    <div className="">
      <div className="page">
        <div>
          <div className="col col-login mx-auto"></div>
          <div className="container-login100">
            <div className="wrap-login100 p-0">
              <Card.Body>
                <form
                  onSubmit={handleSubmit}
                  autoComplete="off"
                  className="login100-form validate-form"
                >
                  <span className="login100-form-title">Registration</span>
                  <div className="wrap-input100 validate-input">
                    <input
                      className="input100"
                      type="text"
                      name="name"
                      placeholder="Name"
                      onChange={onChange}
                    />
                    <span className="focus-input100"></span>
                    <span className="symbol-input100">
                      <i className="mdi mdi-account" aria-hidden="true"></i>
                    </span>
                  </div>
                  <div className="wrap-input100 validate-input">
                    <input
                      className="input100"
                      type="number"
                      name="phone"
                      placeholder="Phone"
                      onChange={onChange}
                    />
                    <span className="focus-input100"></span>
                    <span className="symbol-input100">
                      <i className="mdi mdi-phone" aria-hidden="true"></i>
                    </span>
                  </div>
                  <div className="wrap-input100 validate-input">
                    <input
                      className="input100"
                      type="email"
                      name="email"
                      onChange={onChange}
                      placeholder="Email"
                    />
                    <span className="focus-input100"></span>
                    <span className="symbol-input100">
                      <i className="zmdi zmdi-email" aria-hidden="true"></i>
                    </span>
                  </div>
                  <div className="wrap-input100 validate-input">
                    <input
                      className="input100"
                      type="password"
                      name="password"
                      onChange={onChange}
                      password="true"
                      placeholder="Password"
                    />
                    <span className="focus-input100"></span>
                    <span className="symbol-input100">
                      <i className="zmdi zmdi-lock" aria-hidden="true"></i>
                    </span>
                  </div>
                  <div className="wrap-input100 validate-input">
                    <input
                      className="input100"
                      type="password"
                      name="confirmPassword"
                      onChange={onChange}
                      required
                      password="true"
                      placeholder="Confirm Password"
                    />
                    <span className="focus-input100"></span>
                    <span className="symbol-input100">
                      <i className="zmdi zmdi-lock" aria-hidden="true"></i>
                    </span>
                  </div>

                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      required
                      value=""
                      id="flexCheckDefault"
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      By signing up ,I agree to Justnests{' '}
                      <Link to="/terms-and-conditions">Terms of Use</Link>
                    </label>
                  </div>

                  <div className="container-login100-form-btn">
                    <button
                      type="submit"
                      className="login100-form-btn btn-primary"
                    >
                      Register
                    </button>
                  </div>
                  <div className="text-center pt-3">
                    <p className="text-dark mb-0">
                      Already have account?
                      <Link to={`/login`} className="text-primary ms-1">
                        Sign In
                      </Link>
                    </p>
                  </div>
                </form>
              </Card.Body>
              {/* <Card.Footer>
                <div className="d-flex justify-content-center my-3">
                  <Link to="" className="social-login  text-center me-4">
                    <i className="fa fa-google"></i>
                  </Link>
                  <Link to="#" className="social-login  text-center me-4">
                    <i className="fa fa-facebook"></i>
                  </Link>
                  <Link to="#" className="social-login  text-center">
                    <i className="fa fa-twitter"></i>
                  </Link>
                </div>
              </Card.Footer> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
