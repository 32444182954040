import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import AdminContext from "../Admin/adminContext/AdminContext";
import { Card } from "react-bootstrap";

export default function AdminLogin() {
  const [credentials, setcredentials] = useState({ email: "", password: "" });

  const context = useContext(AdminContext);
  const { adminLogin } = context;
  const handleSubmit = async (e) => {
    e.preventDefault();
    adminLogin(credentials);
  };
  const onChange = (e) => {
    setcredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <div className="mt-9">
        <div>
          <div className="col col-login mx-auto">
            <div className="text-center"></div>
          </div>
          <div className="container-login100">
            <div className="wrap-login100 p-0">
              <Card.Body>
                <form
                  onSubmit={handleSubmit}
                  className="login100-form validate-form"
                >
                  <span className="login100-form-title">Admin Login</span>
                  <div className="wrap-input100 validate-input">
                    <input
                      className="input100"
                      type="email"
                      name="email"
                      onChange={onChange}
                      placeholder="Email"
                    />
                    <span className="focus-input100"></span>
                    <span className="symbol-input100">
                      <i className="zmdi zmdi-email" aria-hidden="true"></i>
                    </span>
                  </div>
                  <div className="wrap-input100 validate-input">
                    <input
                      className="input100"
                      type="password"
                      name="password"
                      onChange={onChange}
                      placeholder="Password"
                    />
                    <span className="focus-input100"></span>
                    <span className="symbol-input100">
                      <i className="zmdi zmdi-lock" aria-hidden="true"></i>
                    </span>
                  </div>
                  <div className="text-end pt-1">
                    <p className="mb-0">
                      <Link
                        to="/admin-forgot-password"
                        className="text-primary ms-1"
                        state={"admin"}
                      >
                        Forgot Password?
                      </Link>
                    </p>
                  </div>
                  <div className="container-login100-form-btn">
                    <button
                      type="submit"
                      className="login100-form-btn btn-primary"
                    >
                      Login
                    </button>
                  </div>
                </form>
              </Card.Body>
              <Card.Footer>
                <div className="d-flex justify-content-center my-3">
                  <Link to="#" className="social-login  text-center me-4">
                    <i className="fa fa-google"></i>
                  </Link>
                  <Link to="#" className="social-login  text-center me-4">
                    <i className="fa fa-facebook"></i>
                  </Link>
                  <Link to="#" className="social-login  text-center">
                    <i className="fa fa-twitter"></i>
                  </Link>
                </div>
              </Card.Footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
