import React, { useState, useContext, useEffect } from "react";
import UserApiContext from "../UserApi/UserApiContext";
import {Link, useNavigate, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Card, Button } from "react-bootstrap";
import "./PropertyManager.css";
import axios from "axios";
import { toast } from "react-toastify";

const PropertyManager = () => {
  const navigate = useNavigate();
  const [allData, setAllData] = useState([]); // getting single data
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, []);
  const [active, setActive] = useState(true); // to set the condition to change the page after submit
  const location = useLocation();
  const id = location.state.id;
  const UserApi = useContext(UserApiContext);

  const { loggedInUserData, callingTaskMailer, sendDashboardData } = UserApi; // to get the Api

  let initialValue = { message: "" };
  const [message, setMessage] = useState(initialValue);
  const [tokendata, settokendata] = useState([]); // to get the user data

  // Logged in user data
  const Authtoken = localStorage.getItem("token");
  const token = async () => {
    const newData = await loggedInUserData(Authtoken);
    settokendata({ ...newData });
  };
  useEffect(() => {
    token();
  }, []);


    //................................promo............................
    const [promodata, setPromodata] = useState(0);
    const [allpromodata, setAllpromodata] = useState();
    const [promoApplied, setpromoApplied] = useState("false");
    const [userPromoCode, setUserPromo] = useState();
    
    console.log('log',allData)
    const sendusedpromodata = () => {
      let promoName = allpromodata.promoName;
      let promoCode = userPromoCode;
      let service = "Engage Property Manager";
      let custumerName = allData.name;
      let custumerId = allData.user_id;
      let Ammount = allpromodata.discountAmount;
      // let custumerId = userInfo.email;
      let companyName = allData.agencyName;
      axios
        .post(`${process.env.REACT_APP_BACKEND_DATA}/used-promo`, {
          promoCode,
          promoName,
          service,
          custumerId,
          custumerName,
          companyName,
          Ammount,
          id,
        })
        .then((response) => {})
        .catch((error) => {
          console.error(error);
        });
    };
    
    
    const validateuserPromo = () => {
      let userPromo = userPromoCode;
      let userState = allData.lookingToBuy;
      let userServise = "Engage Property Manager";
    
    
      axios
        .post(`${process.env.REACT_APP_BACKEND_DATA}/verify-promo`, {
          userPromo,
          userState,
          userServise,
        })
        .then((response) => {
    
          console.log(response);
          if (response.data.promodata) {
            setPromodata(response.data.promodata.discountAmount);
            setpromoApplied("true");
            setAllpromodata(response.data.promodata);
            // sendusedpromodata()
          }
          if (response.data.msg == "Successfully Applied Promocode") {
            toast.success(`${response.data.msg}`, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(response.data.msg, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    
    //................................promo............................
  const onChange = (e) => {
    setMessage({ ...message, [e.target.name]: e.target.value });
  };
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  
  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;
  
  const formattedToday = dd + '/' + mm + '/' + yyyy;
  const handleSubmit = () => {
    let todayDate = Date.parse(new Date());
    const newData = {
      payment: true,
      message: message.message,
      progress: "Completed",
      type: "Property Manager",
      state: location.state.state,
      createdDate: Date.now(),
      completeDate: formattedToday,
      taskId: uuidv4(),
    };
if(promoApplied == "true"){
  sendusedpromodata()
}
    sendDashboardData(newData, id);
    setMessage(initialValue);
    setActive(false);

    // calling mail api
    const mailData = {};
    mailData.customerName = allData.name;
    mailData.requestType = newData.type;
    mailData.taskid = newData.taskId;
    mailData.custId = id;
    mailData.Ammount = allpromodata != undefined ?allpromodata.discountAmount :"N/A";

    mailData.customerId = allData.user_id,
    mailData.customerEmailId = allData.email;
    mailData.customerPhoneNumber = allData.phone;
    mailData.purchaseType = allData.propertyPlan;
    mailData.propertyType = allData.propertType;
    mailData.propertyAddress = allData.address;
    if (allData.lookingToBuy != "N/A") {
      mailData.propertyState = allData.lookingToBuy;
    } else {
      mailData.propertyState = allData.propertyState;
    }
    mailData.instructionFromCustomer = newData.message;
    callingTaskMailer(mailData);
  };

  const dummy = async () => {
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/application/view-single-application/${id}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      });
      const initialValue = [];
      response.data.TASKNAME.map((newData) => {
        initialValue.push(newData);
      });
      // setGettingData(initialValue);
      setAllData(response.data);
      // console.log
    } catch (error) {}
  };
  useEffect(() => {
    dummy();
  }, []);
  return (
    <>
      {active ? (
        <Card className="property-manager-card mt-9 pt-7 pb-0 col-md-7">
          <Card.Header className="mx-auto" style={{ textAlign: "center" }}>
            <h3>
              One of our expert Property Management partner will contact you in
              one business day on{" "}
            </h3>
          </Card.Header>
          <Card.Body>
            <div>
              <div className=" property-manager-header d-flex  align-items-center">
                <i className="fe fe-phone mx-2" style={{ fontSize: "1rem" }} />
                {tokendata.phone}
              </div>
            </div>
            <div>
              <div className=" d-flex property-manager-header align-items-center">
                <i
                  className="fe fe-mail mx-2"
                  style={{ fontSize: "1rem", textTransform: "capitalize" }}
                />
                {tokendata.email}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center">
                <input
                  type="text"
                  className=" m-2 form-control"
                  onChange={(e) => setUserPromo(e.target.value)}
                  placeholder="Apply your promocode if you have any?"
                  style={{ width: "17rem" }}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12  ">
                <Button
                  onClick={validateuserPromo}
                  className="m-2 building-inspection-button "
                >
                  Apply Promo 
                </Button>
              </div>

              {promodata ? (
                <>
                  <div className="mt-4 text-center">
                    <h3>
                      Congratulations! you have received ${promodata} discount{" "}
                    </h3>
                    <h4>Property Manager will reduce the fee by ${promodata}</h4>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            <textarea
              className="border block col-md-9 mt-6 mx-auto p-2"
              style={{
                height: "100px",
                boxShadow: " 0px 0px 7px 2px #e9e7e7",
              }}
              name="message"
              type="text"
              id="mortgageMessage"
              cols="30"
              rows="10"
              placeholder="Any special message ?"
              onChange={onChange}
            ></textarea>
            <div className="mt-8" style={{ textAlign: "center" }}>
              <button
                className="m-4 building-inspection-button border"
                style={{
                  borderColor: " black",
                  borderRadius: "5px",
                  padding: "9px",
                }}
                onClick={() =>
                  navigate("/application-dashboard", {
                    state: {
                      id: id,
                    },
                  })
                }
              >
                Back
              </button>
              <Button
                className="m-4 building-inspection-button"
                onClick={handleSubmit}
              >
                Confirm
              </Button>
            </div>
          </Card.Body>
        </Card>
      ) : (
        <Card className="mt-9 pt-7 pb-0 col-md-7">
          <Card.Header className="mx-auto" style={{ textAlign: "center" }}>
            <h2>
              All done! Sit back and relax. Our prperty management expert will
              get in touch with you.
            </h2>
          </Card.Header>
          <Link className="mt-4" style={{ textAlign: 'center' }} target="_blank" to={'/blog-page'}><span  className="btn btn-success btn-icon text-white mb-3">Go to blog page</span></Link>
          <div style={{ textAlign: "center" }} className="my-6">
            <button
              className="btn btn-primary btn-icon text-white mb-5 "
              style={{ fontSize: "1rem" }}
              onClick={() => {
                navigate("/application-dashboard", {
                  state: {
                    id: id,
                  },
                });
              }}
            >
              Go back to Application Dashboard
            </button>
          </div>
        </Card>
      )}
    </>
  );
};

export default PropertyManager;
