import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalTitle, Card } from 'react-bootstrap';
import UserApiContext from '../UserApi/UserApiContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './UserModal.css';

var FileDownload = require('js-file-download');
function UserModal({ allData, category }) {
  const UserContext = useContext(UserApiContext);
  const { setUserModalHandler, userModalHandler } = UserContext;

  let solicitorAndconveyancer = '';
  if (category === 'solicitorAndconveyancer') {
    allData.map((data) => {
      if (data.type === 'Conveyancer') {
        return (solicitorAndconveyancer = 'Conveyancer');
      }
      if (data.type === 'Solicitor') {
        return (solicitorAndconveyancer = 'Solicitor');
      }
    });
  }
   
  console.log('all data',allData)
  const [alltask, setAlltask] = useState({});
  // const [mystr, setMystr] = useState(" ");

  const mytask = [];
  
  useEffect(() => {
    
    allData.map((data,index) => {

      mytask[index] = data.taskId

      console.log(' from map taskid  console',data.taskId);
      
      setAlltask({mytask})
   
    });

    


  }, []);
  
  console.log("allTask34",alltask);
  
    const [products, setProducts] = useState([]);
    
    
   
    const getProducts = async (allData) => {
      
      
      if(alltask.length != '' ) {
        console.log("hiiiiiiiiii", alltask);
        console.log("hloooooo", alltask.mytask);
        let ntask = alltask.mytask
        let result = await fetch(
          `${process.env.REACT_APP_BACKEND_DATA}/downloadall`,
          {
            method: 'post',
            
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ntask}),
          }
          );
          result = await result.json();
          setProducts(result);
        }
        console.log(products, 2345654);
      };
      
      setTimeout(() => {
       
        
      }, 3000);
      const myfunction =() => {
        
        console.log(alltask,34567)
      }
      myfunction();
      
  // console.log(products.data.preapprovelFile,2345678765434567);
  // console.log(products,9876789)
  // console.log(allData);
  // console.log(allData[0].approval.date,'approval');
  // console.log(allData[0].preapproval.date,'preapproval');
  var newDate="";
  var newDate2="";
  var newDate1="";

  try {
     if (allData !='') {
     if (allData[0].assignDate) {
       const ndate = allData[0].assignDate
       const [year, month, day] = ndate.split('-');
        newDate = `${day}-${month}-${year}`;
            //  console.log(newDate)
      
     }
     if(allData[0].approval )
     {
      if(allData[0].approval.date)
      {
     const appdate = allData[0].approval.date
         const [year1, month2, day3] = appdate.split('-');
          newDate1 = `${day3}-${month2}-${year1}`;
              //  console.log("hello raj",newDate1)
     }
   
    }
     if(allData[0].preapproval)
     {
      if(allData[0].preapproval.date){
     const predate = allData[0].preapproval.date
     const [year4, month5, day6] = predate.split('-');
     newDate2 = `${day6}-${month5}-${year4}`;
          //  console.log(newDate2)
     
    }
  }

}else{
 const newDate ="hello";
  console.log('else running');
}} catch (error) {
  const newDate ="hello catch";
  console.error("Something bad happened");
  console.error(error);
}

 

const downloadReport = (type) => {
  if (type) {
    const url = `${process.env.REACT_APP_BACKEND_DATA}/download-report?fileName=${type}`;
    axios
      .get(url, {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf",
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((response) => {
        FileDownload(response.data, "filename.pdf");
      })
      .catch((error) => {});
  } else {
    toast.warning("Letter is not available at this stage", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};
  console.log(products,'products returns api')

  if (products != '') {
       if (products.approvalFile) {
        console.log('tttttt',products )
       }
  }

  const [products2,setProducts2]=useState([])
  
  const getProducts1=async(tasktype,filetype,taskid)=>{

    console.log(tasktype,filetype,taskid)
   let result1 = await fetch(`${process.env.REACT_APP_BACKEND_DATA}/download_single/${tasktype}/${filetype}/${taskid}`)

   let result = await result1.json();
 
console.log(result);
   if(result.data != "undefined" && result.data != null)
   {
     console.log('this is productttt 1',result.data);
     window.open(`${process.env.REACT_APP_BACKEND_DATA}/${result.data}`);
     console.log('this is productttt',result.data);
   }
   else
   { toast.warning("Letter is not available at this stage", {
     position: "top-center",
     autoClose: 1000,
     hideProgressBar: false,
     closeOnClick: true,
     pauseOnHover: true,
     draggable: true,
     progress: undefined,
   });
     console.log('this is productttt',products2);
   }
  

 }
  return (
    <div>
      <Modal show={userModalHandler} size="lg">
        {category === 'Mortgage Broker' ? (
          allData.map((data, ind) =>
            data.type === 'Mortgage Broker' ? (
              <Card key={ind}>
                <div>
                  <div className="modal-header-content">
                    <span>Status:</span>
                    <span>{data.progress}</span>
                  </div>
                  <div className="modal-header-content">
                    <span>Assigned to Mortgage Broker:</span>
                    <span>{newDate ? newDate : ''}</span>
                  </div>
                  <div
                    className="modal-container-card"
                    style={{ margin: '40px 0' }}
                  >
                    <Card
                      className=" m-1 pt-6 pb-7 selectable-button"
                      style={{
                        background: 'hwb(231deg 95% 2%)',
                        color: '#2e2e2ec4',
                        cursor: 'pointer',
                      }}
                    >
                      <Card.Body>
                        {' '}
                        <Card.Header
                          className="mb-5"
                          style={{ textAlign: 'center' }}
                        >
                          <h1>Preapproval</h1>
                        </Card.Header>
                        <ul>
                          <li className="mb-2 d-flex  justify-content-between">
                            <span>Amount</span>
                            <span>
                              ${data.preapproval ? data.preapproval.amount : ""  }
                            </span>
                          </li>
                          <li className="mb-2 d-flex  justify-content-between">
                            <span>Bank</span>
                            <span>
                              {data.preapproval && data.preapproval.Bank}
                            </span>
                          </li>
                          <li className="mb-2 d-flex  justify-content-between">
                            <span>Date</span>
                            <span>
                              {newDate2 ? newDate2 : ''}
                            </span>
                          </li>
                        </ul>
                     
                        <Button    onClick={() =>   getProducts1(data.type,"Pre",data.task_id) }>  Download Preapproval letter   </Button>
                        
                     
                      </Card.Body>
                    </Card>
                    <Card
                      className=" m-1 pt-6 pb-7 selectable-button"
                      style={{
                        background: 'hwb(231deg 95% 2%)',
                        color: '#2e2e2ec4',
                        cursor: 'pointer',
                      }}
                    >
                      <Card.Body> 
                        {' '}
                        <Card.Header
                          className="mb-5"
                          style={{ textAlign: 'center' }}
                        >
                          <h1>Full approval</h1>
                        </Card.Header>
                        <ul>
                          <li className="mb-2 d-flex  justify-content-between">
                            <span>Amount</span>
                            <span>
                              ${data.approval ? data.approval.amount == 0 ? "":data.approval.amount :""}
                            </span>
                          </li>
                          <li className="mb-2 d-flex  justify-content-between">
                            <span>Bank</span>
                            <span>{data.approval && data.approval.Bank}</span>
                          </li>
                          <li className="mb-2 d-flex  justify-content-between">
                            <span>Date</span>
                            <span>{newDate1 ? newDate1 : ''}</span>
                          </li>
                        </ul>
                     
                          
                        
                        <Button    onClick={() =>   getProducts1(data.type,'Full',data.task_id) }>  Download Full approval letter   </Button>
           
                       
                      </Card.Body>
                    </Card>
                  </div>
                </div>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    className="me-1"
                    onClick={() => setUserModalHandler(false)}
                  >
                    Go back to Application Dashboard
                  </Button>
                </Modal.Footer>
              </Card>
            ) : (
              ''
            )
          )
        ) : (
          <div>
            <Modal.Header>
              {/* <ModalTitle>Legal Progress details</ModalTitle> */}
              <Button
                onClick={() => setUserModalHandler(false)}
                className="btn-close"
                variant=""
              >
                x
              </Button>
            </Modal.Header>
            {allData === undefined
              ? ''
              : allData.map((data, index) =>
                  data.type === solicitorAndconveyancer ||
                  data.type === category ? (
                    <Modal.Body
                      style={{ display: 'block', alignSelf: 'center' }}
                      key={index}
                    >
                      <h3
                        className="text-center m-5 border mb-8"
                        style={{ backgroundColor: 'whitesmoke' }}
                      >
                        {data.type === 'Solicitor' ||
                        data.type === 'Conveyancer'
                          ? 'Legal Progress'
                          : data.type === 'Building and Pest Inspection' ||
                            data.type === 'Building Inspection' ||
                            data.type === 'Pest Inspection'
                          ? 'Building/ Pest Report Progress'
                          : data.type === 'Property Manager'
                          ? 'Property Manager Engagement'
                          : data.type === 'Accountant'
                          ? 'Engage Accountant'
                          : data.type === 'Buyers Advocate'
                          ? 'Buyers Advocate'
                          : data.type === 'Depreciation Schedule'
                          ? 'Depreciation Schedule Report Progress'
                          : ''}
                      </h3>
                      <div className="d-flex m-4">
                        <div style={{ width: '200px' }}>Status :</div>
                        <div style={{ fontWeight: 'bold' }}>
                          {data.progress}
                        </div>
                      </div>
                      <div className="d-flex m-4">
                        <div style={{ width: '200px' }}>
                          Assigned to {' '}
                          {data.type === 'Solicitor'
                            ? 'Solicitor'
                            : data.type === 'Conveyancer'
                            ? 'Conveyancer'
                            : data.type === 'Building and Pest Inspection' ||
                              'Building Inspection' ||
                              'Pest Inspection'
                            ? ''
                            : ''}
                          :
                        </div>
                        <div style={{ fontWeight: 'bold' }}>
                          {new Date(data.createdDate).toLocaleDateString(
                            'en-IN'
                          )}
                        </div>
                      </div>
                      <div className="d-flex m-4">
                        <div style={{ width: '200px' }}>
                          {data.type === 'Solicitor' ||
                          data.type === 'Conveyancer'
                            ? 'Settlement'
                            : 'Inspection'}{' '}
                          Date :
                        </div>
                        <div style={{ fontWeight: 'bold' }}>
                          {data.settlementDate}
                        </div>
                      </div>
                      <button
                        className="btn btn-success btn-icon text-white mt-4"
                        style={{
                          display:
                            data.type === 'Solicitor' ||
                            data.type === 'Conveyancer' ||
                            data.type === 'Building and Pest Inspection' ||
                            data.type === 'Building Inspection' ||
                            data.type === 'Pest Inspection' ||
                            data.type === 'Depreciation Schedule'
                              ? 'block'
                              : 'none',
                          margin: 'auto',
                        }}
                        onClick={() =>
                          data.type === 'Building and Pest Inspection' ||
                          data.type === 'Building Inspection' ||
                          data.type === 'Pest Inspection' ||
                          data.type === 'Depreciation Schedule'
                            ?getProducts1(data.type,'Pest Inspection',data.task_id)
                            : data.type === 'Solicitor' ||
                              data.type === 'Conveyancer'
                            ?getProducts1(data.type,'NA',data.task_id)
                            : ''
                        }
                      >
                        Download Report
                      </button>
                    </Modal.Body>
                  ) : (
                    ''
                  )
                )}
            <Modal.Footer>
              <Button
                variant="primary"
                className="me-1"
                onClick={() => setUserModalHandler(false)}
              >
                Go back to Application Dashboard
              </Button>
            </Modal.Footer>
          </div>
        )}{' '}
      </Modal>
    </div>
  );
}

export default UserModal;
