import React, { useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Button } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";
import AdminContext from "../../../../../adminContext/AdminContext";
import {CSVLink, CSVDownload} from 'react-csv';

export const DataTables = ({
  incomingData,
  incomingPaymentData,
  incomingPartnerData,
}) => {
  const adminContext = useContext(AdminContext);
  let {
    stateLegalRevenueDetails,
    setStateLegalRevenueDetails,
    stateLegalPayableDetails,
    setStateLegalPayableDetails,
    stateLegalNetIncomeDetails,
    setStateLegalNetIncomeDetails,
  } = adminContext;


  const [] = useState();

  const [data, setData] = useState([]);
// console.log(incomingData);
  let newIncomingPartnerData =
    incomingPartnerData &&
    incomingPartnerData.map((data) => {
      return data.partnerServiceStateDetails.map((value) => {
        return value;
      });
    });

  let newPartnerDetails = [];
  newIncomingPartnerData.map((newData) => {
    return newData.map((elem) => {
      newPartnerDetails.push(elem);
    });
  });

  useEffect(() => {
    if (incomingData) {
      setData(incomingData);
    }
  }, [incomingData]);
  function convertArrayOfObjectsToCSV(array) {
    let result;
  
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <Button type="button" className="ml-5" onClick={()=>tableToCSV()}>  Export</Button>
  );

  let NetIncomeincomingPaymentData = 0;
  let NetnewPartnerDetails = 0;
  let newpartnerDetails = 0;
  let newpaymentDetail = 0;
  let newNetIncome = 0;
  let settingData = (data1, data2) => {
    newpaymentDetail = newpaymentDetail + data1;
    newpartnerDetails = newpartnerDetails + data2;
    newNetIncome = newNetIncome + data1 - data2;
    setStateLegalRevenueDetails(newpaymentDetail);
    setStateLegalPayableDetails(newpartnerDetails);
    setStateLegalNetIncomeDetails(newNetIncome);
  };
let netfee= 0;
  const columns = [
    {
      name: "Task ID",
      selector: (row) => [row.task_id],
      sortable: true,
    },
    {
      name: "Task Name",
      selector: (row) => [row.type],
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (row) => [row.name],
      sortable: true,
    },
    {
      name: "Customer ID",
      selector: (row) => [row.user],
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => [row.progress],
      sortable: true,
    },
    { 
      name: "Assigned Date",
      selector: (row) => (
        <div>
          {row.assignDate === undefined
            ? "N/A"
            : row.assignDate.split("-").reverse().join("/")}
        </div>
      ),
      sortable: true,
    },
    {
      name: "State",
      selector: (row) =>
        row.state2 !== "N/A"
          ? row.state2
          : row.state1 !== "N/A"
          ? row.state1
          : "",
      sortable: true,
    },
    {
      name: "Upfront Fee Customer Payment ",
      selector: (row) => row.upfrontFee,
        // incomingPaymentData
        //   .filter((data) => {
        //     return row.taskId === data.task_id;
        //   })
        //   .map((filteredData) => {
        //     NetIncomeincomingPaymentData = filteredData.paymentAmount;

        //     return filteredData.paymentAmount;
        //   }),
      sortable: true,
    },
    {
      name: "Upfront fee Payable to Partner ",
      selector: (row) =>
        newPartnerDetails
          .filter((data) => {
            return (
              row.assignTo === data.companyName &&
              row.state === data.partnerState
            );
          })
          .map((filteredData) => {
            NetnewPartnerDetails = filteredData.upfrontFee;
            return filteredData.upfrontFee;
          }),
      sortable: true,
    },
    {
      name: "Net Income",
      selector: (row) => 
      row.upfrontFee - (newPartnerDetails
        .filter((data) => {
          return (
            row.assignTo === data.companyName &&
            row.state === data.partnerState
          );
        }))
        .map((filteredData) => {
          NetnewPartnerDetails = filteredData.upfrontFee;
          return filteredData.upfrontFee;
        }),
        // settingData(
        //   parseInt(NetIncomeincomingPaymentData),
        //   parseInt(NetnewPartnerDetails)
        // );
        // return (
        //   parseInt(NetIncomeincomingPaymentData) -
        //   parseInt(NetnewPartnerDetails)
        // );
      
      sortable: true,
    },
  ];
  
  
  
  var ufPart = 0;
  let ui = newPartnerDetails.map((elm) => {
 
   if(elm.partnerState == "VIC"){
    ufPart +=  +elm.upfrontFee
   }
  })
  // console.log(ufPart); 
   var uf =0;
  let ai = data.map((elm) => {


    if(elm.upfrontFee){
       uf += +elm.upfrontFee
    }
  })
  
  let net =uf - ufPart
// console.log(net);
  const tableDatas = {
    columns,
    data,
  };
  const actionsMemo = React.useMemo(
    () => 
    // <CSVLink data={incomingData} ><span className="btn btn-primary">Export</span></CSVLink>,
    <Button type="button" className="ml-5" onClick={()=>tableToCSV()}>  Export</Button>,
    []
  );
  function tableToCSV() {
 
    // Variable to store the final csv data
    var csv_data = [];
  
    // Get each row data
    var rows = document.getElementsByClassName('trepx');
    console.log(rows);
    for (var i = 0; i < rows.length; i++) {
  
        // Get each column data
        var cols = rows[i].querySelectorAll('td,th');
  
        // Stores each csv row data
        var csvrow = [];
     
        for (var j = 0; j < cols.length; j++) {
  
            // Get the text data of each cell
            // of a row and push it to csvrow
            csvrow.push(cols[j].innerHTML);
        }
  
        // Combine each column value with comma
        csv_data.push(csvrow.join(","));
    }
  
    // Combine each row data with new line character
    csv_data = csv_data.join('\n');
  
    // Call this function to download csv file 
    downloadCSVFile(csv_data);
  
  }
  
  function downloadCSVFile(csv_data) {
  
    // Create CSV file object and feed
    // our csv_data into it
    var CSVFile = new Blob([csv_data], {
        type: "text/csv"
    });
  
    // Create to temporary link to initiate
    // download process
    var temp_link = document.createElement('a');
  
    // Download csv file
    temp_link.download = "Legal.csv";
    var url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;
  
    // This link should not be displayed
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);
  
    // Automatically click the link to
    // trigger download
    temp_link.click();
    document.body.removeChild(temp_link);
  }
  return (
    <>


<div className="d-none">

<table border="1" cellSpacing="0" cellPadding="10">
  
			<tr className="trepx">
				<th className="thexp">Task ID</th>
				<th className="thexp">Task Name</th>
				<th className="thexp">Customer Name</th>
				<th className="thexp">Customer ID</th>
				<th className="thexp">Status </th>
				<th className="thexp">Assigned Date</th>
				<th className="thexp">State</th>
				<th className="thexp">Upfront Fee Customer Payment</th>
				<th className="thexp">Upfront fee Payable to Partner</th>
				<th className="thexp">Net Income</th>
			</tr>
     { data?.map((elm)=>{
       return(
<>
      <tr className="trepx">
				<td>{elm.task_id}</td>
				<td>{elm.type}</td>
				<td>{elm.name}</td>
				<td>{elm.user}</td>
				<td>{elm.progress}</td>
				<td>{elm.assignDate}</td>
				<td>{elm.state}</td>
				<td>{elm.upfrontFee}</td>
				<td>{newPartnerDetails
          .filter((data) => {
            return (
              elm.assignTo === data.companyName &&
              elm.state === data.partnerState
              );
            })
            .map((filteredData) => {
              NetnewPartnerDetails = filteredData.upfrontFee;
              return filteredData.upfrontFee;
            })}</td>
				<td>{elm.upfrontFee - (newPartnerDetails
        .filter((data) => {
          return (
            elm.assignTo === data.companyName &&
            elm.state === data.partnerState
            );
          }))
          .map((filteredData) => {
            NetnewPartnerDetails = filteredData.upfrontFee;
            return filteredData.upfrontFee;
          })}
        </td>
			
				
        
      {/* <td className="trepx d-none">
      <td>Total</td>
      <td> Revenue: ${" "}{uf}
          </td>
      <td> Payable: ${" "}{ufPart}
         </td>
      <td>Net Income: ${" "}{net}
        
          </td>
    </td> */}
      </tr>
   
</>

      )
      })}
		</table>
        </div>
      <DataTableExtensions {...tableDatas}>
        <DataTable
          actions={actionsMemo}
          columns={columns}
          data={data}
          pagination
          highlightOnHover
          responsive
          dense
        />
      </DataTableExtensions>
      <div className="form-control d-flex" style={{ justifyContent: "end" }}>
        <div className="mx-5" style={{ fontWeight: "bold" }}>
          Revenue: $ {
          // stateLegalRevenueDetails
          uf
          }
        </div>
        <div className="mx-5" style={{ fontWeight: "bold" }}>
          Payable: $ {
          // stateLegalPayableDetails
          ufPart
          }
        </div>
        <div className="mx-5" style={{ fontWeight: "bold" }}>
          Net Income: $ {
          // stateLegalNetIncomeDetails
          net
          }
        </div>
      </div>
     {localStorage.setItem("legalRev", uf)}
     {localStorage.setItem("legalPayable", ufPart)}
     {localStorage.setItem("legalNet", net)}
    </>
  );
};
