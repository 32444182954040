import React from "react";
import "./howItWork.css";
import JustNestsCustomerJourney from "../../assets/images/homePage/JustNests-Customer-Journey.jpg";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Footer from "../../component/footer/Footer";
const HowItWork = () => {
  return (
    <>
      <div className="HowItWork">
        <div className="HowItWork-main-div">
          <div className="home-mid-section-1">
            <div className="home-mid-aside-1 changingPaddingBottom">
              <img src={JustNestsCustomerJourney} alt="About Image" />
            </div>
            <div className="home-mid-section-aside downPadding-home-mid changingBackground">
              <h2 className="home-top-title">
                How Just
                <span style={{ color: "var(--blueButton)" }}>nests</span> Works
              </h2>
              <div className="home-mid-section-aside-body">
                <div className="home-mid-section-2">
                  <span className="home-mid-section-list-number">
                    <span className="bulet-mar">1</span>
                  </span>
                  <span>
                    <div className="home-mid-section-aside-heading">
                      Tell us what you are looking to buy
                    </div>
                    <div className="home-mid-section-aside-paragraph marg">
                      Provide details about what you are looking to buy
                    </div>
                  </span>
                </div>
                <div className="home-mid-section-2">
                  <span className="home-mid-section-list-number">
                    <span className="bulet-mar">2</span>
                  </span>
                  <span>
                    <div className="home-mid-section-aside-heading">
                      Visit dashboard{" "}
                    </div>
                    <div className="home-mid-section-aside-paragraph marg">
                      Choose required services to see the magic happen{" "}
                    </div>
                  </span>
                </div>
                <div className="home-mid-section-3">
                  <span className="home-mid-section-list-number">
                    <span className="bulet-mar">3</span>
                  </span>
                  <span>
                    <div className="home-mid-section-aside-heading">
                      Experts will contact you
                    </div>
                    <div className="home-mid-section-aside-paragraph marg">
                      Depending on what services you have requested, experts
                      will contact you{" "}
                    </div>
                    <ul style={{ listStyleType: "none" }}>
                      <li>Mortgage expert will sort out your Finance</li>
                      <li>Legal expert will take care of your legal process</li>
                      <li>Building and Pest report will be provided to you</li>
                      <li>
                        Property management or Depreciation schedule or an
                        Accounting - You'll have one of the best expert of
                        everything at the click of a button
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HowItWork;
