import React, { useState, useEffect, useCallback, useContext } from "react";
import Table from "./taskTable/Table";
import { Card, Breadcrumb } from "react-bootstrap";
import AdminContext from "../../adminContext/AdminContext";
import { render } from "react-dom";

function Dashboard() {
  const initialValue = { startingDate: "", endingDate: "" };
  const [formInput, setFormInput] = useState(initialValue);
  const [incomingData, setIncomingData] = useState([]);
  const [usersData, setUsersData] = useState(incomingData);
  const dashboardContext = useContext(AdminContext);
  const { handleDashboardApi } = dashboardContext;
  const [render, setrender] = useState(2);

  useEffect(() => {
    callingApiForContext();
  }, [render]);
  // console.log(incomingData);
  const resevedDatafromchild = useCallback((data) => {
    // setDatafromChild(data)
    // console.log(datafromChild)
    setrender(data);
  });
  // setUsersData(table);
  const callingApiForContext = async () => {
    setIncomingData(await handleDashboardApi());
    setUsersData(await handleDashboardApi());
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const min = Date.parse(formInput.startingDate);
    const max = Date.parse(formInput.endingDate);

    let startdate = formInput.startingDate;

    let enddate = formInput.endingDate;

    const [year1, month2, day3] = startdate.split("-");
    let start = `${day3.replace("0", "")}/${month2.replace("0", "")}/${year1}`;
    const [year4, month5, day6] = enddate.split("-");
    let end = `${day6.replace("0", "")}/${month5.replace("0", "")}/${year4}`;

    console.log(start);
    console.log(end);
    let newUserData = incomingData.filter((data) => {
      //  console.log(+new Date(data.createdDate).toLocaleDateString('en-IN').split('/').reverse().join('') , +start.split('/').reverse().join('')  , +new Date(data.createdDate).toLocaleDateString('en-IN').split('/').reverse().join('') , +end.split('/').reverse().join(''))
      return (
        +new Date(data.createdDate)
          .toLocaleDateString("en-IN")
          .split("/")
          .reverse()
          .join("") >= +start.split("/").reverse().join("") &&
        +new Date(data.createdDate)
          .toLocaleDateString("en-IN")
          .split("/")
          .reverse()
          .join("") <= +end.split("/").reverse().join("")
      );
    });
    setUsersData(newUserData);
    // setUsersData(newUserData);
    // setFormInput(initialValue);
    console.log(newUserData);
  };

  const handleChange = (e) => {
    e.preventDefault();

    setFormInput((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });
  };
  const arr = usersData.sort(function (a, b) {
    return new Date(b.createdDate) - new Date(a.createdDate);
  });
  console.log(arr);
  return (
    <>
      <div>
        <div className="page-header  mx-3">
          <div>
            <h1 className="page-title text-light">Dashboard</h1>
            <Breadcrumb className="breadcrumb ">
              <Breadcrumb.Item className="breadcrumb-item text-light" href="#">
                Dashboard
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className="breadcrumb-item active breadcrumds"
                aria-current="page"
              >
                Data Table
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <Card>
          <form onSubmit={handleSubmit}>
            <div className="col-md-5 mb-4">
              <label htmlFor="startingDate">Creation Date Between :</label>
              <div className="input-group input-daterange">
                <input
                  type="date"
                  className="form-control"
                  style={{ fontSize: "15px", background: "#cef0ef" }}
                  value={formInput.startingDate}
                  name="startingDate"
                  onChange={handleChange}
                />
                <div
                  className="input-group-addon border px-2 py-3"
                  style={{ fontSize: "15px", background: "#f2f2f2" }}
                >
                  to
                </div>
                <input
                  type="date"
                  className="form-control"
                  style={{ fontSize: "15px", background: "#cef0ef" }}
                  value={formInput.endingDate}
                  name="endingDate"
                  onChange={handleChange}
                />
              </div>
              <button
                className="btn btn-success btn-icon text-white mt-4"
                style={{ margin: "auto", padding: ".5rem 2rem" }}
                onClick={handleSubmit}
              >
                Search
              </button>
              <button
                className="btn btn-primary btn-icon text-white ml-4 mt-4"
                style={{
                  margin: "auto",
                  marginLeft: "28px",
                  padding: ".5rem 2rem",
                }}
                onClick={() => window.location.reload()}
              >
                Refresh
              </button>
            </div>
          </form>
        </Card>
        <Table
          usersData={arr}
          resevedData={resevedDatafromchild}
          filterData={render}
        />
      </div>
    </>
  );
}

export default Dashboard;
