
import React, { useState, useRef, useContext, useEffect } from 'react';
import userContext from '../UserContext/UserContext';
import UserApiContext from '../UserApi/UserApiContext';
import axios from 'axios';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ApplicationDashboard from './ApplicationDashboard';


// function for google place api

const apiKey = 'AIzaSyA4SevNv3t7rPZiN2JJ5z4SMlcNpjewx3M';
const mapApiJs = 'https://maps.googleapis.com/maps/api/js';
const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';

// load google map api js

function loadAsyncScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    Object.assign(script, {
      type: 'text/javascript',
      async: true,
      src,
    });
    script.addEventListener('load', () => resolve(script));
    document.head.appendChild(script);
  });
}

const extractAddress = (place) => {
  const address = {
    city: '',
    state: '',
    zip: '',
    country: '',
    plain() {
      const city = this.city ? this.city + ', ' : '';
      const zip = this.zip ? this.zip + ', ' : '';
      const state = this.state ? this.state + ', ' : '';
      return city + zip + state + this.country;
    },
  };

  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach((component) => {
    const types = component.types;
    const value = component.long_name;

    if (types.includes('locality')) {
      address.city = value;
    }

    if (types.includes('administrative_area_level_2')) {
      address.state = value;
    }

    if (types.includes('postal_code')) {
      address.zip = value;
    }

    if (types.includes('country')) {
      address.country = value;
    }
  });
  return address;
};

function Updateaddress(props) {
  
  const navigate =  useNavigate()
  const [show, setShow] = useState(true);
  const [show1, setShow1] = useState(true);
    const context = useContext(userContext);
  const UserApi = useContext(UserApiContext);
  const [tokendata, settokendata] = useState([]); // to get the user data
  const [allData, setAllData] = useState([]);
  
  const {
   
 
    loggedInUserData,
    
  } = UserApi;
  //setting  the user prperty details
  const initialValue = {
    address: '',

   
  };
  const [details, setDetails] = useState(initialValue);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log('???>>>>???/', details.address, details.propertyState);
   
  };
  //  mearging the name with details
  console.log(details);

  const Authtoken = localStorage.getItem('token');
  const token = async () => {
    //Getting the logged in user data
    const newData = await loggedInUserData(Authtoken);
    // setDetails({
    //   ...details,
      
    // });
    settokendata({ ...newData });
  };
const objLength = Object.keys(props).length;
if(objLength != 0){

  props.setCaddress ( allData.address == 'N/A'
  ? allData.lookingToBuy
  : allData.address)
}
  useEffect(() => {
    token();
  }, []);
  const onChange = (e) => {
    setDetails((preState) => {
      console.log('prrr',);
      return { ...preState, [e.target.name]: e.target.value };
    });
  };

  // adding google place api---------------------------------------------
  const searchInput = useRef(null);
  const [address, setAddress] = useState({});

  // init gmap script
  const initMapScript = () => {
    // if script already loaded
    if (window.google) {
      return Promise.resolve();
    }

    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  };

  // do something on address change
  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    setAddress(extractAddress(place));
    setDetails((preState) => {
      return { ...preState, address: searchInput.current.value };
    });
    let completeAddress = searchInput.current.value;
    let nsw = /NSW/;
    let vic = /VIC/;
    let qld = /QLD/;
    let sa = /SA/;
    let wa = /WA/;
    let tas = /TAS/;
    let nt = /NT/;
    let act = /ACT/;
    if (nsw.test(completeAddress)) {
      setDetails((preState) => {
        return { ...preState, propertyState: 'NSW' };
      });
    }
    if (vic.test(completeAddress)) {
      setDetails((preState) => {
        return { ...preState, propertyState: 'VIC' };
      });
    }
    if (qld.test(completeAddress)) {
      setDetails((preState) => {
        return { ...preState, propertyState: 'QLD' };
      });
    }
    if (sa.test(completeAddress)) {
      setDetails((preState) => {
        return { ...preState, propertyState: 'SA' };
      });
    }
    if (wa.test(completeAddress)) {
      setDetails((preState) => {
        return { ...preState, propertyState: 'WA' };
      });
    }
    if (tas.test(completeAddress)) {
      setDetails((preState) => {
        return { ...preState, propertyState: 'TAS' };
      });
    }
    if (nt.test(completeAddress)) {
      setDetails((preState) => {
        return { ...preState, propertyState: 'NT' };
      });
    }
    if (act.test(completeAddress)) {
      setDetails((preState) => {
        return { ...preState, propertyState: 'ACT' };
      });
  }
  };

  // init autocomplete
  const initAutocomplete = () => {
    if (!searchInput.current) return;

    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current
    );
    // if (searchInput.current.value != null) {
    //   setDetails({ ...details, address: searchInput.current.value });
    // }

    autocomplete.setFields(['address_component', 'geometry']);
    autocomplete.addListener('place_changed', () =>
      onChangeAddress(autocomplete)
    );
  };

  const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
    const url = `${geocodeJson}?key=${apiKey}&latlng=${lat},${lng}`;

    searchInput.current.value = 'Getting your location...';
    fetch(url)
      .then((response) => response.json())
      .then((location) => {
        const place = location.results[0];
        const _address = extractAddress(place);

        setAddress(_address);
        searchInput.current.value = _address.plain();
      });
  };

  const findMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        reverseGeocode(position.coords);
      });
    }
  };


  // load map script after mounted

  
   
  
  useEffect(() => {
    dummy();
   
    initMapScript().then(() => initAutocomplete());
    
  }, []);
  const userApiContext = useContext(UserApiContext);
  const { feeDetails, setUserModalHandler, setCancelModal } = userApiContext;

  const location = useLocation();
  let id = '';

  if (location.state) {
    id = location.state.id;
  }
  //........................................................................................................................
  console.log('from send api',details);
  const collectData = async () => {
    let objid = id;
    console.log('from send api',details);
    
    console.log('obj id',objid);
    let result = await fetch(
      `${process.env.REACT_APP_BACKEND_DATA}/customer-updateaddress`,
      {
        method: 'post',

        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ details, objid }),
      }
    );
    result = await result.json();
    // console.log(result);
  };
  //...................................................................................................................................
  const [changeLoader, setChangingLoder] = useState(false);
  console.log('123456789876543223456789',id);
  const [incomingData, setIncomingData] = useState();

  const dummy = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_DATA}/application/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setAllData(response.data);
      })
      .catch((error) => {});
  };
  console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<<',allData);
  
  const handleUpdate = () => {
    toast.success('Updated successfully', {
      position: 'top-center',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    collectData()
  };
  
//....................................................................................................................................
console.log(details.propertyState); 
return (
  <div >
      { show ?(<div className='bg-primary'>



                
                 <div 
                 className='' 
                 >               
                   <select
                  className="form-control col-md-14 mx-auto mb-4"
                  name="propertyState"
                  onChange={onChange}
                  value={details.propertyState}
                  >
                  <option value="">Select State</option>
                  <option value="NSW">NSW</option>
                  <option value="VIC">VIC</option>
                  <option value="QLD">QLD</option>
                  <option value="SA">SA</option>
                  <option value="WA">WA</option>
                  <option value="TAS">TAS</option>
                  <option value="NT">NT</option>
                  <option value="ACT">ACT</option>
                </select>
                
                </div>

             
                     <input
                  className="form-control col-md-14 mx-auto mb-1"
                  name="address"
                  ref={searchInput}
                  type="text"
                  defaultValue={
                    allData.address == 'N/A'
                    ? allData.lookingToBuy
                    : allData.address
                  }
                  
                  />
                
                <button
                      type="button"
                      className="btn btn-success "
                      onClick={() => {
                        
                        handleUpdate()
                        window.location.reload()  
                        
                      }
                    }
                    >
                      Save{' '}
                    </button>
                <button
                      type="button"
                      className="btn btn-success m-2   "
                      onClick={() => {
                        
                        window.location.reload()  
                        // setShow(false)
                      }
                    }
                    >
                      Back{' '}
                    </button>
                    </div>):
                    null
                    }
                  </div>
               
                
                

                    
    
    
  )
}

export default Updateaddress
