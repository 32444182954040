import React, { useState, useContext, useEffect } from "react";
import { Button, Card, Form, Modal, ModalBody } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import AdminContext from "../../../adminContext/AdminContext";
import axios from "axios";
import { toast } from 'react-toastify';
import "./taskModal.css";
import d from './img/d.png'




// for MortgageBroker
function TaskType1() { 
  const location = useLocation();
  const navigate = useNavigate();
  const [cancelDropdown, setCancelDropdown] = useState(false);
  const [requiredDropdown, setRequiredDropdown] = useState(false);
  const [companyData, setCompanyData] = useState();
  const contextForCompanyName = useContext(AdminContext);
  const { handlePartnerApi } = contextForCompanyName;

  const callingApiForContext = async () => {
    setCompanyData(await handlePartnerApi());
  };
  useEffect(() => {
    callingApiForContext();
  }, []);

  let data = location.state;

  let newData = data.linkData[0][0];
  let initialValue;
  if (newData.preapproval || newData.approval) {
    initialValue = {
      assignTo: newData.assignTo,
      assignDate: newData.assignDate,
      preapprovalBank: newData.preapproval.Bank,
      preapprovalDate: newData.preapproval.date,
      preapprovalAmount: newData.preapproval.amount,
      approvalBank: newData.approval.Bank,
      approvalDate: newData.approval.date,
      approvalAmount: newData.approval.amount,
      progress: newData.progress,
      cancelText: "",
      cancelAttachedFile: "",
      notRequiredText: "",
      notRequiredAttachedFile: "",
    };
  } else {
    initialValue = {
      assignTo: newData.assignTo,
      assignDate: newData.assignDate,
      preapprovalBank: "",
      preapprovalDate: "",
      preapprovalAmount: "",
      approvalBank: "",
      approvalDate: "",
      approvalAmount: "",
      progress: newData.progress,
      cancelText: "",
      cancelAttachedFile: "",
      notRequiredText: "",
      notRequiredAttachedFile: "",
    };
  }
  
  const [formInput, setFormInput] = useState(initialValue);
  const [file1, setFile1] = useState('');
  const [file2, setFile2] = useState('');
  const [file3, setFile3] = useState('');
  const taskId = newData.task_id
  console.log(taskId);
  // console.log(newData,6667);
console.log(file1);
const today = new Date();
const yyyy = today.getFullYear();
let mm = today.getMonth() + 1; // Months start at 0!
let dd = today.getDate();

if (dd < 10) dd = '0' + dd;
if (mm < 10) mm = '0' + mm;

const formattedToday = dd + '/' + mm + '/' + yyyy;

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formattedToday);
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/application/update-dashboard-tasks`;
    const dataD = {
      _id: newData._id,
      type: newData.type,
      progress: formInput.progress,
      assignTo: formInput.assignTo,
      assignDate: formInput.assignDate,
      completeDate: formInput.progress === "Completed" || formInput.progress === "Fully Approved" ? formattedToday : "N/A",
      preapproval: {
        Bank: formInput.preapprovalBank,
        date: formInput.preapprovalDate,
        amount: formInput.preapprovalAmount,
      },
      approval: {
        Bank: formInput.approvalBank,
        date: formInput.approvalDate,
        amount: formInput.approvalAmount === "" ? 0 : formInput.approvalAmount,
      },
      reason: formInput.cancelText
        ? formInput.cancelText
        : formInput.notRequiredText,
      };
    axios
      .post(url, dataD, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "admin-token": localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {})
      .catch((error) => {});
      
      const url2 = `${process.env.REACT_APP_BACKEND_DATA}/upload`;
    const dataFile = new FormData();
    dataFile.append("name", newData._id);
    dataFile.append("taskId", taskId);
    dataFile.append("type", newData.type);
    dataFile.append("preapprovelFile", file1);
    dataFile.append("approvalFile", file2);
    dataFile.append("reasonFile", file3);
    console.log(76545678987654,".................",dataFile);
    axios
    .post(url2, dataFile, {
      responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf",
          "admin-token": localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {
       
      })
      .catch((error) => {
      
      });
    navigate("/admin/dashboard");
    setFormInput(initialValue);
  };

  const handleChange = (e) => {
    setFormInput((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });
  };

  let newCompanyData =
    companyData &&
    companyData.filter((data) => {
      return data.partnerCategory === newData.type;
    });

    
    // console.log(taskid);
    useEffect(()=>{
      getProducts()
        },[])
     const [products,setProducts]=useState([])
        const getProducts=async()=>{
            console.log(taskId,1234567)  
          let result = await fetch(`${process.env.REACT_APP_BACKEND_DATA}/download/${taskId}`)
          result = await result.json()
          setProducts(result)
    
          
        }
        

        //..........................................................................................

        const [products2,setProducts2]=useState([])
  
        const getProducts1=async(tasktype,filetype,taskid)=>{
          console.log(tasktype,filetype,taskid)
         let result1 = await fetch(`${process.env.REACT_APP_BACKEND_DATA}/download_single/${tasktype}/${filetype}/${taskid}`)
     
         let result = await result1.json();
       
     
     if(result.data !="undefined" && result.data != null){
           console.log('this is productttt 1',result.data);
           window.open(`${process.env.REACT_APP_BACKEND_DATA}/${result.data}`);
           console.log('this is productttt',result.data);
         }
         else
         { toast.warning("Letter is not available at this stage", {
           position: "top-center",
           autoClose: 1000,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
         });
           console.log('this is productttt',products2);
         }  
       }
        //...........................................................................................
  return (
    <div className="task-type-one">
      <form onSubmit={handleSubmit}>
        <Card>
          <Card.Header>
            <h3>Task Details</h3>
          </Card.Header>
        </Card>
        <div className="task-cards-group">
          <Card>
            <h4>Task Info</h4>
            <hr />
            <div className="card-inner-div">
              <div className="task-info-heading"> Task Id :</div>{" "}
              <div style={{ marginLeft: "15px", fontWeight: "500" }}>
                {newData.task_id}
              </div>
            </div>
            <hr />
            <div className="card-inner-div">
              <div className="task-info-heading"> Task Name : </div>
              <span style={{ marginLeft: "15px", fontWeight: "500" }}>
                {newData.type}
              </span>
            </div>
            <hr />
            <div className="card-inner-div">
              <div className="task-info-heading"> Creation Date :</div>{" "}
              <span style={{ marginLeft: "15px", fontWeight: "500" }}>
                {new Date(newData.createdDate).toLocaleDateString("en-IN")}
              </span>
            </div>
            <hr />
            <div className="card-inner-div">
              <div className="task-info-heading"> Status : </div>
              <span style={{ marginLeft: "15px", fontWeight: "500" }}>
                {newData.progress}
              </span>
            </div>
          </Card>
          <Card>
            <h4>Customer Info</h4>
            <hr />
            <div className="card-inner-div">
              <div className="task-info-heading"> Customer Id :</div>{" "}
              <span style={{ marginLeft: "15px", fontWeight: "500" }}>
                {newData.user}
              </span>
            </div>
            <hr />
            <div className="card-inner-div">
              <div className="task-info-heading"> Customer Name :</div>{" "}
              <span style={{ marginLeft: "15px", fontWeight: "500" }}>
                {newData.name}
              </span>
            </div>
            <hr />
            <div className="card-inner-div">
              <div className="task-info-heading"> Property Address :</div>{" "}
              <span style={{ marginLeft: "15px", fontWeight: "500" }}>
                {newData.address}
              </span>
            </div>
            <hr />
            <div className="card-inner-div">
              <div className="task-info-heading"> Property State :</div>{" "}
              <span style={{ marginLeft: "15px", fontWeight: "500" }}>
                {newData.state2 ? newData.state2 : newData.state1}
              </span>
            </div>
          </Card>
        </div>
        <div className="task-cards-group">
          <Card style={{ height: "12rem" }}>
            <div className="form-horizontal">
              <div className="card-inner-input" style={{ fontweight: "400" }}>
                <label className="col-md-3 form-label">Assign to </label>
                <select
                  className="form-select"
                  onChange={handleChange}
                  name="assignTo"
                  value={formInput.assignTo}
                >
                  <option value=""> Category of agent</option> 
                  {newCompanyData &&
                    newCompanyData.map((data, index) => (
                      <option value={data.companyName} key={index}>
                        {data.companyName}
                      </option>
                    ))}
                </select>
              </div>
              <Card.Body>
                <div className=" row mb-4">
                  <label htmlFor="inputEmail3" className="col-md-3 form-label">
                    Assign Date
                  </label>
                  <div className="col-md-9">
                    <input
                      type="date"
                      className="form-control"
                      name="assignDate"
                      id="date"
                      value={formInput.assignDate}
                      placeholder="Date"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </Card.Body>
            </div>
          </Card>
          <Card>
            <div className="form-group ">
              <div className="form-label">Update Status</div>
              <div className="custom-controls-stacked">
                <label className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="progress"
                    defaultValue="To Be Started"
                    onChange={handleChange}
                  />
                  <span className="custom-control-label">To Be Started</span>
                </label>
                <label className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="progress"
                    defaultValue="Completed"
                    onChange={handleChange}
                  />
                  <span className="custom-control-label">Completed</span>
                </label>
                <label className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="progress"
                    defaultValue="Fully Approved"
                    onChange={handleChange}
                  />
                  <span className="custom-control-label">Fully Approved</span>
                </label>
                <label className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="progress"
                    defaultValue="Pre Approved"
                    onChange={handleChange}
                  />
                  <span className="custom-control-label">Pre Approved</span>
                </label>
                <label className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="progress"
                    defaultValue="Cancelled"
                    onChange={handleChange}
                    onClick={() => setCancelDropdown(true)}
                  />
                  <span className="custom-control-label">Cancelled</span>
                </label>
                <Modal show={cancelDropdown}>
                  <Modal.Header>
                    <Modal.Title>Cancelled</Modal.Title>
                    <Button
                      onClick={() => setCancelDropdown(false)}
                      className="btn-close"
                      variant=""
                    >
                      x
                    </Button>
                  </Modal.Header>
                  <ModalBody>
                    <div>
                      <textarea
                        placeholder="Reason"
                        className="col-md-12 form-control m-2"
                        onChange={handleChange}
                        name="cancelText"
                      />
                    </div>
                    <div>
                      <input
                        className="form-control m-2"
                        type="file"
                        placeholder="Attach File"
                        onChange={(e) => setFile3(e.target.files[0])}
                        name="cancelAttachedFile"
                      />
                    </div>
                  </ModalBody>
                  <hr />
                  <div className="d-block text-end m-2">
                    <Button
                      variant="primary"
                      className="me-1"
                      onClick={() => setCancelDropdown(false)}
                    >
                      Close
                    </Button>
                    <Button
                      variant="default"
                      className="me-1"
                      onClick={() => setCancelDropdown(false)}
                    >
                      Save Changes
                    </Button>
                  </div>
                </Modal>
                <label className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="progress"
                    defaultValue="Not Required"
                    onChange={handleChange}
                    onClick={() => setRequiredDropdown(true)}
                  />
                  <span className="custom-control-label">Not Required</span>
                </label>
                {/* <Modal show={requiredDropdown}> */}
                  {/* <Modal.Header>
                    <Modal.Title>Not Required</Modal.Title>
                    <Button
                      onClick={() => setRequiredDropdown(false)}
                      className="btn-close"
                      variant=""
                    >
                      x
                    </Button>
                  </Modal.Header>
                  <ModalBody>
                    <div>
                      <textarea
                        placeholder="Reason"
                        className="col-md-12 form-control m-2"
                        name="notRequiredText"
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <input
                        className="form-control m-2"
                        type="file"
                        placeholder="Attach File"
                        name="notRequiredAttachedFile"
                        onChange={(e) => setFile3(e.target.files[0])}
                      />
                    </div>
                  </ModalBody>
                  <hr />
                  <div className="d-block text-end m-2">
                    <Button
                      variant="primary"
                      className="me-1"
                      onClick={() => setRequiredDropdown(false)}
                    >
                      Close
                    </Button>
                    <Button
                      variant="default"
                      className="me-1"
                      onClick={() => setRequiredDropdown(false)}
                    >
                      Save Changes
                    </Button>
                  </div> */}
                {/* </Modal> */}
              </div>
            </div>
          </Card>
        </div>
        <div className="task-cards-group">
          <Card>
            <Card.Header>
              <span><h4 className="card-title">Preapproval received</h4></span>  
            </Card.Header>
            <Card.Body>
              <div className="form-horizontal">
                <div className=" row mb-4">
                  <label htmlFor="bankName" className="col-md-3 form-label">
                    Bank
                  </label>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="form-control"
                      id="bankName"
                      name="preapprovalBank"
                      value={formInput.preapprovalBank}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className=" row mb-4">
                  <label htmlFor="date" className="col-md-3 form-label">
                    Date
                  </label>
                  <div className="col-md-9">
                    <input
                      type="date"
                      className="form-control"
                      id="date"
                      name="preapprovalDate"
                      value={formInput.preapprovalDate}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className=" row mb-4">
                  <label htmlFor="amount" className="col-md-3 form-label">
                    Amount
                  </label>
                  <div className="col-md-9">
                    <input
                      type="number"
                      className="form-control"
                      id="amount"
                      name="preapprovalAmount"
                      value={formInput.preapprovalAmount}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <Form.Group>
                  <div className="form-label">Attach Preapproval 
                  <Button    onClick={() =>   getProducts1("Mortgage Broker","Pre",newData.task_id) }>   <img src={d}  />  </Button>
                    </div>
                  <input
                    className="form-control"
                    type="file"
                    id="formFile"
                    onChange={(e) => setFile1(e.target.files[0])}
                  />
                </Form.Group>
              </div>
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>
              <h4 className="card-title">Full Approval received</h4>
              
            </Card.Header>
            <Card.Body>
              <div className="form-horizontal">
                <div className=" row mb-4">
                  <label htmlFor="bankName" className="col-md-3 form-label">
                    Bank
                  </label>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="form-control"
                      id="bankName"
                      name="approvalBank"
                      value={formInput.approvalBank}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className=" row mb-4">
                  <label htmlFor="date" className="col-md-3 form-label">
                    Date
                  </label>
                  <div className="col-md-9">
                    <input
                      type="date"
                      className="form-control"
                      id="date"
                      name="approvalDate"
                      value={formInput.approvalDate}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className=" row mb-4">
                  <label htmlFor="amount" className="col-md-3 form-label">
                    Amount
                  </label>
                  <div className="col-md-9">
                    <input
                      type="number"
                      className="form-control"
                      id="amount"
                      name="approvalAmount"
                      value={formInput.approvalAmount}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <Form.Group>
                  <div className="form-label">Attach Full Approval             
                  <Button    onClick={() =>   getProducts1("Mortgage Broker",'Full',newData.task_id) }>   <img src={d}  />  </Button>
                     </div>
                  <input
                    className="form-control"
                    type="file"
                    id="formFile"
                    onChange={(e) => setFile2(e.target.files[0])}
                  />
                </Form.Group>
              </div>
            </Card.Body>
          </Card>
        </div>
        <button
          className="btn btn-success btn-icon text-white"
          style={{ margin: "auto", padding: ".5rem 2rem", display: "block" }}
          type="submit"
        >
          {" "}
          UPDATE{" "}
        </button>
      </form>
    </div>
  );
}

export default TaskType1;
