import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

function ResetPassword() {
  const navigate = useNavigate();
  const initialValue = { email: "", password: "", confirmPassword: "" };
  const [passwordToggle1, setPasswordToggle1] = useState(false);
  const [passwordToggle2, setPasswordToggle2] = useState(false);
  const [error, setError] = useState(false);
  const [formInput, setFormInput] = useState(initialValue);

  const params = new URLSearchParams(window.location.search);

  const handleChange = (e) => {
    setFormInput((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      formInput.email === "" ||
      formInput.password === "" ||
      formInput.confirmPassword === ""
    ) {
      toast.error("The new Password and confirmation password do not match", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (formInput.password !== formInput.confirmPassword) {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 5000);
    } else {
      const url2 = `${
        process.env.REACT_APP_BACKEND_DATA
      }/api/password/reset-password?token=${params.get("token")}`;
      const data2 = {
        password: formInput.password,
      };
      axios
        .post(url2, data2, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "admin-token": localStorage.getItem("admin-token"),
          },
        })
        .then(({ data }) => {
          toast.success(data.msg, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate("/");
        })
        .catch((error) => {});
      setError(false);
    }
  };
  return (
    <form>
      <Card className="col-md-4 mt-9">
        <Card.Header>
          <h3>Reset Password</h3>
        </Card.Header>
        <Card.Body>
          <label className="form-label">
            Email
            <input
              className="form-control"
              type="email"
              placeholder="Enter your email address"
              name="email"
              value={formInput.email}
              onChange={handleChange}
            />
          </label>
          <label className="form-label">
            Password
            <div className="d-flex form-control">
              <input
                className="w-100"
                type={passwordToggle1 ? "text" : "password"}
                placeholder="Enter your password"
                name="password"
                value={formInput.password}
                onChange={handleChange}
              />
              <i
                onClick={() => setPasswordToggle1(!passwordToggle1)}
                className={
                  passwordToggle1 ? "fe fe-eye p-1" : "fe fe-eye-off p-1"
                }
              />
            </div>
          </label>
          <label className="form-label mb-5">
            Confirm Password
            <div className="d-flex form-control">
              <input
                className="w-100"
                type={passwordToggle2 ? "text" : "password"}
                placeholder="Enter your password"
                name="confirmPassword"
                value={formInput.confirmPassword}
                onChange={handleChange}
              />
              <i
                onClick={() => setPasswordToggle2(!passwordToggle2)}
                className={
                  passwordToggle2 ? "fe fe-eye p-1" : "fe fe-eye-off p-1"
                }
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                fontSize: "14px",
                color: "#rgb(237 39 39)",
                opacity: error ? "1" : "0",
              }}
            >
              please valid password
            </div>
          </label>
          <button
            type="submit"
            onClick={handleSubmit}
            className="btn btn-success col-md-5 d-block m-auto"
          >
            Reset
          </button>
        </Card.Body>
      </Card>
    </form>
  );
}

export default ResetPassword;
