import React, {useState, useCallback} from 'react';
import * as datatable from './TableFormat';
import { Row, Card, Col } from 'react-bootstrap';
import newImage from '../../../../assets/images/ZZ5H.gif';
function Table({ usersData ,filterData ,resevedData}) {
  console.log(filterData);
  // const {datafromChild ,setDatafromChild} = useState()

  
  return (
    <div>
      {usersData.length === 0 ? (
        <div
          style={{
            height: '80vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            className="text-center"
            style={{ fontSize: '2rem', fontWeight: 'bold', color: 'white' }}
          >
            No task available
          </div>
        </div> 
      ) : (
        <Row className=" row-sm">
          <Col lg={12}>
            <Card>
            
              <Card.Body>
                
                <div className="table-responsive">
                  
                  <datatable.DataTables usersData={usersData} sendData={resevedData} filterData={filterData} />
                    
                </div>
              </Card.Body>
              
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default Table;
