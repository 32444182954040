import React, { useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  MortgageTable,
  SolicitorTable,
  InspectionTable,
  DepreciationTable,
  PropertyTable,
  AccountantTable,BuyersTable,
  RevenueTable,
} from "./TablesRoute";
import AdminContext from "../../adminContext/AdminContext";
import newImage from "../../../assets/images/ZZ5H.gif";

function Reports() {
  const initialValue = {
    category: "",
    assignTo: "",
    startingDate: "",
    endingDate: "",
  };

  const [formInput, setFormInput] = useState(initialValue);
  const [heading, setHeading] = useState("Reports Details");
  const [companyData, setCompanyData] = useState();
  const [incomingData, setIncomingData] = useState([]);
  const [incomingPaymentData, setIncomingPaymentData] = useState([]);
  const contextForCompanyName = useContext(AdminContext);
  const { handlePartnerApi, handleDashboardApi, handlePaymentDetailsApi } =
    contextForCompanyName;
  const [Mortgagedata, setMortgagedata] = useState();
  const [Solicitordata, setSolicitordata] = useState();
  const [Buildingdata, setBuildingdata] = useState();
  const [Propertydata, setPropertydata] = useState();
  const [Byersdata ,setByersdata]= useState();
  const [Conveyancerdata, setConveyancerdata] = useState();
  const [Depreciationdata, setDepreciationdata] = useState();
  const [Accountantdata, setAccountantdata] = useState();
  const [companyName, setcompanyName] = useState();
  const [solicdata, setSolicData] = useState();
  const [depdata, setDepData] = useState();
  const [buildingData, setBuildingData] = useState();
  const companyshow = () => {
    // setCompanyData(await handlePartnerApi());
    let Mortgagedata = companyData?.filter((element) => {
      // console.log(element.partnerCategory)
      if (element.partnerCategory === "Mortgage Broker") {
        return element;
      }
    });
    let Solicitordata = companyData.filter((element) => {
      // console.log(element.partnerCategory)
      if (
        element.partnerCategory === "Solicitor" &&
        element.partnerCategory === "Conveyancer"
      ) {
        return element;
      }
    });
    let Buildingdata = companyData.filter((element) => {
      // console.log(element.partnerCategory)
      if (element.partnerCategory === "Building and Pest Inspection") {
        return element;
      }
    });

    let Depreciationdata = companyData.filter((element) => {
      // console.log(element.partnerCategory)
      if (element.partnerCategory === "Depreciation Schedule") {
        // console.log(element);
        return element;
      }
    });
    const Byersdata = companyData.filter((element) => {
      // console.log(element.partnerCategory)
      if (element.partnerCategory === "Buyers Advocate") {
        // console.log(element);
        return element;
      }
    });
    console.log(Byersdata);
    let Conveyancerdata = companyData.filter((element) => {
      // console.log(element.partnerCategory)
      if (element.partnerCategory === "Solicitor") {
        return element;
      }
      if (element.partnerCategory === "Conveyancer") {
        return element;
      }
    });
    let Propertydata = companyData.filter((element) => {
      // console.log(element)
      if (element.partnerCategory === "Property Manager") {
        return element;
      }
    });
    let Accountantdata = companyData.filter((element) => {
      // console.log(element.partnerCategory)
      if (element.partnerCategory === "Accountant") {
        return element;
      }
    });
    setMortgagedata(Mortgagedata);
    setSolicitordata(Conveyancerdata);
    setSolicitordata(Conveyancerdata);
    setBuildingdata(Buildingdata);
    setPropertydata(Propertydata);
    setByersdata(Byersdata)
    setDepreciationdata(Depreciationdata);
    setAccountantdata(Accountantdata);
  };
// console.log(companyData);
  let startdate = formInput.startingDate;

  let enddate = formInput.endingDate;

  const [year1, month2, day3] = startdate.split("-");
  let start = `${day3}/${month2}/${year1}`;
  const [year4, month5, day6] = enddate.split("-");
  let end = `${day6}/${month5}/${year4}`;

  // console.log( start, end);

  let filteredDataMortgage1 = incomingData.filter((element) => {
    // console.log(element.approval)
    if (element.approval) {
      return element;
    }
  });

  // console.log(enddate,startdate);
  // console.log(filteredDataMortgage2);

  // console.log(heading);
  // if(heading == "Mortgage Broker Report") {
  //     console.log(Mortgagedata );
  // }
  // if(heading == "Solicitor / Conveyancer Report") {
  //     console.log(Solicitordata );
  //     console.log(Conveyancerdata );
  // }
  // if(heading == "Building and Pest Inspection Report") {
  //     console.log(Depreciationdata );
  // }
  // if(heading == "Depreciation Report") {
  //     console.log(Depreciationdata );
  // }
  // if(heading == "Property Manager Report") {
  //     console.log(Propertydata );
  // }
  // if(heading == "Accountant Report") {
  //     console.log(Accountantdata );
  // }
  // console.log(Solicitordata );
  // console.log(Depreciationdata );
  // console.log(Conveyancerdata );
  // console.log(Propertydata );

  const callingApiForContext = async () => {
    setCompanyData(await handlePartnerApi());
    setIncomingData(await handleDashboardApi());
    setcompanyName(await handleDashboardApi());
    setDepData(await handleDashboardApi());
    setSolicData(await handleDashboardApi());
    setBuildingData(await handleDashboardApi());
    setIncomingPaymentData(await handlePaymentDetailsApi());

    // companyshow()
  };

  useEffect(() => {
    callingApiForContext();
  }, []);
  const [companyName2, setcompanyName2] = useState([]);
  const handleChange = (e) => {
    console.log(e.target.value);
    e.preventDefault();
    // setcompanyName2(e.target.value);
    setFormInput((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });

    if (e.target.value === "accountant") {
      setHeading("Accountant Report");
    } else if (e.target.value === "propertyManager") {
      setHeading("Property Manager Report");
    } else if (e.target.value === "Buyers Advocate") {
      setHeading("Buyers Advocate Report");
    } else if (e.target.value === "depreciationSchedule") {
      setHeading("Depreciation Report");
    } else if (e.target.value === "combinedInspection") {
      setHeading("Building and Pest Inspection Report");
    } else if (e.target.value === "solicitor-conveyancer") {
      setHeading("Solicitor / Conveyancer Report");
    } else if (e.target.value === "mortgageBroker") {
      setHeading("Mortgage Broker Report");
    } else if (e.target.value === "") {
      setHeading("Reports Details");
    }
  };

  const handleSubmit = () => {};

  const handleClick = (e) => {
    e.preventDefault();
    handleSubmite();
    // setHeading("Revenue Reports");

    // console.log(e.target.value);
  };

  const handleClick1 = (e) => {
    e.preventDefault();

    setHeading("Revenue Reports");

    // console.log(e.target.value);
  };

  let filteredDataMortgage = incomingData.filter((element) => {
    // console.log(element.type)
    if (element.type === "Mortgage Broker") {
      return element;
    }
  });
  //  const  [filteredDataMortgage,setfilteredDataMortgage] =useState(filteredDataMortgage1)
  let filteredDataSolicitorConveyancer = incomingData.filter((element) => {
    if (element.type === "Solicitor" || element.type === "Conveyancer") {
      return element;
    }
  });
  let filteredPaymentDataSolicitorConveyancer = incomingPaymentData.filter(
    (element) => {
      if (
        element.taskName === "Solicitor" ||
        element.taskName === "Conveyancer"
      ) {
        return element;
      }
    }
  );
  let filteredDataInspection = incomingData.filter((element) => {
    if (
      element.type === "Pest Inspection" ||
      element.type === "Building and Pest Inspection" ||
      element.type === "Building Inspection"
    ) {
      return element;
    }
  });
  let filteredPaymentDataInspection = incomingPaymentData.filter((element) => {
    if (
      element.taskName === "Pest Inspection" ||
      element.taskName === "Building and Pest Inspection" ||
      element.taskName === "Building Inspection"
    ) {
      return element;
    }
  });
  let filteredDataDepreciation = incomingData.filter((element) => {
    if (element.type === "Depreciation Schedule") {
      return element;
    }
  });
  let filteredPaymentDataDepreciation = incomingPaymentData.filter(
    (element) => {
      if (element.taskName === "Depreciation Schedule") {
        return element;
      }
    }
  );
  let filteredDataProperty = incomingData.filter((element) => {
    if (element.type === "Property Manager") {
      return element;
    }
  });
  let filteredDataAccountant = incomingData.filter((element) => {
    if (element.type === "Accountant") {
      return element;
    }
  });

  setTimeout(() => {
    companyshow();
  }, 1000);

  // console.log(filteredDataSolicitorConveyancer);

  let filterData;
  // const [incomingData,setUserData]= useState()
  let sum;
  const [companyDate, setcompanyDate] = useState();

  // console.log(solicdata);
  // console.log(depdata);
  // console.log(buildingData);
  const handleSubmite = () => {
    // date filter with companyname

    if (
      formInput.assignTo &&
      start != "undefined/undefined/" &&
      end != "undefined/undefined/"
    ) {
      // console.log("formInput.assignTo)");
      // console.log("company date else ");

      if (heading == "Mortgage Broker Report") {
        let filteredDataMortgage4 = filteredDataMortgage1?.filter((element) => {
          if (element.assignTo === formInput.assignTo) {
            return element;
          }
        });

        console.log("filteredDataMortgage4", filteredDataMortgage4);

        setcompanyDate(filteredDataMortgage4);

        let filteredDataMortgage2 = filteredDataMortgage4?.filter((element) => {
          console.log(filteredDataMortgage4);
          if (
            +element.completeDate.split("/").reverse().join("") >=
              +start.split("/").reverse().join("") &&
            +element.completeDate.split("/").reverse().join("") <=
              +end.split("/").reverse().join("")
          ) {
            return element;
          }
        });
        console.log("filteredDataMortgage2", filteredDataMortgage2);
        setcompanyName(filteredDataMortgage2);
      }

      if (heading == "Solicitor / Conveyancer Report") {
        console.log("solic");
        let filteredDataMortgage4 = filteredDataSolicitorConveyancer.filter(
          (element) => {
            if (element.assignTo === formInput.assignTo) {
              return element;
            }
          }
        );
        setcompanyDate(filteredDataMortgage4);
        console.log();
        let filteredDataMortgage2 = filteredDataMortgage4?.filter((element) => {
          if (
            element.completeDate != undefined &&
            element.completeDate != "N/A"
          ) {
            console.log(element);

            if (
              +element.completeDate.split("/").reverse().join("") >=
                +start.split("/").reverse().join("") &&
              +element.completeDate.split("/").reverse().join("") <=
                +end.split("/").reverse().join("")
            ) {
              return element;
            }
          }
        });
        setSolicData(filteredDataMortgage2);
      }

      if (heading == "Depreciation Report") {
        // console.log("dep");
        let filteredDataMortgage4 = filteredDataDepreciation.filter(
          (element) => {
            if (element.assignTo === formInput.assignTo) {
              return element;
            }
          }
        );
        setcompanyDate(filteredDataMortgage4);
        console.log(companyDate);
        let filteredDataMortgage2 = filteredDataMortgage4?.filter((element) => {
          if (
            +element.completeDate.split("/").reverse().join("") >=
              +start.split("/").reverse().join("") &&
            +element.completeDate.split("/").reverse().join("") <=
              +end.split("/").reverse().join("")
          ) {
            return element;
          }
        });
        setDepData(filteredDataMortgage2);
        console.log(filteredDataMortgage2);
      }

      if (heading == "Building and Pest Inspection Report") {
        console.log("buld");
        let filteredDataMortgage4 = filteredDataInspection.filter((element) => {
          if (element.assignTo === formInput.assignTo) {
            return element;
          }
        });
        setcompanyDate(filteredDataMortgage4);

        let filteredDataMortgage2 = filteredDataMortgage4?.filter((element) => {
          if (
            element.completeDate != undefined &&
            element.completeDate != "N/A"
          ) {
            if (
              +element.completeDate.split("/").reverse().join("") >=
                +start.split("/").reverse().join("") &&
              +element.completeDate.split("/").reverse().join("") <=
                +end.split("/").reverse().join("")
            ) {
              return element;
            }
          }
        });
        setBuildingData(filteredDataMortgage2);
      }
    } else if (formInput.assignTo) {
      console.log("company else ");

      // mortgage
      if (heading == "Mortgage Broker Report") {
        let filteredDataMortgage2 = filteredDataMortgage1.filter((element) => {
          console.log(heading);

          if (element.assignTo === formInput.assignTo) {
            return element;
          }
        });
        setcompanyName(filteredDataMortgage2);
      }
      if (heading == "Solicitor / Conveyancer Report") {
        let filteredDatasolic = filteredDataSolicitorConveyancer.filter(
          (element) => {
            console.log(element);

            if (element.assignTo === formInput.assignTo) {
              return element;
            }
          }
        );
        setSolicData(filteredDatasolic);
      }
      if (heading == "Depreciation Report") {
        console.log(filteredDataDepreciation);
        let filteredDataDepreciation1 = filteredDataDepreciation.filter(
          (element) => {
            console.log(element);
            console.log(formInput.assignTo);
            console.log(element.assignTo);

            if (element.assignTo === formInput.assignTo) {
              return element;
            }
          }
        );
        setDepData(filteredDataDepreciation1);
        console.log(filteredDataDepreciation1);
      }
      if (heading == "Building and Pest Inspection Report") {
        let filteredDataInspection1 = filteredDataInspection.filter(
          (element) => {
            console.log(element);
            console.log(formInput.assignTo);
            console.log(element.assignTo);

            if (element.assignTo === formInput.assignTo) {
              return element;
            }
          }
        );
        setBuildingData(filteredDataInspection1);
        console.log(filteredDataInspection1);
      }
    } else if (
      start != "undefined/undefined/" &&
      end != "undefined/undefined/"
    ) {
      console.log("date else ");

      if (heading == "Mortgage Broker Report") {
        let filteredDataMortgage2 = filteredDataMortgage1.filter((element) => {
          console.log("mort", end, start);

          if (
            +element.completeDate.split("/").reverse().join("") >=
              +start.split("/").reverse().join("") &&
            +element.completeDate.split("/").reverse().join("") <=
              +end.split("/").reverse().join("")
          ) {
            return element;
          }
        });
        setcompanyName(filteredDataMortgage2);
        // console.log(filteredDataMortgage2);
      }

      if (heading == "Solicitor / Conveyancer Report") {
        console.log("solic", filteredDataSolicitorConveyancer);
        let filteredDataMortgage2 = filteredDataSolicitorConveyancer.filter(
          (element) => {
            if (
              element.completeDate != undefined &&
              element.completeDate != "N/A"
            ) {
              if (
                +element.completeDate.split("/").reverse().join("") >=
                  +start.split("/").reverse().join("") &&
                +element.completeDate.split("/").reverse().join("") <=
                  +end.split("/").reverse().join("")
              ) {
                return element;
              }
            }
          }
        );
        setSolicData(filteredDataMortgage2);
        console.log(filteredDataMortgage2);
      }

      if (heading == "Depreciation Report") {
        console.log("dep");
        let filteredDataMortgage2 = filteredDataDepreciation.filter(
          (element) => {
            if (
              element.completeDate != undefined &&
              element.completeDate != "N/A"
            ) {
              if (
                +element.completeDate.split("/").reverse().join("") >=
                  +start.split("/").reverse().join("") &&
                +element.completeDate.split("/").reverse().join("") <=
                  +end.split("/").reverse().join("")
              ) {
                return element;
              }
            }
          }
        );
        setDepData(filteredDataMortgage2);
        console.log(filteredDataMortgage2);
      }

      if (heading == "Building and Pest Inspection Report") {
        console.log("build");
        let filteredDataMortgage2 = filteredDataInspection.filter((element) => {
          if (
            element.completeDate != undefined &&
            element.completeDate != "N/A"
          ) {
            if (
              +element.completeDate.split("/").reverse().join("") >=
                +start.split("/").reverse().join("") &&
              +element.completeDate.split("/").reverse().join("") <=
                +end.split("/").reverse().join("")
            ) {
              return element;
            }
          }
        });
        setBuildingData(filteredDataMortgage2);
        console.log(filteredDataMortgage2);
      }
    } else {
      console.log("else ");
      setcompanyName(filteredDataMortgage);
    }
  };

  // console.log(companyName);
  return (
    <>
      {incomingData.length === 0 ? (
        <div
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img style={{ maxWidth: "7%" }} src={newImage} />
        </div>
      ) : (
        <div className="mt-6">
          <Card className="mb-4">
            <Card.Header>
              <h2>{heading}</h2>
              <div className="ms-auto pageheader-btn">
                <Link to="/admin/reports/report-revenue-report">
                  <button
                    className="btn btn-primary btn-icon text-white me-3"
                    onClick={handleClick1}
                  >
                    <i className="fa fa-folder-open" />
                    Revenue Report
                  </button>
                </Link>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="col-lg-6 col-md-12">
                <div className="form-horizontal">
                  <div className=" row mb-4">
                    <label className="col-md-3 form-label">Report</label>
                    <div className="col-md-9">
                      <select
                        style={{ fontSize: "15px", background: "#cef0ef" }}
                        className="form-select"
                        name="category"
                        onChange={handleChange}
                      >
                        <option value="">Select Category</option>
                        <option value="mortgageBroker">Mortgage Broker</option>
                        <option value="Buyers Advocate">Buyers Advocate</option>
                        <option value="solicitor-conveyancer">
                          Solicitor / Conveyancer
                        </option>
                        <option value="combinedInspection">
                          Building and Pest Inspection
                        </option>
                        <option value="depreciationSchedule">
                          Depreciation Schedule
                        </option>
                        <option value="propertyManager">
                          Property Manager
                        </option>
                        <option value="accountant">Accountant</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
          {heading === "Reports Details" || heading === "Revenue Reports" ? (
            ""
          ) : (
            <Card className="mt-5">
              <Card.Body>
                <div className="row">
                  <form onSubmit={handleSubmit}>
                    <div className="col-md-12 mb-4">
                      <div className=" row mb-12">
                        <label className="col-md-3 form-label">Company</label>
                        <div className="col-md-4 mb-3">
                          <select
                            style={{ fontSize: "15px", background: "#cef0ef" }}
                            className="form-select"
                            onChange={handleChange}
                            name="assignTo"
                          >
                            <option value=""> Company Name</option>
                            {heading && heading == "Mortgage Broker Report"
                              ? Mortgagedata &&
                                Mortgagedata.map((data, index) => (
                                  // console.log(data)
                                  <option value={data.companyName} key={index}>
                                    {data.companyName}
                                  </option>
                                ))
                              : heading &&
                                heading == "Solicitor / Conveyancer Report"
                              ? Solicitordata &&
                                Solicitordata.map((data, index) => (
                                  // console.log(data)

                                  <option value={data.companyName} key={index}>
                                    {data.companyName}
                                  </option>
                                ))
                              : heading &&
                                heading == "Building and Pest Inspection Report"
                              ? Buildingdata &&
                                Buildingdata.map((data, index) => (
                                  // console.log(data)

                                  <option value={data.partnerName} key={index}>
                                    {data.partnerName}
                                  </option>
                                ))
                              : heading && heading == "Depreciation Report"
                              ? Depreciationdata &&
                                Depreciationdata.map((data, index) => (
                                  // console.log(data)

                                  <option value={data.partnerName} key={index}>
                                    {data.partnerName}
                                  </option>
                                ))
                              : heading && heading == "Buyers Advocate Report"
                              ? Byersdata &&
                              Byersdata.map((data, index) => (
                                  // console.log(data)

                                  <option value={data.partnerName} key={index}>
                                    {data.partnerName}
                                  </option>
                                ))
                              : heading && heading == "Property Manager Report"
                              ? Propertydata &&
                                Propertydata.map((data, index) => (
                                  // console.log(data)

                                  <option value={data.companyName} key={index}>
                                    {data.companyName}
                                  </option>
                                ))
                              : heading && heading == "Accountant Report"
                              ? Accountantdata &&
                                Accountantdata.map((data, index) => (
                                  // console.log(data)

                                  <option value={data.companyName} key={index}>
                                    {data.companyName}
                                  </option>
                                ))
                              : ""}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-7">
                        <label className="form-label" htmlFor="startingDate">
                          Completion Date Between :
                        </label>
                        <div className="input-group input-daterange">
                          <input
                            type="date"
                            style={{ fontSize: "15px", background: "#cef0ef" }}
                            className="form-control"
                            value={formInput.startingDate}
                            name="startingDate"
                            onChange={handleChange}
                          />
                          <div
                            className="input-group-addon border px-2 py-3"
                            style={{ fontSize: "15px", background: "#f2f2f2" }}
                          >
                            to
                          </div>
                          <input
                            style={{ fontSize: "15px", background: "#cef0ef" }}
                            type="date"
                            className="form-control"
                            value={formInput.endingDate}
                            name="endingDate"
                            onChange={handleChange}
                          />
                        </div>
                        <button
                          className="btn btn-success btn-icon text-white mt-4"
                          style={{ margin: "auto", padding: ".5rem 2rem" }}
                          onClick={handleClick}
                        >
                          Search
                        </button>
                        <button
                          className="btn btn-primary btn-icon text-white  mt-4"
                          style={{
                            margin: "auto",
                            marginLeft: "15px",
                            padding: ".5rem 2rem",
                          }}
                          onClick={() => window.location.reload()}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Card.Body>
            </Card>
          )}
          <div>
            {heading === "Mortgage Broker Report" ? (
              // console.log("filteredDataMortgage",filteredDataMortgage)

              <MortgageTable
                input={formInput}
                filter={companyName}
                incomingData={filteredDataMortgage}
              />
            ) : heading === "Solicitor / Conveyancer Report" ? (
              <SolicitorTable
                filter={solicdata}
                incomingData={filteredDataSolicitorConveyancer}
                incomingPaymentData={filteredPaymentDataSolicitorConveyancer}
              />
            ) : heading === "Building and Pest Inspection Report" ? (
              <InspectionTable
                filter={buildingData}
                incomingData={filteredDataInspection}
                incomingPaymentData={filteredPaymentDataInspection}
              />
            ) : heading === "Depreciation Report" ? (
              <DepreciationTable
                filter={depdata}
                incomingData={filteredDataDepreciation}
                incomingPaymentData={filteredPaymentDataDepreciation}
              />
            ) : heading === "Property Manager Report" ? (
              <PropertyTable incomingData={filteredDataProperty} />
            ) : heading === "Buyers Advocate Report" ? (
              <BuyersTable incomingData={filteredDataProperty} />
            ) : heading === "Accountant Report" ? (
              <AccountantTable incomingData={filteredDataAccountant} />
            ) : heading === "Revenue Reports" ? (
              <RevenueTable />
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Reports;
