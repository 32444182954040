import React, { useState } from "react";
import { Card } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

function ForgotPassword() {
  const navigate = useNavigate();
  const initialValue = { userEmail: "" };
  const [formInput, setFormInput] = useState(initialValue);
  const handleChange = (e) => {
    setFormInput((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const url1 = `${process.env.REACT_APP_BACKEND_DATA}/api/password/forgot-password`;
    const data1 = {
      email: formInput.userEmail,
    };

    axios
      .post(url1, data1, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(({ data }) => {
        toast.success(data.msg, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <form>
      <Card className="col-md-4 mt-9">
        <Card.Header>
          <h3>Forgot Password</h3>
        </Card.Header>
        <Card.Body>
          <label className="form-label mb-6">
            Email
            <input
              className="form-control"
              type="email"
              name="userEmail"
              placeholder="Enter your email address"
              onChange={handleChange}
            />
          </label>
          <button
            type="submit"
            onClick={handleSubmit}
            className="btn btn-success col-md-5 d-block m-auto"
          >
            Send Mail
          </button>
        </Card.Body>
      </Card>
    </form>
  );
}

export default ForgotPassword;
