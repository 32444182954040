import React from "react";
import "./termAndCondition.css";
import { Card, Button, Row, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../component/footer/Footer";
const TermAndCondition = () => {
  return (
    <div>
      <Card className="term-and-condition-main-div">
        {" "}
        <h1 className="term-and-condition-main-heading">
          Terms and Conditions
        </h1>
        <h2 className="term-and-condition-second-main-heading">
          Just Nests Pty Ltd (JustNests)
        </h2>
        <div className="term-and-condition-body">
          <div className="term-and-condition-bold-heading">
            Terms and Conditions
          </div>
          <p className="inline-term-and-condition-paragraph">
            The JustNests terms & conditions outline JustNest's and Your
            obligations and responsibilities on the JustNests Platform.
          </p>
          <div className="term-and-condition-bold-heading">
            User Agreement:{" "}
            <a href="http://www.JustNests.com.au">www.JustNests.com.au</a>
          </div>
          <p className="inline-term-and-condition-paragraph">
            JustNests operates an online platform allowing Users to connect
            through the JustNests platform with Service providers.{" "}
          </p>
          <p className="inline-term-and-condition-paragraph">
            By using JustNests platform you agree to these terms & Conditions
            and
            <Link to="/privacy-policy">Privacy Policy</Link> . Privacy Policies
            are incorporated into this Agreement by reference.
          </p>
          <div className="term-and-condition-points">
            <div className="term-and-condition-first-point">
              <div className="term-and-condition-bold-heading">
                <span>1</span> SCOPE OF JUSTNESTS SERVICES
              </div>
              <ul className="term-and-condition-section-points">
                <li>
                  <span>1.1</span> JustNests provides the JustsNests Platform to
                  enable users to receive services.
                </li>
                <li>
                  <span>1.2</span> JustNests doesn't associate or represent the
                  service providers. They are licensed and indipendent and
                  doesn't associate or act on behalf of JustNests. They help
                  users with their service request in their areas of expertise.
                </li>
                <li>
                  <span>1.3</span> Users may chose to receive one or more
                  service available in the platform. JustNests doesn't recommend
                  or oblige users to use the service providers on JustNests
                  platform. It's solely the decision of users.
                </li>
                <li>
                  <span>1.4</span> Users can view the information about the
                  progress and completion of their requested service in the
                  JustNests website.
                </li>
                <li>
                  <span>1.5</span> Users upon contacted by the service providers
                  have complete freedom to not to receive services or help.
                  JustNests doesn't recommend or advise users to act in any way.
                </li>
              </ul>
            </div>
            <div className="term-and-condition-second-point">
              <div className="term-and-condition-bold-heading">
                <span>2</span> JUSTNEST's ROLE AND OBLIGATIONS
              </div>
              <ul className="term-and-condition-section-points">
                <li>
                  <span>2.1</span> JustNests provides the JustsNests digital
                  platform, enabling the users to request for a service from the
                  service providers available in JustNests platform.
                </li>
                <li>
                  <span>2.2</span> Service providers in JustNests platform who
                  are licensed experts to service customers in their area of
                  service.
                </li>
                <li>
                  <span>2.3</span> Creating an account with JustNests is free
                  for users.
                </li>
                <li>
                  <span>2.4</span> JustNests accepts no liability for any aspect
                  of the user and Service Provider's interaction, including but
                  not limited to the performance or delivery of Services.
                </li>
                <li>
                  <span>2.5</span> JustNests has no obligation to any User to
                  assist or involve itself in any dispute between User and
                  Service Provider, although may do so to improve User
                  experience.
                </li>
              </ul>
            </div>
            <div className="term-and-condition-third-point">
              <div className="term-and-condition-bold-heading">
                <span>3</span> USER OBLIGATIONS
              </div>
              <ul className="term-and-condition-section-points">
                <li>
                  <span>3.1</span> You agree to comply with this terms and
                  conditions agreement, privacy policies and all applicable laws
                  and regulations.
                </li>
                <li>
                  <span>3.2</span> You understand that JustNests is just a
                  digital platform allowing to find a service provider.
                  JustNests doesn't represent or recommend any service provider
                  in its platform. It's completely your responsibility to
                  receive their services and JustNests doesn't have any
                  obligations or association with the work done by the service
                  providers.
                </li>
                <li>
                  <span>3.3</span> You agree that any content on JustNests
                  Platform may not be used on third party sites or for other
                  business purposes without JustNests' prior permission.
                </li>
                <li>
                  <span>3.4</span> You must not use the JustNests Platform for
                  any illegal purposes
                </li>
                <li>
                  <span>3.5</span> You agree that JustNests might send the
                  information about you and the service request to the Service
                  Provider through an email to help them provide service to you
                </li>
                <li>
                  <span>3.6</span> You are responsible for the accuracy of the
                  information you provide on JustNests platform
                </li>
              </ul>
            </div>
            <div className="term-and-condition-fourth-point">
              <div className="term-and-condition-bold-heading">
                <span>4</span> Fees
              </div>
              <ul className="term-and-condition-section-points">
                <li>
                  <span>4.1</span> JustNests is free to use platform for the
                  users however JustNests might receive commission from the
                  Service Provider for the referral of User requests to the
                  Service Provider.
                </li>
              </ul>
            </div>
            <div className="term-and-condition-fifth-point">
              <div className="term-and-condition-bold-heading">
                <span>5</span> PAYMENTS AND REFUNDS
              </div>
              <ul className="term-and-condition-section-points">
                <li>
                  <span>5.1</span> All payments for the services would need to
                  payed by the end users directly to the service provideres.
                </li>
                <li>
                  <span>5.2</span> JustNests doesn't receive any fees or payment
                  from the users and hence doesn't offer any refund facilities.
                </li>
                <li>
                  <span>5.3</span> In case the users are not satisfied with the
                  work of service providers after making the payment to them,
                  users have to directly deal with the service providers.
                </li>
              </ul>
            </div>
            <div className="term-and-condition-sixth-point">
              <div className="term-and-condition-bold-heading">
                <span>6</span> MODIFICATIONS TO THE AGREEMENT
              </div>
              <ul className="term-and-condition-section-points">
                <li>
                  <span>6.1</span> JustNests may carry out updates to this terms
                  & conditions and also to the Privacy policy to keep up with
                  the changes to services, product, to comply with legal,
                  compliance and regulatory guidelines. You can see updated
                  agreement in JustNests website. You agree that you comply with
                  updated terms and conditions when using the JustNests
                  platform.
                </li>
                <li>
                  <span>6.2</span> If You do not agree at any stage with any
                  changes to terms and conditions or privacy policy, you must
                  stop using JustNests platform.
                </li>
              </ul>
            </div>
            <div className="term-and-condition-seventh-point">
              <div className="term-and-condition-bold-heading">
                <span>7</span> MEDIATION AND DISPUTE RESOLUTION
              </div>
              <ul className="term-and-condition-section-points">
                <li>
                  <span>7.1</span>
                  In case there is a dispute with the service provider,
                  JustNests encourages You to co-operate and make reasonable
                  genuine attempt to directly resolve them with the Service
                  Provider.
                </li>
                <li>
                  <span>7.2</span> JustNests is not liable for Service provided
                  by the Service Provider however might chose to resolve dispute
                  for better User experience.
                </li>
                <li>
                  <span>7.3</span> If you have a complaint about a Service
                  Provider please contact JustNests
                </li>
              </ul>
            </div>
            <div className="term-and-condition-eighth-point">
              <div className="term-and-condition-bold-heading">
                <span>8</span> TERMINATION
              </div>
              <ul className="term-and-condition-section-points">
                <li>
                  <span>8.1</span>
                  Either You or JustNests may terminate your account and this
                  Agreement at any time for any reason.
                </li>
                <li>
                  <span>8.2</span> Termination of this Agreement does not affect
                  any ongoing services between the User and the Service
                  Provider.
                </li>
                <li>
                  <span>8.3</span> Services provided by Service Providers are
                  governed by their terms and conditions.
                </li>
                <li>
                  <span>8.4</span> If your account or this Agreement are
                  terminated by JustNests for any reason then You may not create
                  another account with JustNests without prior consent from
                  JustNest.
                </li>
              </ul>
            </div>
            <div className="term-and-condition-ninth-point">
              <div className="term-and-condition-bold-heading">
                <span>9</span> GENERAL
              </div>
              <ul className="term-and-condition-section-points">
                <li>
                  <span>9.1</span>
                  This Agreement is governed by the laws specified in Australia
                </li>
                <li>
                  <span>9.2</span> This Agreement sets our the entire
                  understanding and agreement between the User and JustNests.
                </li>
                <li>
                  <span>9.3</span> The provisions of this Agreement are
                  severable and enforceable.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Card>
      <Footer />
    </div>
  );
};

export default TermAndCondition;
