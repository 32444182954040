import React, { useState, useRef, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CategoryCard1 from './partnerCategory/PartnerCategoryCard1';
import CategoryCard2 from './partnerCategory/PartnerCategoryCard2';
import PartnerHistory from './partnerCategory/PartnerHistory';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import './partnerCategory/AddPartner.css';

// import userContext from '../UserContext/UserContext';
// import UserApiContext from '../UserApi/UserApiContext';

// function for google place api

const apiKey = 'AIzaSyA4SevNv3t7rPZiN2JJ5z4SMlcNpjewx3M';
const mapApiJs = 'https://maps.googleapis.com/maps/api/js';
const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';

// load google map api js

function loadAsyncScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    Object.assign(script, {
      type: 'text/javascript',
      async: true,
      src,
    });
    script.addEventListener('load', () => resolve(script));
    document.head.appendChild(script);
  });
}

const extractAddress = (place) => {
  const address = {
    city: '',
    state: '',
    zip: '',
    country: '',
    plain() {
      const city = this.city ? this.city + ', ' : '';
      const zip = this.zip ? this.zip + ', ' : '';
      const state = this.state ? this.state + ', ' : '';
      return city + zip + state + this.country;
    },
  };

  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach((component) => {
    const types = component.types;
    const value = component.long_name;

    if (types.includes('locality')) {
      address.city = value;
    }

    if (types.includes('administrative_area_level_2')) {
      address.state = value;
    }

    if (types.includes('postal_code')) {
      address.zip = value;
    }

    if (types.includes('country')) {
      address.country = value;
    }
  });
  return address;
};


function AddPartner() {
//........................................................................................

 
const [tokendata, settokendata] = useState([]); // to get the user data
const [allData, setAllData] = useState([]);


const [details, setDetails] = useState();

const handleSubmit1 = async (e) => {
  e.preventDefault();
 
  
};
console.log(details);

const onChange = (e) => {
  setDetails((preState) => {
    console.log('prrr',);
    return { ...preState, [e.target.name]: e.target.value };
  });
};

// adding google place api---------------------------------------------
const searchInput = useRef(null);
const [address, setAddress] = useState({});

// init gmap script
const initMapScript = () => {
  // if script already loaded
  if (window.google) {
    return Promise.resolve();
  }

  const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
  return loadAsyncScript(src);
};

// do something on address change
const onChangeAddress = (autocomplete) => {
  const place = autocomplete.getPlace();
  setAddress(extractAddress(place));
  setDetails((preState) => {
    return { ...preState, address: searchInput.current.value };
  });
  let completeAddress = searchInput.current.value;
  let nsw = /NSW/;
  let vic = /VIC/;
  let qld = /QLD/;
  let sa = /SA/;
  let wa = /WA/;
  let tas = /TAS/;
  let nt = /NT/;
  let act = /ACT/;
  if (nsw.test(completeAddress)) {
    setDetails((preState) => {
      return { ...preState, propertyState: 'NSW' };
    });
  }
  if (vic.test(completeAddress)) {
    setDetails((preState) => {
      return { ...preState, propertyState: 'VIC' };
    });
  }
  if (qld.test(completeAddress)) {
    setDetails((preState) => {
      return { ...preState, propertyState: 'QLD' };
    });
  }
  if (sa.test(completeAddress)) {
    setDetails((preState) => {
      return { ...preState, propertyState: 'SA' };
    });
  }
  if (wa.test(completeAddress)) {
    setDetails((preState) => {
      return { ...preState, propertyState: 'WA' };
    });
  }
  if (tas.test(completeAddress)) {
    setDetails((preState) => {
      return { ...preState, propertyState: 'TAS' };
    });
  }
  if (nt.test(completeAddress)) {
    setDetails((preState) => {
      return { ...preState, propertyState: 'NT' };
    });
  }
  if (act.test(completeAddress)) {
    setDetails((preState) => {
      return { ...preState, propertyState: 'ACT' };
    });
  }
};

// init autocomplete
const initAutocomplete = () => {
  if (!searchInput.current) return;

  const autocomplete = new window.google.maps.places.Autocomplete(
    searchInput.current
  );

  autocomplete.setFields(['address_component', 'geometry']);
  autocomplete.addListener('place_changed', () =>
    onChangeAddress(autocomplete)
  );
};

const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
  const url = `${geocodeJson}?key=${apiKey}&latlng=${lat},${lng}`;

  searchInput.current.value = 'Getting your location...';
  fetch(url)
    .then((response) => response.json())
    .then((location) => {
      const place = location.results[0];
      const _address = extractAddress(place);

      setAddress(_address);
      searchInput.current.value = _address.plain();
    });
};

const findMyLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      reverseGeocode(position.coords);
    });
  }
};

useEffect(() => {
  initMapScript().then(() => initAutocomplete());
  
}, []);

const location1 = useLocation();
let id = '';

if (location1.state) {
  id = location1.state.id;
} 

//============================================================================================================================================================
  //...............................................................................................................................................
  const navigate = useNavigate();
  const location = useLocation();
  let linkData1 = {};
  let partnerData;
  let array1 = [];

  if (location.state) {
    linkData1 = location.state[0][0];
    partnerData = linkData1.partnerServiceStateDetails;
  }

  // const [array1, setServiceState]= useState();
  var [isChecked, setIsChecked] = useState({
    NSW: false,
    VIC: false,
    QLD: false,
    SA: false,
    WA: false,
    TAS: false,
    NT: false,
    ACT: false,
    ALL: false,
  });

  console.log(isChecked);
  const [allState, setAllState] = useState([
    'NSW',
    'VIC',
    'QLD',
    'SA',
    'WA',
    'TAS',
    'NT',
    'ACT',
    'ALL',
  ]);

  if (linkData1 === {}) {
    var initialValue1 = {
      partnerName: '',
      companyName: '',
      partnerEmail: '',
      partnerNumber: '',
      partnerAddress: '',
      partnerCategory: '',
      fee: '',
    };
  } else {
    initialValue1 = {
      partnerName: linkData1.partnerName,
      companyName: linkData1.companyName,
      partnerEmail: linkData1.partnerEmail,
      partnerNumber: linkData1.partnerNumber,
      partnerAddress: linkData1.partnerAddress,
      partnerCategory: linkData1.partnerCategory,
      fee: linkData1.fee,
    };
  }

  const [formInput, setFormInput] = useState(initialValue1);
  const [incomingValue, setIncomingValue] = useState();
  const [error, setError] = useState();

  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [checkbox3, setCheckbox3] = useState(false);
  const [checkbox4, setCheckbox4] = useState(false);
  const [checkbox5, setCheckbox5] = useState(false);
  const [checkbox6, setCheckbox6] = useState(false);
  const [checkbox7, setCheckbox7] = useState(false);
  const [checkbox8, setCheckbox8] = useState(false);
  const [checkbox9, setCheckbox9] = useState(false);

  const [counter, setCounter] = useState(0);

  const checkBoxFunction = () => {
    // linkData1.partnerState &&
    //   linkData1.partnerState.map((data) => {
    //     if (data === 'NSW') {
    setCheckbox1(isChecked.NSW);
    // }
    // if (data === 'VIC') {
    setCheckbox2(isChecked.VIC);
    // }
    // if (data === 'QLD') {
    setCheckbox3(isChecked.QLD);
    // }
    // if (data === 'SA') {
    setCheckbox4(isChecked.SA);
    // }
    // if (data === 'WA') {
    setCheckbox5(isChecked.WA);
    // }
    // if (data === 'TAS') {
    setCheckbox6(isChecked.TAS);
    // }
    // if (data === 'NT') {
    setCheckbox7(isChecked.NT);
    setCheckbox9(isChecked.ACT);
    // }
    // if (data === 'ALL') {
    setCheckbox8(isChecked.ALL);
    // }
    // });/
    setFormInput((init) => {
      return {
        ...init,
        checkbox1: isChecked.NSW,
        checkbox2: isChecked.VIC,
        checkbox3: isChecked.QLD,
        checkbox4: isChecked.SA,
        checkbox5: isChecked.WA,
        checkbox6: isChecked.TAS,
        checkbox7: isChecked.NT,
        checkbox9: isChecked.ACT,
        // checkbox8: isChecked.ALL,
      };
    });
  };
  // useEffect(() => {
  //   checkBoxFunction();
  // }, []);

  const importFormData = (data) => {
    setIncomingValue(data);
  };

  if (formInput.checkbox1) {
    array1.push(formInput.checkbox1);
  }
  if (formInput.checkbox2) {
    array1.push(formInput.checkbox2);
  }
  if (formInput.checkbox3) {
    array1.push(formInput.checkbox3);
  }
  if (formInput.checkbox4) {
    array1.push(formInput.checkbox4);
  }
  if (formInput.checkbox5) {
    array1.push(formInput.checkbox5);
  }
  if (formInput.checkbox6) {
    array1.push(formInput.checkbox6);
  }
  if (formInput.checkbox7) {
    array1.push(formInput.checkbox7);
  }
  if (formInput.checkbox8) {
    
    array1.push(formInput.checkbox8);
  }
  if (formInput.checkbox9) {
    
    array1.push(formInput.checkbox9);
  }

  const handleSubmit = (event) => {
    let tosterE = () =>
      toast.error('Field is required', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    let tosterS = () =>
      toast.success('Form successfully submitted', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    event.preventDefault();
    if (
      formInput.partnerName === '' ||
      formInput.companyName === '' ||
      formInput.partnerEmail === '' ||
      // formInput.partnerNumber === '' ||
      formInput.partnerNumber.length != 10 ||
      formInput.partnerAddress === details.address ||
      formInput.partnerCategory === '' ||
      (!isChecked.NSW &&
        !isChecked.NT &&
        !isChecked.QLD &&
        !isChecked.SA &&
        !isChecked.TAS &&
        !isChecked.VIC &&
        !isChecked.WA &&
        !isChecked.ACT &&
        !isChecked.ALL)
    ) {
      setError(tosterE);
    } else {
      setError(tosterS);
      const url = `${process.env.REACT_APP_BACKEND_DATA}/api/partners/partner-details`;
      let keys = Object.keys(isChecked);
      let filtered = [];
      if (isChecked.ALL) {
        filtered = ['NSW', 'VIC', 'QLD', 'SA', 'WA', 'TAS', 'ACT','NT'];
      } else {
        filtered = keys.filter((key) => isChecked[key]);
      }
      const data = {
        partnerName: formInput.partnerName,
        companyName: formInput.companyName,
        partnerEmail: formInput.partnerEmail,
        partnerNumber: formInput.partnerNumber,
        partnerAddress: details.address,
        partnerCategory: formInput.partnerCategory,
        fee: formInput.fee,
        partnerState: filtered,
        partnerServiceStateDetails: incomingValue,
      };
      axios
        .post(url, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'admin-token': localStorage.getItem('admin-token'),
          },
        })
        .then((response) => {})
        .catch((error) => {});

      navigate('/admin/partner-details');

      setFormInput(initialValue);
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    if ( formInput.partnerNumber.length != (10) 
    ) {
      toast.error('Enter 10 digits number', {
        position: 'top-center',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }else{
    const url = `${process.env.REACT_APP_BACKEND_DATA}/api/partners/update-partner-details`;
    let keys = Object.keys(isChecked);

    
    let filtered = [];
    if (isChecked.ALL) {
      filtered = ['NSW', 'VIC', 'QLD', 'SA', 'WA', 'TAS','ACT', 'NT'];
    } else {
      filtered = keys.filter((key) => isChecked[key]);
    }

    // console.log(formInput.partnerAddress);
    // console.log(details.address);
    const data = {
      _id: linkData1._id,
      partnerName: formInput.partnerName,
      companyName: formInput.companyName,
      partnerEmail: formInput.partnerEmail,
      partnerNumber: formInput.partnerNumber,
      partnerAddress:  details?details.address:formInput.partnerAddress,
      partnerCategory: formInput.partnerCategory,
      fee: formInput.fee,
      partnerState: filtered,
      partnerServiceStateDetails: !incomingValue
        ? linkData1.partnerServiceStateDetails
        : linkData1.partnerServiceStateDetails.concat(incomingValue),
    };
    const array2 = linkData1.partnerState.concat(array1);
    // console.log(data);
    // return;

    axios
      .put(url, data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'admin-token': localStorage.getItem('admin-token'),
        },
      })
      .then((response) => {})
      .catch((error) => {});
    navigate('/admin/partner-details');
  }
  };

  const handleChange = (e) => {
    console.log('click >>>>>', e.target.name, e.target.value);

    setFormInput((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });
    checkBoxFunction();
  };

  let a = JSON.stringify(linkData1);

  useEffect(() => {
    if (location.state) {
      let getDateTemp = location.state[0][0].partnerState;
      // let temp2 = getDateTemp.reduce((a, v) => ({ ...a, [v]: true }), {});
      getDateTemp.map((data) => (isChecked[data] = true));
      if (getDateTemp.length == 8) {
        isChecked.ALL = true;
      }
    }
  }, [location]);
  useEffect(() => {
    checkBoxFunction();
  }, [counter]);
  return (
    <div>
      <Row>
        <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
          <form>
            <Card>
              <Card.Header>
                <h4 className="card-title">ADD PARTNER</h4>
              </Card.Header>
              <Card.Body>
                <Row>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-horizontal">
                      <div className=" row mb-4">
                        <label className="col-md-3 form-label">Name</label>
                        <div className="col-md-9">
                          <input
                           style={{ background: '#cef0ef' }}
                            type="text"
                            name="partnerName"
                            value={formInput.partnerName}
                            className="form-control"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className=" row mb-4">
                        <label className="col-md-3 form-label">
                          Company Name
                        </label>
                        <div className="col-md-9">
                          <input
                           style={{ background: '#cef0ef' }}
                            type="text"
                            name="companyName"
                            value={formInput.companyName}
                            className="form-control"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className=" row mb-4">
                        <label
                          className="col-md-3 form-label"
                          htmlFor="example-email"
                        >
                          Email
                        </label>
                        <div className="col-md-9">
                          <input
                           style={{ background: '#cef0ef' }}
                            type="email"
                            id="email"
                            name="partnerEmail"
                            value={formInput.partnerEmail}
                            className="form-control"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className=" row mb-4">
                        <label className="col-md-3 form-label">
                          Phone Number
                        </label>
                        <div className="col-md-9">
                          <input
                           style={{ background: '#cef0ef' }}
                            type="number"
                            name="partnerNumber"
                            value={formInput.partnerNumber}
                            className="form-control"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className=" row mb-4">
                        <label className="col-md-3 form-label">Address</label>
                        <div className="col-md-9">
                        <input
                         className="form-control col-md-14 mx-auto mb-1"
                         name="partnerAddress"
                         style={{ background: '#cef0ef' }}
                         ref={searchInput}
                         type="text"
                         defaultValue={formInput.partnerAddress}
                         />
                          {/* <input
                           style={{ background: '#cef0ef' }}
                            type="text"
                            name="partnerAddress" 
                            value={formInput.partnerAddress}
                            className="form-control"
                            onChange={handleChange}
                          /> */}
                        </div>
                      </div>
                      <div className="form-label">Service State</div>
                      <div className="custom-controls-stacked mt-3 mb-6 text-center">
                        <div className="d-block">
                          {/* {checkbox1 ? (
                            <label className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                name="checkbox2"
                                className="custom-control-input"
                                readOnly
                                checked
                              />
                              <span className="custom-control-label">NSW</span>
                            </label>
                          ) : ( */}
                          <label className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="checkbox1"
                              className="custom-control-input"
                              // onChange={handleChange}
                              // defaultValue={!isChecked.NSW ? 'NSW' : ''}
                              checked={isChecked.ALL ? true : isChecked.NSW}
                              onChange={() => {
                                setIsChecked((perState) => ({
                                  ...perState,
                                  NSW: !isChecked.NSW,
                                }));
                                isChecked.ALL &&
                                  setIsChecked({
                                    NSW: false,
                                    VIC: true,
                                    QLD: true,
                                    SA: true,
                                    WA: true,
                                    TAS: true,
                                    NT: true,
                                    ACT: true,
                                    ALL: false,
                                  });
                                setCounter(counter + 1);
                              }}
                            />
                            <span className="custom-control-label">NSW</span>
                          </label>
                          {/* )} */}
                          {/* {checkbox2 ? (
                            <label className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                name="checkbox2"
                                className="custom-control-input"
                                readOnly
                                checked
                              />
                              <span className="custom-control-label">VIC</span>
                            </label>
                          ) : ( */}
                          <label className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="checkbox2"
                              className="custom-control-input"
                              // onChange={handleChange}
                              // defaultValue={!isChecked.VIC ? 'VIC' : ''}
                              checked={isChecked.ALL ? true : isChecked.VIC}
                              onChange={() => {
                                setIsChecked((perState) => ({
                                  ...perState,
                                  VIC: !isChecked.VIC,
                                }));
                                isChecked.ALL &&
                                  setIsChecked({
                                    NSW: true,
                                    VIC: false,
                                    QLD: true,
                                    SA: true,
                                    WA: true,
                                    TAS: true,
                                    NT: true,
                                    ACT: true,
                                    ALL: false,
                                  });
                                setCounter(counter + 1);
                              }}
                            />
                            <span className="custom-control-label">VIC</span>
                          </label>
                          {/* )} */}
                        </div>
                        <div className="d-block">
                          {/* {checkbox3 ? (
                            <label className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                name="checkbox2"
                                className="custom-control-input"
                                readOnly
                                checked
                              />
                              <span className="custom-control-label">QLD</span>
                            </label>
                          ) : ( */}
                          <label className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="checkbox3"
                              className="custom-control-input"
                              // onChange={handleChange}
                              // value={!isChecked.QLD ? 'QLD' : ''}
                              checked={isChecked.ALL ? true : isChecked.QLD}
                              onChange={() => {
                                setIsChecked((perState) => ({
                                  ...perState,
                                  QLD: !isChecked.QLD,
                                }));

                                isChecked.ALL &&
                                  setIsChecked({
                                    NSW: true,
                                    VIC: true,
                                    QLD: false,
                                    SA: true,
                                    WA: true,
                                    TAS: true,
                                    NT: true,
                                    ACT: true,
                                    ALL: false,
                                  });
                                setCounter(counter + 1);
                              }}
                            />
                            <span className="custom-control-label">QLD</span>
                          </label>
                          {/* )} */}
                          {/* {checkbox4 ? (
                            <label className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                name="checkbox2"
                                className="custom-control-input"
                                readOnly
                                checked
                              />
                              <span className="custom-control-label">SA</span>
                            </label>
                          ) : ( */}
                          <label className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="checkbox4"
                              className="custom-control-input"
                              // onChange={handleChange}
                              // defaultValue={!isChecked.SA ? 'SA' : ''}
                              checked={isChecked.ALL ? true : isChecked.SA}
                              onChange={() => {
                                setIsChecked((perState) => ({
                                  ...perState,
                                  SA: !isChecked.SA,
                                }));
                                isChecked.ALL &&
                                  setIsChecked({
                                    NSW: true,
                                    VIC: true,
                                    QLD: true,
                                    SA: false,
                                    WA: true,
                                    TAS: true,
                                    NT: true,
                                    ACT: true,
                                    ALL: false,
                                  });
                                setCounter(counter + 1);
                              }}
                            />
                            <span className="custom-control-label">SA</span>
                          </label>
                          {/* )} */}
                        </div>
                        <div className="d-block">
                          {/* {checkbox5 ? (
                            <label className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                name="checkbox2"
                                className="custom-control-input"
                                readOnly
                                checked
                              />
                              <span className="custom-control-label">WA</span>
                            </label>
                          ) : ( */}
                          <label className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="checkbox5"
                              className="custom-control-input"
                              // onChange={handleChange}
                              // defaultValue={!isChecked.WA ? 'WA' : ''}
                              checked={isChecked.ALL ? true : isChecked.WA}
                              onChange={() => {
                                setIsChecked((perState) => ({
                                  ...perState,
                                  WA: !isChecked.WA,
                                }));
                                isChecked.ALL &&
                                  setIsChecked({
                                    NSW: true,
                                    VIC: true,
                                    QLD: true,
                                    SA: true,
                                    WA: false,
                                    TAS: true,
                                    NT: true,
                                    ACT: true,
                                    ALL: false,
                                  });
                                setCounter(counter + 1);
                              }}
                            />
                            <span className="custom-control-label">WA</span>
                          </label>
                          {/* )} */}
                          {/* {checkbox6 ? (
                            <label className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                name="checkbox2"
                                className="custom-control-input"
                                readOnly
                                checked
                              />
                              <span className="custom-control-label">TAS</span>
                            </label>
                          ) : ( */}
                          <label className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="checkbox6"
                              className="custom-control-input"
                              // onChange={handleChange}
                              // defaultValue={!isChecked.TAS ? 'TAS' : ''}
                              checked={isChecked.ALL ? true : isChecked.TAS}
                              onChange={() => {
                                setIsChecked((perState) => ({
                                  ...perState,
                                  TAS: !isChecked.TAS,
                                }));
                                isChecked.ALL &&
                                  setIsChecked({
                                    NSW: true,
                                    VIC: true,
                                    QLD: true,
                                    SA: true,
                                    WA: true,
                                    TAS: false,
                                    NT: true,
                                    ACT: true,
                                    ALL: false,
                                  });
                                setCounter(counter + 1);
                              }}
                            />
                            <span className="custom-control-label">TAS</span>
                          </label>
                          {/* )} */}
                        </div>
                        <div className="d-block">
                          {/* {checkbox7 ? (
                            <label className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                readOnly
                                name="checkbox2"
                                className="custom-control-input"
                                checked
                              />
                              <span className="custom-control-label">NT</span>
                            </label>
                          ) : ( */}
                         
                          <label className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="checkbox9"
                              className="custom-control-input"
                              // onChange={handleChange}
                              // defaultValue={!isChecked.NT ? 'NT' : ''}
                              checked={isChecked.ALL ? true : isChecked.ACT}
                              onChange={() => {
                                setIsChecked((perState) => ({
                                  ...perState,
                                  ACT: !isChecked.ACT,
                                }));
                                isChecked.ALL &&
                                  setIsChecked({
                                    NSW: true,
                                    VIC: true,
                                    QLD: true,
                                    SA: true,
                                    WA: true,
                                    TAS: true,
                                    ACT: false,
                                    NT: true,
                                    ALL: false,
                                  });
                                setCounter(counter + 1);
                              }}
                            />
                            <span className="custom-control-label">ACT</span>
                          </label>
                          <label className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="checkbox7"
                              className="custom-control-input"
                              // onChange={handleChange}
                              // defaultValue={!isChecked.NT ? 'NT' : ''}
                              checked={isChecked.ALL ? true : isChecked.NT}
                              onChange={() => {
                                setIsChecked((perState) => ({
                                  ...perState,
                                  NT: !isChecked.NT,
                                }));
                                isChecked.ALL &&
                                  setIsChecked({
                                    NSW: true,
                                    VIC: true,
                                    QLD: true,
                                    SA: true,
                                    WA: true,
                                    TAS: true,
                                    ACT: true,
                                    NT: false,
                                    ALL: false,
                                  });
                                setCounter(counter + 1);
                              }}
                            />
                            <span className="custom-control-label">NT</span>
                          </label>
                          {/* )} */}
                          {/* {checkbox8 ? (
                            <label className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                name="checkbox2"
                                readOnly
                                className="custom-control-input"
                                checked
                              />
                              <span className="custom-control-label">ALL</span>
                            </label>
                          ) : ( */}
                          <label className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="checkbox8"
                              className="custom-control-input"
                              // onChange={handleChange}
                              // defaultValue={!isChecked.ALL ? 'ALL' : ''}
                              checked={isChecked.ALL ? true : isChecked.ALL}
                              onChange={() => {
                                setIsChecked((perState) => ({
                                  ...perState,
                                  ALL: !isChecked.ALL  , NT: !isChecked.NT,
                                  NSW: !isChecked.ALL  , QLD:!isChecked.NT,
                                  TAS: !isChecked.ALL  , WA: !isChecked.NT,
                                  VIC: !isChecked.ALL  , SA: !isChecked.NT,
                                  ACT: !isChecked.Act  , 
                                }));
                                isChecked.ALL &&
                                  setIsChecked({
                                    NSW: false,
                                    VIC: false,
                                    QLD: false,
                                    SA: false,
                                    WA: false,
                                    TAS: false,
                                    ACT: false,
                                    NT: false,
                                    ALL: false,
                                  });
                                setCounter(counter + 1);
                              }}
                            />
                            <span className="custom-control-label">ALL</span>
                          </label>
                          {/* )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-horizontal">
                      <div className=" row mb-4">
                        <label className="col-md-3 form-label">Category</label>
                        <div className="col-md-9">
                          {a.length === 2 ? (
                            <select
                            style={{ background: '#cef0ef' }}
                              className="form-select"
                              name="partnerCategory"
                              value={formInput.partnerCategory}
                              onChange={handleChange}
                            >
                              <option value="">Select Category</option>
                              <option value="Mortgage Broker">
                                Mortgage Broker
                              </option>
                              <option value="Buyers Advocate">
                                Buyers Advocate
                              </option>
                              <option value="Solicitor">Solicitor</option>
                              <option value="Conveyancer">Conveyancer</option>
                              <option value="Building and Pest Inspection">
                                Building and Pest Inspection
                              </option>
                              <option value="Property Manager">
                                Property Manager
                              </option>
                              <option value="Depreciation Schedule">
                                Depreciation Schedule
                              </option>
                              <option value="Accountant">Accountant</option>
                            </select>
                          ) : (
                            <select
                              className="form-select"
                              name="partnerCategory"
                              value={formInput.partnerCategory}
                              onChange={handleChange}
                            >
                              <option
                                value=""
                                style={{
                                  display: linkData1.partnerCategory
                                    ? 'none'
                                    : '',
                                }}
                              >
                                Select Category
                              </option>
                              <option
                                value="Mortgage Broker"
                                style={{
                                  display:
                                    linkData1.partnerCategory ===
                                    'Mortgage Broker'
                                      ? ''
                                      : 'none',
                                }}
                              >
                                Mortgage Broker
                              </option>
                              <option
                                value="Solicitor"
                                style={{
                                  display:
                                    linkData1.partnerCategory === 'Solicitor'
                                      ? ''
                                      : 'none',
                                }}
                              >
                                Solicitor
                              </option>
                              <option
                                value="Conveyancer"
                                style={{
                                  display:
                                    linkData1.partnerCategory === 'Conveyancer'
                                      ? ''
                                      : 'none',
                                }}
                              >
                                Conveyancer
                              </option>
                              <option
                                value="Building and Pest Inspection"
                                style={{
                                  display:
                                    linkData1.partnerCategory ===
                                    'Building and Pest Inspection'
                                      ? ''
                                      : 'none',
                                }}
                              >
                                Building and Pest Inspection
                              </option>
                              <option
                                value="Property Manager"
                                style={{
                                  display:
                                    linkData1.partnerCategory ===
                                    'Property Manager'
                                      ? ''
                                      : 'none',
                                }}
                              >
                                Property Manager Property Manager
                              </option>
                              <option
                                value="Depreciation Schedule"
                                style={{
                                  display:
                                    linkData1.partnerCategory ===
                                    'Depreciation Schedule'
                                      ? ''
                                      : 'none',
                                }}
                              >
                                Depreciation Schedule
                              </option>
                              <option
                                value="Accountant"
                                style={{
                                  display:
                                    linkData1.partnerCategory === 'Accountant'
                                      ? ''
                                      : 'none',
                                }}
                              >
                                Accountant
                              </option>
                            </select>
                          )}
                        </div>
                      </div>
                      <div
                        className="row mb-4"
                        style={{
                          display:
                            formInput.partnerCategory ===
                            'Depreciation Schedule'
                              ? ''
                              : 'none',
                        }}
                      >
                        <label className="col-md-3 form-label">Fee</label>
                        <div className="col-md-9">
                          <input
                            className="form-control"
                            type="number"
                            name="fee"
                            value={formInput.fee}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>
              </Card.Body>
            </Card>
            <div
              style={{
                width: '100%',
                position: 'absolute',
                bottom: '30px',
              }}
            >
              {!linkData1.partnerName ? (
                <button
                  className="btn btn-success btn-icon text-white"
                  style={{
                    margin: 'auto',
                    padding: '.5rem 2rem',
                    display: 'block',
                    position: 'relative',
                    zIndex: '5',
                  }}
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              ) : (
                <button
                  className="btn btn-success btn-icon text-white"
                  style={{
                    margin: 'auto',
                    padding: '.5rem 2rem',
                    display: 'block',
                    position: 'relative',
                    zIndex: '5',
                  }}
                  type="submit"
                  onClick={handleUpdate}
                >
                  {' '}
                  Update{' '}
                </button>
              )}
            </div>
          </form>
          <div
            style={{
              display: `${
                formInput.partnerCategory == 'Solicitor' ? 'block' : 'none'
              }`,
            }}
          >
            <CategoryCard1
              importFormData={importFormData}
              formInput={formInput}
            />
          </div>
          <div
            style={{
              display: `${
                formInput.partnerCategory == 'Conveyancer' ? 'block' : 'none'
              }`,
            }}
          >
            <CategoryCard1
              importFormData={importFormData}
              formInput={formInput}
            />
          </div>
          <div
            style={{
              display: `${
                formInput.partnerCategory == 'Building and Pest Inspection'
                  ? 'block'
                  : 'none'
              }`,
            }}
          >
            <CategoryCard2
              importFormData={importFormData}
              formInput={formInput}
            />
          </div>
        </div>
      </Row>
      <PartnerHistory partnerData={partnerData} linkData={linkData1} />
    </div>
  );
}

export default AddPartner;
